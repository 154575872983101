import React, { useState } from 'react'
import { SIDEBAR_FILTERS } from '../../constants/sidebar'
import RiverSidebar from './children/sidebar/RiverSidebar'

const RiverSidebarContent = ({
  riverId,
  riverKey,
  riverConfig,
  loading,
  riverStateConfig,
  toggleFilters,
  filtersOpen
}) => {
  const { sidebar = SIDEBAR_FILTERS } = riverConfig

  const isSearchFormPage = riverId === ''
  const isSearchResultsPage = riverKey === 'search'
  const isRiverPage = !isSearchFormPage && !isSearchResultsPage

  const sideBarProps = {
    riverId,
    sidebar,
    loading,
    toggleFilters,
    filtersOpen,
    isRiverPage,
    flex: 'column',
    ...riverStateConfig?.sidebar
  }

  return (
    <div>
      <RiverSidebar {...sideBarProps} />
    </div>
  )
}

export default RiverSidebarContent
