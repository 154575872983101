import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  fetching: true,
  liveFeed: [],
  error: null,
  liveFeedTags: []
}

const liveFeedSlice = createSlice({
  name: 'liveFeed',
  initialState,
  reducers: {
    requestLiveFeed: (state, { payload }) => {
      state.fetching = true
      state.liveFeedTags = payload
    },
    requestLiveFeedSuccess: (state, { payload }) => {
      state.fetching = false
      state.liveFeed = payload
    },
    requestLiveFeedFailed: state => {
      state.fetching = false
      state.liveFeed = []
      state.error = 'failed to get livefeed'
    }
  }
})

export const { actions, reducer } = liveFeedSlice

export const {
  requestLiveFeed,
  requestLiveFeedSuccess,
  requestLiveFeedFailed
} = actions

export default reducer
