import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { tagWithCategory } from 'src/utils/propTypes'
import { Link } from 'react-router-dom'
import LineClamp from 'react-dotdotdot'
import Typography from 'src/lib/ui/Typography'
import Tag from '../Tag'
import FullImage from '../FullImage'
import SaveButton from '../SaveButton'
import Interests from '../Interests'
import { fromNow } from 'src/utils'
import styles from './FeaturedStoryExcerpt.module.sass'
import useComponentSize from '@rehooks/component-size'
import throttle from 'lodash/throttle'
import classNames from 'classnames/bind'
import { requestFWReportTracking } from 'src/features/singleStory/SingleStorySlice'
import { getConfigFilteredTags } from 'src/features/config/ConfigSelector'
import defaultFeaturedImages from 'src/features/river/config/featuredImageMapping'

const cx = classNames.bind(styles)

const FWReport = ({ dispatch, id, children, ...rest }) => (
  <div
    onClick={() => {
      dispatch(requestFWReportTracking(id))
    }}
    {...rest}
  >
    {children}
  </div>
)

FWReport.propTypes = {
  dispatch: PropTypes.func,
  id: PropTypes.any,
  children: PropTypes.node
}

const Article = ({ title, sidebar }) => (
  <Typography
    type="title"
    className={cx(styles.title, { sidebar })}
    gutterBottom
  >
    {title}
  </Typography>
)

Article.propTypes = {
  title: PropTypes.string,
  sidebar: PropTypes.bool
}

const FeaturedStoryExcerpt = ({
  congress,
  title: titleSource = 'Title',
  to = '/',
  published,
  image = null,
  body,
  type,
  plus,
  typeTo = '/',
  saveButton,
  featured,
  tags = [],
  source,
  sponsor,
  verticalAlign = false
}) => {
  const dispatch = useDispatch()
  const ref = useRef(null)
  const throttledComponentSize = throttle(useComponentSize, 250)
  const size = throttledComponentSize(ref)
  const { width } = size
  const sidebar = width < 420
  const title = `${titleSource}${
    congress ? `: Presented at ${congress.join(', ')}` : ''
  }`
  const isReport = type === 'FWReports'

  const filteredTags =
    useSelector(
      getConfigFilteredTags({
        interests: tags
      })
    ) || tags

  const getStoryImage = () => {
    // Return story image
    if (image) return image
    // Return default type image
    if (defaultFeaturedImages[type]) return defaultFeaturedImages[type]
    // Return default
    return defaultFeaturedImages['FirstWord']
  }
  return (
    <article
      ref={ref}
      className={cx(
        featured ? styles.featured : styles.container,
        verticalAlign && styles.verticalAlign
      )}
    >
      <div
        className={cx(
          featured && !image && styles.defaultImage,
          styles.imageContainer
        )}
      >
        <FullImage
          className={styles.image}
          src={getStoryImage()}
          objectFit="cover"
          width="100%"
          height="100%"
        />
      </div>
      <div className={styles.content}>
        {saveButton && (
          <div className={styles.saveButton}>
            <SaveButton />
          </div>
        )}
        <div>
          {type && <Tag to={typeTo} label={type} />}
          {plus && <Tag className={styles.plus} label="PLUS" />}
        </div>
        <header>
          {isReport ? (
            <FWReport
              dispatch={dispatch}
              id={to.split('/')[2]}
              className={styles.fwReportLink}
            >
              <Article title={title} sidebar={sidebar} />
            </FWReport>
          ) : (
            <Link to={to} className={styles.link}>
              <Article title={title} sidebar={sidebar} />
            </Link>
          )}
        </header>
        {body && (
          <LineClamp clamp={3} className={styles.body}>
            <Typography type="body1">{body}</Typography>
          </LineClamp>
        )}
        {source && (
          <LineClamp clamp={3}>
            <p className={styles.source}>{source && `Ref: ${source}`}</p>
          </LineClamp>
        )}
        {!!filteredTags.length && <Interests tags={filteredTags} />}
        {published && (
          <footer>
            <div className={styles.published}>{fromNow(published)}</div>
          </footer>
        )}
        {sponsor && (
          <footer>
            <div className={styles.sponsor}>{sponsor}</div>
          </footer>
        )}
      </div>
    </article>
  )
}
FeaturedStoryExcerpt.propTypes = {
  /**
   * The excerpt title
   */
  title: PropTypes.string,
  /**
   * The excerpt link to full story
   */
  to: PropTypes.string,
  /**
   * The date published
   */
  published: PropTypes.number,
  /**
   * The excerpt image
   */
  image: PropTypes.string,
  /**
   * The excerpt body text
   */
  body: PropTypes.string,
  /**
   * The excerpt "type" tag
   */
  type: PropTypes.string,
  /**
   * If `true`, this is marked as a "plus" excerpt
   */
  plus: PropTypes.bool,
  /**
   * The link for the "type" tag
   */
  typeTo: PropTypes.string,
  /**
   * If `true`, this excerpt displays a "save" button
   */
  saveButton: PropTypes.bool,
  /**
   * The excerpt displays "featured" styling
   */
  featured: PropTypes.bool,
  /**
   * The array of tags to be displayed for this excerpt
   */
  tags: PropTypes.arrayOf(tagWithCategory),
  /**
   * The source to be displayed
   */
  source: PropTypes.string,

  /**
   * The sponsor to be displayed
   */
  sponsor: PropTypes.string,
  /**
   * The excerpt displays "vertical" styling
   */
  verticalAlign: PropTypes.bool,
  /**
   * congress list
   */
  congress: PropTypes.array
}

export default FeaturedStoryExcerpt
