import { useEffect } from 'react'
import { useServices } from 'src/services/ServiceContext'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from 'src/features/singleStory/SingleStorySlice'
import { push, replace } from 'connected-react-router'
import { notificationsEnqueue } from 'src/features/notifications/NotificationsSlice'
import { logPush } from 'src/features/contactUs/ContactUsSlice'
import { setLastArticleId } from 'src/features/benefits/BenefitsSlice'
import FW5MLError from 'src/services/FW5ML/FW5MLError'
import { ANON_MAX_ARTICLES, ANON_MAX_ARTICLES_EXPIRE_DAYS } from 'src/constants'
import { getAnonArticleLimitSettings } from 'src/features/config/ConfigSelector'
import { actions as paywallActions } from 'src/features/paywall/PaywallSlice'
import { useLazyGetSingleStoryQuery } from 'src/api/endpoints/singleStoryEndpoints'
import { handleNetworkError } from 'src/api/helpers'
import FW5Error, { ANON_ARTICLE_LIMIT_REACHED } from 'src/api/errors/FW5Error'
import { siteName } from 'src/utils/oneCodeBase'
import sharedFunctions from './sharedFunctions'
import { licenseTypes } from 'src/services/FW5ML/parsers/UserParser'

import {
  NOT_ALLOWED,
  SERVER_ERROR,
  ACCESS_DENIED,
  ARTICLE_NOT_PUBLISHED
} from 'src/services/FW5ML/errorCodes'

const useSingleStoryRequest = ({ id }) => {
  const { Session, StoryParser, queryString, Piwik, UserRepository } =
    useServices([
      'Session',
      'queryString',
      'StoryParser',
      'Piwik',
      'UserRepository'
    ])

  const [getSingleStoryQuery] = useLazyGetSingleStoryQuery()
  const { notifyMeSubscribe } = sharedFunctions()

  const location = useSelector(state => state.router.location)
  const anonArticleLimitSettings = useSelector(getAnonArticleLimitSettings())
  const storyState = useSelector(state => state.singleStoryReducer)

  const paywallVisible = useSelector(
    state => state?.paywallReducer?.paywallVisible
  )

  const dispatch = useDispatch()

  const isSearchApiFlow = async () => {
    if (!queryString.get('sapi')) return false
    const cookie = 'search_api_cookie'

    if (Session.getCookie(cookie)) {
      Session.removeCookie(cookie)
    }

    const user = await Session.userLoad()

    if (!user) {
      Session.setCookie('search_api_cookie', location.pathname)
      dispatch(replace('/sign-in'))
      return true
    }

    return false
  }

  const limitAnonViews = async (id, article) => {
    if (anonArticleLimitSettings?.hideArticleLimit) {
      return
    }

    let viewedArticles = Session.getCookie('viewedArticles')

    if (!viewedArticles) {
      viewedArticles = {
        articles: [],
        created: Date.now()
      }
    }

    const { articles, created } = viewedArticles
    const {
      anonArticleLimit = ANON_MAX_ARTICLES,
      anonArticleLimitExpiry = ANON_MAX_ARTICLES_EXPIRE_DAYS
    } = anonArticleLimitSettings

    if (articles.length + 1 > anonArticleLimit) {
      dispatch(
        paywallActions.updatePaywallVisible({
          visible: true,
          type: 'free_only',
          blurArticle: true,
          subtype: 'article_limit'
        })
      )
      article.body = article.teaser
      return
    }

    dispatch(
      paywallActions.updatePaywallVisible({
        visible: true,
        type: 'free_only',
        subtype: 'article_counter'
      })
    )
    const updatedArticleArray = [...articles, id]
    const updatedArticles = {
      articles: [...new Set(updatedArticleArray)],
      created
    }

    Session.setCookie('viewedArticles', updatedArticles, {
      expires: anonArticleLimitExpiry
    })
  }

  const handlePlusOnlyArticles = async (license = '') => {
    const VALID_LICENSES = [licenseTypes.LICENSE_PLUS]

    if (!VALID_LICENSES.includes(license)) {
      dispatch(
        paywallActions.updatePaywallVisible({
          visible: true,
          type: 'plus_only',
          blurArticle: true,
          subtype: 'plus_article'
        })
      )
    }
  }

  const paywallAction = async (id, article, user) => {
    const PLUS_ONLY_ARTICLES = ['Conference Dispatch']

    // Handles anonymous and free users since they don't have plus license
    if (PLUS_ONLY_ARTICLES.includes(article.type)) {
      await handlePlusOnlyArticles(user.license)
      return
    }

    if (!user) {
      await limitAnonViews(id, article)
    }
  }

  const popAnonView = id => {
    let viewedArticles = Session.getCookie('viewedArticles')
    if (!viewedArticles) {
      viewedArticles = {
        articles: [],
        created: Date.now()
      }
    }
    const { articles, created } = viewedArticles
    const updatedArticles = {
      created,
      articles: articles.filter(article => article !== id)
    }
    Session.setCookie('viewedArticles', updatedArticles)
  }

  const trackSingleStory = id => {
    const nlEventId = queryString.get('nle')
    const nlCampaignId = queryString.get('nlc')
    const nlPosition = queryString.get('nlp')

    // the tracking action by default is the ID of the NL
    let nlAction = id

    if (nlEventId) {
      let newQS = queryString.removeKey('nle')
      Piwik.updateCustomVariable('event_id', nlEventId)
      if (nlCampaignId) {
        newQS = queryString.removeKey('nlc', newQS)
        Piwik.updateCustomVariable('project_id', nlCampaignId)
      }
      if (nlPosition) {
        newQS = queryString.removeKey('nlp', newQS)
        if (siteName === 'Reports') {
          nlAction = nlPosition
        }
      }
      dispatch(replace({ search: newQS }))
      Piwik.updateCustomVariable('article_id', id)
      Piwik.track('newsletter', 'article-click', nlAction)
    }

    Piwik.updateCustomVariable('article_id', id)
    const actionType = paywallVisible ? 'paywall' : 'article'
    Piwik.track('page', 'view', actionType)
  }

  const requestSingleStory = async id => {
    dispatch(actions.requestSingleStory(id))

    try {
      if (await isSearchApiFlow()) {
        return
      }

      const { data, isError, error } = await getSingleStoryQuery(id)
      const user = await Session.userLoad()

      handleNetworkError({ isError, error })

      const article = await StoryParser.normalizeArticle(data?.data)

      if (article.type === 'Sponsored Story') {
        window.location.replace(article.url)
        return
      }

      await paywallAction(id, article, user)

      await notifyMeSubscribe()
      await dispatch(actions.successSingleStory(article))
    } catch (e) {
      console.log(e)
      if (e instanceof FW5MLError) {
        const code = e.getCode()
        if (code !== ANON_ARTICLE_LIMIT_REACHED) {
          popAnonView(id)
        }
        if (code === NOT_ALLOWED || code === ACCESS_DENIED) {
          dispatch(
            paywallActions.updatePaywallVisible({
              visible: true,
              type: 'plus_only',
              blurArticle: true,
              subtype: 'plus_article'
            })
          )
        }
        if (code === ARTICLE_NOT_PUBLISHED) {
          dispatch(
            notificationsEnqueue({
              message: 'The article you are trying to access is not published.'
            })
          )
          return
        }
        if (code === SERVER_ERROR) {
          dispatch(
            logPush(
              'Single Story',
              `Error while loading article ${id}: ${e.message}`
            )
          )
          dispatch(
            notificationsEnqueue({
              message:
                'Unable to load article. Please refresh the page and try again.'
            })
          )
          Piwik.track('notification', 'error', 'could-not-request-single-story')
          dispatch(push('/contact-us'))
        }
      }
      if (e instanceof FW5Error) {
        const code = e.getCode()
        if (code === ANON_ARTICLE_LIMIT_REACHED) {
          dispatch(setLastArticleId(id))
          dispatch(
            paywallActions.updatePaywallVisible({
              visible: true,
              type: 'free_only',
              blurArticle: true,
              subtype: 'article_limit'
            })
          )
        }
      }
      await dispatch(actions.errorSingleStory(e.message))
    }
  }

  useEffect(() => {
    // Track once the article has finished loading
    const storyLoaded = !!(
      storyState?.loading === false &&
      (storyState?.story?.id === id || storyState?.error)
    )
    if (storyLoaded) {
      trackSingleStory(id)
    }
  }, [storyState?.loading])

  return {
    requestSingleStory
  }
}

export default useSingleStoryRequest
