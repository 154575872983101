import { useSelector } from 'react-redux'
import RelatedAnalysis from './children/RelatedAnalysis'
import ReadMoreTags from './children/ReadMoreTags'
import AllNews from './children/AllNews'
import ReportRelatedUpdates from './children/reports/ReportRelatedUpdates'
import NotifyUpdates from './children/reports/NotifyUpdates'
import { siteName } from 'src/utils/oneCodeBase'
import Ad from 'src/features/ads/components/Ad'
import ShowForMediaSize from 'src/components/Responsive/ShowForMediaSize'
import styles from 'src/features/singleStory/SingleStory.module.sass'

const ReportsSingleStorySideBarContent = () => {
  return (
    <>
      <ReportRelatedUpdates />
      <NotifyUpdates />
      <ReadMoreTags />
    </>
  )
}

const SingleStorySideBarContent = () => {
  const singleStoryRectangleAd1 = useSelector(
    state => state?.adsReducer.singleStoryRectangle1 || null
  )
  const singleStoryRectangleAd2 = useSelector(
    state => state?.adsReducer.singleStoryRectangle2 || null
  )

  const singleStoryRectangleAd3 = useSelector(
    state => state?.adsReducer.singleStoryRectangle3 || null
  )

  return (
    <>
      {singleStoryRectangleAd1 && (
        <ShowForMediaSize showFor={['large']}>
          <Ad
            {...singleStoryRectangleAd1}
            className={styles.singleStoryRectangleAd}
          />
        </ShowForMediaSize>
      )}

      <RelatedAnalysis />
      <ReadMoreTags />

      {singleStoryRectangleAd2 && (
        <ShowForMediaSize showFor={['large']}>
          <Ad
            {...singleStoryRectangleAd2}
            className={styles.singleStoryRectangleAd}
          />
        </ShowForMediaSize>
      )}

      <AllNews />

      {singleStoryRectangleAd3 && (
        <ShowForMediaSize showFor={['large']}>
          <Ad
            {...singleStoryRectangleAd3}
            className={styles.singleStoryRectangleAd}
          />
        </ShowForMediaSize>
      )}
    </>
  )
}

const mapping = {
  Reports: ReportsSingleStorySideBarContent,
  default: SingleStorySideBarContent
}

export default mapping[siteName] || mapping.default
