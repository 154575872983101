import FW5MLError from 'src/services/FW5ML/FW5MLError'

export const handleNetworkError = ({ isError, error }) => {
  if (isError) {
    if (error?.data) {
      const requestError = error.data.error
      const errorMessage = requestError?.message || requestError?.msg
      throw new FW5MLError(errorMessage, requestError?.code)
    } else {
      throw new Error(
        'Something went wrong! Please refresh the page and try again.'
      )
    }
  }
}
