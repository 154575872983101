import React from 'react'
import PropTypes from 'prop-types'
import Typography from 'src/lib/ui/Typography'
import SocialButtons from 'src/components/SocialButtons'
import { CustomizableModal } from 'src/components/CustomizableModal'
import { siteName } from 'src/utils/oneCodeBase'
import styles from './ShareModal.module.sass'

const ShareModal = ({
  articleTitle = '',
  modalOpen = false,
  modalInfo = {},
  onClose = () => {},
  handleSocialIconClick = () => {},
  handleSiteModalOpen = () => {}
}) => {
  const { header_text = '', invite_button = '', subtitle = '' } = modalInfo
  return (
    <CustomizableModal
      open={modalOpen}
      onClose={onClose}
      modalStyles={{ zIndex: '80' }}
      modalContentStyles={{ padding: '20px 30px' }}
      width="500px"
      height="470px"
    >
      <div>
        <div className={styles.header}>
          <Typography
            type="title"
            gutterBottom
            paragraph
            className={styles.title}
          >
            {header_text}
          </Typography>
        </div>

        <section style={{ marginTop: '20px' }}>
          <div>
            <Typography
              type="title"
              gutterBottom
              paragraph
              style={{ margin: '5px 0' }}
            >
              {articleTitle}
            </Typography>

            <Typography className={styles.paragraph}>{subtitle}</Typography>
            <div className={styles.socialButtonsContainer}>
              <SocialButtons
                addEmail={true}
                align="between"
                order="reverse"
                grayIcons={true}
                twitterText="X"
                linkedinText="LinkedIn"
                emailText="Email"
                linkText="URL"
                onClick={handleSocialIconClick}
              />
            </div>

            <div className={styles.divider}>
              <Typography type="body1" className={styles.diviverText}>
                OR
              </Typography>
            </div>
          </div>

          <div style={{ marginTop: '20px' }}>
            <Typography
              type="title"
              gutterBottom
              paragraph
              style={{ textAlign: 'center', margin: '20px 0' }}
            >
              Invite a Colleague to FirstWord {siteName}
            </Typography>
          </div>

          <div className={styles.buttonContainer}>
            <button
              onClick={handleSiteModalOpen}
              className={styles.inviteButton}
            >
              {invite_button}
            </button>
          </div>
        </section>
      </div>
    </CustomizableModal>
  )
}

ShareModal.propTypes = {
  articleTitle: PropTypes.string,
  modalInfo: PropTypes.object,
  modalOpen: PropTypes.bool,
  onClose: PropTypes.func,
  handleSocialIconClick: PropTypes.func,
  handleSiteModalOpen: PropTypes.func
}

export default ShareModal
