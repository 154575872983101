import { createSlice } from '@reduxjs/toolkit'
import _mergeWith from 'lodash/mergeWith'
import _uniq from 'lodash/uniq'

export const buildRiverObject = ({
  results = [],
  batchCount = 0,
  count = 0,
  page = 0,
  aggs = {},
  filter = {},
  filtersModified = false,
  sponsoredHeadlines = [],
  ignoreOnMount = false,
  loading = false,
  loadingMore = false,
  showMedicalAbstracts,
  featuredResults = [],
  loadingFeaturedContent = false,
  excludeIds = [],
  tagFound = false,
  tagForCategory = {}
} = {}) => ({
  loading,
  loadingMore,
  results,
  batchCount,
  count,
  page,
  aggs,
  filter,
  filtersModified,
  sponsoredHeadlines,
  showMedicalAbstracts,
  ignoreOnMount, // Skip componentDidMount triggers
  featuredResults,
  loadingFeaturedContent,
  excludeIds,
  tagFound,
  tagOptions: [],
  loadingTags: false,
  tagForCategory
})

const initialState = {
  myfwTabValue: 'all'
}

const riverSlice = createSlice({
  name: 'contentRiverReducer',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      const { loading, riverId } = payload
      if (!state[riverId]) {
        state[riverId] = {}
      }
      state[riverId].loading = loading
    },
    setSearch: (state, { payload }) => {
      const {
        riverId,
        searchBody,
        isSavedSearch = false,
        showMedicalAbstracts
      } = payload
      state.isSavedSearch = isSavedSearch

      const previousFilters = state[riverId]?.filter?.filters || {}
      const previousTags = state[riverId]?.tagForCategory || {}

      state[riverId] = {
        ...buildRiverObject({
          filter: {
            ...searchBody,
            filters: { ...previousFilters, ...searchBody.filters },
            from: 0
          },
          tagForCategory: previousTags,
          loading: true,
          showMedicalAbstracts
        })
      }
    },
    mergeSearch: (state, { payload }) => {
      const { riverId, searchBody, replaceFiltersCategory = '' } = payload

      if (!state[riverId]) {
        state[riverId] = {}
      }

      let filter = _mergeWith(
        {},
        state[riverId].filter,
        searchBody,
        (obj, src, key) => {
          if (key === 'publication_date') return src
          if (Array.isArray(obj)) return _uniq(obj.concat(src))
        }
      )
      if (filter.filters[replaceFiltersCategory]) {
        filter.filters[replaceFiltersCategory] =
          searchBody.filters[replaceFiltersCategory]
      }
      delete filter.from
      state[riverId].loading = true
      state[riverId].filter = filter
    },
    removeSearch: (state, { payload }) => {
      const { riverId, searchBody } = payload

      if (!state[riverId]) {
        state[riverId] = {}
      }

      const currentFilter = state[riverId].filter || {}
      let filter = {}
      const removedKeys = Object.keys(searchBody)
      for (let key in currentFilter) {
        if (!removedKeys.includes(key)) {
          filter[key] = currentFilter[key]
        }
      }
      delete filter.from
      state[riverId].loading = true
      state[riverId].filter = filter
      state[riverId].batchCount = 0
    },
    requestSearch: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'request',
          asyncKey: 'contentRiver'
        }
      }),
      reducer: () => {}
    },
    successSearch: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'success',
          asyncKey: 'contentRiver'
        }
      }),
      reducer: (state, { payload }) => {
        const { riverId, data, skipAggs = false } = payload
        const { results = [], count = 0, aggs = {}, tagFound = false } = data

        if (!state[riverId]) {
          state[riverId] = {}
        }

        const finalAggs = skipAggs ? state[riverId].aggs : aggs
        state.isSavedSearch = false
        state[riverId].loading = false
        state[riverId].loadingMore = false
        state[riverId].results = results
        state[riverId].batchCount = results.length
        state[riverId].count = count
        state[riverId].ignoreOnMount = false
        state[riverId].tagFound = tagFound
        state[riverId].aggs = finalAggs
      }
    },
    errorSearch: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'error',
          asyncKey: 'contentRiver'
        }
      }),
      reducer: () => {}
    },
    clearSearch: () => {
      state = initialState
    },
    requestLoadMore: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'request',
          asyncKey: 'contentRiverLoadMore'
        }
      }),
      reducer: (state, { payload }) => {
        const { riverId } = payload

        if (!state[riverId]) {
          state[riverId] = {}
        }

        const from = (state[riverId]?.filter?.from || 0) + 10
        const filter = { ...state[riverId].filter, from }
        state[riverId].loadingMore = true
        state[riverId].filter = filter
      }
    },
    successLoadMore: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'success',
          asyncKey: 'contentRiverLoadMore'
        }
      }),
      reducer: (state, { payload }) => {
        const { riverId, data } = payload
        const { results = [] } = data

        if (!state[riverId]) {
          state[riverId] = {}
        }

        const from = state[riverId]?.filter?.from || 10
        state[riverId].loadingMore = false
        state[riverId].results = [...state[riverId].results, ...results]
        state[riverId].batchCount = results.length
        state[riverId].page = from / 10
      }
    },
    errorLoadMore: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'error',
          asyncKey: 'contentRiverLoadMore'
        }
      }),
      reducer: () => {}
    },
    removeFilterWithoutSearch: (state, { payload }) => {
      const {
        riverId,
        filter: { field: fieldToRemove, value: valueToRemove },
        defaultFilters = { filters: {} }
      } = payload

      if (!state[riverId]) {
        state[riverId] = {}
      }

      const { filters: currentFilters } = state[riverId].filter || {}

      // Ensure a new object reference to trigger React updates
      const updatedFilters = { ...currentFilters }

      if (updatedFilters[fieldToRemove]) {
        updatedFilters[fieldToRemove] = updatedFilters[fieldToRemove].filter(
          val => val !== valueToRemove
        )

        // If empty, remove category
        if (updatedFilters[fieldToRemove].length === 0) {
          delete updatedFilters[fieldToRemove]
        }
      }

      state[riverId] = {
        ...state[riverId],
        filter: {
          ...state[riverId].filter,
          filters: { ...updatedFilters, ...defaultFilters.filters }
        }
      }
    },
    removeFilter: (state, { payload }) => {
      const {
        riverId,
        filter: { field: fieldToRemove, value: valueToRemove },
        defaultFilters = { filters: {} }
      } = payload

      if (!state[riverId]) {
        state[riverId] = {}
      }

      const { filters: currentFilters } = state[riverId].filter
      const updatedFilters = Object.keys(currentFilters).reduce((acc, key) => {
        const value = currentFilters[key]
        if (fieldToRemove !== key) return { ...acc, [key]: value }
        if (Array.isArray(value) && value.indexOf(valueToRemove) > -1) {
          return {
            ...acc,
            [key]: value.filter(val => val !== valueToRemove)
          }
        } else if (typeof value === 'string' && value === valueToRemove) {
          return { ...acc }
        }
        return { ...acc, [key]: value }
      }, {})
      delete updatedFilters.from
      let newFilters = {
        ...state[riverId].filter,
        filters: {
          ...updatedFilters,
          ...defaultFilters.filters
        },
        from: 0
      }
      state[riverId].filter = newFilters
    },
    setSorting: (state, { payload }) => {
      const { riverId, sort } = payload

      if (!state[riverId]) {
        state[riverId] = {}
      }

      state[riverId].page = 0
      state[riverId].from = 0
      state[riverId].filter.sort = sort
      state[riverId].filter.from = 0
    },
    appendSponsoredHeadlines: (state, { payload }) => {
      const { riverId, sponsoredHeadlines } = payload

      if (!state[riverId]) {
        state[riverId] = {}
      }

      state[riverId].sponsoredHeadlines = [
        ...(state[riverId]?.sponsoredHeadlines
          ? state[riverId].sponsoredHeadlines
          : []),
        ...sponsoredHeadlines
      ]
    },
    setMedicalAbstracts: (state, { payload: { riverId, value } }) => {
      if (!state[riverId]) {
        state[riverId] = {}
      }
      state[riverId].showMedicalAbstracts = value
    },
    setIgnoreOnMount: (state, { payload: ignoreOnMount }) => {
      state.ignoreOnMount = ignoreOnMount
    },
    setMyfwTab: (state, { payload: myfwTabValue }) => {
      state.myfwTabValue = myfwTabValue
    },
    requestFeaturedContent: (state, { payload }) => {
      const { riverId, loading = true } = payload

      if (!state[riverId]) {
        state[riverId] = {}
      }

      state[riverId].loadingFeaturedContent = loading
    },
    successFeaturedContent: (state, { payload }) => {
      const { riverId, data, excludeIds } = payload
      const { results = [] } = data

      if (!state[riverId]) {
        state[riverId] = {}
      }

      state[riverId].loadingFeaturedContent = false
      state[riverId].featuredResults = results
      state[riverId].excludeIds = excludeIds
    },
    errorFeaturedContent: () => {},
    setTagsLoading: (state, { payload }) => {
      const { loading, riverId, category } = payload

      if (!state[riverId]) {
        state[riverId] = {}
      }

      if (!state[riverId]?.tagForCategory[category]) {
        state[riverId].tagForCategory[category] = {}
      }

      state[riverId].tagForCategory[category].loadingTags = loading
    },
    successTagsSearch: (state, { payload }) => {
      const { riverId, data = [], category = '' } = payload
      state.isSavedSearch = false

      if (!state[riverId]) {
        state[riverId] = {}
      }

      if (!state[riverId]?.tagForCategory[category]) {
        state[riverId].tagForCategory[category] = {}
      }

      state[riverId].tagForCategory[category].loadingTags = false
      state[riverId].tagForCategory[category].tagOptions = data
    },
    errorTagsSearch: (state, { payload }) => {
      const { category, riverId } = payload

      if (!state[riverId]) {
        state[riverId] = {}
      }

      if (!state[riverId]?.tagForCategory[category]) {
        state[riverId].tagForCategory[category] = {}
      }

      state[riverId].tagForCategory[category].loadingTags = false
    },
    cleanTagsSearch: (state, { payload }) => {
      const { category, riverId } = payload

      if (!state[riverId]) {
        state[riverId] = {}
      }

      if (!state[riverId]?.tagForCategory[category]) {
        state[riverId].tagForCategory[category] = {}
      }

      state[riverId].tagForCategory[category].tagOptions = []
    },
    addSelectedTag: (state, { payload }) => {
      const { option, riverId, category, singleSelect } = payload

      if (!option || !option.value || !riverId || !category) return

      if (!state[riverId]) {
        state[riverId] = { tagForCategory: {} }
      }

      if (!state[riverId].tagForCategory) {
        state[riverId].tagForCategory = {}
      }

      if (!state[riverId]?.tagForCategory[category]) {
        state[riverId].tagForCategory[category] = { selectedTags: [] }
      }

      const newTag = { ...option, selected: true }
      let newSelectedTagsState = []

      if (singleSelect || category === 'type') {
        newSelectedTagsState = [newTag]
      } else {
        const alreadySelectedTags =
          state[riverId]?.tagForCategory[category]?.selectedTags ?? []
        const isOptionAlreadySelected = alreadySelectedTags.some(
          opt => opt.value === newTag.value
        )

        if (!isOptionAlreadySelected) {
          newSelectedTagsState = [...alreadySelectedTags, newTag]
        } else {
          newSelectedTagsState = [...alreadySelectedTags]
        }
      }

      state[riverId].tagForCategory[category].selectedTags =
        newSelectedTagsState

      const updatedFilters = new URLSearchParams(window.location.search)
      let currentValues = updatedFilters.get(category)?.split(',') || []

      const encodedValue = newTag.value.includes(',')
        ? `"${newTag.value}"`
        : newTag.value

      if (
        category === 'type' ||
        category === 'fw_report_therapy_areas' ||
        category === 'fw_report_disease_areas'
      ) {
        updatedFilters.set(category, encodedValue)
      } else {
        if (!currentValues.includes(encodedValue)) {
          currentValues.push(encodedValue)
          updatedFilters.set(category, currentValues.join(','))
        }
      }
      state[riverId].filtersModified = true
      window.history.replaceState(null, '', `?${updatedFilters.toString()}`)
    },
    removeSelectedTag: (state, { payload }) => {
      const { option: newTag, riverId, category } = payload

      if (!state[riverId]) {
        state[riverId] = {}
      }

      if (!state[riverId]?.tagForCategory[category]) {
        state[riverId].tagForCategory[category] = {}
      }

      const alreadySelectedTags =
        state[riverId]?.tagForCategory[category]?.selectedTags ?? []

      const cleanedTagValue = decodeURIComponent(newTag.value)
        .trim()
        .replace(/\s+/g, ' ')

      const filteredTags = alreadySelectedTags.filter(
        tag => tag.value.trim().replace(/\s+/g, ' ') !== cleanedTagValue
      )
      state[riverId].tagForCategory[category].selectedTags = filteredTags

      const updatedFilters = new URLSearchParams(window.location.search)
      let rawValues = decodeURIComponent(updatedFilters.get(category) ?? '')

      let currentValues =
        rawValues
          .match(/"[^"]+"|[^,]+/g)
          ?.map(v => v.replace(/"/g, '').trim()) || []
      currentValues = currentValues.filter(
        value => value.replace(/\s+/g, ' ') !== cleanedTagValue
      )

      if (currentValues.length > 0) {
        const encodedValues = currentValues.map(v =>
          v.includes(',') ? `"${v}"` : v
        )
        updatedFilters.set(category, encodedValues.join(','))
      } else {
        updatedFilters.delete(category)
      }

      state[riverId].filtersModified = true
      window.history.replaceState(null, '', `?${updatedFilters.toString()}`)
    },
    loadFiltersFromUrl: (state, { payload: riverId }) => {
      if (!state[riverId]) {
        state[riverId] = { tagForCategory: {}, filter: {} }
      }

      const urlParams = new URLSearchParams(window.location.search)

      urlParams.forEach((encodedValue, key) => {
        if (!state[riverId].tagForCategory[key]) {
          state[riverId].tagForCategory[key] = { selectedTags: [] }
        }

        const decodedValue = decodeURIComponent(encodedValue)

        // ✅ Preserve values wrapped in quotes (don't split them)
        const values =
          decodedValue
            .match(/"[^"]+"|[^,]+/g)
            ?.map(v => v.replace(/"/g, '').trim()) || []
        state[riverId].tagForCategory[key].selectedTags = values.map(
          (val, index) => ({
            value: val,
            selected: true,
            field: key,
            position: index
          })
        )
      })
    },
    clearSearchFilters: (state, { payload: { riverId } }) => {
      state[riverId] = {
        ...state[riverId],
        filter: {},
        tagForCategory: {}
      }
    },
    clearAllFilters: state => {
      Object.keys(state).forEach(riverId => {
        if (state[riverId]?.filter) {
          state[riverId] = {
            ...state[riverId],
            filter: {},
            tagForCategory: {}
          }
        }
      })
    },
    requestTagsSearch: () => {},
    loadContentRiver: () => {},
    postErrorSearch: () => {},
    addFilter: () => {},
    selectFilter: () => {},
    replaySearch: () => {},
    redirectSearch: () => {},
    redirectSearchFromSaved: () => {}
  }
})

export const { actions, reducer } = riverSlice

export const {
  setLoading,
  setTagsLoading,
  setSearch,
  mergeSearch,
  removeSearch,
  requestSearch,
  successSearch,
  errorSearch,
  clearSearch,
  loadContentRiver,
  removeFilter,
  setSorting,
  requestLoadMore,
  successLoadMore,
  appendSponsoredHeadlines,
  setIgnoreOnMount,
  replaySearch,
  redirectSearch,
  redirectSearchFromSaved,
  addFilter,
  selectFilter,
  postErrorSearch,
  setMyfwTab,
  setMedicalAbstracts,
  requestFeaturedContent,
  successFeaturedContent,
  errorFeaturedContent,
  requestTagsSearch,
  successTagsSearch,
  errorTagsSearch,
  cleanTagsSearch,
  removeFilterWithoutSearch,
  addSelectedTag,
  removeSelectedTag,
  errorLoadMore,
  loadFiltersFromUrl,
  clearSearchFilters,
  clearAllFilters
} = actions

export default reducer
