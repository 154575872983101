import { useSelector } from 'react-redux'
import Story from './children/Story'
import ReferenceArticle from './children/ReferenceArticle'
import RelatedNews from './children/RelatedNews'
import { siteName } from 'src/utils/oneCodeBase'
import Ad from 'src/features/ads/components/Ad'
import ShowForMediaSize from 'src/components/Responsive/ShowForMediaSize'
import styles from 'src/features/singleStory/SingleStory.module.sass'

const ReportsSingleStoryMainContent = ({ id }) => {
  return <Story id={id} isLimited={true} />
}

const SingleStoryMainContent = ({ id }) => {
  const singleStoryRectangleAd1 = useSelector(
    state => state?.adsReducer.singleStoryRectangle1 || null
  )
  const singleStoryRectangleAd3 = useSelector(
    state => state?.adsReducer.singleStoryRectangle3 || null
  )

  return (
    <>
      <Story id={id} isLimited={true} />

      {singleStoryRectangleAd1 && (
        <ShowForMediaSize showFor={['small', 'medium']}>
          <Ad
            {...singleStoryRectangleAd1}
            className={styles.singleStoryRectangleAd}
          />
        </ShowForMediaSize>
      )}

      <ReferenceArticle />
      <RelatedNews id={id} />

      {singleStoryRectangleAd3 && (
        <ShowForMediaSize showFor={['small', 'medium']}>
          <Ad
            {...singleStoryRectangleAd3}
            className={styles.singleStoryRectangleAd}
          />
        </ShowForMediaSize>
      )}
    </>
  )
}

const mapping = {
  Reports: ReportsSingleStoryMainContent,
  default: SingleStoryMainContent
}

export default mapping[siteName] || mapping.default
