import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Typography from 'src/lib/ui/Typography'
import RegisterForm from '../RegisterForm/RegisterFormContainer'
import styles from './RegisterPage.module.sass'
import { Link, useHistory } from 'react-router-dom'
import { historyHandler } from 'src/utils'
import RegisterEmailSent from 'src/features/static/components/RegisterEmailSentPage'
import ReactHtmlParser from 'react-html-parser'
import cx from 'classnames'
import { getConfig } from 'src/features/config/ConfigSelector'
import FullImage from 'src/components/FullImage'
import usePiwik from 'src/hooks/usePiwik'

const RegisterPage = ({
  handleLoginLinkFlow = null,
  parentClassName = '',
  landingPageCompany = null,
  redirectEmailSent = true,
  config = {},
  ...props
}) => {
  const { handleTracking: piwikHandleTracking } = usePiwik()
  const webpageContent = useSelector(getConfig('webpage_content'))
  const registerConfig = webpageContent?.register || {}
  const finalConfig = { ...registerConfig, ...config }
  const {
    description = null,
    display = 'single_column',
    disclaimer = 'By creating a FirstWord account, you agree to our Terms of Service and Privacy Policy. You may also receive promotional emails from FirstWord. You can opt out at any time.',
    login = {
      text: 'Already have a FirstWord account?',
      linkText: 'Sign in'
    },
    form = {},
    header = null,
    logo = null
  } = finalConfig

  const newDisplay = landingPageCompany ? 'single_column' : display

  const history = useHistory()
  const [showEmailSent, setShowEmailSent] = useState(false)

  const onSuccess = values => {
    handleTracking('button', 'click', 'register')

    const { requestUserRegistration } = props
    requestUserRegistration(values)

    if (redirectEmailSent !== undefined && redirectEmailSent === false) {
      return setShowEmailSent(true)
    }
    return historyHandler('/register-email-sent', '', history, 'push')
  }

  const handleTracking = (category, action, name) => {
    // Only track if it has a landing page
    if (landingPageCompany) {
      const { Piwik } = props.services
      Piwik.track(category, action, name)
    }
  }

  const handleLoginLink = e => {
    e.preventDefault()
    piwikHandleTracking('register-page', 'text-link-click', 'sign-in')
    if (handleLoginLinkFlow) {
      return handleLoginLinkFlow()
    }
    return historyHandler('/sign-in', '', history, 'push')
  }

  return (
    <>
      {showEmailSent && (
        <RegisterEmailSent parentClassName={styles.registerEmailSent} />
      )}
      {!showEmailSent && (
        <div
          className={cx(
            newDisplay === 'double_column' && styles.doubleColumnContainer,
            parentClassName && styles[parentClassName]
          )}
        >
          <div
            className={cx(
              styles.content,
              newDisplay === 'double_column'
                ? styles.contentDoubleLayout
                : styles.contentSingleLayout
            )}
          >
            {logo?.src && (
              <FullImage
                src={logo.src}
                alt={logo.label}
                className={styles.logo}
              />
            )}
            {description && (
              <div className={styles.description}>
                {ReactHtmlParser(description)}
              </div>
            )}
          </div>
          <div className={styles.form}>
            {(landingPageCompany || header) && (
              <Typography type="headline" gutterBottom align="left">
                {landingPageCompany
                  ? 'Register To Access This Content'
                  : header}
              </Typography>
            )}
            {newDisplay === 'single_column' && (
              <Typography
                type="body1"
                align="left"
                className={styles.info}
                style={{ width: '100%' }}
              >
                {login.text}{' '}
                <Link to="" className={styles.link} onClick={handleLoginLink}>
                  {login.linkText}
                </Link>
              </Typography>
            )}

            <RegisterForm
              handleLoginLink={handleLoginLink}
              login={login}
              newDisplay={newDisplay}
              onSuccess={onSuccess}
              busy={props.busy}
              parentServices={props.services}
              fromLanding={Boolean(landingPageCompany)}
              {...form}
            />
            <Typography type="body1" align="center" className={styles.info}>
              {ReactHtmlParser(disclaimer)}
            </Typography>
          </div>
        </div>
      )}
    </>
  )
}

RegisterPage.propTypes = {
  handleLoginLinkFlow: PropTypes.func,
  requestUserRegistration: PropTypes.func,
  parentClassName: PropTypes.string,
  landingPageCompany: PropTypes.string,
  redirectEmailSent: PropTypes.bool,
  config: PropTypes.object,
  busy: PropTypes.bool,
  services: PropTypes.object,
  location: PropTypes.object
  // Add other props here
}

export default RegisterPage
