import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  meta: {
    token: null,
    type: null,
    redirect: null,
    nlEventId: null,
    nlCampaignId: null
  }
}

const captchaSlice = createSlice({
  name: 'captchaReducer',
  initialState,
  reducers: {
    setCaptchaLoginMeta: (state, action) => {
      const {
        token,
        type,
        redirect = null,
        nlEventId = null,
        nlCampaignId = null
      } = action.payload
      state.meta = { token, type, redirect, nlEventId, nlCampaignId }
    },
    requestCaptchaLogin: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'request',
          asyncKey: 'captchaLogin'
        }
      }),
      reducer: () => {}
    },
    successCaptchaLogin: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'success',
          asyncKey: 'captchaLogin'
        }
      }),
      reducer: state => {
        state.meta = {
          token: null,
          type: null,
          redirect: null,
          nlEventId: null,
          nlCampaignId: null
        }
      }
    },
    errorCaptchaLogin: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'error',
          asyncKey: 'captchaLogin'
        }
      }),
      reducer: () => {}
    }
  }
})

export const { actions, reducer } = captchaSlice

export const {
  setCaptchaLoginMeta,
  successCaptchaLogin,
  errorCaptchaLogin,
  requestCaptchaLogin
} = actions

export default captchaSlice.reducer
