import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Typography from 'src/lib/ui/Typography'

import cx from 'classnames'
import styles from './Warning.module.sass'

const Warning = ({ boxed = false, hasTags = true }) => {
  const { t } = useTranslation()

  return (
    <div className={cx(styles.container, boxed && styles.boxed)}>
      {/* <Typography type="list-institute" align={boxed ? 'center' : 'left'}>
        {t('warningTitle')}
      </Typography> */}
      <Typography type="caption" className={styles.text} align="left">
        {hasTags ? t('warningMessage') : t('warningMessageNoTags')}
      </Typography>
    </div>
  )
}

Warning.propTypes = {
  /**
   * Boxed style
   */
  boxed: PropTypes.bool,
  /**
   * If the response has Tags or not
   */
  hasTags: PropTypes.bool
}

export default Warning
