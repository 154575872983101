import { useSelector } from 'react-redux'
import StoryRiver from 'src/components/StoryRiver'
import ReferenceArticleExcerpt from 'src/components/ReferenceArticleExcerpt'
import { getStory } from 'src/features/singleStory/SingleStorySelector'

const ReferenceArticle = () => {
  const story = useSelector(getStory())

  if (!story?.referenceArticles || story?.referenceArticles?.length <= 0) {
    return <></>
  }

  return (
    <StoryRiver
      title={
        story.referenceArticles.length > 1
          ? 'Reference Articles'
          : 'Reference Article'
      }
    >
      {story.referenceArticles.map((article, i) => (
        <ReferenceArticleExcerpt
          key={i}
          to={article.url}
          title={article.title}
          name={article.name}
        />
      ))}
    </StoryRiver>
  )
}
export default ReferenceArticle
