import React, { Fragment, useContext } from 'react'
import PropTypes from 'prop-types'
import { SessionContext } from 'src/features/session/SessionContext'
import { licenseTypes } from 'src/services/FW5ML/parsers/UserParser'
import RiverInterest from 'src/components/RiverInterest'
import Button from 'src/lib/ui/Buttons/Button'
import Typography from 'src/lib/ui/Typography'
import styles from './RiverHeader.module.sass'
import { siteName } from 'src/utils/oneCodeBase'

const RiverHeader = ({
  title,
  showFilterMenu,
  toggleFilters,
  riverKey,
  riverArgs,
  requestAddInterestToUser,
  requestDeleteInterestToUser,
  paywallRedirection,
  userInterests
}) => {
  const showRiverInterestButton = riverKey === 'tag' && siteName !== 'Reports'
  const { checked: sessionReady, user } = useContext(SessionContext)

  const hndAddInterest = (...args) => {
    if (!sessionReady) return
    if (user.license === licenseTypes.LICENSE_PLUS) {
      requestAddInterestToUser(...args)
    } else {
      paywallRedirection({
        type: 'plus_feature',
        subtype: 'add_interests'
      })
    }
  }

  const hndDeleteInterest = (...args) => {
    if (!sessionReady) return
    if (user.license === licenseTypes.LICENSE_PLUS) {
      requestDeleteInterestToUser(...args)
    } else {
      paywallRedirection({
        type: 'plus_feature',
        subtype: 'remove_interests'
      })
    }
  }

  return (
    <Fragment>
      <Typography className={styles.headline} type="headline">
        <span className={styles.title}>{title}</span>
        {showRiverInterestButton && (
          <RiverInterest
            riverArgs={riverArgs}
            userInterests={userInterests}
            onAddInterest={hndAddInterest}
            onDeleteInterest={hndDeleteInterest}
          />
        )}
      </Typography>
      {showFilterMenu && (
        <div className={styles.bar}>
          <div className={styles.buttons}>
            <Button
              minWidth="0"
              iconLeft="svg/custom/filter"
              tier="action"
              raised={false}
              className={styles.contentRiverFilterButton}
              textCase="none"
              onClick={toggleFilters}
            >
              Filter
            </Button>
          </div>
        </div>
      )}
    </Fragment>
  )
}

RiverHeader.propTypes = {
  title: PropTypes.string,
  showFilterMenu: PropTypes.bool,
  toggleFilters: PropTypes.func,
  riverKey: PropTypes.string,
  riverArgs: PropTypes.string,
  requestAddInterestToUser: PropTypes.func,
  requestDeleteInterestToUser: PropTypes.func,
  paywallRedirection: PropTypes.func,
  userInterests: PropTypes.array
}

export default RiverHeader
