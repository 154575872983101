import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PredeterminatedListFilters from './PredeterminatedListFilters'
import FreeInputText from './FreeInputText'
import styles from './FilterInput.module.sass'
import { actions } from 'src/features/river/RiverSlice'
import * as selectors from 'src/features/river/RiverSelector'
import { getDefaultFilters } from 'src/features/river/config/riverConfig'

const FilterInput = ({
  riverId,
  options = [],
  index = '',
  inputType = '',
  type = '',
  filterCategory = '',
  loading = false,
  open = false,
  orderFiltersByPosition = false,
  singleSelect = false
}) => {
  const dispatch = useDispatch()
  const tagsForCategory = useSelector(selectors.getFoundTags)

  const onSelectFilter =
    (filterKey, filter, singleSelect = false) =>
    () => {
      dispatch(
        actions.selectFilter({
          key: filterKey,
          filter,
          defaultFilters: getDefaultFilters(riverId, filterKey),
          singleSelect
        })
      )
    }

  const onRemoveFilter = filter => () => {
    dispatch(
      actions.removeFilter({
        riverId,
        filter,
        defaultFilters: getDefaultFilters(riverId, filter.field)
      })
    )
  }

  const addSelectedFilter = (
    option = {},
    category = '',
    singleSelect = false
  ) => {
    dispatch(
      actions.addSelectedTag({
        option,
        category,
        riverId,
        singleSelect
      })
    )
  }

  const onTagSearch = (filterKey, category) => () => {
    dispatch(
      actions.requestTagsSearch({
        query_string: filterKey,
        category
      })
    )
  }

  const removeSelectedFilter = (option = {}, category = '') => {
    dispatch(
      actions.removeSelectedTag({
        option,
        category,
        riverId
      })
    )
  }

  const [validOptions, setValidOptions] = useState([])

  useEffect(() => {
    if (!options.length) return
    const parsedOpts = parseOptions(options)
    const chosenOpts = checkSelectedOptns(parsedOpts)
    setValidOptions(chosenOpts)
  }, [options, tagsForCategory[index]?.selectedTags, open])

  // Handler functions
  const parseOptions = (options = []) => {
    const filterdByCategory = options?.filter(opt => opt.category === index)

    let result = filterdByCategory.map((opt, position) => {
      return {
        value: opt.tag,
        selected: false,
        field: index,
        position
      }
    })

    const chosen = getChosen(index)
    if (chosen.length > 0) {
      result = result.filter(item =>
        chosen.find(subitem => subitem.value !== item.value)
      )
      result = [...chosen, ...result]
    }

    if (orderFiltersByPosition) {
      // Order by position
      result = result.sort((a, b) => {
        if (a.position < b.position) return -1
        if (a.position > b.position) return 1
        return 0
      })
    } else {
      // Order alphabetically
      result = result.sort((a, b) => a.value.localeCompare(b.value))
    }

    return result
  }

  const checkSelectedOptns = validOptions => {
    let options = validOptions.map(opt => {
      const isOptionSelected = isOptionChosen(opt)

      return {
        ...opt,
        selected: isOptionSelected
      }
    })
    options = cleanOptions(options)
    return options
  }

  const cleanOptions = (optns = []) => {
    return optns
      .filter(
        (opt, index, self) =>
          index === self.findIndex(o => o.value === opt.value)
      )
      .filter(opt => !isValueAnURL(opt.value))
  }

  const isValueAnURL = url => {
    const regexHttp = /^(https?|ftp):\/\//
    const regex = /(Http:__www\.|Http:\/\/www\.)/
    return regexHttp.test(url) || regex.test(url)
  }

  const getChosen = index => tagsForCategory[index]?.selectedTags ?? []

  const isOptionChosen = option => {
    const selectedOptns = getChosen(index)
    const isOptionSelected = selectedOptns.findIndex(
      opt => opt.value === option.value
    )

    return isOptionSelected !== -1
  }

  const handleOptionSelect = async option => {
    const selectedOptionIndex = validOptions.findIndex(
      opt => opt.value === option.value
    )

    // If not found, it means this is a new search (plus icon)
    if (selectedOptionIndex === -1) {
      option.selected = true
      setValidOptions(validOptions => [...validOptions, option])
      onSelectFilter(index, option?.value, singleSelect)()
      return
    }

    const updatedOption = validOptions[selectedOptionIndex]

    if (!updatedOption?.selected) {
      onSelectFilter(index, option?.value, singleSelect)()
      addSelectedFilter(updatedOption, index, singleSelect)
    } else {
      onRemoveFilter(option)()
      removeSelectedFilter(updatedOption, index)
    }
  }

  return inputType === 'freeInputText' ? (
    <FreeInputText
      options={validOptions}
      index={index}
      handleOptionSelect={handleOptionSelect}
      type={type}
      filterCategory={filterCategory}
      onTagSearch={onTagSearch}
      isLoadingTags={loading}
      riverId={riverId}
    />
  ) : (
    <div
      className={type.toLowerCase() === 'fwreports' ? styles.floatingList : ''}
    >
      <PredeterminatedListFilters
        options={validOptions}
        index={index}
        handleOptionSelect={handleOptionSelect}
      />
    </div>
  )
}

export default FilterInput
