import React, { useContext } from 'react'
import { SessionContext } from 'src/features/session/SessionContext'
import AnonReportsView from './children/AnonReportsView'
import ProfileReportsView from './children/ProfileReportsView'

const YourReportsView = () => {
  const { authenticated, checked: sessionReady } = useContext(SessionContext)

  // Wait for session to be ready before evaluating
  if (!sessionReady) return

  if (!authenticated) {
    return <AnonReportsView />
  }

  return <ProfileReportsView />
}

export default YourReportsView
