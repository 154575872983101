import { useSelector } from 'react-redux'
import Page from 'src/components/Page'
import ResourcePageContent from './components/ResourcesPageContent'

const ResourcesStaticPage = () => {
  const resourcePageConfig =
    useSelector(
      state => state?.configReducer?.webpage_content?.data?.resources
    ) || {}

  if (!resourcePageConfig?.content_layout) {
    return null
  }

  const { content_layout = {} } = resourcePageConfig

  return (
    <Page>
      <ResourcePageContent content={content_layout.shared} />
    </Page>
  )
}

export default ResourcesStaticPage
