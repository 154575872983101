import { takeEvery, put, all, call, select } from 'redux-saga/effects'
import { reset } from 'redux-form'
import { reauthAndRetryCall } from 'src/utils/sagaHelpers.js'
import { actions } from './PaymentSlice'
import { notificationsEnqueue } from 'src/features/notifications/NotificationsSlice'
import { getConfig } from 'src/features/config/ConfigSelector'

function* sendPayment(services, { payload }) {
  const PaymentRepository = services('PaymentRepository')
  const Piwik = services('Piwik')
  const Session = services('Session')
  const user = yield call([Session, 'userLoad'])
  try {
    yield call([Piwik, 'track'], 'checkout', 'submit')
    const data = yield reauthAndRetryCall(
      services,
      [
        PaymentRepository,
        user.profile_id ? 'processPayment' : 'processGuestPayment'
      ],
      payload
    )
    yield put(actions.logClear())
    yield put(actions.successProcessPayment(data))
    yield put(reset('payment'))
    yield put(notificationsEnqueue({ message: 'Subscription Confirmed' }))
    return true
  } catch (e) {
    yield put(actions.errorProcessPayment(e.message))
    yield put(notificationsEnqueue({ message: e.message }))

    yield call(
      [Piwik, 'track'],
      'notification',
      'error',
      `could-not-proccess-payment-to-the-user ${
        user.profile_id ? user.profile_id : 'guest-payment'
      }, message_${e.message}`
    )
  }
}

function* applyCoupon(services, { payload }) {
  const PaymentRepository = services('PaymentRepository')
  const Piwik = services('Piwik')
  const Session = services('Session')
  const user = yield call([Session, 'userLoad'])
  const { coupon } = payload
  try {
    yield call([Piwik, 'track'], 'applyCoupon', 'action')
    const data = yield reauthAndRetryCall(
      services,
      [PaymentRepository, 'applyCoupon'],
      {
        coupon
      }
    )
    yield put(actions.logClear())
    yield put(actions.successApplyCoupon(data))
    return true
  } catch (e) {
    yield put(actions.errorApplyCoupon(e.message))
    yield put(notificationsEnqueue({ message: e.message }))
    yield call(
      [Piwik, 'track'],
      'notification',
      'error',
      `could-not-apply-the-coupon-${coupon}-to-user-${user.profile_id}-message_${e.message}`
    )
  }
}

function* getPrice(services) {
  const PaymentRepository = services('PaymentRepository')
  const Piwik = services('Piwik')
  const Session = services('Session')
  const user = yield call([Session, 'userLoad'])
  try {
    yield call([Piwik, 'track'], 'getPrice', 'action')
    let data = {}
    const paywallConfig = yield select(getConfig('paywall')) ?? {}
    if (paywallConfig?.price_stripe) {
      data = {
        success: true,
        data: {
          currency: 'usd',
          price: paywallConfig.price_stripe
        }
      }
    } else {
      data = yield reauthAndRetryCall(services, [PaymentRepository, 'getPrice'])
    }
    yield put(actions.logClear())
    yield put(actions.successGetPrice(data))
    return true
  } catch (e) {
    yield put(actions.errorGetPrice(e.message))
    yield call(
      [Piwik, 'track'],
      'notification',
      'error',
      `could-not-get-the-product-price-to-user-${user.profile_id}-message_${e.message}`
    )
  }
}

export default function* watchPayment(services) {
  yield all([takeEvery(actions.requestProcessPayment, sendPayment, services)])
  yield all([takeEvery(actions.requestApplyCoupon, applyCoupon, services)])
  yield all([takeEvery(actions.requestGetPrice, getPrice, services)])
}
