import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  content: {},
  error: null,
  loginInfo: {},
  registrationInfo: {},
  consentInfo: {},
  consent: false,
  loading: false
}

const gatedLandingPageSlice = createSlice({
  name: 'landingPageReducer',
  initialState,
  reducers: {
    requestLandingContentData: state => {
      state.content = {}
      state.consent = false
      state.loading = true
      state.error = null
    },
    successLandingContentData: (state, { payload }) => {
      state.content = payload
      state.consent = true
      state.loading = false
      state.error = null
    },
    errorLandingContentData: (state, { payload }) => {
      state.content = {}
      state.consent = false
      state.loading = false
      state.error = payload.error
    },
    requestLandingCompanyData: (state, { payload }) => {
      state[`${payload.type}Info`] = {}
      state.loading = true
      state.error = null
    },
    successLandingCompanyData: (state, { payload }) => {
      state[`${payload.type}Info`] = payload.data
      state.loading = false
      state.error = null
    },
    errorLandingCompanyData: (state, { payload }) => {
      state[`${payload.type}Info`] = {}
      state.loading = false
      state.error = payload.error
    },
    requestConfirmConsent: state => {
      state.loading = true
      state.consent = false
    },
    successConfirmConsent: state => {
      state.loading = false
      state.consent = true
    },
    errorConfirmConsent: (state, { payload }) => {
      state.loading = false
      state.consent = false
      state.error = payload.error
    }
  }
})

export const { actions, reducer } = gatedLandingPageSlice

export const {
  requestLandingContentData,
  successLandingContentData,
  errorLandingContentData,
  requestLandingCompanyData,
  successLandingCompanyData,
  errorLandingCompanyData,
  requestConfirmConsent,
  successConfirmConsent,
  errorConfirmConsent
} = actions

export default reducer
