import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import RenderFullPage from 'src/components/RenderFullPage'
import { getLanguageCode } from 'src/features/fw-ai/children/translation/TranslationSelector'
import { actions as historyActions } from 'src/features/fw-ai/children/history/HistorySlice'
import { actions as faqActions } from 'src/features/fw-ai/children/faq/FaqSlice'
import { actions as liveFeedActions } from 'src/features/fw-ai/children/liveFeed/LiveFeedSlice'
import ChatPage from 'src/features/fw-ai/children/chatPage'
import LeftBar from './sections/LeftBar'
import { Box, styled } from '@mui/material'
import Chat from 'src/features/fw-ai/children/dougallChat/Chat'
import RightBar from './sections/RightBar'
import { MobileButtonBar } from './sections/MobileButtonBar'
import useMediaSize from 'src/hooks/useMediaSize'
import useScrollBlock from 'src/hooks/useScrollBlock'

/**
 * Home Middle Container Styles
 */
const HomeMiddleContainer = styled(Box, {
  name: 'Home',
  label: 'homeMiddleContainer',
  overridesResolver: (props, styles) => [styles.middleContainer]
})({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100% - 20px)'
})

const DougallFW = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [blockScroll, allowScroll] = useScrollBlock()
  blockScroll()
  const lang = useSelector(getLanguageCode())
  const dougallTitle = useSelector(
    state => state.configReducer.dougallFw.data.sections.title
  )

  const mediaSize = useMediaSize({
    mediaQueries: { small: '(max-width: 860px)' }
  })

  const { state = {} } = history.location
  // Default: Popular Prompts expanded (1)
  const { listExpanded = 1, empty = true } = state

  useEffect(() => {
    return () => {
      allowScroll()
    }
  }, [location.pathname])

  useEffect(() => {
    dispatch(historyActions.requestHistory())
    dispatch(faqActions.requestFaq(lang))
    dispatch(liveFeedActions.requestLiveFeed())
    // eslint-disable-next-line
    //
  }, [])

  return (
    <RenderFullPage hideFooter noScrollableContent={mediaSize !== 'small'}>
      <ChatPage
        leftContent={
          <LeftBar listExpanded={listExpanded} dougallTitle={dougallTitle} />
        }
        rightContent={<RightBar />}
        footer={mediaSize === 'small' && <MobileButtonBar />}
      >
        <HomeMiddleContainer>
          <Chat empty={empty} />
        </HomeMiddleContainer>
      </ChatPage>
    </RenderFullPage>
  )
}

export default DougallFW
