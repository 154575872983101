import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Checkbox from 'src/lib/ui/Checkbox'
import { getSetting } from 'src/features/mySettings/MySettingsSelector'
import styles from './MedicalAbstractsToggle.module.sass'
import usePiwik from 'src/hooks/usePiwik'
import { actions } from 'src/features/river/RiverSlice'

const MedicalAbstractsToggle = ({ loading, riverId, query }) => {
  const dispatch = useDispatch()
  const { handleTracking } = usePiwik()
  const defaultValue = useSelector(getSetting('include_medical_abstract'))

  const [medicalAbstractsEnabled, setMedicalAbstractsEnabled] =
    useState(defaultValue)

  const hndToggle = () => {
    setMedicalAbstractsEnabled(!medicalAbstractsEnabled)

    if (riverId) {
      dispatch(
        actions.setMedicalAbstracts({
          riverId,
          value: !medicalAbstractsEnabled
        })
      )
    }

    handleTracking(
      'search',
      'click',
      !medicalAbstractsEnabled
        ? 'show-medical-abstracts'
        : 'hide-medical-abstracts'
    )
  }

  // Set default setting value when doing a new search
  useEffect(() => {
    setMedicalAbstractsEnabled(defaultValue)
  }, [query])

  return (
    !loading && (
      <Checkbox
        altTheme={styles}
        label="Show Medical Abstracts"
        name="settings_show_medical_abstracts_in_river"
        hasDivider={false}
        checked={medicalAbstractsEnabled}
        disabled={loading}
        onChange={hndToggle}
      />
    )
  )
}

export default MedicalAbstractsToggle
