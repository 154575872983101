import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { ProtectedArea, ShowFor } from '../LicenseProtected'
import HeaderItem from '../HeaderItem'
import useMediaSize from '../../hooks/useMediaSize'
import Icon from '../../lib/ui/Icon'
import styles from './Menu.module.sass'
import HeaderButton from '../HeaderButton'
import usePiwik from 'src/hooks/usePiwik'
import { Route, Link, Routes, useLocation } from 'react-router-dom'

const Menu = ({ handleSlideMenu, hideMenu }) => {
  const location = useLocation()
  const { handleTracking } = usePiwik()
  const { pathname } = location
  const mediaSize = useMediaSize({
    mediaQueries: {
      small: '(max-width: 550px)',
      medium: '(max-width: 960px)'
    }
  })

  const handleTrackingButtons = actionType => {
    if (pathname !== '/register') return
    handleTracking('register-page', 'button-click', actionType)
  }

  return (
    <nav className={styles.menu}>
      <ul>
        {!hideMenu && (
          <>
            {mediaSize === 'large' && (
              <ProtectedArea>
                <ShowFor anonymous>
                  <Fragment>
                    <HeaderItem register>
                      <HeaderButton
                        color="secondary"
                        label="Register"
                        to="/register"
                        handleTrackingEvent={() =>
                          handleTrackingButtons('register')
                        }
                      />
                    </HeaderItem>
                    <HeaderItem register>
                      <HeaderButton
                        color="secondary"
                        label="Sign In"
                        to="/sign-in"
                        handleTrackingEvent={() =>
                          handleTrackingButtons('sign-in')
                        }
                      />
                    </HeaderItem>
                  </Fragment>
                </ShowFor>
              </ProtectedArea>
            )}
          </>
        )}
        <ProtectedArea>
          <ShowFor authenticated>
            <HeaderItem icon>
              <Icon
                icon="svg/material-design-icons/action/account_circle"
                iconSize={32}
                onClick={handleSlideMenu}
                button
                data-testid="Header_account-icon"
              />
            </HeaderItem>
          </ShowFor>
        </ProtectedArea>
      </ul>
    </nav>
  )
}

Menu.propTypes = {
  handleSlideMenu: PropTypes.func,
  hideMenu: PropTypes.bool
}

export default Menu
