import React from 'react'
import PropTypes from 'prop-types'
import { Typography, styled, Box } from '@mui/material'
import RequestAccessForm from 'src/components/RequestAccessForm/RequestAccessFormContainer'

import Modal from 'src/components/DougallModal'

const RequestAccessModal = ({
  popTitle = 'Complete the below form and a member of our team will reach out to you shortly to discuss this report.',
  open,
  onSuccess = () => {},
  onCancel,
  disableConsultantContact = false,
  withSubmitTracking = () => {},
  notifySettings = null
}) => {
  return (
    <Modal
      style={{ width: '700px' }}
      customActions={<></>}
      open={open}
      onCancel={onCancel}
    >
      <Box sx={{ margin: 'auto', width: '80%', textAlign: 'center' }}>
        <PopTitle>{popTitle}</PopTitle>
      </Box>
      <RequestAccessForm
        onSuccess={onSuccess}
        disableConsultantContact={disableConsultantContact}
        withSubmitTracking={withSubmitTracking}
        notifySettings={notifySettings}
      />
    </Modal>
  )
}

RequestAccessModal.propTypes = {
  popTitle: PropTypes.string,
  open: PropTypes.bool,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  disableConsultantContact: PropTypes.bool,
  withSubmitTracking: PropTypes.func,
  notifySettings: PropTypes.object
}

export default RequestAccessModal

const PopTitle = styled(Typography, {
  name: 'popTitle',
  label: 'popTitle',
  overridesResolver: (props, styles) => [styles.warning]
})(({ theme }) => ({
  fontSize: '20px',
  fontWeight: 'bold',
  marginTop: '8px',
  padding: '0 10px',
  color: '#494949',
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
    marginTop: '4px',
    padding: '0 8px'
  }
}))
