import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'

import Typography from 'src/lib/ui/Typography'
import styles from './CustomizableModal.module.sass'
import cx from 'classnames'

export const CustomizableModal = ({
  children,
  open,
  onClose,
  modalStyles,
  modalContentStyles,
  width,
  height,
  isLoading,
  modalTitle
}) => {
  const modalSize = useMemo(
    () => ({ maxWidth: width, height }),
    [width, height]
  )

  const handleClose = useCallback(() => {
    if (!isLoading) {
      onClose()
    }
  }, [isLoading, onClose])

  if (!open) return null

  return (
    <div id="modal" className={styles.modal} style={modalStyles}>
      <div
        className={styles.content}
        style={{ ...modalContentStyles, ...modalSize }}
      >
        <div
          className={cx(
            styles.closeSection,
            modalTitle ? styles.between : styles.end
          )}
        >
          {modalTitle && (
            <Typography className={styles.modalTitle} type="subheading">
              {modalTitle}
            </Typography>
          )}
          <button
            onClick={handleClose}
            className={styles.modalButton}
            disabled={isLoading}
          >
            <span id="closeModal" className={styles.close}>
              &times;
            </span>
          </button>
        </div>
        {children}
      </div>
    </div>
  )
}

CustomizableModal.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  modalContentStyles: PropTypes.object,
  modalStyles: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
  isLoading: PropTypes.bool,
  modalTitle: PropTypes.string
}

CustomizableModal.defaultProps = {
  open: false,
  onClose: () => {},
  modalStyles: {},
  modalContentStyles: {},
  width: '',
  height: '',
  isLoading: false,
  modalTitle: ''
}
