import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Page from 'src/components/Page'
import RegisterPage from '../../RegisterPageContainer'
import commonStyles from 'src/styles/common.module.sass'
import styles from './RegisterPage.module.sass'
import landingCompanyStyles from 'src/features/landingCompanyInfo/components/LandingCompanyInfo.module.sass'
import LandingCompanyInfo from 'src/features/landingCompanyInfo/LandingCompanyInfoContainer'
import FullImage from 'src/components/FullImage'
import StorySpinner from 'src/components/StorySpinner'
import cx from 'classnames'
import AboutUs from 'src/components/AboutUs'
import { ArticlesList } from 'src/features/gatedLandingPage/pages/Landing'
import Contributors from 'src/features/gatedLandingPage/components/Contributors'
import ContributorsList from 'src/features/gatedLandingPage/components/ContributorsList'

class RegisterLandingPage extends Component {
  componentDidMount() {
    this.props.loadCompanyContent({
      type: 'registration'
    })
  }

  render() {
    const { landingPageCompany = null, busyLandingPage } = this.props
    const asset = landingPageCompany?.asset?.type || { type: 'screenshot' }

    const {
      contributors = [],
      contributorsList = [],
      contributorsExtraItems = 0
    } = landingPageCompany ?? {}

    return (
      <Page className={!landingPageCompany && commonStyles.content}>
        {busyLandingPage ? (
          <StorySpinner />
        ) : (
          <>
            <div
              className={
                landingPageCompany && cx(styles.container, commonStyles.content)
              }
            >
              {landingPageCompany && (
                <LandingCompanyInfo data={landingPageCompany} />
              )}
              <section className={landingPageCompany && styles.landingSection}>
                <RegisterPage
                  parentClassName={landingPageCompany && 'formBorders'}
                  landingPageCompany={landingPageCompany}
                />
              </section>
              {landingPageCompany && asset && asset.type === 'screenshot' ? (
                <div className={landingCompanyStyles.largeViewImg}>
                  <FullImage
                    src={landingPageCompany.screenshot}
                    objectFit="cover"
                    width="100%"
                    height="100%"
                  />

                  {contributors && contributors.length > 0 ? (
                    <div className={styles.contributors}>
                      <Contributors contributors={contributors ?? []} />
                    </div>
                  ) : (
                    contributorsList &&
                    contributorsList.length > 0 && (
                      <div className={styles.contributors}>
                        <ContributorsList
                          contributors={contributorsList ?? []}
                          extraItems={contributorsExtraItems}
                        />
                      </div>
                    )
                  )}
                </div>
              ) : (
                landingPageCompany?.asset && (
                  <div className={landingCompanyStyles.largeViewImg}>
                    <ArticlesList
                      articles={landingPageCompany.asset.articles}
                      accesible={false}
                      articlesHtml={landingPageCompany.asset.articlesHtml}
                    />

                    {contributors && contributors.length > 0 ? (
                      <div className={styles.contributors}>
                        <Contributors contributors={contributors ?? []} />
                      </div>
                    ) : (
                      contributorsList &&
                      contributorsList.length > 0 && (
                        <div className={styles.contributors}>
                          <ContributorsList
                            contributors={contributorsList || []}
                            extraItems={contributorsExtraItems}
                          />
                        </div>
                      )
                    )}
                  </div>
                )
              )}
            </div>
            {landingPageCompany?.sponsor && (
              <AboutUs sponsor={landingPageCompany.sponsor} />
            )}
          </>
        )}
      </Page>
    )
  }
}

RegisterLandingPage.propTypes = {
  loadCompanyContent: PropTypes.func,
  busyLandingPage: PropTypes.bool,
  landingPageCompany: PropTypes.object
}

export default RegisterLandingPage
