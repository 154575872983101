import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { licenseTypes } from 'src/services/FW5ML/parsers/UserParser.js'
import { SessionContext } from 'src/features/session/SessionContext'
import { TitleContext } from './TitleContext'
import { siteName } from 'src/utils/oneCodeBase.js'

const titleByPath = path => {
  if (path.includes('/search')) {
    return 'Search'
  } else if (path.includes('/contact-us')) {
    return 'Contact Us'
  } else if (path.includes('/river/top-stories')) {
    return 'Top Stories'
  } else if (path.includes('/river/iav')) {
    return 'Insight Analysis & Views'
  } else if (path.includes('/river/conference-news')) {
    return 'Conference Dispatch'
  } else if (path.includes('/my-account')) {
    return 'My Profile'
  } else {
    return ''
  }
}

export const TitleProvider = ({ children, history }) => {
  const [titleLocation, setTitleLocation] = useState(
    titleByPath(history.location.pathname)
  )
  history.listen(location => setTitleLocation(titleByPath(location.pathname)))
  const { userHasLicense = () => false } = useContext(SessionContext)
  const plus = userHasLicense(licenseTypes.LICENSE_PLUS) ? ' +' : ''
  const [storyTitle, setStoryTitle] = useState('')

  const baseTitleDescription = `${siteName} Industry News and Analysis`
  const baseTitleSubscription = `| FirstWord ${siteName}${plus}`

  const baseTitle = `${baseTitleDescription} ${baseTitleSubscription}`

  const baseStoryTitle = `${
    !storyTitle ? baseTitleDescription : storyTitle
  } ${baseTitleSubscription}`

  // Static page title (uses titleLocation for %s):
  // Pharma Industry News and Analysis | FirstWord Pharma - [Page Title]

  // Story page title (uses defaultTitle rather than titleTemplate):
  // [This is a Title for a Story] | FirstWord Pharma
  return (
    <TitleContext.Provider value={setStoryTitle}>
      <Helmet titleTemplate={`${baseTitle} - %s`} defaultTitle={baseStoryTitle}>
        <title>{titleLocation}</title>
      </Helmet>
      {children}
    </TitleContext.Provider>
  )
}

TitleProvider.propTypes = {
  children: PropTypes.node,
  history: PropTypes.object
}

export default TitleProvider
