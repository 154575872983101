import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const registerSlice = createSlice({
  name: 'registerReducer',
  initialState,
  reducers: {
    requestUserRegistration: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'request',
          asyncKey: 'userRegistration'
        }
      }),
      reducer: () => {}
    },
    successUserRegistration: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'success',
          asyncKey: 'userRegistration'
        }
      }),
      reducer: () => {}
    },
    errorUserRegistration: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'error',
          asyncKey: 'userRegistration'
        }
      }),
      reducer: () => {}
    }
  }
})

export const { actions, reducer } = registerSlice

export const {
  requestUserRegistration,
  successUserRegistration,
  errorUserRegistration
} = actions

export default reducer
