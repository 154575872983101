import StoryRiverView from 'src/components/StoryRiverView'
import { useSelector } from 'react-redux'
import { getViewByName } from 'src/features/views/ViewsSelector'
import useViewProps from 'src/hooks/useViewProps'

const RelatedAnalysis = () => {
  const title = 'Related Analysis'
  const view = useSelector(getViewByName('related analysis')) || null
  const { viewsError, viewsLoading, handleViewMoreClick } = useViewProps()

  return (
    <StoryRiverView
      view={view}
      viewsError={viewsError}
      viewsLoading={viewsLoading}
      handleViewMoreClick={() => handleViewMoreClick('story', title)}
      title={title}
      viewMore={{ show: false }}
      sidebar={true}
      showTeaser={false}
      tagsClickable={true}
      showPublished={false}
      articlesLength="i < 1"
    />
  )
}

export default RelatedAnalysis
