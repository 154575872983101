import { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { ShareIcon } from 'src/static/icons'
import Typography from 'src/lib/ui/Typography'
import { styled } from '@mui/material/styles'
import usePiwik from 'src/hooks/usePiwik'

/**
 * Action Bar Container
 */
const ActionBarContainer = styled('div', {
  name: 'ActionBar',
  label: 'actionBarContainer',
  overridesResolver: (props, styles) => [styles.container]
})({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '9px 0px 0px'
})

/**
 * Action Button Container
 */
const ActionButtonContainer = styled('div', {
  name: 'ActionBar',
  label: 'actionBarButtonContainer',
  overridesResolver: (props, styles) => [styles.buttonContainer]
})({
  display: 'flex',
  alignItems: 'center'
})

/**
 * Action Bar Text
 */
const ActionLabel = styled(Typography, {
  name: 'ActionBar',
  label: 'actionBarLabel',
  overridesResolver: (props, styles) => [styles.label]
})(({ theme }) => ({
  paddingTop: '3px',
  fontSize: '12px',
  fontWeight: 'bold',
  color: theme.palette.actionBar.label
}))

/**
 * Action Button
 * forwardProps: active
 */
const ActionButton = styled('div', {
  name: 'ActionBar',
  label: 'actionBarButton',
  overridesResolver: (props, styles) => [styles.button],
  shouldForwardProp: prop => prop !== 'active'
})(({ active, theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  minWidth: '40px',
  minHeight: '24px',
  margin: '0px 2.5px',
  border: `solid 2px ${theme.palette.actionBar.buttonBorder}`,
  borderRadius: '30px',
  boxSizing: 'border-box',
  mixBlendMode: 'multiply',
  backgroundColor: active
    ? theme.palette.actionBar.buttonBackgroundActive
    : theme.palette.actionBar.buttonBackground,
  transition: '0.3s',
  '&:hover': {
    backgroundColor: theme.palette.actionBar.buttonBackgroundHover,
    transition: '0.3s',
    p: {
      color: theme.palette.actionBar.buttonLabelHover,
      transition: '0.3s'
    },
    '.MuiSvgIcon-root': {
      stroke: theme.palette.actionBar.buttonIconHover,
      transition: '0.3s'
    }
  }
}))

/**
 * Share Button
 */
const ShareButton = styled('div', {
  name: 'ActionBar',
  label: 'actionBarShareButton',
  overridesResolver: (props, styles) => [styles.shareButton]
})({
  display: 'flex',
  marginRight: '5px',
  cursor: 'pointer',
  alignItems: 'center'
})

/**
 * Action SVG
 */
const ActionSVG = ({ icon, ...props }) => {
  const Icon = styled(icon)(({ theme }) => ({
    stroke: theme.palette.actionBar.icon,
    fill: 'none',
    width: '16px',
    height: '16px',
    marginTop: '3px',
    marginRight: '3px'
  }))
  return <Icon {...props} />
}
ActionSVG.propTypes = {
  /**
   * Icon component to be rendered
   */
  icon: PropTypes.func
}

const ActionBar = ({
  onAction = () => {},
  prompt = '',
  promptId,
  answerId,
  showLikes,
  showShare
}) => {
  const { t } = useTranslation()
  //  1 - Like
  //  0 - None
  // -1 - Dislike
  const [liked, setLiked] = useState(0)

  const { handleActionTracking } = usePiwik()

  const handleAction = action => {
    if (action === 'share') {
      onAction({ action, prompt, promptId, answerId })
      return
    }

    const isLike = action === 'like'
    const isDislike = action === 'dislike'

    if (isLike || isDislike) {
      handleActionTracking(`Site/Click/Answer/${action}`, {
        answerId,
        promptId
      })
      const currentState = liked
      const newState = currentState === (isLike ? 1 : -1) ? 0 : isLike ? 1 : -1

      setLiked(newState)
      onAction({
        action,
        promptId,
        answerId,
        undo: currentState === newState
      })
    }
  }

  return (
    <ActionBarContainer data-testid="action-bar">
      {showLikes && (
        <ActionButtonContainer>
          <ActionLabel sx={{ marginRight: '3.5px' }}>
            {t('actionBarText')}
          </ActionLabel>
          <ActionButton
            data-testid="likeActionButton"
            active={liked === 1}
            onClick={() => handleAction('like')}
          >
            <ActionLabel>{t('yes')}</ActionLabel>
          </ActionButton>
          <ActionButton
            data-testid="dislikeActionButton"
            active={liked === -1}
            onClick={() => handleAction('dislike')}
          >
            <ActionLabel>{t('no')}</ActionLabel>
          </ActionButton>
        </ActionButtonContainer>
      )}
      {showShare && (
        <ShareButton
          data-testid="shareActionButton"
          onClick={() => handleAction('share')}
        >
          <ActionSVG icon={ShareIcon} />
          <ActionLabel>{t('socialElementShareText')}</ActionLabel>
        </ShareButton>
      )}
    </ActionBarContainer>
  )
}

ActionBar.propTypes = {
  onAction: PropTypes.func,
  prompt: PropTypes.string,
  promptId: PropTypes.string,
  answerId: PropTypes.string,
  showShare: PropTypes.bool,
  showLikes: PropTypes.bool
}

export default ActionBar
