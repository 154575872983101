import { withExtraProps } from 'src/components/ExtraPropsComponent'
import SimpleStory from 'src/features/simpleStory/SimpleStoryPage'
import StaticPage from 'src/features/static/StaticPage'
import { Redirect } from 'react-router-dom'

const ContactRedirect = () => {
  return <Redirect to="/contact-us" />
}

export default [
  {
    path: '/print/:id',
    component: withExtraProps(SimpleStory, {
      simplePage: true
    }),
    exact: true
  },
  // TODO Remove this in case FB XML team can produce a /story/simple/:id url with the authlogin at the end
  // Handle the XML feeds url to recreate the url to simple story page
  {
    path: '/:login/story/:id',
    component: withExtraProps(SimpleStory, {
      fromXML: true
    }),
    exact: true
  },
  {
    path: '/fwdealflow',
    component: ContactRedirect,
    exact: true
  },
  {
    path: '/fwbiosimilarindex',
    component: ContactRedirect,
    exact: true
  },
  {
    path: '/marketing-specs',
    component: StaticPage('marketingSpecs'),
    exact: true
  },
  {
    path: '/features-and-benefits',
    component: StaticPage('featuresAndBenefits'),
    exact: true
  },
  {
    path: '/perspectives/conference-rooms-to-zooms',
    component: StaticPage('conferenceRoomsToZooms'),
    exact: true
  }
]
