import React from 'react'
import cx from 'classnames'
import styles from '../Ad/Ad.module.sass'
import PropTypes from 'prop-types'

const IframeAd = ({
  src,
  title,
  fcid,
  className,
  height,
  width,
  viewPercent,
  viewTime,
  viewUrl
}) => {
  return (
    <>
      {src && (
        <div className={cx(styles.ad, className)}>
          <iframe
            seamless=""
            title={title}
            height={height}
            width={width}
            className="_sasia_fcid"
            id={`sasia-fcid-${fcid}`}
            src={src}
            data-sasia-viewtime={viewTime}
            data-sasia-view-percent={viewPercent}
            data-sasia-view-url={viewUrl}
          />
        </div>
      )}
    </>
  )
}

IframeAd.propTypes = {
  /**
   * Source of the iframe ad
   */
  src: PropTypes.string,
  /**
   * Title of the image ad
   */
  title: PropTypes.string,
  /**
   * Used as the iframe id for viewability purposes
   */
  fcid: PropTypes.string,
  /**
   * Used for setting div container styles
   */
  className: PropTypes.string,
  /**
   * Used for setting the iframe height
   */
  height: PropTypes.string,
  /**
   * Used for setting the iframe width
   */
  width: PropTypes.string,
  /**
   * Used as data-sasia-view-url image prop for viewability purposes
   */
  viewUrl: PropTypes.string,
  /**
   * Used as data-sasia-view percent image prop for viewability purposes
   */
  viewPercent: PropTypes.string,
  /**
   * Used as data-sasia-view-time image prop for viewability purposes
   */
  viewTime: PropTypes.string
}

export default IframeAd
