import React, { Fragment, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import useMediaSize from 'src/hooks/useMediaSize'
import { actions } from 'src/features/river/RiverSlice'
import * as selectors from 'src/features/river/RiverSelector'
import Filters from '../filters'
import MyStoriesRiver from 'src/components/MyStoriesRiver'
import { SessionContext } from 'src/features/session/SessionContext'
import { licenseTypes } from 'src/services/FW5ML/parsers/UserParser'
import useViews from 'src/features/views/hooks/useViews'
import ShowForMediaSize from 'src/components/Responsive/ShowForMediaSize'
import Ad from 'src/features/ads/components/Ad'
import commonStyles from 'src/styles/common.module.sass'

const RecommendedForYouSidebar = ({
  busy,
  toggleFilters,
  filtersOpen,
  isRiverPage,
  showMyFW = true,
  showFilters = true,
  showTitle = true,
  showForAll = false,
  flex = 'column',
  riverId
}) => {
  const dispatch = useDispatch()
  const mediaSize = useMediaSize()
  const large = mediaSize === 'large'

  const { userHasLicense = () => false, getInterests } =
    useContext(SessionContext)

  const recommendedViews = showMyFW
    ? [
        {
          name: 'Recommended for you',
          tags: getInterests(),
          include_related_tags: true
        }
      ]
    : []

  const riverMediumRectangle3 = useSelector(
    state => state.adsReducer.riverMediumRectangle3
  )

  const availableFilters = useSelector(selectors.getAvailableRiverFilters)

  const onAddFilter = (filterKey, filter) => () => {
    dispatch(
      actions.addFilter({
        key: filterKey,
        filter,
        defaultFilters: getDefaultFilters(riverId, filterKey)
      })
    )
  }
  const { views, viewsError, viewsLoading } = useViews({
    views: recommendedViews
  })

  return (
    <Fragment>
      {showFilters && (
        <Filters
          busy={busy}
          large={large}
          filters={availableFilters}
          onFilterClick={onAddFilter}
          handleCloseFilters={toggleFilters}
          filtersOpen={filtersOpen}
          isPlus={userHasLicense(licenseTypes.LICENSE_PLUS)}
          showForAll={showForAll}
          showTitle={showTitle}
          flex={flex}
          riverId={riverId}
        />
      )}
      {isRiverPage && flex === 'column' && (
        <ShowForMediaSize showFor={['large', 'medium']}>
          <Ad
            className={commonStyles.newsletterSignUpAd}
            {...riverMediumRectangle3}
          />
        </ShowForMediaSize>
      )}

      {/* MyFW+ / Recommended For you River */}
      {showMyFW && (
        <MyStoriesRiver
          view={views['Recommended for you']}
          viewsError={viewsError}
          viewsLoading={viewsLoading}
          imageSize={large ? 'small' : mediaSize} // Use 'small' teaser image when it is flexed in the sidebar
          getArticleLink={story => `/story/${story.id}`}
          flex={flex}
        />
      )}
    </Fragment>
  )
}

RecommendedForYouSidebar.propTypes = {
  busy: PropTypes.bool,
  toggleFilters: PropTypes.func,
  filtersOpen: PropTypes.bool,
  isRiverPage: PropTypes.bool,
  showMyFW: PropTypes.bool,
  showFilters: PropTypes.bool,
  showTitle: PropTypes.bool,
  flex: PropTypes.oneOf(['column', 'row']),
  showForAll: PropTypes.bool
}

export default RecommendedForYouSidebar
