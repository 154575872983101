import React from 'react'
import { useDispatch } from 'react-redux'
import styles from './DisplayTabsBox.module.sass'
import Tab from 'src/lib/ui/Tabs/Tab'
import Tabs from 'src/lib/ui/Tabs/Tabs'
import { actions } from 'src/features/river/RiverSlice'
import { push } from 'connected-react-router'

const options = {
  all: 'All',
  byInterest: 'By Interest'
}

export const DisplayTabs = ({ value }) => {
  const dispatch = useDispatch()

  const sortMyFW = key => {
    const sortUrlParam = key === 'byInterest' ? 'interest' : 'all'
    dispatch(push(`${window.location.pathname}?sort=${sortUrlParam}`))
    dispatch(actions.setMyfwTab(key))
  }

  return Object.keys(options).map(key => (
    <Tab
      className={styles.tab}
      key={key}
      selected={key === value}
      onClick={() => sortMyFW(key)}
      data-piwik-class="myfw-display-button"
      showsActiveBar
    >
      {options[key]}
    </Tab>
  ))
}

const DisplayTabsBox = ({ value }) => (
  <div className={styles.bar}>
    <Tabs className={styles.container}>
      <DisplayTabs value={value} />
    </Tabs>
  </div>
)

export default DisplayTabsBox
