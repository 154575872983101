import React from 'react'
import PropTypes from 'prop-types'
import AdHardcoded from '../AdHardcoded'
import AdAiMatch from '../AdAiMatch'
import AdUnknown from '../AdUnknown'
import {
  AD_TYPE_AIMATCH,
  AD_TYPE_HARDCODED,
  AD_UNKNOWN
} from 'src/services/Ads/constants/adTypes'
import { getObjectByProperty } from 'src/utils'

const componentsByAdType = {
  [AD_TYPE_AIMATCH]: AdAiMatch,
  [AD_TYPE_HARDCODED]: AdHardcoded,
  [AD_UNKNOWN]: AdUnknown
}

const Ad = ({ type = AD_TYPE_HARDCODED, onAdClick, ...rest }) => {
  const Component = getObjectByProperty(componentsByAdType, type, null)
  return <Component {...rest} onAdClick={onAdClick} />
}

Ad.propTypes = {
  type: PropTypes.oneOf([AD_TYPE_AIMATCH, AD_TYPE_HARDCODED, AD_UNKNOWN]),
  onAdClick: PropTypes.func
}

export default Ad
