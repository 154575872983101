import React, { Fragment } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import PropTypes from 'prop-types'
import Button from 'src/lib/ui/Buttons/Button'
import Typography from 'src/lib/ui/Typography'
import { filterLabelsConfigModule } from 'src/config/sites/river'
import styles from './RiverSubHeader.module.sass'
import { actions } from 'src/features/river/RiverSlice'
import * as selectors from 'src/features/river/RiverSelector'
import { getDefaultFilters } from 'src/features/river/config/riverConfig'

const { getFilterLabel } = filterLabelsConfigModule

const SearchTerm = ({ filters, query }) => {
  let term = query
  if (!query && filters.length > 0) {
    term = filters[0].value
  }
  const segmentedTerm = term.match(/^(\w+)\/(.*)$/)
  return (
    term && (
      <Fragment>
        for <b>{`${segmentedTerm ? segmentedTerm[2] : term}`}</b>
      </Fragment>
    )
  )
}

SearchTerm.propTypes = {
  query: PropTypes.string,
  filters: PropTypes.array
}

const formatTotal = total => {
  let returnTotal = ''

  for (let index = `${total}`.length - 1; index >= 0; index--) {
    if ((returnTotal.length + 1) % 4 === 0 && returnTotal.length > 0) {
      returnTotal = `${total}`[index].toString() + ',' + returnTotal
    } else {
      returnTotal = `${total}`[index].toString() + returnTotal
    }
  }
  return returnTotal
}

const RiverSubHeader = ({
  riverId,
  total = 0,
  query: actualQuery = '',
  excludeFilterValues = [],
  viewCount = true,
  hide = false,
  showClearAllFilters = false,
  clearAllFiltersButton = null,
  isRiverPage = false,
  riverFilters = []
}) => {
  const dispatch = useDispatch()
  const actualFilters = isRiverPage
    ? riverFilters
    : useSelector(selectors.getUsedRiverFilters, shallowEqual)

  const handleFilterClick = filter => {
    dispatch(
      actions.removeFilter({
        riverId,
        filter,
        defaultFilters: getDefaultFilters(riverId, filter.field)
      })
    )

    dispatch(
      actions.removeSelectedTag({
        option: filter,
        category: filter.field,
        riverId
      })
    )
  }

  let query = actualQuery
  let filters = actualFilters

  // interest rivers, which are actually filters, must display brief in the same way as a search query - not a filter
  if (viewCount && !actualQuery && actualFilters.length > 1) {
    query = filters.slice(0, 1)[0].value
    filters = filters.slice(1)
  }
  const displayFilters =
    filters &&
    filters.filter(filter => !excludeFilterValues.includes(filter.value))
      .length >= 1

  return (
    !hide && (
      <div className={styles.container}>
        <div
          className={
            showClearAllFilters && filters?.length > 0 ? styles.headerRow : ''
          }
        >
          <div
            className={
              showClearAllFilters && filters?.length > 0
                ? styles.filtersWrapper
                : ''
            }
          >
            {viewCount && (
              <div>
                <Typography type="body1" data-testid="RiverSubHeader_count">
                  {formatTotal(total)} news articles{' '}
                  <SearchTerm filters={filters} query={query} />
                  {displayFilters && ' filtered by:'}
                </Typography>
              </div>
            )}
            <div
              className={
                showClearAllFilters && filters?.length > 0
                  ? styles.filtersGroup
                  : ''
              }
            >
              {displayFilters &&
                filters.length > 0 &&
                filters
                  .filter(filter => !excludeFilterValues.includes(filter.value))
                  .map(filter => (
                    <Button
                      key={`${filter.field}-${filter.value}`}
                      iconLeft="svg/material-design-icons/navigation/close"
                      minWidth="0"
                      raised={false}
                      text={getFilterLabel(filter.value)}
                      onClick={() => handleFilterClick(filter)}
                      className={styles.button}
                    />
                  ))}
            </div>
          </div>
          {showClearAllFilters && filters?.length > 0 && (
            <div className={styles.clearFiltersButton}>
              {clearAllFiltersButton}
            </div>
          )}
        </div>
      </div>
    )
  )
}

export default RiverSubHeader
