import { takeEvery, call, put } from 'redux-saga/effects'
import { actions } from './LoginSlice'
import { push } from 'connected-react-router'
import { notificationsEnqueue } from 'src/features/notifications/NotificationsSlice'
import { notificationDuration as duration } from 'src/constants'
import { logPush } from 'src/features/contactUs/ContactUsSlice'
import FW5MLError from 'src/services/FW5ML/FW5MLError'
import {
  USER_DOES_NOT_EXIST,
  USER_SUBSCRIPTION_INACTIVE,
  SSO_REDIRECT
} from 'src/services/FW5ML/errorCodes'

function* requestPasswordlessLogin(services, action) {
  const queryString = services('queryString')
  const UserRepository = services('UserRepository')
  const Piwik = services('Piwik')
  const { payload: email } = action
  try {
    const metadata = {}
    const redirection = queryString.get('r')
    if (redirection) {
      metadata.redirection = decodeURIComponent(redirection)
    }
    const { success, error, data } = yield call(
      [UserRepository, 'passwordlesslogin'],
      email,
      metadata
    )
    if (success) {
      yield put(actions.successPasswordlessLogin())
      const { test_access_token } = data
      if (process.env.NODE_ENV === 'development') {
        const newUrl = `http://localhost:3000?at=${test_access_token}`
        window.location.href = newUrl // This will reload the page with the new URL
      }
      yield call([Piwik, 'track'], 'login', 'login', 'request')
      return
    }

    if (error && error.code === SSO_REDIRECT) {
      yield call([Piwik, 'track'], 'login', 'error', 'sso-redirect')
      // User will be redirected to sso redirect
      const { redirect_to } = data
      window.location.replace(redirect_to)
    }
  } catch (e) {
    console.log(e)
    if (
      e instanceof FW5MLError &&
      (e.getCode() === USER_DOES_NOT_EXIST ||
        e.getCode() === USER_SUBSCRIPTION_INACTIVE)
    ) {
      yield put(logPush('Login', `Error while logging in: ${e.message}`))
      yield call(
        [Piwik, 'track'],
        'Notification',
        'error',
        'sign in: user is not registered'
      )
    } else {
      yield put(logPush('Login', `Error while logging in: ${e.message}`))
      yield put(
        notificationsEnqueue({
          message:
            'Something went wrong!  Please refresh the page and try again.',
          duration
        })
      )
      yield call([Piwik, 'track'], 'notification', 'error', 'could-not-sign-in')
      yield put(push('/contact-us'))
    }
    yield put(actions.errorPasswordlessLogin(e.message))
  }
}

export default function* watchLogin(services) {
  yield takeEvery(
    actions.requestPasswordlessLogin,
    requestPasswordlessLogin,
    services
  )
}
