import SvgIcon from '@mui/material/SvgIcon'

const FileIcon = props => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <defs>
      <path id="zafu1i1gva" d="M0 0h24v24H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="ysev7uor4b" fill="#fff">
        <use xlinkHref="#zafu1i1gva" />
      </mask>
      <path
        d="M4.327 3.638a3 3 0 0 1 1.311-1.311C6.28 2 7.12 2 8.8 2h6.402c1.68 0 2.732.11 3.16.327a3 3 0 0 1 1.311 1.311C20 4.28 20 5.12 20 6.8V17.202c0 1.68-.11 2.732-.327 3.16a3 3 0 0 1-1.311 1.311C17.72 22 16.88 22 15.2 22H8.798c-1.68 0-2.732-.11-3.16-.327a3 3 0 0 1-1.311-1.311C4 19.72 4 18.88 4 17.2V6.798c0-1.68.11-2.732.327-3.16zM14 11H8m2 4H8m8-8H8"
        stroke="#B3B8DB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0,0"
        mask="url(#ysev7uor4b)"
      />
    </g>
  </SvgIcon>
)
export default FileIcon
