import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Page from 'src/components/Page'
import MyAccountForm from './components/MyAccountForm/MyAccountFormContainer'
import MyInterests from 'src/features/myInterests/MyInterestsContainer'
import MySavedSearches from 'src/features/mySavedSearches/containers/MySavedSearchesContainer'
import MyNewsletters from 'src/features/myNewsletters/containers/MyNewslettersContainer'
import commonStyles from 'src/styles/common.module.sass'
import Tab from 'src/lib/ui/Tabs/Tab'
import Tabs from 'src/lib/ui/Tabs/Tabs'
import styles from './MyAccountPage.module.sass'
import { Route, Link, Redirect } from 'react-router-dom'
import { SessionContext } from 'src/features/session/SessionContext'
import { withProtectionAndRedirection } from 'src/components/LicenseProtected'
import { licenseTypes } from 'src/services/FW5ML/parsers/UserParser'
import { getConfig } from 'src/features/config/ConfigSelector'
import { siteName } from 'src/utils/oneCodeBase'
import cx from 'classnames'

const ProtectedInterests = ({ forAll = false, ...props }) => {
  if (siteName === 'Reports') {
    return <Redirect to="/contact-us" />
  }

  if (forAll) return <MyInterests {...props} />

  const ProtectedComponent = withProtectionAndRedirection(
    withProtectionAndRedirection(MyInterests, {
      showFor: {
        license: licenseTypes.LICENSE_PLUS,
        paywall: 'plus_only',
        subtype: 'my_interests'
      }
    }),
    {
      showFor: { authenticated: true },
      redirectForOthers: '/sign-in'
    }
  )
  return <ProtectedComponent {...props} />
}

ProtectedInterests.propTypes = {
  forAll: PropTypes.bool
}

const ProtectedSavedSearches = ({ forAll = false, ...props }) => {
  if (forAll) return <MySavedSearches {...props} />

  const ProtectedComponent = withProtectionAndRedirection(
    withProtectionAndRedirection(MySavedSearches, {
      showFor: {
        license: licenseTypes.LICENSE_PLUS,
        paywall: 'plus_only',
        subtype: 'my_saved_searches'
      }
    }),
    {
      showFor: { authenticated: true },
      redirectForOthers: '/sign-in'
    }
  )

  return <ProtectedComponent {...props} />
}
ProtectedSavedSearches.propTypes = {
  forAll: PropTypes.bool
}

const MyAccountPage = ({ history }) => {
  const { userHasLicense = () => false } = useContext(SessionContext)
  const config = useSelector(getConfig('my_account_settings')) || {}
  const {
    my_information = {},
    tabs = [],
    my_newsletters = {},
    saved_searches = {},
    my_interests = {}
  } = config
  const fields = my_information?.fields
  const { hide_top_section = false } = my_newsletters

  return (
    <Page>
      <div className={cx(commonStyles.content, styles.page)}>
        <div className={styles.tabContainer}>
          <Tabs
            value={history.location.pathname}
            className={styles.tabs}
            mode="scroll"
          >
            {tabs.map(tab =>
              tab.plus ? (
                userHasLicense(licenseTypes.LICENSE_PLUS) && (
                  <Tab
                    key={tab.key}
                    showsActiveBar
                    className={styles.tab}
                    data-piwik-class="my-account-tab"
                    value={`/my-account/${tab.to}`}
                  >
                    <Link to={`/my-account/${tab.to}`}>{tab.key}</Link>
                  </Tab>
                )
              ) : (
                <Tab
                  key={tab.key}
                  showsActiveBar
                  className={styles.tab}
                  data-piwik-class="my-account-tab"
                  value={`/my-account/${tab.to}`}
                >
                  <Link to={`/my-account/${tab.to}`}>{tab.key}</Link>
                </Tab>
              )
            )}
          </Tabs>
        </div>
        <Route
          path="/my-account/info"
          render={routerProps => (
            <MyAccountForm fields={fields} {...routerProps} />
          )}
        />
        <Route
          path="/my-account/interests"
          render={routerProps => (
            <ProtectedInterests
              forAll={my_interests?.for_all}
              {...routerProps}
            />
          )}
        />
        <Route
          path="/my-account/searches"
          render={routerProps => (
            <ProtectedSavedSearches
              forAll={saved_searches?.for_all}
              {...routerProps}
            />
          )}
        />
        <Route
          path="/my-account/newsletters"
          render={routerProps => (
            <MyNewsletters hideTopNewslettersSection={hide_top_section} />
          )}
        />
      </div>
    </Page>
  )
}

MyAccountPage.propTypes = {
  history: PropTypes.object
}

export default MyAccountPage
