import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { tagWithCategory } from '../../../../utils/propTypes'
import Button from '../../../../lib/ui/Buttons/Button'
import Icon from '../../../../lib/ui/Icon'
import SelectMenu from '../../../../lib/ui/SelectMenu'
import Spinner from '../../../../lib/ui/Spinner'
import styles from './Autosuggest.module.sass'
import _throttle from 'lodash/throttle'
import { filterLabelsConfigModule } from '../../../../config/sites/river'
import Typography from '../../../../lib/ui/Typography'

const { getKey } = filterLabelsConfigModule

class Autosuggest extends Component {
  constructor(props) {
    super(props)

    this.state = {
      addingInterest: '',
      suggestionSelected: false,
      suggestionHighlighted: false,
      fullInterest: {},
      active: false
    }

    const {
      onSuggestionRequested = () => {
        /* NOOP */
      }
    } = this.props

    this.hndDebouncedChange = _throttle(
      value => onSuggestionRequested(value),
      250,
      { leading: true }
    )
  }

  // TODO: we have 3 instances of this function we need to probably consolidate
  // into a hook
  hndAddInterest = e => {
    const {
      onAddInterest = () => {
        /* NOOP */
      }
    } = this.props
    const { fullInterest } = this.state
    onAddInterest(fullInterest)
    this.setState({
      addingInterest: '',
      fullInterest: {},
      suggestionSelected: false
    })
  }

  hndChange = (e, { newValue: value, method }) => {
    const TrimValue = value.trim()
    const { addingInterest: oldAddingInterest } = this.state
    this.setState(
      {
        addingInterest: value
      },
      () => {
        if (method === 'type') {
          if (TrimValue.length >= 3 && TrimValue !== oldAddingInterest.trim()) {
            this.hndDebouncedChange(TrimValue)
          } else if (value.length === 0) {
            this.props.clearSuggestInterest()
          }
          // Hide add button if typed value no longer matches a tag
          const { fullInterest } = this.state
          const { tag } = fullInterest
          if (tag && tag.trim() !== TrimValue) {
            this.setState({
              suggestionSelected: false,
              fullInterest: {}
            })
          }
        }
      }
    )
  }

  hndSuggestionHighlighted = ({ suggestion }) => {
    if (suggestion) {
      const { addingInterest } = this.state
      const { key } = suggestion
      const [category, tag, related_tags] = key.split('::')
      if (addingInterest.toLowerCase() === tag.toLowerCase()) {
        this.setState({
          fullInterest: { category, tag, related_tags },
          suggestionHighlighted: true,
          suggestionSelected: true
        })
      }
    } else {
      this.setState({
        suggestionHighlighted: false
      })
    }
  }

  hndSuggestionSelected = (e, { suggestion }) => {
    const { key } = suggestion
    const [category, tag, related_tags] = key.split('::')
    this.setState({
      fullInterest: { category, tag, related_tags },
      suggestionSelected: true,
      addingInterest: tag
    })
  }
  hndOpen = () => {
    this.setState({ active: true })
  }
  hndClose = () => {
    this.setState({ active: false })
  }

  render() {
    const {
      suggestions = [],
      loadingSuggestions = false,
      insertingInterest = false,
      suggestionsMessage
    } = this.props
    const {
      addingInterest,
      suggestionSelected,
      suggestionHighlighted,
      active
    } = this.state
    const suggestionsOptions = !loadingSuggestions
      ? suggestions.reduce(
          (acc, cur) => ({
            ...acc,
            [`${cur.category}::${cur.tag}::${cur.related_tags}`]: `${
              cur.tag
            }::(${getKey(cur.category)})`
          }),
          {}
        )
      : undefined
    const displayAddButton =
      addingInterest && (suggestionSelected || suggestionHighlighted)
    const label =
      this.props?.autoSuggestConfig?.label || 'Add interest to MyFW+'
    return (
      <div className={styles.container}>
        {active ? (
          <div className={styles.selectContainer}>
            <SelectMenu
              label={label}
              showIcon={false}
              onChange={this.hndChange}
              onSuggestionSelected={this.hndSuggestionSelected}
              onSuggestionHighlighted={this.hndSuggestionHighlighted}
              value={addingInterest}
              options={suggestionsOptions}
              loading={loadingSuggestions}
              inputFocus={active}
              inputClassName={displayAddButton ? styles.rightSpacing : null}
              passthrough
              sort={false}
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              error={suggestionsMessage}
              rightElements={
                <>
                  {insertingInterest ? (
                    <div className={styles.selectChild}>
                      <Spinner size={24} fillColor="transparent" />
                    </div>
                  ) : (
                    displayAddButton && (
                      <Icon
                        className={styles.selectChild}
                        icon="svg/material-design-icons/content/add_circle"
                        colorTier="secondary"
                        onClick={this.hndAddInterest}
                        button
                      />
                    )
                  )}
                </>
              }
            />
            <Typography className={styles.note}>
              Please note this functionality is a beta version and may return
              invalid results.
            </Typography>
          </div>
        ) : (
          <Button
            className={styles.button}
            onClick={this.hndOpen}
            textCase="none"
            raised={false}
            iconLeft="svg/material-design-icons/content/add_circle"
            tier="action"
          >
            {label}
          </Button>
        )}
        {active && (
          <Icon
            className={styles.close}
            button
            icon="svg/material-design-icons/navigation/close"
            size={42}
            onClick={this.hndClose}
          />
        )}
      </div>
    )
  }
}

Autosuggest.propTypes = {
  /**
   * Called when autocomplete changes. Debounced.
   */
  onSuggestionRequested: PropTypes.func,
  /**
   * Called when autocomplete changes. Debounced.
   */
  onAddInterest: PropTypes.func,
  /**
   * Array of suggestions based on the interest typed in
   */
  suggestions: PropTypes.arrayOf(tagWithCategory),
  /**
   * If `true`, suggestions are being loaded
   */
  loadingSuggestions: PropTypes.bool,
  /**
   * If `true`, an interest is being inserted
   */
  insertingInterest: PropTypes.bool,
  /**
   * Suggestion feedback displayed as the field error
   */
  suggestionsMessage: PropTypes.string,
  /**
   * More props
   */
  clearSuggestInterest: PropTypes.func,
  autoSuggestConfig: PropTypes.object
}

export default Autosuggest
