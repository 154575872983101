import { createSelector } from 'reselect'
import { getConfig } from 'src/features/config/ConfigSelector'

export const getLandingPagesRoutes = () =>
  createSelector(getConfig('landing_pages'), config => {
    try {
      return config.pages.map(page => `/${page.uri_path}`)
    } catch (e) {
      return []
    }
  })
