import React from 'react'
import PropTypes from 'prop-types'
import Typography from 'src/lib/ui/Typography'
import commonStyles from 'src/styles/common.module.sass'
import styles from './Heading.module.sass'

const Heading = ({ title }) => {
  return (
    <header className={commonStyles.header}>
      <Typography className={styles.auxTag} type="title">
        {title}
      </Typography>
    </header>
  )
}

Heading.propTypes = {
  title: PropTypes.string
}

export default Heading
