import React, { Fragment, useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import RiverBody from './children/body/RiverBody'
import {
  SIDEBAR_FILTERS,
  SIDEBAR_INTERESTS_AND_FILTERS
} from '../../constants/sidebar'
import styles from '../../River.module.sass'
import RiverHeader from './children/header/RiverHeader'
import RiverSubHeader from './children/header/RiverSubHeader'
import SearchBar from './children/search/SearchBar'
import SearchForm from './children/search/SearchForm'
import NoResults from './children/search/NoResults'
import DisplayTabsBox from './children/myFW/DisplayTabsBox'
import GroupedArticles from './children/myFW/GroupedArticles'
import EventRiverBody from './children/events/EventsRiverBody'
import MedicalAbstractsToggle from './children/medicalAbstracts/MedicalAbstractsToggle'
import ExternalSiteArticlesToggle from './children/externalSites/ExternalSiteArticlesToggle'
import { ClearAllFiltersButton } from './children/clearAllFilters'
import RiverSidebar from '../RiverSidebarContent/children/sidebar/RiverSidebar'
import * as selectors from '../../RiverSelector'
import {
  contentRiversConfigModule,
  externalSitesConfigModule
} from 'src/config/sites/river'
import { siteName } from 'src/utils/oneCodeBase'

const { rivers, mediaEventTypes } = contentRiversConfigModule
const externalSitesConfig = externalSitesConfigModule.default

const RiverMainContent = ({
  riverId,
  riverKey,
  riverConfig,
  loading,
  riverStateConfig,
  filtersOpen,
  toggleFilters,
  onClearAllFilters = () => {}
}) => {
  const myfwTabValue = useSelector(
    state => state.contentRiverReducer.myfwTabValue
  )
  const riverArgs = useSelector(selectors.getRiverArgs)
  const riverSlice = useSelector(selectors.getContentRiver)
  const filters = useSelector(selectors.getUsedRiverFilters, shallowEqual)

  //Verifies that the filters were triggered by user selection
  const { filtersModified } = useSelector(
    state => state.contentRiverReducer[riverId] || {},
    shallowEqual
  )

  const {
    results,
    sponsoredHeadlines,
    count,
    page: currentPage = 0,
    featuredResults = []
  } = riverSlice

  const {
    title,
    sidebar = SIDEBAR_FILTERS,
    hideSubHeader,
    excludeFilterValues,
    viewCount,
    showMedicalAbstractsToggle,
    showExternalArticlesToggle = false,
    showGroupByInterestTabs = false,
    showFeaturedContent = false,
    mediaEventConfig = {}
  } = riverConfig

  //We extract any filters from the url
  const urlFilters = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search)

    return [...urlParams.entries()].reduce((acc, [key, value]) => {
      const decodedValue = decodeURIComponent(value).trim()

      const values =
        decodedValue
          .match(/"[^"]+"|[^,]+/g)
          ?.map(v => v.replace(/"/g, '').trim()) || []

      const uniqueValues = [
        ...new Set(values.map(val => val.replace(/\s+/g, ' ')))
      ]

      uniqueValues.forEach(val => acc.push({ field: key, value: val }))
      return acc
    }, [])
  }, [window.location.search])

  const articlesLoaded = count > 0
  const isSearchFormPage = riverId === ''
  const isSearchResultsPage = riverKey === 'search'
  const isRiverPage = !isSearchFormPage && !isSearchResultsPage

  const riverFilters = isRiverPage ? urlFilters : filters

  const showFilterActions = useMemo(() => {
    if (isSearchFormPage || isSearchResultsPage) return true
    return !loading && isRiverPage && (filtersModified || urlFilters.length > 0)
  }, [
    isSearchFormPage,
    isSearchResultsPage,
    loading,
    isRiverPage,
    filtersModified,
    urlFilters.length
  ])

  const getHeaderTitle = title => {
    if (showFeaturedContent && (featuredResults.length || loading))
      return `More ${title} stories`
    return title
  }

  const sideBarProps = {
    riverId,
    sidebar,
    loading,
    filtersOpen,
    isRiverPage,
    flex: riverStateConfig?.flex ?? 'column',
    ...riverStateConfig?.sidebar
  }

  //Reusable button across all sites
  const clearAllFiltersButton = () => (
    <ClearAllFiltersButton
      show={!loading && filters?.length > 0}
      size="medium"
      onClearAllFilters={onClearAllFilters}
    />
  )

  const showClearAllInToggles = siteName !== 'Reports' && !isRiverPage
  const showClearAllInSubHeader = siteName === 'Reports' || isRiverPage

  return (
    <>
      <RiverHeader
        title={getHeaderTitle(title)}
        showFilterMenu={
          isRiverPage &&
          [SIDEBAR_FILTERS, SIDEBAR_INTERESTS_AND_FILTERS].includes(sidebar)
        }
        toggleFilters={toggleFilters}
        riverKey={riverKey}
        riverArgs={riverArgs}
      />

      {riverStateConfig?.flex === 'row' && (
        <div className={styles.topSidebar}>
          <div>
            <RiverSidebar toggleFilters={toggleFilters} {...sideBarProps} />
          </div>
        </div>
      )}

      {!loading && !isSearchFormPage && !isRiverPage && (
        <SearchBar
          toggleFilters={toggleFilters}
          articlesLoaded={articlesLoaded}
          riverId={riverId}
        />
      )}

      {showGroupByInterestTabs && <DisplayTabsBox value={myfwTabValue} />}

      <div className={styles.toggleContainer}>
        <div className={styles.togglesGroup}>
          {showExternalArticlesToggle &&
            externalSitesConfig.map((externalSite, index) => (
              <ExternalSiteArticlesToggle
                query={riverArgs}
                loading={loading}
                searchConfig={externalSite?.searchConfig}
                label={externalSite?.label}
                key={`external_site_articles_${index}`}
              />
            ))}

          {showMedicalAbstractsToggle && (
            <MedicalAbstractsToggle
              query={riverArgs}
              loading={loading}
              riverId={riverId}
            />
          )}
        </div>
        {/* This one only shows up for Pharma and HT */}
        {showClearAllInToggles && showFilterActions && clearAllFiltersButton()}
      </div>

      <RiverSubHeader
        hide={
          loading || isSearchFormPage || hideSubHeader || !showFilterActions
        }
        total={count}
        query={riverArgs}
        excludeFilterValues={excludeFilterValues}
        viewCount={viewCount}
        riverId={riverId}
        showFeaturedContent={showFeaturedContent}
        showClearAllFilters={showClearAllInSubHeader && showFilterActions}
        clearAllFiltersButton={clearAllFiltersButton()}
        isRiverPage={isRiverPage}
        riverFilters={riverFilters}
      />

      {myfwTabValue === 'byInterest' && riverKey === rivers.MY_FW ? (
        <GroupedArticles />
      ) : (
        <Fragment>
          {mediaEventTypes.includes(riverKey) ? (
            <EventRiverBody
              loading={!isSearchFormPage && loading}
              results={results}
              config={mediaEventConfig}
            />
          ) : (
            <RiverBody
              loading={!isSearchFormPage && loading}
              results={results}
              sponsoredHeadlines={sponsoredHeadlines}
              pageStart={currentPage}
              isRiverPage={isRiverPage}
              riverStateConfig={riverStateConfig}
              riverId={riverId}
            />
          )}
        </Fragment>
      )}

      {!isRiverPage &&
        !(loading && isSearchResultsPage) &&
        !(articlesLoaded && isSearchResultsPage) && <SearchForm />}

      {!loading && !articlesLoaded && results.length <= 0 && (
        <NoResults riverKey={riverKey} />
      )}
    </>
  )
}

export default RiverMainContent
