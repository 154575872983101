import React, { useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './Article.module.sass'
import classNames from 'classnames/bind'
import { addExtraData } from 'src/features/paywall/PaywallSlice'
import ArticleHeader from './children/ArticleHeader'
import ArticleBody from './children/ArticleBody'
import ArticleFooter from './children/ArticleFooter'
import { TitleContext } from 'src/features/title/TitleContext'
import { getConfig } from 'src/features/config/ConfigSelector'

const cx = classNames.bind(styles)

const Article = ({
  story,
  isLimited = false,
  simplePage = false,
  setNotifyMeVisible = () => {}
}) => {
  const [liked, setLiked] = useState(story?.likedByProfile)
  const [likeCount, setLikeCount] = useState(story?.likes)
  const dispatch = useDispatch()
  const setStoryTitle = useContext(TitleContext)
  const configSettings = useSelector(getConfig('article'))

  const onLikeUpdate = () => {
    const likeUpdated = !liked
    setLiked(likeUpdated)

    if (likeUpdated) {
      setLikeCount(item => item + 1) //liked
    } else {
      setLikeCount(item => item - 1) //unliked
    }
  }

  const getTitle = () => {
    if (story.congress) {
      return `${story.title}: Presented at ${story.congress.join(', ')}`
    }
    return story.title
  }

  const title = getTitle()

  useEffect(() => {
    setStoryTitle(title)
    dispatch(addExtraData({ extra: story.reportSettings }))
  }, [])

  const headerProps = {
    story,
    title,
    liked,
    likeCount,
    onLikeUpdate,
    configSettings,
    simplePage
  }

  const bodyProps = { story, simplePage, configSettings }

  const footerProps = {
    ...headerProps,
    setNotifyMeVisible
  }

  return (
    <div data-clarity-mask={story?.flags?.isPaid}>
      <article
        className={cx(
          styles.singleStory,
          configSettings?.showDividers && styles.borderBottom,
          isLimited && styles.hideOverflow
        )}
      >
        <ArticleHeader {...headerProps} />
        <ArticleBody {...bodyProps} />

        {/* Simple page does not have footer */}
        {!simplePage && <ArticleFooter {...footerProps} />}
      </article>
    </div>
  )
}

export default Article
