import { all, call, put, takeEvery, select } from 'redux-saga/effects'
import { actions as followUpPromptsActions } from './FollowUpPromptsSlice'
import { getLanguageCode } from '../translation/TranslationSelector'

function* followUpPromptsRequest(services, { payload }) {
  const DougallService = services('DougallService')

  try {
    const userLanguage = yield select(getLanguageCode())
    const [languageCode] = userLanguage.split('-')

    const { result } = yield call([DougallService, 'followUpPromptsRequest'], {
      ...payload,
      type: 'followup',
      languageCode
    })
    const { followUpPrompts } = result
    yield put(
      followUpPromptsActions.requestSuccess({ prompts: followUpPrompts })
    )
  } catch (e) {
    yield put(followUpPromptsActions.requestFail())
  }
}

export default function* watchFollowUpPrompts(services) {
  yield all([
    takeEvery(
      followUpPromptsActions.requestPrompts,
      followUpPromptsRequest,
      services
    )
  ])
}
