import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AddInterests from 'src/components/AddInterests'
import NotifyMe from 'src/components/NotifyMe'
import SocialButtons from 'src/components/SocialButtons'
import styles from '../Article.module.sass'
import isEmpty from 'lodash/isEmpty'
import copyToClipboard from 'src/utils/copyToClipboard'
import { notificationsEnqueue } from 'src/features/notifications/NotificationsSlice'
import { piwikRequest } from 'src/features/piwik/PiwikSlice'
import ReportArticleSections from './reports/ReportArticleSections'
import { getConfigFilteredTags } from 'src/features/config/ConfigSelector'
import classNames from 'classnames/bind'
import ActionButtons from 'src/features/actionButtons/ActionButtons'
import ReportButtons from './reports/ReportButtons'
import { siteName, socialMediaInfo } from 'src/utils/oneCodeBase'
import { getDisabled } from 'src/features/singleStory/SingleStorySelector'
import { SessionContext } from 'src/features/session/SessionContext'
import { getPendingForInsertion } from 'src/features/myInterests/MyInterestsSelector'
import { treeToArray } from 'src/utils'
import _get from 'lodash/get'

const cx = classNames.bind(styles)

const ArticleFooter = ({
  story,
  title,
  liked,
  likeCount,
  onLikeUpdate,
  setNotifyMeVisible,
  configSettings
}) => {
  const { authenticated, user } = useContext(SessionContext)
  const pendingForInsertion = useSelector(getPendingForInsertion()) ?? []
  const disabledTags = useSelector(getDisabled()) ?? []
  const dispatch = useDispatch()

  const filteredTags =
    useSelector(
      getConfigFilteredTags({
        interests: story.tags
      })
    ) || story.tags

  const getUserTags = () => {
    if (!user?.profile_id) return []
    return treeToArray(_get(user, 'interests.stated', {}), (category, tag) => ({
      category,
      tag
    }))
  }

  const getDisabledTags = () => {
    return [...pendingForInsertion, ...disabledTags, ...getUserTags()]
  }

  const hndSocialIconClick = socialNetwork => e => {
    e.preventDefault()

    // Piwik tracking
    dispatch(
      piwikRequest({
        customVarUpdate: {
          key: 'article_id',
          value: story.id
        },
        tracking: {
          category: 'article',
          action: 'share-click',
          name: socialNetwork === 'link' ? 'copy-url' : socialNetwork
        }
      })
    )

    if (socialNetwork === 'link') {
      copyToClipboard(window.location)
      dispatch(
        notificationsEnqueue({
          message: 'Article URL was copied to your clipboard'
        })
      )
      return
    }
    const linkBuilders = {
      twitter: () => {
        const message = encodeURIComponent(
          `${title} ${window.location} via ${socialMediaInfo?.twitter}`
        )
        return `https://twitter.com/intent/tweet?text=${message}`
      },
      linkedin: () => {
        const summary =
          story.body.length > 50 ? story.body.slice(0, 50) + '...' : story.body
        return `http://www.linkedin.com/shareArticle?mini=true&url=${
          window.location
        }&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(
          summary
        )}&source=${socialMediaInfo?.linkedin}`
      }
    }
    if (!linkBuilders[socialNetwork]) return
    const link = linkBuilders[socialNetwork]()
    window.open(link, 'pop', 'width=600, height=400, scrollbars=no')
  }

  const {
    showSocialLinks = true,
    showDividers = true,
    showActionBar = false,
    showInterests = true
  } = configSettings

  return (
    <div>
      {siteName !== 'Reports' && (
        <NotifyMe
          article={{
            id: story.id,
            type: story.type,
            title,
            tags: story.allTags,
            publication_date: story.published
          }}
          setModalVisible={setNotifyMeVisible}
          onParentClick={() => setNotifyMeVisible(!user.profile_id)}
        />
      )}

      {/* Firstword Report specific content */}
      {siteName === 'Reports' && story?.reportSettings && (
        <>
          <ReportButtons
            authenticated={authenticated}
            user={user}
            articleId={story.id}
            reportSettings={story.reportSettings}
          />
          <ReportArticleSections {...story.reportSettings} />
        </>
      )}

      {showActionBar && (
        <div
          className={styles.actionButtonsContainer}
          style={{ marginTop: '40px' }}
        >
          <ActionButtons
            learnMoreProps={{ active: false }}
            reportSettings={story.reportSettings}
            articleId={story.id}
            articleBody={story.body}
            articleTitle={title}
            singleStory={true}
            likeProps={{
              liked,
              likeCount,
              action: onLikeUpdate
            }}
          />
        </div>
      )}

      <footer className={cx(styles.footer, showDividers && styles.borderTop)}>
        {showInterests && !isEmpty(filteredTags) && (
          <AddInterests
            interests={filteredTags}
            disabledTags={getDisabledTags()}
          />
        )}

        {showSocialLinks && <SocialButtons onClick={hndSocialIconClick} />}
      </footer>
    </div>
  )
}

export default ArticleFooter
