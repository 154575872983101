import React, { Fragment } from 'react'
import Typography from 'src/lib/ui/Typography'
import Uncap from 'src/components/Uncap'
import MyInterests from 'src/features/myInterests/MyInterestsContainer'
import commonStyles from 'src/styles/common.module.sass'
import RecommendedForYouSidebar from '../recommendedForYou/RecommendedForYouSidebar'
import { ProtectedArea, ShowFor } from 'src/components/LicenseProtected'
import { licenseTypes } from 'src/services/FW5ML/parsers/UserParser'
import {
  SIDEBAR_FILTERS,
  SIDEBAR_INTERESTS,
  SIDEBAR_INTERESTS_AND_FILTERS
} from '../../../../constants/sidebar'

const RiverSidebar = ({
  sidebar,
  isRiverPage,
  showMyFW = true,
  showFilters = true,
  showInterests = true,
  showTitle = true,
  showForAll = false,
  flex = 'row',
  riverId,
  ...props
}) => {
  const sidebars = {
    [SIDEBAR_FILTERS]: ({ loading, toggleFilters, filtersOpen }) => (
      <RecommendedForYouSidebar
        busy={loading}
        toggleFilters={toggleFilters}
        filtersOpen={filtersOpen}
        isRiverPage={isRiverPage}
        showMyFW={showMyFW}
        showFilters={showFilters}
        showTitle={showTitle}
        showForAll={showForAll}
        flex={flex}
        riverId={riverId}
      />
    ),
    [SIDEBAR_INTERESTS]: () => (
      <ProtectedArea>
        {showInterests && (
          <ShowFor license={licenseTypes.LICENSE_PLUS}>
            <Fragment>
              <Typography className={commonStyles.title} type="title">
                <Uncap>MyFW+</Uncap> Interests
              </Typography>
              <MyInterests partial />
            </Fragment>
          </ShowFor>
        )}
      </ProtectedArea>
    ),
    [SIDEBAR_INTERESTS_AND_FILTERS]: ({
      loading,
      toggleFilters,
      filtersOpen
    }) => (
      <Fragment>
        <ProtectedArea>
          {showInterests && (
            <ShowFor license={licenseTypes.LICENSE_PLUS}>
              <Fragment>
                <Typography className={commonStyles.title} type="title">
                  <Uncap>MyFW+</Uncap> Interests
                </Typography>
                <MyInterests partial />
              </Fragment>
            </ShowFor>
          )}
        </ProtectedArea>
        <RecommendedForYouSidebar
          busy={loading}
          toggleFilters={toggleFilters}
          filtersOpen={filtersOpen}
          isRiverPage={isRiverPage}
          showMyFW={showMyFW}
          showFilters={showFilters}
          showTitle={showTitle}
          flex={flex}
          riverId={riverId}
        />
      </Fragment>
    )
  }
  const renderSidebar = sidebars[sidebar] ? sidebars[sidebar] : sidebars.filters
  return renderSidebar(props)
}

export default RiverSidebar
