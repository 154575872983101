import { reduxForm, isDirty } from 'redux-form'
import { compose } from 'redux'
import { connect } from 'react-redux'
import withCatalogs from 'src/features/catalogs/withCatalogs'
import MyAccountForm from './index'
import validate from './validate'
import {
  licenseTypes,
  audienceTypes
} from 'src/services/FW5ML/parsers/UserParser'
import { actions } from '../../MyAccountSlice'
import * as flags from 'src/features/asyncFlags/AsyncFlagsSelector'
import dayjs from 'dayjs'

const mapStateToProps = state => {
  const expirationDate = state.session.user.expiration
    ? dayjs(state.session.user.expiration).format('MMMM DD, YYYY')
    : null
  return {
    initialValues: {
      salutation: state.session.user.salutation,
      email: state.session.user.email,
      first_name: state.session.user.firstName,
      last_name: state.session.user.lastName,
      company: state.session.user.company,
      phone_number: state.session.user.phone_number,
      job_title: state.session.user.job_title,
      country: state.session.user.address.country,
      job_function: state.session.user.job_function,
      subscription_type:
        state.session.user.license === licenseTypes.LICENSE_PLUS
          ? audienceTypes.PLUS
          : audienceTypes.FREE,
      subscription_expiry: expirationDate
    },
    error: flags.getError('accountUpdate')(state),
    busy: flags.isBusy('accountUpdate')(state),
    isDirty: isDirty('updateAccount')(state),
    settings: state.mySettingsReducer.settings,
    settingsBusy:
      flags.isBusy('accountSettingsLoad')(state) ||
      flags.isBusy('accountSettingsUpdate')(state)
  }
}

const mapDispatchToProps = dispatch => ({
  onSubmit: account => dispatch(actions.requestAccountUpdate(account))
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withCatalogs({
    jobFunctions: 'jobFunction',
    country: 'country'
  }),
  reduxForm({
    form: 'updateAccount',
    validate,
    touchOnBlur: false,
    destroyOnUnmount: false,
    enableReinitialize: false
  })
)(MyAccountForm)
