import { useState } from 'react'
import LoginRegisterModal from 'src/components/LoginRegisterModalPage/components/LoginRegisterModal'
import SingleStory from 'src/components/SingleStory'
import styles from 'src/features/singleStory/SingleStory.module.sass'

const Story = props => {
  const [notifyMeVisible, setNotifyMeVisible] = useState(false)

  return (
    <div>
      {notifyMeVisible && (
        <LoginRegisterModal
          loginConfig={{ header: 'Sign In to be notified of the results' }}
          registerConfig={{
            header: 'Register to be notified of the results'
          }}
          modalClassName={styles.registerModal}
          showCloseBtn={true}
          setModalVisible={setModalVisible}
        />
      )}

      <SingleStory
        {...props}
        setNotifyMeVisible={setNotifyMeVisible}
        simplePage={false}
      />
    </div>
  )
}

export default Story
