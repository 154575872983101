import React, { useContext, useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import Icon from 'src/lib/ui/Icon'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styles from './Header.module.sass'
import { SessionContext } from 'src/features/session/SessionContext'
import ReactHtmlParser from 'react-html-parser'
import { licenseTypes } from 'src/services/FW5ML/parsers/UserParser'
import Typography from 'src/lib/ui/Typography'
import { getConfig } from 'src/features/config/ConfigSelector'
import HeaderLinks from 'src/components/HeaderLinks'
import SearchBarHeader from 'src/components/SearchBarHeader'
import HeaderItem from 'src/components/HeaderItem'
import useMediaSize from 'src/hooks/useMediaSize'
import Menu from 'src/components/Menu'
import Notice from 'src/components/Notice'
import cx from 'classnames'
import { siteName } from 'src/utils/oneCodeBase'
import Logo from 'src/components/Logo'
import useLaunchDarkly from 'src/features/launchDarkly/useLaunchDarkly'

// TODO: remove this from sagas its a header!
const Header = ({
  handleSlideMenu,
  handleLeftSlideMenu,
  leftMenuOpen,
  rightMenuOpen,
  hideMenu = false,
  setStoryTitle = () => {}
}) => {
  const headerRef = useRef()
  const menuConfig = useSelector(getConfig('menus')) || {}
  const headerSettings = useSelector(getConfig('headerSettings')) || {}
  const { getFeatureFlagMenuItem } = useLaunchDarkly()
  const { userHasLicense = () => false } = useContext(SessionContext)
  const { fixed = true } = headerSettings
  const {
    main: menuItems = [],
    left: leftMenuItems = [],
    header = {}
  } = menuConfig
  const { sites, search = true } = header

  useEffect(() => {
    setStoryTitle('')
  }, [])

  const handleCloseMenus = () => {
    if (rightMenuOpen) handleSlideMenu()
    if (leftMenuOpen) handleLeftSlideMenu()
  }

  const [openSearch, setOpenSearch] = useState(false)
  const handleOpenSearch = () => setOpenSearch(!openSearch)

  const mediaSize = useMediaSize({
    mediaQueries: {
      small: '(max-width: 550px)',
      medium: '(max-width: 960px)'
    }
  })

  const existingSites = sites && sites.length > 0
  const existingMenuItems = menuItems && menuItems.length > 0
  const existingLeftMenuItems = leftMenuItems && leftMenuItems.length > 0

  let topSpace = 'topSpace'

  if (!existingSites && !existingMenuItems) {
    topSpace = 'simpleTopSpace'
  } else if (!existingSites) {
    topSpace = 'topSpaceNoTopBar'
  } else if (!existingMenuItems) {
    topSpace = 'topSpaceNoItems'
  }

  const topSpaceStyles = siteName === 'Edge' ? `${topSpace}Edge` : topSpace

  return (
    <>
      <header
        ref={headerRef}
        className={
          fixed ? cx(styles.header, styles.headerFixed) : styles.header
        }
      >
        {existingSites && (
          <div className={styles.headerBar} data-testid="Header_header-bar">
            <ul className={styles.sitesContainer}>
              {sites.map((siteMenu, i) => {
                const site = getFeatureFlagMenuItem(siteMenu)
                return (
                  site && (
                    <li key={`${site.label}${i + 1}`}>
                      {site.external ? (
                        <a className={styles.sites} href={site.to}>
                          <Typography className={styles.paragraph} paragraph>
                            {ReactHtmlParser(site.label)}
                          </Typography>
                        </a>
                      ) : (
                        <Link className={styles.sites} to={site.to}>
                          <Typography className={styles.paragraph} paragraph>
                            {ReactHtmlParser(site.label)}
                          </Typography>
                        </Link>
                      )}
                    </li>
                  )
                )
              })}
            </ul>
          </div>
        )}
        <div
          className={
            siteName === 'Pharma' ? styles.headerTopSmall : styles.headerTop
          }
        >
          <div className={styles.container}>
            <div
              className={
                !openSearch ? styles.iconContainer : styles.iconContainerOpen
              }
            >
              {/* Hamburger Icon  */}
              {mediaSize === 'large' && existingLeftMenuItems ? (
                <div
                  className={styles.hamburgerIconContainer}
                  data-testid="Header_hamburger-icon"
                >
                  <Icon
                    icon="svg/material-design-icons/navigation/menu"
                    iconSize={30}
                    className={styles.mobileLeftMenuButton}
                    onClick={handleLeftSlideMenu}
                  />
                </div>
              ) : (
                !leftMenuOpen &&
                existingLeftMenuItems && (
                  <div className={styles.hamburgerIconContainer}>
                    <Icon
                      icon="svg/material-design-icons/navigation/menu"
                      iconSize={30}
                      className={styles.mobileLeftMenuButton}
                      onClick={handleLeftSlideMenu}
                    />
                  </div>
                )
              )}
              {leftMenuOpen && (
                <Icon
                  icon="svg/material-design-icons/navigation/close"
                  iconSize={32}
                  className={styles.closeButton}
                  onClick={handleCloseMenus}
                  button
                />
              )}
              {/* Search bar */}
              {mediaSize === 'large' &&
                search &&
                (openSearch ? (
                  <HeaderItem className={styles.searchItem}>
                    <SearchBarHeader
                      handleOpenSearch={handleOpenSearch}
                      openSearch={openSearch}
                    />
                  </HeaderItem>
                ) : (
                  <HeaderItem icon>
                    <Icon
                      button
                      icon="svg/material-design-icons/action/search"
                      iconSize={32}
                      onClick={handleOpenSearch}
                      data-testid="Header_search-icon"
                    />
                  </HeaderItem>
                ))}
            </div>

            {/* FW Logo */}
            {!hideMenu && (
              <Link to="/" className={styles.link}>
                <Logo className={styles.logo} />
                <h1 className={styles.title}>
                  {`FirstWord ${siteName}${
                    userHasLicense(licenseTypes.LICENSE_PLUS) ? ' +' : ''
                  }`}
                </h1>
              </Link>
            )}
            {/* Person Icon */}
            {rightMenuOpen ? (
              <Icon
                icon="svg/material-design-icons/navigation/close"
                iconSize={32}
                className={styles.closeButton}
                onClick={handleCloseMenus}
                button
              />
            ) : (
              <div className={styles.mobileMenuButtonContainer}>
                <Icon
                  icon="svg/material-design-icons/action/account_circle"
                  iconSize={32}
                  className={styles.mobileMenuButton}
                  onClick={handleSlideMenu}
                  button
                />
              </div>
            )}
            <Menu handleSlideMenu={handleSlideMenu} hideMenu={hideMenu} />
          </div>
        </div>
        {existingMenuItems && (
          <div className={styles.headerItems} data-testid="Header_header-items">
            <HeaderLinks />
          </div>
        )}
      </header>

      <div className={fixed === true ? styles[topSpaceStyles] : ''}>
        <Notice />
      </div>
    </>
  )
}

Header.propTypes = {
  handleSlideMenu: PropTypes.func,
  handleLeftSlideMenu: PropTypes.func,
  leftMenuOpen: PropTypes.bool,
  rightMenuOpen: PropTypes.bool,
  hideMenu: PropTypes.bool,
  setStoryTitle: PropTypes.func
}

export default Header
