import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import SectionHeading from 'src/components/SectionHeading'
import { getStory } from 'src/features/singleStory/SingleStorySelector'
import styles from 'src/features/singleStory/SingleStory.module.sass'
import { siteName } from 'src/utils/oneCodeBase'

const ReadMoreTags = () => {
  const story = useSelector(getStory())

  if (!story?.tags || story?.tags?.length <= 0) return <></>

  const sortedTags = [...story.tags].sort((a, b) => {
    return a.tag.toLowerCase().localeCompare(b.tag.toLowerCase())
  })

  const title = siteName === 'Reports' ? 'Tags' : 'Read More'

  return (
    <>
      <SectionHeading title={title} />
      <section className={styles.more}>
        {sortedTags.map(interest => {
          const category =
            interest.category === 'fw_therapeutic_category'
              ? 'drug_classes'
              : interest.category
          return (
            <Link
              to={`/river/tag/${encodeURIComponent(
                category
              )}/${encodeURIComponent(interest.tag)}`}
              key={`${interest.category}-${interest.tag}`}
              className={styles.link}
              data-piwik-class="view-more-tag"
              data-piwik-name={`${interest.category}-${interest.tag}`}
            >
              {interest.tag}
            </Link>
          )
        })}
      </section>
    </>
  )
}
export default ReadMoreTags
