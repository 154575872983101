import React from 'react'
import PropTypes from 'prop-types'
// PSL components
import Icon from '../../../../lib/ui/Icon'
// Styles
import styles from './ActionIcon.module.sass'
import cx from 'classnames'
// Helpers/Hooks
import { useLocation } from 'react-router-dom'
import usePiwik from '../../../../hooks/usePiwik'
import { getNumericIconSize, getPage } from '../../helpers'

const ActionIcon = ({
  iconPath = '',
  active = true,
  action = () => {},
  iconSize = '',
  iconText = '',
  textAlign = '',
  buttonType = '',
  articleId = '',
  width
}) => {
  const { handleTrackingWithArticleIdProp } = usePiwik()
  const { pathname } = useLocation()

  const handleIconClick = () => {
    handleTrackingWithArticleIdProp(
      `action-bar_${getPage(pathname)}`,
      'click',
      buttonType,
      articleId
    )
    if (action) {
      action()
    }
  }

  const iconCloned = child => {
    return React.cloneElement(child, {
      style: { minWidth: iconSize, height: iconSize }
    })
  }
  const size = getNumericIconSize(iconSize)
  const sizeAndText = iconText && textAlign === 'below' ? size : size + 20
  return (
    <div
      className={cx(
        styles.iconContainer,
        styles[textAlign],
        !active && styles.disabled
      )}
      style={{ minWidth: iconSize, width: width ?? sizeAndText }}
    >
      {iconCloned(
        <Icon
          className={cx(styles.icon, { disabled: active })}
          icon={iconPath}
          colorTier={!active ? 'default' : 'secondary'}
          onClick={handleIconClick}
          button={active}
          iconSize={size}
        />
      )}
      {iconText && (
        <span
          style={{ overflow: 'hidden', minWidth: '17px' }}
          className={styles.iconText}
        >
          {iconText}
        </span>
      )}
    </div>
  )
}

ActionIcon.propTypes = {
  iconPath: PropTypes.string,
  active: PropTypes.bool,
  action: PropTypes.func,
  iconSize: PropTypes.string,
  iconText: PropTypes.string,
  textAlign: PropTypes.string,
  buttonType: PropTypes.string,
  articleId: PropTypes.string,
  width: PropTypes.string
}

export default ActionIcon
