import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  prestitialAd: {}
}

const adsSlice = createSlice({
  name: 'adsReducer',
  initialState,
  reducers: {
    registerAds: (state, { payload: ads }) => {
      const newAds = Object.keys(ads).reduce(
        (acc, key) => ({
          ...acc,
          [key]: {
            ...ads[key],
            ready: false
          }
        }),
        {}
      )
      return {
        ...state,
        ...newAds
      }
    },
    successAds: (state, { payload: { key, ad } }) => {
      state[key] = {
        ...state[key],
        ...ad,
        ready: true
      }
    },
    errorAds: (state, { payload: { key, error } }) => {
      state[key].error = error
      state[key].ready = true
    },
    cleanAds: () => initialState,
    prestitialAdRequest: state => {
      state.prestitialAd.ready = false
    },
    prestitialAdFinished: (state, { payload }) => {
      state.prestitialAd = {
        ...payload,
        ready: true
      }
    },
    prestitialAdReset: state => {
      state.prestitialAd.emptyContent = true
    },
    requestAds: () => {}
  }
})

export const { actions, reducer } = adsSlice

export const {
  registerAds,
  requestAds,
  successAds,
  errorAds,
  cleanAds,
  prestitialAdRequest,
  prestitialAdFinished,
  prestitialAdReset
} = actions

export default reducer
