import React, { Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { notificationsEnqueue } from 'src/features/notifications/NotificationsSlice'
import PropTypes from 'prop-types'
import styles from './Perspectives.module.sass'
import commonStyles from 'src/styles/common.module.sass'
import classNames from 'classnames/bind'
import SectionHeading from 'src/components/SectionHeading'
import Metadata from 'src/components/Metadata'
import Page from 'src/components/Page'
import FullImage from 'src/components/FullImage'
import Button from 'src/lib/ui/Buttons/Button'
import ReactHtmlParser from 'react-html-parser'
import copyToClipboard from 'src/utils/copyToClipboard'
import SocialButtons from 'src/components/SocialButtons'

const cx = classNames.bind(styles)

const Perspectives = ({ article, sponsor, articles, contributors }) => {
  const getUrl = () => window.location
  const getImgUrl = imgPath => `${window.location.origin}/${imgPath}`
  const dispatch = useDispatch()

  const hndSocialIconClick = socialNetwork => e => {
    e.preventDefault()
    if (socialNetwork === 'link') {
      copyToClipboard(getUrl())
      dispatch(
        notificationsEnqueue({
          message: 'URL was copied to your clipboard'
        })
      )
      return
    }

    const linkBuilders = {
      twitter: () => {
        const message = encodeURIComponent(
          `${article.twitterShare.text} ${getUrl()} via ${
            article.twitterShare.accounts
          }`
        )
        return `https://twitter.com/intent/tweet?text=${message}`
      },
      linkedin: () => {
        return `http://www.linkedin.com/shareArticle?mini=true&url=${getUrl()}&source=firstwordpharma.com`
      }
    }
    if (!linkBuilders[socialNetwork]) return
    const link = linkBuilders[socialNetwork]()
    window.open(link, 'pop', 'width=600, height=400, scrollbars=no')
  }

  return (
    <Page>
      {article && (
        <Metadata
          title={`FirstWord Perspectives - ${article.title}`}
          description={article.description}
        />
      )}
      <div className={commonStyles.content}>
        <section className={commonStyles.main}>
          {/* Article Header */}
          {article && (
            <Fragment>
              <SectionHeading title={article.title} gutterBottom paragraph />
              <div className={styles.subtitle}>
                <h2>{article.subtitle}</h2>
              </div>
            </Fragment>
          )}

          {/* Article items */}
          {articles && articles.length > 0 && (
            <div className={styles.articlesContainer}>
              {articles.map((articleItem, index) => {
                const mediaId = `${article.title} Episode ${index + 1} Poster`

                return (
                  <div
                    key={mediaId}
                    className={cx(
                      styles.article,
                      !articleItem.title ? styles.bgWhite : styles.bgGray
                    )}
                  >
                    {(!articleItem.multimediaFiles ||
                      (article.type === 'podcast' && articleItem.imageUrl)) && (
                      <div className={styles.imageContainer}>
                        <FullImage
                          className={styles.normalImage}
                          src={getImgUrl(articleItem.imageUrl)}
                          alt={mediaId}
                        />
                      </div>
                    )}
                    {articleItem.multimediaFiles &&
                      articleItem.multimediaType === 'video' && (
                        <video
                          className={styles.multimediaFile}
                          id={mediaId}
                          preload="auto"
                          controls
                          playsInline
                          controlsList="nodownload"
                          poster={getImgUrl(articleItem.imageUrl)}
                        >
                          {articleItem.multimediaFiles.map(file => (
                            <source
                              key={`${file.type} - ${file.url}`}
                              src={file.url}
                              type={file.type}
                            ></source>
                          ))}
                        </video>
                      )}

                    {articleItem.multimediaFiles &&
                      articleItem.multimediaType === 'audio' && (
                        <audio
                          id={mediaId}
                          preload="auto"
                          controls
                          className={cx(
                            styles.multimediaFile,
                            styles.multimediaFileAudio
                          )}
                        >
                          {articleItem.multimediaFiles.map(file => (
                            <source
                              key={`${file.type} - ${file.url}`}
                              src={file.url}
                              type={file.type}
                            ></source>
                          ))}
                        </audio>
                      )}

                    {!articleItem.multimediaFiles && (
                      <div
                        className={cx(
                          styles.multimediaFileTemp,
                          article.type === 'podcast'
                            ? styles.multimediaFileTempAudio
                            : styles.multimediaFileTempVideo
                        )}
                      ></div>
                    )}

                    {articleItem.title && (
                      <div className={cx(styles.articleDescription)}>
                        <h3>
                          {articleItem.titlePresentation
                            ? articleItem.titlePresentation
                            : `Episode ${index + 1}:`}
                        </h3>
                        {articleItem.title && <h4>{articleItem.title}</h4>}
                        {articleItem.description && (
                          <p>{ReactHtmlParser(articleItem.description)}</p>
                        )}
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          )}
        </section>

        {/* Sidebar Information */}
        <section className={commonStyles.sidebar}>
          {/* Sponsor */}
          {sponsor && (
            <section className={styles.sponsor}>
              <h1>
                {`A FirstWord Perspectives ${article.type} Series Presented By ${sponsor.name}`}
              </h1>
              <div
                className={cx(
                  styles.sponsorLogoSmall,
                  sponsor.name.toLowerCase()
                )}
              >
                <a href={sponsor.url} target="_blank" rel="noopener noreferrer">
                  <FullImage
                    src={getImgUrl(sponsor.smallLogoUrl)}
                    alt={`${sponsor.name} Logo`}
                  />
                </a>
              </div>
            </section>
          )}

          {/* Share Buttons */}
          {article && (
            <section className={styles.shareButtons}>
              <SocialButtons onClick={hndSocialIconClick} />
            </section>
          )}

          {/* Contributors */}
          {contributors && contributors.length > 0 && (
            <section className={cx(styles.contributors, styles.singleStory)}>
              <SectionHeading title="Contributors" />
              <div className={styles.contributorsList}>
                {contributors.map((contributor, index) => {
                  return (
                    <div key={`${contributor.name} - ${index + 1}`}>
                      {contributor.name && contributor.imageUrl && (
                        <div className={styles.contributor}>
                          <div className={styles.contributorImage}>
                            <FullImage
                              src={getImgUrl(contributor.imageUrl)}
                              alt={`${contributor.name} Photo`}
                            />
                          </div>
                          <div className={styles.contributorDetails}>
                            <div className={styles.title}>
                              <h3>{contributor.name}</h3>
                            </div>
                            {contributor?.credentials?.map(
                              (credential, index) => (
                                <p key={`${credential.name} - ${index + 1}`}>
                                  {credential}
                                </p>
                              )
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            </section>
          )}

          {/* Button Promotional Button */}
          {sponsor && (
            <a
              href={sponsor.promoButtonUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.promoButton}
            >
              <Button
                text={sponsor.promoButtonText}
                type="submit"
                tier="secondary"
                textCase="none"
                minWidth="100%"
              ></Button>
            </a>
          )}
        </section>
      </div>

      {/* Sponsor Footer */}
      {sponsor && (
        <div className={styles.sponsorFooterContainer}>
          <div className={styles.footerContainer}>
            <a
              href={sponsor.url}
              className={styles.sponsorLogo}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FullImage
                src={getImgUrl(sponsor.largeLogoUrl)}
                alt={`${sponsor.name} Logo`}
              />
            </a>
            <div className={cx(styles.sponsorText, styles.singleStory)}>
              <h2>About {sponsor.name}</h2>
              <p>{sponsor.description}</p>
            </div>
          </div>
        </div>
      )}
    </Page>
  )
}

Perspectives.propTypes = {
  /**
   * Main Article Information
   */
  article: PropTypes.object,
  /**
   *  Sponsor Information
   */
  sponsor: PropTypes.object,
  /**
   * Array of articles to display as audio/video
   */
  articles: PropTypes.array,
  /**
   * List of contributors
   */
  contributors: PropTypes.array
}

export default Perspectives
