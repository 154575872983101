import useMediaSize from 'src/hooks/useMediaSize'
import { useSelector } from 'react-redux'
import { getViewByName } from 'src/features/views/ViewsSelector'
import MyStoriesRiver from 'src/components/MyStoriesRiver'
import useViewProps from 'src/hooks/useViewProps'

const RecommendedForYou = () => {
  const view = useSelector(getViewByName('recommended for you')) || null
  const { viewsError, viewsLoading } = useViewProps()

  const mediaSize = useMediaSize({
    mediaQueries: {
      small: '(max-width: 550px)',
      medium: '(max-width: 960px)'
    }
  })

  return (
    <MyStoriesRiver
      view={view}
      viewsError={viewsError}
      viewsLoading={viewsLoading}
      imageSize={mediaSize}
    />
  )
}

export default RecommendedForYou
