import React from 'react'
import PropTypes from 'prop-types'
import Button from '../../../../lib/ui/Buttons/Button'
import styles from './Newsletters.module.sass'
import Typography from '../../../../lib/ui/Typography/index'
import BasicLoader from '../../../../lib/ui/BasicLoader/BasicLoader'
import ToggleLabel from '../ToggleLabel/index'
import { siteName } from '../../../../utils/oneCodeBase'
import useOnboardingNewsletters from './useOnboardingNewsletters'

const NewsletterToggles = ({
  newsletters,
  type,
  onToggleChange,
  description,
  containerStyle
}) => {
  return (
    <div className={containerStyle}>
      {description && (
        <Typography className={styles.description}>{description}</Typography>
      )}
      <div className={styles.nlSections}>
        {newsletters?.map(item => (
          <ToggleLabel
            checked={item.checked}
            onToggleChange={onToggleChange}
            type={type}
            key={`${type}-${item.id}-toggle`}
            name={item.name}
            id={item.id}
            frequency={item.frequency_description}
            disabled={item.disabled}
          />
        ))}
      </div>
    </div>
  )
}

const Newsletters = ({ configNewsletters, user }) => {
  const {
    loading,
    freeNewsletters,
    plusNewsletters,
    reportsNewsletters,
    emptyChecked,
    submitNewsletters,
    onToggleChange
  } = useOnboardingNewsletters({ configNewsletters, user })

  return (
    <>
      {loading && <BasicLoader />}

      <div>
        {freeNewsletters?.length > 0 && (
          <NewsletterToggles
            newsletters={freeNewsletters}
            type="free"
            onToggleChange={onToggleChange}
            description={null}
          />
        )}

        {plusNewsletters?.length > 0 && (
          <NewsletterToggles
            newsletters={plusNewsletters}
            type="plus"
            onToggleChange={onToggleChange}
            description={`Exclusive to FirstWord ${siteName}+ subscribers`}
          />
        )}

        {reportsNewsletters?.length > 0 && (
          <NewsletterToggles
            newsletters={reportsNewsletters}
            type="reports"
            onToggleChange={onToggleChange}
            description={`Stay on top of the latest research with KOLs, industry experts, and treating physicians`}
            containerStyle={styles.reportsContainer}
          />
        )}

        {(freeNewsletters?.length > 0 || plusNewsletters?.length > 0) && (
          <>
            {emptyChecked && (
              <Typography className={styles.emptyChecked}>
                * Please select a newsletter
              </Typography>
            )}
            <Button
              className={styles.button}
              tier={'secondary'}
              onClick={submitNewsletters}
              textCase="uppercase"
              text={'Confirm'}
            />
          </>
        )}
      </div>
    </>
  )
}

Newsletters.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      name: PropTypes.string,
      frequency_description: PropTypes.string
    })
  )
}

export default Newsletters
