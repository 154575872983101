import React, { useContext } from 'react'
import styles from './SimpleHeader.module.sass'
import { SessionContext } from 'src/features/session/SessionContext'
import Logo from 'src/components/Logo'
import { licenseTypes } from 'src/services/FW5ML/parsers/UserParser'
import { siteName } from 'src/utils/oneCodeBase'

const SimpleHeader = () => {
  const { userHasLicense = () => false } = useContext(SessionContext)

  return (
    <header className={styles.header}>
      <div className={styles.headerTop}>
        <div className={styles.container}>
          <div className={styles.link}>
            <Logo className={styles.logo} />
            <h1 className={styles.title}>
              {`FirstWord ${siteName}${
                userHasLicense(licenseTypes.LICENSE_PLUS) ? ' +' : ''
              }`}
            </h1>
          </div>
        </div>
      </div>
    </header>
  )
}

export default SimpleHeader
