import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Checkbox from 'src/lib/ui/Checkbox'
import styles from './ExternalSiteArticlesToggle.module.sass'
import { actions } from 'src/features/river/RiverSlice'

const ExternalSiteArticlesToggle = ({
  label,
  loading,
  searchConfig,
  query,
  riverId
}) => {
  const dispatch = useDispatch()
  const [externalSiteArticles, setExternalSiteArticles] = useState(false)

  const setExternalArticlesSearch = (value, searchConfig) => {
    if (riverId) {
      if (value === true) {
        dispatch(
          actions.mergeSearch({
            riverId,
            searchBody: { ...searchConfig }
          })
        )
      } else {
        dispatch(
          actions.removeSearch({
            riverId,
            searchBody: { ...searchConfig }
          })
        )
      }
    }
  }

  const hndToggle = () => {
    setExternalSiteArticles(!externalSiteArticles)
    onToggle(!externalSiteArticles)
    setExternalArticlesSearch(!externalSiteArticles, searchConfig)
  }

  // Set default setting value when doing a new search
  useEffect(() => {
    return () => {
      setExternalSiteArticles(false)
    }
  }, [query])

  return (
    <>
      {!loading && (
        <Checkbox
          altTheme={styles}
          label={label}
          name="settings_show_external_site_articles_in_river"
          hasDivider={false}
          checked={externalSiteArticles}
          disabled={loading}
          onChange={hndToggle}
        />
      )}
    </>
  )
}

export default ExternalSiteArticlesToggle
