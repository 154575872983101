import React, { useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Typography from 'src/lib/ui/Typography'
import styles from './AddInterests.module.sass'
import Interest from 'src/components/Interest/SingleStoryInterest'
import { getConfigFilteredTags } from 'src/features/config/ConfigSelector'
import { SessionContext } from 'src/features/session/SessionContext'
import {
  requestInsertInterest,
  requestDeleteInterest
} from 'src/features/myInterests/MyInterestsSlice'
import { licenseTypes } from 'src/services/FW5ML/parsers/UserParser'
import { paywallRedirectFlow } from 'src/features/benefits/BenefitsSlice'

const AddInterests = ({ interests = [], disabledTags = [] }) => {
  const filteredTags =
    useSelector(
      getConfigFilteredTags({
        interests
      })
    ) || interests

  const sessionContext = useContext(SessionContext)
  const dispatch = useDispatch()
  const onAddInterest = (interest, e) => {
    const { checked: sessionReady, user } = sessionContext
    if (!sessionReady) return
    if (user.license !== licenseTypes.LICENSE_PLUS) {
      return dispatch(
        paywallRedirectFlow({
          type: 'plus_feature',
          subtype: 'add_interests'
        })
      )
    }
    return dispatch(requestInsertInterest(interest))
  }
  const onDeleteInterest = (...args) => {
    const { checked: sessionReady, user } = sessionContext
    if (!sessionReady) return
    if (user.license !== licenseTypes.LICENSE_PLUS) {
      return dispatch(
        paywallRedirectFlow({
          type: 'plus_feature',
          subtype: 'remove_interests'
        })
      )
    }
    return dispatch(requestDeleteInterest(...args))
  }

  return (
    <section className={styles.section}>
      <Typography type="subheading" className={styles.label}>
        Add Interests to MyFW+
      </Typography>

      <div>
        {filteredTags
          .sort((a, b) =>
            a.tag.toLowerCase().localeCompare(b.tag.toLowerCase())
          )
          .map(interest => {
            const active = disabledTags.some(
              disabledInterest => disabledInterest.tag === interest.tag
            )
            return (
              <Interest
                active={active}
                interest={interest}
                key={`${interest.category}-${interest.tag}`}
                onDeleteInterest={onDeleteInterest}
                onAddInterest={onAddInterest}
                iconPosition="left"
                deleteEnabled
                inline
                showIcons={true}
                clickable={true}
              />
            )
          })}
      </div>
    </section>
  )
}

export default AddInterests
