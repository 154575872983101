import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { sessionReducer } from 'redux-react-session'

import { connectRouter, routerMiddleware } from 'connected-react-router'
import { reducer as formReducer } from 'redux-form'
import * as reducers from 'src/rootReducers'
import sagas from 'src/rootSagas'
import { rootApi } from 'src/api/rootApi'

export default (history, container) => {
  const { resolve, cradle: services } = container // Get Services resolver

  const rootReducer = combineReducers({
    ...reducers,
    router: connectRouter(history),
    form: formReducer,
    session: sessionReducer,
    [rootApi.reducerPath]: rootApi.reducer
  })
  const sagaMiddleware = createSagaMiddleware()

  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
        thunk: {
          extraArgument: {
            services
          }
        }
      }).concat(sagaMiddleware, routerMiddleware(history), rootApi.middleware),
    devTools: process.env.NODE_ENV !== 'production'
  })

  sagaMiddleware.run(sagas, resolve)

  if (
    process.env.NODE_ENV !== 'production' &&
    process.env.NODE_ENV !== 'test'
  ) {
    module.hot.accept('src/rootReducers', () => {
      store.replaceReducer(rootReducer)
    })
  }
  window.store = store

  return store
}
