import { useState } from 'react'
import PropTypes from 'prop-types'

import { Box, Tooltip, tooltipClasses, styled } from '@mui/material'

import TagsDisplay from '../TagsDisplay'
import Typography from 'src/lib/ui/Typography'

import QuestionMark from '../../static/icons/QuestionMark.js'

/**
 * Consulted sources Container
 */
const ConsultedSourcesSection = styled(Box, {
  name: 'consultedSources',
  label: 'ConsultedSourcesSection',
  overridesResolver: (props, styles) => [styles.container]
})(({ theme, open }) => ({
  margin: '10px 8px 34px 0px',
  '& .title': {
    fontSize: '18px',
    color: '#919191'
  },
  '& h1': {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: 1.67,
    textAlign: 'center',
    color: theme.palette?.consultedSourcesSection?.title || '#fff',
    marginBottom: '9px'
  },
  '& .titleWithIcon': {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    '> svg': {
      height: '24px',
      width: '24px',
      marginRight: '6px'
    },
    '> div': {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      '> h2': { marginRight: '5px' },
      '> h2, > div > h2': {
        lineHeight: '28px',
        fontWeight: 'normal',
        textAlign: 'left',
        marginBottom: '0px'
      },
      // Last word in title
      '> div': {
        display: 'flex',
        alignItems: 'center'
      },
      '& .tooltipAnchor': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '16px',
        width: '16px',
        borderRadius: '50%',
        marginLeft: '6px',
        backgroundColor:
          theme.palette?.consultedSourcesSection?.tooltip?.anchorBackground ||
          '#3e5966',
        '> svg': {
          color:
            theme.palette?.consultedSourcesSection?.tooltip?.anchorColor ||
            '#fff',
          height: '9px',
          width: '5px'
        }
      }
    }
  },
  '@media all and (max-width: 820px)': {
    margin: '20px 0 0 0',
    '& h1': {
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: 1.5,
      textAlign: 'center',
      color: theme.palette.consultedSourcesSection?.titleMobile || '#667085',
      marginBottom: '9px'
    },
    '& .titleWithIcon': {
      justifyContent: 'center',
      '> div': {
        justifyContent: 'center',
        '> h1, > div > h1': {
          fontWeight: 'bold',
          lineHeight: '24px',
          textTransform: 'uppercase'
        },
        '& .tooltipAnchor': {
          backgroundColor:
            theme.palette?.consultedSourcesSection?.tooltip
              ?.anchorBackgroundMobile || '#fff',
          border: 'solid 1.5px #4e5ba6',
          width: '14px',
          height: '14px',
          outline: 'none',
          '&:hover': open && {
            backgroundColor:
              theme.palette?.consultedSourcesSection?.tooltip
                ?.anchorBackgroundMobile || '#4e5ba6',
            borderColor: '#fff'
          },
          '&:focus': open && {
            backgroundColor:
              theme.palette?.consultedSourcesSection?.tooltip
                ?.anchorBackgroundMobile || '#4e5ba6',
            borderColor: '#fff'
          },

          '> svg': {
            color:
              theme.palette?.consultedSourcesSection?.tooltip
                ?.anchorColorMobile || '#4e5ba6',
            height: '9px',
            width: '5px'
          },
          '&:hover > svg': open && {
            color:
              theme.palette?.consultedSourcesSection?.tooltip
                ?.anchorColorMobile || '#fff'
          },
          '&:focus > svg': open && {
            color:
              theme.palette?.consultedSourcesSection?.tooltip
                ?.anchorColorMobile || '#fff'
          }
        }
      }
    }
  }
}))

const tooltipArrowStyles = {
  content: '""',
  width: '0px',
  height: '0px',
  position: 'absolute',
  borderLeft: '15px solid transparent',
  borderRight: '15px solid transparent',
  borderBottom: '15px solid transparent',
  transform: 'none',
  backgroundColor: 'transparent',
  left: '-9px'
}

/**
 * Trusted Sources Title Tooltip
 */
const TooltipStyled = styled(
  ({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
  {
    name: 'consultedSourcesSection',
    label: 'consultedSourcesSectionTooltip',
    overridesResolver: (props, styles) => [styles.tooltip]
  }
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltipPlacementTop}`]: {
    '@media all and (max-width: 820px)': {
      marginBottom: '15px !important'
    }
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor:
      theme.palette.consultedSourcesSection?.tooltip?.background || '#fff',
    color: theme.palette.consultedSourcesSection?.tooltip?.color || '#475467',
    width: '236px',
    padding: '16px 0px',
    borderRadius: '3px',
    marginLeft: '10px',
    '> p': {
      fontSize: '12px',
      margin: '0px 16px'
    },
    border: `solid 1px ${
      theme.palette.consultedSourcesSection?.tooltip?.borderColor || '#d5d9eb'
    }`,
    '@media all and (max-width: 820px)': {
      marginRight: '35px',
      marginBottom: '10px',
      backgroundColor:
        theme.palette.consultedSourcesSection?.tooltip?.backgroundMobile ||
        '#4e5ba6',
      color:
        theme.palette.consultedSourcesSection?.tooltip?.colorMobile || '#fff',
      border: 'none'
    },
    '@media all and (max-width: 375px)': {
      marginRight: '20px'
    }
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.consultedSourcesSection?.tooltip?.background || '#fff',
    overflow: 'visible',
    '&::before': {
      ...tooltipArrowStyles,
      borderTop: `solid 13px ${
        theme.palette.consultedSourcesSection?.tooltip?.borderColor || '#d5d9eb'
      }`
    },
    '&::after': {
      ...tooltipArrowStyles,
      borderTop: `solid 13px ${
        theme.palette.consultedSourcesSection?.tooltip?.background || '#fff'
      }`
    },
    '@media all and (max-width: 820px)': {
      marginLeft: '17.5px',
      color:
        theme.palette.consultedSourcesSection?.tooltip?.backgroundMobile ||
        '#4e5ba6',
      '&::before': {
        borderTop: `solid 13px ${
          theme.palette.consultedSourcesSection?.tooltip?.backgroundMobile ||
          '#4e5ba6'
        }`
      },
      '&::after': {
        borderTop: `solid 13px ${
          theme.palette.consultedSourcesSection?.tooltip?.backgroundMobile ||
          '#4e5ba6'
        }`
      }
    },
    '@media all and (max-width: 375px)': { marginLeft: '10px' }
  }
}))

const ConsultedSources = ({
  title = 'consultedSources',
  tooltip = '',
  icon,
  hideLabels = false,
  isFetching = false,
  accessToken,
  interactionId,
  mtmSource,
  onTagClick,
  message = {},
  testid = 'dougall-pages',
  selectedTag = '',
  displayShowmore = false
}) => {
  const { urls = [], tags = [] } = message
  const [open, setOpen] = useState(false)
  const handleTooltipOpen = () => {
    setOpen(true)
  }

  const handleTooltipClose = () => {
    setOpen(false)
  }
  if (isFetching) return null

  const titleSplit = title.concat(':')?.split(' ')

  return (
    <ConsultedSourcesSection data-testid={testid} open={open}>
      <Box className="titleWithIcon">
        {icon}
        <Box>
          {titleSplit?.map((word, index) =>
            index === titleSplit?.length - 1 ? (
              <Box key={word} display="flex">
                <Typography type="title" className={'title'}>
                  {word}
                </Typography>
                <TooltipStyled
                  placement="top"
                  title={
                    <Typography data-testid="tooltipMessage">
                      {tooltip}
                    </Typography>
                  }
                  arrow
                  enterTouchDelay={0}
                  leaveTouchDelay={10000}
                  open={open}
                  onOpen={handleTooltipOpen}
                  onClose={handleTooltipClose}
                  PopperProps={{
                    modifiers: [
                      {
                        name: 'flip',
                        options: { fallbackPlacements: [] }
                      }
                    ]
                  }}
                >
                  <Box className="tooltipAnchor" data-testid="tooltip">
                    <QuestionMark />
                  </Box>
                </TooltipStyled>
              </Box>
            ) : (
              <Typography key={word} type="title" className={'title'}>
                {word}
              </Typography>
            )
          )}
        </Box>
      </Box>
      <TagsDisplay
        urls={urls}
        tags={tags}
        hideLabels={hideLabels}
        accessToken={accessToken}
        interactionId={interactionId}
        mtmSource={mtmSource}
        onTagClick={onTagClick}
        selectedTag={selectedTag}
        displayShowmore={displayShowmore}
      />
    </ConsultedSourcesSection>
  )
}

ConsultedSources.propTypes = {
  /**
   * Section Title translation Key
   */
  title: PropTypes.string,
  /**
   * Secion Tooltip Text
   */
  tooltip: PropTypes.string,
  /**
   * Icon to display besides the title
   */
  icon: PropTypes.node,
  /**
   * Hide Labels when a category has no elements
   */
  hideLabels: PropTypes.bool,
  /**
   * Show Loading Component, usually when it's fetching
   */
  isFetching: PropTypes.bool,
  /**
   * User's Access Token
   */
  accessToken: PropTypes.string,
  /**
   * User's Interaction Id
   */
  interactionId: PropTypes.string,
  /**
   * Matomo Source for tag link
   */
  mtmSource: PropTypes.string,
  /**
   * On Consulted Sources Tags click
   */
  onTagClick: PropTypes.func,
  /**
   * Message information to display tags
   */
  message: PropTypes.object,
  /**
   * Data-testid for testing purposes
   */
  testid: PropTypes.string,
  /**
   * Selected Tag when Consulted Sources modal is open
   */
  selectedTag: PropTypes.string,
  /**
   * Display show more button
   */
  displayShowmore: PropTypes.bool
}

export default ConsultedSources
