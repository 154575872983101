import FirstImpact from 'src/assets/images/featured/firstimpact.png'
import FirstImpactResults from 'src/assets/images/featured/firstimpact_results.png'
import FirstWord from 'src/assets/images/featured/firstword.png'
import FridayFive from 'src/assets/images/featured/friday_five.png'
import IavOther from 'src/assets/images/featured/iav_other.png'
import InFocus from 'src/assets/images/featured/in_focus.png'
import InTheKnow from 'src/assets/images/featured/in_the_know.png'
import KolViews from 'src/assets/images/featured/kol_views.png'
import OneToWatch from 'src/assets/images/featured/one_to_watch.png'
import PhysicianViews from 'src/assets/images/featured/physician_views.png'
import SpotlightOn from 'src/assets/images/featured/spotlight_on.png'
import TheFirstTake from 'src/assets/images/featured/the_first_take.png'
import TheWiderView from 'src/assets/images/featured/the_wider_view.png'
import TopStory from 'src/assets/images/featured/top_story.png'
import ViewPoints from 'src/assets/images/featured/viewpoints.png'

export default {
  'Top Story': TopStory,
  FirstImpact: FirstImpact,
  'FirstImpact Findings': FirstImpactResults,
  FirstWord: FirstWord,
  'Friday Five': FridayFive,
  'IAV Other': IavOther,
  'In Focus': InFocus,
  'KOL Views': KolViews,
  'KOL Views Results': KolViews,
  'Physician Views': PhysicianViews,
  'Physician Views Poll Results': PhysicianViews,
  'Physician Views In depth': PhysicianViews,
  'Physician Views In depth results': PhysicianViews,
  'Spotlight On': SpotlightOn,
  'The First Take': TheFirstTake,
  ViewPoints: ViewPoints,
  'The Wider View': TheWiderView,
  'One To Watch': OneToWatch,
  'In The Know': InTheKnow
}
