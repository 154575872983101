import { compose } from 'redux'
import { connect } from 'react-redux'
import { actions } from 'src/features/register/RegisterSlice'
import * as flags from 'src/features/asyncFlags/AsyncFlagsSelector'
import RegisterPage from 'src/features/register/components/RegisterPage'
import { withServices } from 'src/services/ServiceContext'

const mapStateToProps = state => ({
  error: flags.getError('userRegistration')(state),
  busy: flags.isBusy('userRegistration')(state)
})

const mapDispatchToProps = dispatch => ({
  requestUserRegistration: data =>
    dispatch(actions.requestUserRegistration(data))
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withServices(resolve => ({
    Piwik: resolve('Piwik')
  }))
)(RegisterPage)
