import { createSlice } from '@reduxjs/toolkit'
import { generateUid } from '../../utils'

const notificationsSlice = createSlice({
  name: 'notificationsReducer',
  initialState: {
    notifications: []
  },
  reducers: {
    notificationsEnqueue: (state, { payload }) => {
      const { message, duration } = payload
      const uid = generateUid()
      state.notifications = [{ uid, message, duration }]
    },
    notificationsDequeue: (state, { payload: uid }) => {
      const idx = state.notifications.findIndex(n => n.uid === uid)
      if (idx === -1) return state
      const notificationsClone = state.notifications.slice()
      notificationsClone.splice(idx, 1)
      state.notifications = notificationsClone
    },
    notificationsDismiss: state => {
      state.notifications = []
    },
    notificationsPop: state => {
      state.notifications.shift()
    }
  }
})

export const { reducer, actions } = notificationsSlice

export const {
  notificationsEnqueue,
  notificationsDequeue,
  notificationsDismiss,
  notificationsPop
} = actions

export default reducer
