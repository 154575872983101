import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    cursor: 'pointer',
    marginLeft: '4%',
    marginBottom: '8%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  topRightText: {
    fontSize: '.8rem',
    textAlign: 'right',
    marginLeft: 'auto'
  },
  clickable: {
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  avatar: {
    marginRight: 8
  },
  boldText: {
    fontWeight: 'bold'
  }
}))

function AdsLiveFeed({ data }) {
  const classes = useStyles()

  // Handle empty values that are required
  if (!data?.html || !data?.clickUrl || data?.clickUrl === '#') {
    return null
  }

  const {
    headline,
    teaserText,
    ctaText,
    publicationDoctor,
    topRightText,
    logo
  } = data.html

  const handleReadMoreClick = () => {
    window.open(data.clickUrl, '_blank')
  }

  const headerValidation = logo || publicationDoctor || topRightText
  const detailsValidation = headline || teaserText

  // Make sure at least one of the required fields is present
  if (!headerValidation && !detailsValidation && !ctaText) {
    return null
  }

  return (
    <>
      <Box className={classes.root} onClick={handleReadMoreClick}>
        {headerValidation && (
          <Box className={classes.header}>
            {logo && <Avatar className={classes.avatar} src={logo} />}
            {publicationDoctor && (
              <Typography className={`${classes.boldText} ${classes.avatar}`}>
                {publicationDoctor}
              </Typography>
            )}
            {topRightText && (
              <Typography className={classes.topRightText}>
                {topRightText}
              </Typography>
            )}
          </Box>
        )}
        {detailsValidation && (
          <Box className={classes.details}>
            {headline && (
              <Typography component="subtitle1" variant="subtitle1">
                {headline}
              </Typography>
            )}
            {teaserText && (
              <Typography variant="body2" color="text.secondary">
                {teaserText}
                <Link
                  onClick={handleReadMoreClick}
                  className={classes.clickable}
                >
                  …read more
                </Link>
              </Typography>
            )}
          </Box>
        )}
        {ctaText && (
          <Box display="flex" alignItems="center" marginTop="2px">
            <Typography variant="body2" color="text.secondary">
              {ctaText}
            </Typography>
          </Box>
        )}
      </Box>
      <Divider
        variant="middle"
        sx={{
          width: '50%',
          margin: 'auto',
          color: '#d6d6d6'
        }}
      />
    </>
  )
}

AdsLiveFeed.propTypes = {
  data: PropTypes.object
}

export default AdsLiveFeed
