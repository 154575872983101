import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../../lib/ui/Icon'
import styles from './RiverInterest.module.sass'

const RiverInterest = ({
  riverArgs,
  userInterests,
  onAddInterest = () => {
    /* NOOP */
  },
  onDeleteInterest = () => {
    /* NOOP */
  }
}) => {
  const [urlCategory, tag] = riverArgs.split('/')
  const category =
    urlCategory === 'drug_classes' ? 'fw_therapeutic_category' : urlCategory
  const interest = { category, tag }
  const active = userInterests.some(
    disabledInterest => disabledInterest.tag === tag
  )
  const hndClick = () =>
    active ? onDeleteInterest(interest) : onAddInterest(interest)

  return (
    <div onClick={hndClick} className={styles.container}>
      <Icon
        className={styles.icon}
        icon={
          active
            ? 'svg/material-design-icons/navigation/check'
            : 'svg/material-design-icons/content/add_circle'
        }
        colorTier={active ? 'default' : 'secondary'}
        button
      />
      <span className={styles.content}>
        {active ? 'Added to MyFW+' : 'Add to MyFW+'}
      </span>
    </div>
  )
}

RiverInterest.propTypes = {
  riverArgs: PropTypes.string,
  userInterests: PropTypes.array,
  onAddInterest: PropTypes.func,
  onDeleteInterest: PropTypes.func
}

export default RiverInterest
