import React from 'react'
import Page from 'src/components/Page'
import ContactUsForm from './components/ContactUsForm/ContactUsFormContainer'
import commonStyles from 'src/styles/common.module.sass'
import cx from 'classnames'
import styles from './ContactUsPage.module.sass'

const ContactUsPage = props => (
  <Page>
    <div className={cx(commonStyles.content, styles.page)}>
      <ContactUsForm {...props} />
    </div>
  </Page>
)

export default ContactUsPage
