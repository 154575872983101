import { useSelector } from 'react-redux'
import { getViewByName } from 'src/features/views/ViewsSelector'
import useViewProps from 'src/hooks/useViewProps'
import View from 'src/components/View'
import StoryExcerpt from 'src/components/StoryExcerpt'
import { getConfig } from 'src/features/config/ConfigSelector'

const LeadArticle = () => {
  const view = useSelector(getViewByName('lead article')) || null
  const { viewsError, viewsLoading } = useViewProps()

  const configSettings = useSelector(getConfig('lead_article')) || {}

  return (
    <View
      error={viewsError}
      loading={viewsLoading}
      view={view}
      render={story => {
        return (
          <div key={story.id}>
            <StoryExcerpt
              id={story.id}
              showBody={true}
              showImage={true}
              to={`/story/${story.id}`}
              title={story.title}
              body={story.teaser}
              congress={story.congress}
              published={story.published}
              image={story.image}
              reportSettings={story?.reportSettings}
              featured
              likes={story.likes}
              likedByProfile={story.likedByProfile}
              {...configSettings}
            />
          </div>
        )
      }}
    />
  )
}

export default LeadArticle
