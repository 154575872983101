import React from 'react'
import styles from './Footer.module.sass'
import { Link } from 'react-router-dom'
import { getCurrentYear } from 'src/utils'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import usePiwik from 'src/hooks/usePiwik'

const ChatWarning = styled(Typography, {
  name: 'Chat',
  label: 'chatWarning',
  overridesResolver: (props, styles) => [styles.warning]
})(() => ({
  fontSize: '12px',
  color: '#667085',
  padding: '0px 8px ',
  textAlign: 'center',
  margin: '0,0, 5px, 0'
}))

function StrippedFooter() {
  const { handleTracking } = usePiwik()

  const menu = [
    {
      to: '/terms',
      label: 'Terms of Use',
      tracking: {
        category: 'firstword-ai',
        action: 'click',
        name: 'terms-of-use'
      }
    },
    {
      to: '/privacy',
      label: 'Privacy Policy',
      tracking: {
        category: 'firstword-ai',
        action: 'click',
        name: 'privacy-policy'
      }
    },

    {
      to: '/contact-us',
      label: 'Contact Us',
      tracking: {
        category: 'firstword-ai',
        action: 'click',
        name: 'contact-us'
      }
    }
  ]

  return (
    <div style={{ textAlign: 'center', paddingTop: '8px' }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ChatWarning variant="caption" style={{ marginBottom: '5px' }}>
          &copy; {getCurrentYear()} FirstWord. All rights reserved{' '}
        </ChatWarning>
        <ChatWarning variant="caption" style={{ marginBottom: '5px' }}>
          |
        </ChatWarning>

        <ChatWarning variant="caption" style={{ marginBottom: '5px' }}>
          1140 Avenue of the Americas, 14th Floor, New York, NY 10036
        </ChatWarning>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {menu.map((item, i) => {
          return (
            <>
              <div key={`menu-${item.label}${i + 1}`}>
                <Link
                  to={item.to}
                  data-piwik-class="footer-link"
                  className={styles.strippedMenuItem}
                  key={`link-${item.label}${i + 1}`}
                  onClick={() => {
                    if (item?.tracking) {
                      const { category, action, name } = item.tracking
                      handleTracking(category, action, name)
                    }
                  }}
                >
                  <ChatWarning key={`warning-${item.label}${i + 1}`}>
                    {item.label}
                  </ChatWarning>
                </Link>
              </div>
              <ChatWarning key={`divider-warning-${item.label}${i + 1}`}>
                {i + 1 !== menu.length && <span>|</span>}
              </ChatWarning>
            </>
          )
        })}
      </div>
    </div>
  )
}

export default StrippedFooter
