import React, { useCallback, useRef, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ImageAd from '../ImageAd'
import IframeAd from '../IframeAd'
import HtmlAd from '../HtmlAd'
import PropTypes from 'prop-types'
import { getAdsViewTrackingStatus } from 'src/features/viewability/ViewabilitySelector'
import * as viewabilityActions from 'src/features/viewability/ViewabilitySlice'
import { arrayify } from 'src/utils'

// Code shared from phoenix team for viewability
const AdAiMatch = ({
  emptyContent = false,
  mediaType,
  onAdClick = () => {},
  ChildComponent,
  ...adProps
}) => {
  const { viewUrl: url, area, id, src } = adProps
  const trackedData = {
    area,
    id,
    src,
    url
  }

  let viewTracked = useSelector(getAdsViewTrackingStatus({ ...trackedData }))
  const dispatch = useDispatch()
  const adObserverRef = useRef()
  const adCountedRef = useRef(false)
  const adElementRef = useCallback(
    node => {
      if (
        url === undefined ||
        area === undefined ||
        src === undefined ||
        id === undefined ||
        (adCountedRef.current !== undefined &&
          adCountedRef.current == `ad-${area}-${id}-${src}`) ||
        viewTracked
      )
        return
      if (adObserverRef.current) adObserverRef.current.disconnect()
      adObserverRef.current = new IntersectionObserver(entries => {
        if (
          entries[0].isIntersecting &&
          (!adCountedRef.current ||
            adCountedRef.current != `ad-${area}-${id}-${src}`) &&
          url !== undefined &&
          !viewTracked
        ) {
          dispatch(viewabilityActions.adsViewTrackRequest({ ...trackedData }))
          adCountedRef.current = `ad-${area}-${id}-${src}`
        }
      })
      if (node) adObserverRef.current.observe(node)
    },
    [dispatch, viewTracked, trackedData]
  )

  const getAdComponent = () => {
    // If there is a component passed down give priority
    if (ChildComponent) {
      return <ChildComponent {...adProps} />
    }

    const mediaTypes = arrayify(mediaType)
    for (let mediaType of mediaTypes) {
      switch (mediaType) {
        case 'image':
          return <ImageAd {...adProps} />
        case 'iframe':
          return <IframeAd {...adProps} />
        case 'html':
          return <HtmlAd {...adProps} />
        default:
          return <></>
      }
    }
  }

  return (
    <Fragment>
      <div ref={adElementRef} />
      <div onClick={onAdClick}>
        {emptyContent !== undefined &&
          emptyContent === false &&
          getAdComponent()}
      </div>
    </Fragment>
  )
}

AdAiMatch.propTypes = {
  /**
   * Boolean to determine if ad is empty or not to validate its visibility
   */
  emptyContent: PropTypes.bool,
  /**
   * Type of the ad (image,iframe,etc.)
   */
  mediaType: PropTypes.string,
  /**
   * Used as data-sasia-view-url image prop for viewability purposes
   */
  viewUrl: PropTypes.string,
  /**
   * Ad area defined in aimatch request
   */
  area: PropTypes.string,
  /**
   * HTML id for the ad element container
   */
  id: PropTypes.string,
  /**
   * Source of the ad
   */
  src: PropTypes.string,
  /**
   * Used for setting the img styles
   */
  imgStyle: PropTypes.object,
  /**
   * Callback function to be called when the ad is clicked
   */
  onAdClick: PropTypes.func,
  /**
   * Custom component to be rendered instead of the default ad component
   */
  ChildComponent: PropTypes.elementType
}

export default AdAiMatch
