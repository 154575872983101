import { useSelector } from 'react-redux'
import Page from 'src/components/Page'
import StaticPageContent from './components/StaticPageContent'
import ShowForMediaSize from 'src/components/Responsive/ShowForMediaSize'
import { getStaticPageConfig } from 'src/features/config/ConfigSelector'

const ShowForPage = ({ screen, content }) => {
  // Same content for desktop and mobile (no mediaSize required)
  if (screen === 'shared') {
    return <StaticPageContent content={content} />
  }

  const showFor = screen === 'desktop' ? ['large'] : ['small', 'medium']

  // Different content for mobile and desktop
  return (
    <ShowForMediaSize showFor={showFor}>
      <StaticPageContent content={content} />
    </ShowForMediaSize>
  )
}

const DynamicStaticPage = () => {
  const staticPageConfig = useSelector(getStaticPageConfig()) || {}

  if (!staticPageConfig?.content_layout) {
    return null
  }

  const { content_layout = {} } = staticPageConfig

  return (
    <Page>
      {Object.keys(content_layout).map((screen, index) => (
        <ShowForPage
          key={`${screen}-${index + 1}`}
          content={content_layout[screen]}
          screen={screen}
        />
      ))}
    </Page>
  )
}

export default DynamicStaticPage
