import { useSelector } from 'react-redux'
import useMediaSize from 'src/hooks/useMediaSize'

import PopularPrompts from 'src/features/fw-ai/children/popularPrompts/PopularPrompts'
import History from 'src/features/fw-ai/children/history/History'
import Faq from 'src/features/fw-ai/children/faq/Faq'
import LiveFeedComponent from 'src/features/fw-ai/children/liveFeed/LiveFeed'

import { getDisplayContainer } from '../../chatPage/ChatPageSelector'

const RightBar = () => {
  const mediaSize = useMediaSize({
    mediaQueries: {
      small: '(max-width: 860px)'
    }
  })
  const displayContainer = useSelector(getDisplayContainer())

  const displayContent = () => {
    if (displayContainer === 'popular') {
      return <PopularPrompts />
    }
    if (displayContainer === 'faqs') {
      return <Faq />
    }
    return <History />
  }

  return (
    <div
      data-testid="right-bar"
      style={{
        height: 'calc(100% - 20px)',
        overflowY: 'scroll',
        msOverflowStyle: 'none', // Scrollbar Hidden for IE and Edge
        scrollbarWidth: 'none', // Scrollbar Hidden for Firefox
        '&::-webkit-scrollbar': { display: 'none' } // Scrollbar Hidden for Chrome, Safari and Opera
      }}
    >
      {mediaSize === 'small' ? displayContent() : <LiveFeedComponent />}
    </div>
  )
}

export default RightBar
