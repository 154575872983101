// Code shared from phoenix team for viewability
import { createSelector } from 'reselect'

export const adsSelector = state => state.adsReducer

export const getAdsViewTrackingStatus = ({ area, id, src }) =>
  createSelector(adsSelector, ads => {
    try {
      return ads.trackRequestStatus[area][id][src]
    } catch (e) {
      return false
    }
  })
