import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon'
import icons from 'src/assets/icons/listIcons'
import styles from './FieldButton.module.sass'

const FieldButton = ({ icon, button, text, disabled, onClick }) => (
  <Fragment>
    <div className={disabled ? styles.backgroundDisabled : styles.background}>
      {icon && (
        <Icon
          className={disabled ? styles.iconDisabled : styles.icon}
          icon={icon}
          onClick={onClick}
          disabled={disabled}
          colorTier="action"
          button
        />
      )}
    </div>
    {button && (
      <button
        type="submit"
        aria-label={text}
        disabled={disabled}
        onClick={onClick}
        className={disabled ? styles.buttonDisabled : styles.button}
      >
        {text}
      </button>
    )}
  </Fragment>
)
FieldButton.propTypes = {
  /**
   * Text to display for a button (only rendered if button boolean is true)
   */
  text: PropTypes.string,
  /**
   * If `true`, a text-style button is displayed
   */
  button: PropTypes.bool,
  /**
   * If `true`, the button is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Called when button is clicked
   */
  onClick: PropTypes.func,
  /**
   * The string corresponding to an icon
   */
  icon: PropTypes.oneOf(icons)
}

FieldButton.displayName = 'FieldButton'
export default FieldButton
