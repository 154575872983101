import StoryRiverView from 'src/components/StoryRiverView'
import { useSelector } from 'react-redux'
import { getViewByName } from 'src/features/views/ViewsSelector'
import useViewProps from 'src/hooks/useViewProps'

const ConferenceNews = () => {
  const title = 'Conference News'
  const view = useSelector(getViewByName('conference news')) || null
  const { viewsError, viewsLoading, handleViewMoreClick } = useViewProps()

  return (
    <StoryRiverView
      view={view}
      viewsError={viewsError}
      viewsLoading={viewsLoading}
      handleViewMoreClick={() => handleViewMoreClick('home', title)}
      title={title}
      viewMore={{
        to: '/river/conference-news',
        show: true,
        handleMore: title
      }}
    />
  )
}

export default ConferenceNews
