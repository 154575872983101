import { useServices } from 'src/services/ServiceContext'
import { useDispatch } from 'react-redux'
import { notificationsEnqueue } from 'src/features/notifications/NotificationsSlice'

const sharedFunctions = () => {
  const { Session, Piwik, UserRepository } = useServices([
    'Session',
    'Piwik',
    'UserRepository'
  ])

  const dispatch = useDispatch()

  const notifyMeSubscribe = async (prevCookie = null) => {
    if (!prevCookie) {
      prevCookie = Session.getCookie('current_flow')
    }

    const user = await Session.userLoad()

    if (!user || !prevCookie?.includes('notify-me-subscribe')) return

    Session.removeCookie('current_flow')

    const cookieSplit = prevCookie.split('|')
    const articleId = cookieSplit[1]
    const newsletterUuid = cookieSplit[2]
    let { notifyMe = [] } = user

    try {
      if (!articleId) {
        throw new Error('No article id defined.')
      }

      if (!newsletterUuid) {
        throw new Error('No newsletter defined.')
      }

      if (
        notifyMe?.find(item => item?.preview_article_ids?.includes(articleId))
      ) {
        dispatch(
          notificationsEnqueue({
            message:
              'It appears that you are already subscribed to be notified of the results.'
          })
        )
        return
      }

      // ML call
      await UserRepository.notifyMeSubscribe(newsletterUuid, articleId)
      dispatch(
        notificationsEnqueue({
          message:
            'Thank you. We will notify you via email when the results are published'
        })
      )

      // Update user info for notifyMe
      const currentItemIndex = notifyMe.findIndex(
        item => item.newsletter_uuid === newsletterUuid
      )
      if (currentItemIndex >= 0) {
        const { preview_article_ids } = notifyMe[currentItemIndex]
        notifyMe[currentItemIndex].preview_article_ids = [
          ...preview_article_ids,
          articleId
        ]
      } else {
        notifyMe = [
          ...notifyMe,
          { newsletter_uuid: newsletterUuid, preview_article_ids: [articleId] }
        ]
      }

      Session.userUpdate({
        notifyMe
      })
    } catch (e) {
      dispatch(
        notificationsEnqueue({
          message:
            'Unable to subscribe to be notified of the results. Please refresh the page and try again.'
        })
      )
      Piwik.track(
        'notification',
        'error',
        `could-not-update-${articleId}-notify-me`
      )
    }
  }

  return {
    notifyMeSubscribe
  }
}

export default sharedFunctions
