import SvgIcon from '@mui/material/SvgIcon'

const MinusIcon = props => (
  <SvgIcon viewBox="0 0 17 17" {...props}>
    <defs>
      <path id="kjwqvs1oia" d="M0 0h11v11H0z" />
    </defs>
    <g transform="rotate(45 3.379 10.621)" fill="none" fillRule="evenodd">
      <mask id="f4bf903a9b" fill="#fff">
        <use xlinkHref="#kjwqvs1oia" />
      </mask>
      <path
        d="m9.207 2.5-6 6"
        stroke="#4E5BA6"
        strokeWidth="1.667"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0,0"
        mask="url(#f4bf903a9b)"
      />
    </g>
  </SvgIcon>
)

export default MinusIcon
