import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  fetching: false,
  status: false,
  languageCode: 'en'
}

const translationSlice = createSlice({
  name: 'translationReducer',
  initialState,
  reducers: {
    translationRequest: state => {
      state.fetching = true
    },
    translationRequestSuccess: (
      state,
      { payload: { languageCode, resources } }
    ) => {
      state.languageCode = languageCode
      state.resources = resources
      state.status = true
      state.fetching = false
    },
    translationRequestFail: state => {
      state.status = true
      state.fetching = false
    }
  }
})

export const { actions, reducer } = translationSlice

export const {
  translationRequest,
  translationRequestSuccess,
  translationRequestFail
} = actions

export default reducer
