import React from 'react'
import PropTypes from 'prop-types'
import ReCAPTCHA from 'react-google-recaptcha'
import Typography from '../../../../lib/ui/Typography'
import SectionHeading from '../../../../components/SectionHeading'
import Spinner from '../../../../lib/ui/Spinner'

const CaptchaForm = ({ onSuccess = () => {}, busy }) => {
  const siteKey =
    process.env.REACT_APP_CAPTCHA_SITE_KEY ||
    '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
  return (
    <div>
      <SectionHeading title="Unknown device" gutterBottom paragraph />
      <Typography type="title" gutterBottom paragraph>
        Please confirm that you are not a robot
      </Typography>
      <div>
        {busy ? (
          <Spinner
            style={{ margin: 10 }}
            size={42}
            fillColor="transparent"
            strokeColor="rgba(213,213,213,1)"
          />
        ) : (
          <ReCAPTCHA
            sitekey={siteKey}
            onChange={onSuccess}
            style={{ visibility: 'hidden' }}
          />
        )}
      </div>
    </div>
  )
}

CaptchaForm.propTypes = {
  onSuccess: PropTypes.func,
  busy: PropTypes.bool
}

export default CaptchaForm
