import StaticComponentLoop from '../StaticComponentLoop'
import commonStyles from 'src/styles/common.module.sass'
import styles from '../../DynamicStaticPage.module.sass'

const StaticPageContent = ({ content }) => {
  const hasSidebar = content?.sidebar && content?.sidebar.length > 0
  const hasMain = content?.main && content?.main.length > 0
  const hasTop = content?.top && content?.top.length > 0

  return (
    <div className={styles.static}>
      {/* Top Section */}
      {hasTop && (
        <div className={commonStyles.top}>
          <StaticComponentLoop section="top" items={content.top} />
        </div>
      )}

      {/* Main and Sidebar Sections */}
      {(hasMain || hasSidebar) && (
        <div className={hasSidebar && styles.doubleColumnLayout}>
          {/* Main Section */}
          {content?.main && (
            <div>
              <StaticComponentLoop section="main" items={content.main} />
            </div>
          )}

          {/* Sidebar Section */}
          {hasSidebar && (
            <div>
              <StaticComponentLoop section="sidebar" items={content.sidebar} />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default StaticPageContent
