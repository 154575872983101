import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { getAnonArticleLimitSettings } from 'src/features/config/ConfigSelector'
import { getPageFound } from 'src/features/config/utils'
import { arrayify } from 'src/utils'
import LoginRegisterModal from './components/LoginRegisterModal'
import { SessionContext } from 'src/features/session/SessionContext'
import { piwikRequest } from 'src/features/piwik/PiwikSlice'

const LoginRegisterModalPage = ({ ...props }) => {
  const dispatch = useDispatch()
  const { checked: sessionReady, authenticated } = useContext(SessionContext)
  const {
    location = {},
    services = {},
    registerModalValidation = () => {}
  } = props
  const [articleLimitValidation, setArticleLimitValidation] = useState(true)

  const anonArticleLimitSettings =
    useSelector(getAnonArticleLimitSettings()) || {}

  const {
    anonArticleLimit,
    registerPopUpFlow,
    showRegisterPaywall = false
  } = anonArticleLimitSettings

  const cookie = services?.Session?.getCookie('viewedArticles') || {}

  const pagesToInclude = registerPopUpFlow?.uri_path
    ? arrayify(registerPopUpFlow.uri_path)
    : []

  const maxArticleLimitReached =
    articleLimitValidation &&
    registerPopUpFlow &&
    Object.keys(registerPopUpFlow).length > 0 &&
    (!pagesToInclude ||
      pagesToInclude.length === 0 ||
      getPageFound(pagesToInclude))

  const getArticleId = () => {
    const pathArray = location.pathname.split('/')
    const storyIndex = pathArray.indexOf('story')

    if (!storyIndex || storyIndex < 0) return null

    const article_id = pathArray[storyIndex + 1] // get the next parameter after "story"
    return article_id || null
  }

  const modalTracking = () => {
    const article_id = getArticleId()
    // Piwik tracking
    dispatch(
      piwikRequest({
        customVarUpdate: {
          key: 'article_id',
          value: article_id
        },
        tracking: {
          category: 'benefits',
          action: 'view',
          name: 'popup'
        }
      })
    )
  }

  useEffect(() => {
    const anonCookieArticleLimit =
      cookie.articles &&
      anonArticleLimit &&
      cookie.articles.length >= anonArticleLimit

    setArticleLimitValidation(anonCookieArticleLimit)
  }, [location.pathname, anonArticleLimitSettings.anonArticleLimit])

  // Validate and show modal on page and config load
  useEffect(() => {
    if (registerPopUpFlow) {
      registerModalValidation()
    }
  }, [registerPopUpFlow])

  return (
    <>
      {!['/register', '/sign-in'].includes(location.pathname) &&
        sessionReady &&
        (showRegisterPaywall || (maxArticleLimitReached && !authenticated)) && (
          <LoginRegisterModal tracking={modalTracking} />
        )}
    </>
  )
}

LoginRegisterModalPage.propTypes = {
  location: PropTypes.object,
  services: PropTypes.object,
  registerModalValidation: PropTypes.func
}

export default LoginRegisterModalPage
