import { rootApi } from '../rootApi'
import { TagTypes } from '../tags'

const singleStoryEndpoints = rootApi.injectEndpoints({
  endpoints: builder => ({
    getSingleStory: builder.query({
      query: id => {
        return {
          url: `/articles/${id}`,
          method: 'GET'
        }
      },
      providesTags: [TagTypes.SINGLE_STORY]
    }),
    getNotifyMeResults: builder.query({
      query: searchBody => {
        return {
          url: '/notify-me/search',
          method: 'POST',
          body: searchBody
        }
      }
    })
  })
})

// Export endpoints
export const {
  useGetSingleStoryQuery,
  useLazyGetSingleStoryQuery,
  useLazyGetNotifyMeResultsQuery
} = singleStoryEndpoints
