import { takeEvery, all, call, put, select } from 'redux-saga/effects'
import { actions } from './PiwikSlice'
import { arrayify } from 'src/utils'
import { replace } from 'connected-react-router'

/**
 *  Trigger a piwik request
 */
function* piwikRequest(services, { payload }) {
  const Piwik = services('Piwik')
  try {
    const { tracking, customVarUpdate } = payload

    // check for custom variable updates
    if (customVarUpdate) {
      const { key, value } = customVarUpdate

      if (!key || !value) {
        throw new Error('Piwik: Invalid custom variable update payload')
      }
      yield call([Piwik, 'updateCustomVariable'], key, value)
    }

    // Check for tracking actions
    if (tracking) {
      const { actionName, category, action, name, customData = null } = tracking

      if (actionName) {
        yield call([Piwik, 'trackAction'], actionName, customData)
      } else if (category && action && name) {
        yield call([Piwik, 'track'], category, action, name, customData || null)
      } else {
        throw new Error('Piwik: Invalid tracking payload')
      }
    }

    yield put(actions.piwikSuccess())
  } catch (e) {
    console.log(e)
    yield put(actions.piwikError({ error: e.message }))
  }
}

/**
 *  Piwik tracking when it comes from the newsletter
 */
function* handleNewsletterPiwikTracking(
  services,
  { payload: newsletterTrackingRequests }
) {
  const queryString = services('queryString')
  try {
    const newsletterEventId = queryString.get('nle')
    const newsletterCampaignId = queryString.get('nlc')
    const newletterFrom = queryString.get('from')

    const location = yield select(state => state.router.location)

    // Check that it comes from a newsletter based on path params
    if (newsletterEventId && newsletterCampaignId && newletterFrom) {
      const trackingDetails = newsletterTrackingRequests[newletterFrom]

      // Get tracking info mapping
      if (trackingDetails) {
        const arrayfiedTrackingRequests = arrayify(
          trackingDetails.trackingDetails
        )
        if (
          trackingDetails?.validation === undefined ||
          trackingDetails.validation(location, newletterFrom)
        ) {
          for (const trackingPayload of arrayfiedTrackingRequests) {
            yield call(piwikRequest, services, { payload: trackingPayload })
          }
        }
        // Remove 'from' path param
        const newQS = queryString.removeKey('from')
        yield put(replace({ search: newQS }))
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export default function* watchTracking(services) {
  yield all([takeEvery(actions.piwikRequest, piwikRequest, services)])
  yield all([
    takeEvery(
      actions.newsletterPiwikRequest,
      handleNewsletterPiwikTracking,
      services
    )
  ])
}
