import { createSelector } from '@reduxjs/toolkit'

export const chatSelector = state => state.chatReducer

export const getFetching = () =>
  createSelector(chatSelector, chat => chat.fetching)

export const getFormState = () =>
  createSelector(chatSelector, chat => chat.formState)

export const getMessages = () =>
  createSelector(chatSelector, chat => chat.messages)

export const getTags = () => createSelector(chatSelector, chat => chat.tags)

export const getShowInterests = () =>
  createSelector(chatSelector, chat => chat.showInterests)

export const getActionHandler = () =>
  createSelector(chatSelector, chat => chat.actionHandler)

export const getLinkedPromptId = () =>
  createSelector(chatSelector, chat => chat.linkedPromptId)

export const getCurrentPrompt = () =>
  createSelector(chatSelector, chat => chat.current)

export const getPromptNum = () =>
  createSelector(chatSelector, chat => chat.promptNum)
export const getUrlRequested = () =>
  createSelector(chatSelector, chat => chat.urlRequested)

export const getIsTyping = () =>
  createSelector(chatSelector, chat => chat.isTyping)
// Modal
export const getActiveModal = () =>
  createSelector(chatSelector, chat => chat.activeModal)

export const getLastMessage = () =>
  createSelector(chatSelector, chat =>
    !chat.fetching && chat.messages?.length
      ? chat.messages[chat.messages.length - 1]
      : {}
  )
