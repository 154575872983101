import { takeEvery, all, put } from 'redux-saga/effects'
import { LOCATION_CHANGE } from 'connected-react-router'
import { actions as paywallActions } from 'src/features/paywall/PaywallSlice'
import { actions } from './BenefitsSlice'

function* handleBenefitsPaywall(services, _) {
  const queryString = services('queryString')
  const paywall = queryString.get('paywall')

  if (paywall) {
    yield put(
      paywallActions.updatePaywallVisible({
        visible: true,
        type: 'plus_feature',
        text: null,
        subtype: paywall
      })
    )
  }
}

/**
 * Handle paywall redirect logic
 */
function* handlePaywallRedirect(services, { payload }) {
  // Show register popup if the flow is defined
  yield put(
    paywallActions.updatePaywallVisible({
      visible: true,
      type: payload.type,
      text: payload?.text || null,
      subtype: payload?.subtype
    })
  )
}

export default function* watchTracking(services) {
  yield all([
    takeEvery(LOCATION_CHANGE, handleBenefitsPaywall, services),
    takeEvery(actions.paywallRedirectFlow, handlePaywallRedirect, services)
  ])
}
