import React from 'react'
import { useSelector } from 'react-redux'
import { SessionContext } from './SessionContext'

const getSessionProviderValue = session => {
  return {
    ...session,
    userIsAuthenticated: () => session.checked && session.authenticated,
    userHasLicense: license =>
      session.checked &&
      session.authenticated &&
      session.user.license === license,
    getInterests: () =>
      session?.user?.interests ? session.user.interests.stated : null,
    getUserPartyId: () => session?.user?.party_id
  }
}

export const SessionProvider = ({ children }) => {
  const session = useSelector(state => state.session)
  return (
    <SessionContext.Provider value={getSessionProviderValue(session)}>
      {children}
    </SessionContext.Provider>
  )
}

export default SessionProvider
