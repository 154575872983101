import { connect } from 'react-redux'
import { compose } from 'redux'
import { actions } from 'src/features/mobileMenu/MobileMenuSlice'
import { actions as configActions } from 'src/features/config/ConfigSlice'
import Header from '.'
import { withTitle } from 'src/utils/hocs'

const mapDispatchToProps = dispatch => ({
  handleSlideMenu: () => dispatch(actions.toggleRightMenu()),
  handleHeaderScroll: data => dispatch(configActions.updateConfigRequest(data)),
  handleLeftSlideMenu: () => dispatch(actions.toggleLeftMenu())
})

const mapStateToProps = ({ mobileMenuReducer }) => ({
  leftMenuOpen: mobileMenuReducer.leftMenuOpen,
  rightMenuOpen: mobileMenuReducer.rightMenuOpen
})

export default compose(
  withTitle,
  connect(mapStateToProps, mapDispatchToProps)
)(Header)
