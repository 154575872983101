import { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useChat } from 'src/features/fw-ai/children/dougallChat/hooks'
import usePiwik from 'src/hooks/usePiwik'

import FeedbackModal from 'src/components/DougallModal/FeedbackModal'
import ConsultedSourcesModal from 'src/components/ConsultedSourcesModal'
import { licenseTypes } from 'src/services/FW5ML/parsers/UserParser'
import { SessionContext } from 'src/features/session/SessionContext'
const ActionModals = () => {
  // Hooks
  const {
    activeModal,
    actionHandler,
    parentPromptId,
    actionSubmit,
    setActiveModal,
    setCurrentPrompt,
    currentPrompt,
    lastMessage
  } = useChat()

  // Variables
  const { answerId, promptId } = actionHandler
  const { handleActionTracking } = usePiwik()

  const { userHasLicense = () => false } = useContext(SessionContext)
  const isPlus = userHasLicense(licenseTypes.LICENSE_PLUS)

  // Feedback Form hook
  const feedbackMethods = useForm({
    mode: 'onTouched',
    defaultValues: { feedback: '' }
  })
  const { setValue, getValues, reset } = feedbackMethods

  const dispatchAction = ({ feedback }) => {
    actionSubmit({
      ...actionHandler,
      parentPromptId: parentPromptId ? parentPromptId.toString() : undefined,
      feedback
    })
    setActiveModal(null)
  }

  /**
   * Feedback Form handler
   * @param {boolean} dismissedFeedback
   */
  const handleActionSubmit = dismissedFeedback => {
    if (dismissedFeedback) {
      dispatchAction({ feedback: '' })
    } else {
      const { feedback } = getValues()
      if (feedback.trim()) {
        handleActionTracking('Site/Click/Feedback-Overlay/form/submit', {
          answerId,
          promptId
        })
        dispatchAction({ feedback })
        reset({ feedback: '' })
      }
    }
  }

  const onConsultedSourcesClose = () => {
    setActiveModal(null)
    setCurrentPrompt({ answerId: '', promptId: '' })

    const trackData = {
      languageCode: currentPrompt?.urls?.links[0]?.languageCode,
      userType: isPlus ? 'plus' : 'free',
      interactionId: lastMessage?.interactionId
    }
    handleActionTracking('click', 'Site/Click/SourcesConsultedExit', trackData)
  }

  // When user clicks on one of the articles inside the overlay
  const handleConsultedSourceClick = source => {
    const trackData = {
      languageCode: source.languageCode,
      userType: isPlus ? 'plus' : 'free',
      pos: source.pos,
      interactionId: lastMessage?.interactionId,
      articleId: source.articleId
    }

    handleActionTracking('click', 'Site/Click/Citation', trackData)
  }

  useEffect(() => {
    if (activeModal === 'feedback') {
      handleActionTracking('Site/View/Feedback-Overlay', {
        answerId,
        promptId
      })
    }
  }, [activeModal])

  return (
    <>
      <form>
        <FeedbackModal
          open={activeModal === 'feedback'}
          onCancel={() => handleActionSubmit(true)}
          onConfirm={() => handleActionSubmit(false)}
          onTextAreaChange={e => {
            setValue('feedback', e.target.value.trim())
          }}
        />
      </form>
      {/* Consulted Sources Modal */}
      <ConsultedSourcesModal
        open={activeModal === 'consulted-sources'}
        onCancel={() => {
          onConsultedSourcesClose()
        }}
        source={currentPrompt?.urls}
        onSourceClick={handleConsultedSourceClick}
      />
    </>
  )
}

export default ActionModals
