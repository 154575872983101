import React from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import ScrollToTop from 'src/components/ScrollToTop'
import routes, { getDynamicRoutes } from './Routes'
import { createBrowserHistory } from 'history'
import MobileLeftMenu from 'src/features/mobileMenu/containers/LeftMenuContainer'
import MobileMenu from 'src/features/mobileMenu/containers/MenuContainer'
import PrestitialAd from 'src/components/PrestitialAd/PrestitialAdContainer'
import LoginRegisterModalPage from 'src/components/LoginRegisterModalPage/LoginRegisterModalPageContainer'
import { getConfig } from 'src/features/config/ConfigSelector'
import PaywallFlowModal from 'src/features/paywall/PaywallModal'
import useLaunchDarkly from 'src/features/launchDarkly/useLaunchDarkly'
import Notifications from 'src/features/notifications/NotificationsContainer'

export const history = createBrowserHistory()

const Router = () => {
  // Config Selectors
  const configBootstrapped =
    useSelector(state => state?.configReducer?.bootstrapped) || false

  const pagesConfig = useSelector(getConfig('pages')) || []

  // Routes set up
  const dynamicRoutes = getDynamicRoutes(pagesConfig)
  const finalRoutes = [...dynamicRoutes, ...routes]

  const { featureFlagConfig } = useLaunchDarkly()

  if (!featureFlagConfig?.initialized || !configBootstrapped) {
    return <></>
  }

  return (
    <ConnectedRouter history={history}>
      <Notifications>
        <ScrollToTop>
          <MobileMenu />
          <MobileLeftMenu />
          <PrestitialAd />
          <LoginRegisterModalPage />
          <PaywallFlowModal />
          <Switch>
            {finalRoutes.map((route, i) => (
              <Route key={`route-${route.path}-${i}`} {...route} />
            ))}
          </Switch>
        </ScrollToTop>
      </Notifications>
    </ConnectedRouter>
  )
}

export default Router
