import StorySpinner from 'src/components/StorySpinner'
import styles from './StoryLoader.module.sass'
import imgFakeArticle from 'src/assets/images/singleStoryFake.png'

const StoryLoader = () => {
  return (
    <div
      style={{
        backgroundImage: `url('${imgFakeArticle}')`
      }}
      className={styles.fakeContent}
    >
      <StorySpinner />
    </div>
  )
}

export default StoryLoader
