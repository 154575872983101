import React from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
import styles from './LandingCompanyInfo.module.sass'
import Typography from '../../../lib/ui/Typography'
import useMediaSize from '../../../hooks/useMediaSize'
import FullImage from '../../../components/FullImage'
import { ArticlesList } from '../../gatedLandingPage/pages/Landing'
import { arrayify } from '../../../utils'
import Contributors from '../../gatedLandingPage/components/Contributors'
import ContributorsList from '../../gatedLandingPage/components/ContributorsList'

const LandingCompanyInfo = ({ data }) => {
  let {
    logo = '',
    description = [],
    screenshot = '',
    name = '',
    asset = { type: 'screenshot' },
    contributors = [],
    contributorsList = [],
    contributorsExtraItems = 0
  } = data

  const descriptionPage = arrayify(description)

  const mediaSize = useMediaSize({
    mediaQueries: {
      small: '(max-width: 600px)',
      medium: '(max-width: 960px)'
    }
  })
  return (
    <section className={styles.companySection}>
      {logo && (
        <div className={styles.sponsorImgContainer}>
          <FullImage
            className={styles.mainLogo}
            src={logo}
            alt={name}
            objectFit="cover"
          />
        </div>
      )}

      {descriptionPage.length > 0 && (
        <div className={styles.descriptionContainer}>
          {descriptionPage.map((paragraph, i) => (
            <Typography key={i} type="body1">
              {ReactHtmlParser(paragraph)}
            </Typography>
          ))}
          {data?.bold_text && <strong>{data.bold_text}</strong>}
        </div>
      )}

      {asset && asset.type !== 'screenshot' && mediaSize === 'large' && (
        <ArticlesList
          accesible={false}
          articles={asset.articles}
          articlesHtml={asset.articlesHtml}
        />
      )}

      {asset.type === 'screenshot' && mediaSize === 'large' && screenshot && (
        <div className={styles.sponsorImgContainer}>
          <FullImage
            className={styles.benefitsImg}
            src={screenshot}
            objectFit="cover"
            width="100%"
            height="100%"
          />
        </div>
      )}

      {contributors && contributors.length > 0 && mediaSize === 'large' ? (
        <div className={styles.contributors} style={{ marginTop: '20px' }}>
          <Contributors contributors={contributors ?? []} />
        </div>
      ) : (
        contributorsList &&
        contributorsList.length > 0 &&
        mediaSize === 'large' && (
          <div className={styles.contributors} style={{ marginTop: '20px' }}>
            <ContributorsList
              contributors={contributorsList ?? []}
              extraItems={contributorsExtraItems}
            />
          </div>
        )
      )}
    </section>
  )
}

LandingCompanyInfo.propTypes = {
  data: PropTypes.object
}

export default LandingCompanyInfo
