import React from 'react'
import Page from 'src/components/Page'
import List from 'src/lib/ui/List/List'
import ListItem from 'src/lib/ui/List/ListItem'
import Typography from 'src/lib/ui/Typography'
import cx from 'classnames'
import styles from 'src/features/static/Static.module.sass'
import SectionHeading from 'src/components/SectionHeading'
import { Link } from 'react-router-dom'
import Metadata from 'src/components/Metadata'

const PrivacyPolicyPage = () => {
  return (
    <Page className={styles.static}>
      <Metadata title="FirstWord Privacy Policy" />
      <SectionHeading title="FirstWord Privacy Policy" gutterBottom paragraph />
      <Typography type="caption" gutterBottom paragraph>
        Updated June 1, 2022
      </Typography>
      <Typography type="title" className={styles.title} paragraph>
        FirstWord is a service mark of Doctor’s Guide Publishing Limited
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        In this Privacy Policy, we, Doctor’s Guide Publishing Limited, 1, rue
        Hildegard von Bingen, L-1282 Luxembourg, Luxembourg, provide information
        about how we collect, use, disclose and otherwise process personal
        information through websites, mobile applications and newsletters that
        FirstWord operates<strong> (“Services”)</strong> and rights individuals
        may have under applicable data protection and privacy laws. This Privacy
        Policy only applies to Services that include a link to this Privacy
        Policy and may be supplemented by additional notices to you depending on
        the specific Service you are accessing or using. By “personal
        information” or “personal data”, we mean information that identifies you
        or can reasonably be used to identify you. If you are located in the
        European Economic Area <strong> (“EEA”) </strong> or the United Kingdom
        <strong> (“UK”)</strong>, please also see the Supplemental EEA and UK
        Online Privacy Statement{' '}
        <Link className={styles.link} to="/SEEAUKPrivacy">
          here
        </Link>
        . If you are a resident of California, please also see our Supplemental
        California Consumer Privacy Act (CCPA) Privacy Policy{' '}
        <Link className={styles.link} to="/CCPAPrivacyPolicy">
          here
        </Link>
        . Individuals who have a visual disability may be able to use a screen
        reader or other text-to-speech or text-to-Braille tools to review the
        contents of this document.
      </Typography>
      <Typography type="subheading" gutterBottom paragraph>
        1. Collection of Personal Information from Users of our Services.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        <strong>Information You Actively Submit.</strong> We collect certain
        types of personal information when you interact with our Services, such
        as when you:
      </Typography>
      <List className={styles.list} listStyle="outside">
        <ListItem>
          Register for an account on any Service or update your profile
          information;
        </ListItem>
        <ListItem>Respond to an online survey;</ListItem>
        <ListItem>
          Submit comments, reviews, or other user-generated content on our
          Services;
        </ListItem>
        <ListItem>Request customer or technical support from us; or</ListItem>
        <ListItem>
          Otherwise submit personal information to us via the Services.
        </ListItem>
      </List>
      <Typography type="body1" className={styles.paragraph} paragraph>
        The personal information we collect includes your name, position,
        company, email address, mailing address, telephone number, and any other
        personal information that you actively submit to us. In each such
        instance, you will know what personal information we collect, because
        you actively submit it. We endeavor to clearly label which information
        is required and which information is optional in all cases. If you are
        unable or choose not to provide us with the personal information we
        reasonably require, we may be unable to provide you with the information
        or services you have requested.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        <strong>Information Passively Submitted.</strong> When you use our
        Services, we may also passively track information about your computer,
        device or Internet connection, such as the IP address of your computer
        and/or Internet service provider, the date and time you access our
        website, the Internet address of websites from which you come to our
        websites, the type of device you are using to access the Service, the
        Web browser and operating system you are using, and your movements and
        interactions on our Services. This data is generated and collected
        automatically, as part of the standard operation of the Services. Unless
        you also actively submit personal information to us, any information
        that is passively submitted typically does not allow us to identify you
        directly.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        <strong>Cookies and Web Beacons.</strong> We may place cookies on users’
        computers. Cookies can save data about individual users, such as the
        user's name, password, username, screen preferences, and the pages of a
        site viewed by the user. When the user revisits the website, we may
        recognize the user based on any cookies placed on their computer and
        customize the user's experience accordingly. For more information about
        use of cookies, please see our{' '}
        <Link className={styles.link} to="/cookie-policy">
          Cookie Policy
        </Link>
        .
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        We may also use web beacons to track who has opened our emails. Web
        beacons are tiny graphic image files embedded in a website page that
        notify the home server (which can belong to the host website, a network
        advertiser, or some other third party) that some interaction has taken
        place. This notice may also result in a cookie being set on your device,
        as described above.
      </Typography>
      <Typography
        type="body1"
        className={cx(styles.paragraph, styles.bold)}
        paragraph
      >
        Use of Personal Data Collected.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        We use personal information that we collect about you through the
        Services to:
      </Typography>
      <List className={styles.list} listStyle="outside">
        <ListItem>
          Upon request, create an account for you for the Services;
        </ListItem>
        <ListItem>Respond appropriately to your inquiries;</ListItem>
        <ListItem>Update you regarding your account;</ListItem>
        <ListItem>
          Provide you with, and maintain, secure and improve our Services;
        </ListItem>
        <ListItem>
          Provide you with a customized experience in connection with our
          Services;
        </ListItem>
        <ListItem>
          Improve the accuracy and relevance of the results you see when you
          interact with the search engine and the search engine's integrated
          chat-bot functionality that is part of our Services;
        </ListItem>
        <ListItem>Discharge our contractual obligations to you; and</ListItem>
        <ListItem>Comply with any legal obligations that apply to us.</ListItem>
      </List>
      <Typography type="body1" className={styles.paragraph} paragraph>
        To the extent permitted by applicable law, including in accordance with
        your consent where required by applicable law, we also use your personal
        information for the following purposes:
      </Typography>
      <List className={styles.list} listStyle="outside">
        <ListItem>
          Send you newsletters including the top industry news in your field of
          interest. If you do not wish to receive such e-mails, you may opt out
          by following the opt-out instructions included in such e-mails. We may
          also invite you via postal mail.
        </ListItem>
        <ListItem>
          Display or send marketing communications to you regarding products and
          services that we believe are relevant to you based on the information
          we have about you. Such advertisements may be sent by email or shown
          on our Services. We achieve this purpose in part by using certain
          cookies and web beacons to track your activities on our Online
          Services. For more information about these activities and how to opt
          out of them, please see our{' '}
          <Link className={styles.link} to="/cookie-policy">
            Cookie Policy
          </Link>{' '}
          under Cookies and Web Beacons or contact us at{' '}
          <a className={styles.link} href="mailto:Privacy@firstwordgroup.com">
            Privacy@firstwordgroup.com
          </a>
          .
        </ListItem>
      </List>
      <Typography type="body1" className={styles.paragraph} paragraph>
        We also perform statistical analyses of the users of our Services to
        improve the content, design and navigation of the Services. In these
        cases, we use aggregate or statistical data that cannot reasonably be
        used to identify you.
      </Typography>
      <Typography type="subheading" gutterBottom paragraph>
        2. Disclosures of Information.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        We do not disclose your personal information with others, except in the
        following circumstances and in accordance with applicable law:
      </Typography>
      <List className={styles.list} listStyle="outside">
        <ListItem>With your consent;</ListItem>
        <ListItem>
          To our agents and service providers, who act on our behalf and under
          confidentiality agreements to deliver services, provide IT support,
          and help us fulfill the other purposes set forth above;
        </ListItem>
        <ListItem>
          To our affiliates under confidentiality agreements who work with us to
          improve our and services and business practices; and
        </ListItem>
        <ListItem>
          If you are an employee of an organization and your employer has
          purchased a group subscription under which you receive our Services,
          we may share information about your usage of our Services with your
          employer to help your employer decide how to manage its group
          subscription;
        </ListItem>
        <ListItem>
          As required by applicable law, including, without limitation, in
          response to any government or regulatory agency request, to cooperate
          with law enforcement investigations, or upon receipt of any court
          order; and
        </ListItem>
        <ListItem>
          To courts and public authorities to protect you, us, or third parties
          from harm, such as fraud.
        </ListItem>
      </List>
      <Typography type="subheading" gutterBottom paragraph>
        3. Security Measures.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        We take steps to protect your personal information from loss, misuse,
        and unauthorized access, disclosure, alteration, and destruction. We
        have implemented technical and administrative security features to help
        prevent the unauthorized release of or access to personal information.
        Please note, however, that while we have endeavored to create a secure
        and reliable site for users, the confidentiality or accuracy of any
        communication or material transmitted to/from us over the Internet
        cannot be guaranteed. You are responsible for the security of
        information that you transmit via the Internet. It is your
        responsibility to safeguard the username and password that you use to
        access our websites, if any, and to notify us immediately at the contact
        information below if you ever suspect that your username and/or password
        has been compromised.
      </Typography>
      <Typography type="subheading" gutterBottom paragraph>
        4. Opt-Out Right and Updating Account Information.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        If you would like to opt out of receiving invitations to medical
        education programs, medical surveys or medical newsletters from us,
        please click the opt-out link at the bottom of our e-mails. Even after
        you opt out or update your preferences, however, please allow us
        sufficient time to process your preferences. It may take up to 10 days
        to process your e-mail related requests, and up to 30 days for all other
        requests. Even after you have opted out of receiving invitations to
        medical education programs, medical surveys and medical newsletters from
        us, we may still contact you for transactional or informational
        purposes. These include, for example, customer service issues or any
        questions regarding a specific inquiry you made to us.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        If you believe any information we have about you is no longer accurate
        or current or would like to access personal information we may have
        about you, please contact us by emailing{' '}
        <a className={styles.link} href="mailto:Privacy@firstwordgroup.com">
          Privacy@firstwordgroup.com
        </a>
        . Upon authenticating your request, we will update or amend your
        information, or provide you with access, but we reserve the right to use
        personal information obtained previously to verify your identity or take
        other actions that we believe are appropriate.
      </Typography>
      <Typography type="subheading" gutterBottom paragraph>
        5. Collection of Personal Data from Children.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        We do not knowingly collect personal information from children under the
        age of 13, and we do not knowingly market the Services or any goods or
        services to such children.
      </Typography>
      <Typography type="subheading" gutterBottom paragraph>
        6. Contacting Us.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        If you have any questions regarding this Privacy Policy, you may contact
        us by emailing{' '}
        <a className={styles.link} href="mailto:Privacy@firstwordgroup.com">
          Privacy@firstwordgroup.com
        </a>
        .
      </Typography>
      <Typography type="subheading" gutterBottom paragraph>
        7. Effective Date and Changes to Privacy Policy.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        We reserve the right to change this Privacy Policy. If we make a
        material change to how we collect, use, or disclose Personal
        Information, we will prominently post an updated Privacy Policy on our
        Services. Where required to do so by law, we may seek your prior consent
        to any material changes we make to this Privacy Policy. If you disagree
        with our Privacy Policy changes, you may discontinue using the Services.
      </Typography>
    </Page>
  )
}

export default PrivacyPolicyPage
