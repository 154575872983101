import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import InfiniteScroller from 'react-infinite-scroller'

const InfiniteLoader = ({
  isLoading,
  hasMore,
  children,
  loader,
  loadMore,
  ...props
}) => {
  const [initialLoad, setInitialLoad] = useState(true)

  const hndInfiniteLoad = page => {
    loadMore(page)
    // Fix to loadMore function called infinitely:
    // MORE INFO: https://github.com/danbovey/react-infinite-scroller/issues/163#issuecomment-411201250
    setInitialLoad(false)
  }

  return (
    <InfiniteScroller
      {...props}
      initialLoad={initialLoad}
      hasMore={hasMore && !isLoading}
      loadMore={hndInfiniteLoad}
    >
      {children}
      {isLoading && loader}
    </InfiniteScroller>
  )
}

InfiniteLoader.propTypes = {
  isLoading: PropTypes.bool,
  hasMore: PropTypes.bool,
  children: PropTypes.node,
  loader: PropTypes.node,
  loadMore: PropTypes.func
}

export default InfiniteLoader
