import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react'
import * as interceptors from './interceptors/networkInterceptors'
import * as sessionInterceptors from './interceptors/sessionInterceptors'
import { TagTypes } from './tags'

const getBaseUrl = () => {
  const blueGreenPort = process.env.REACT_APP_BLUE_GREEN_PORT
  const middlelayerUrl = process.env.REACT_APP_ML_URL

  if (window.location.href.includes(blueGreenPort)) {
    return middlelayerUrl.replace('.com', `.com:${blueGreenPort}`)
  }

  return middlelayerUrl
}

const prepareHeaders = async (headers, { endpoint, extra }) => {
  const services = extra?.services
  const token = await sessionInterceptors.getAuthToken({ endpoint, services })

  if (token) {
    headers.set('authorization', `Bearer ${token}`)
  }

  headers.set('Content-Type', 'application/json')
  headers.set('Cache-Control', 'no-cache, no-store, must-revalidate, max-age=0')

  return headers
}

export const baseQuery = fetchBaseQuery({
  baseUrl: getBaseUrl(),
  prepareHeaders,
  credentials: 'include'
})

// Custom baseQuery wrapper
export const customBaseQuery = async (args, api, extraOptions) => {
  const { dispatch, extra } = api
  const services = extra?.services

  // Call the original baseQuery
  const result = await baseQuery(args, api, extraOptions)

  const response = result?.meta?.response
  const request = result?.meta?.request
  const error = result?.error

  interceptors.catchEmptyResponse({ request, response, error, dispatch })
  await sessionInterceptors.killSessionIfTokenExpired({
    error,
    url: args.url,
    dispatch,
    services
  })
  interceptors.detectMLfailuresOnFail({ error, response })

  // Return the modified result (or the same result)
  return result
}

export const rootApi = createApi({
  baseQuery: customBaseQuery,
  tagTypes: Object.values(TagTypes),
  reducerPath: 'rootApi',
  endpoints: () => ({})
})
