import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  lastArticleId: null
}

const benefitsSlice = createSlice({
  name: 'benefitsReducer',
  initialState,
  reducers: {
    setLastArticleId: (state, action) => {
      state.lastArticleId = action.payload
    },
    paywallRedirectFlow: () => {}
  }
})
export const { actions, reducer } = benefitsSlice

export const { setLastArticleId, paywallRedirectFlow } = actions

export default reducer
