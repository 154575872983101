import React from 'react'
import { Link } from 'react-router-dom'
import Page from 'src/components/Page'
import List from 'src/lib/ui/List/List'
import ListItem from 'src/lib/ui/List/ListItem'
import Typography from 'src/lib/ui/Typography'
import staticStyles from 'src/features/static/Static.module.sass'
import styles from './NoReportsFound.module.sass'
import SectionHeading from 'src/components/SectionHeading'
import Metadata from 'src/components/Metadata'
import cx from 'classnames'
import usePiwik from 'src/hooks/usePiwik'

const NoReportsFound = () => {
  const { handleTracking } = usePiwik()
  return (
    <Page className={staticStyles.static}>
      <Metadata title="YOUR REPORTS" />
      <SectionHeading title="YOUR REPORTS" gutterBottom paragraph />

      <Typography
        type="body1"
        className={cx(staticStyles.paragraph, styles.infoParagraph)}
        paragraph
      >
        You currently do not have any FirstWord Reports available to you.{' '}
        <Link
          to="/contact-us"
          className={styles.link}
          onClick={() => {
            handleTracking('page', 'click', 'your-reports_contact-us')
          }}
        >
          Contact us
        </Link>{' '}
        to learn more about our reports and how you can gain access.
      </Typography>

      <div className={styles.buttonsContainer}>
        <Link
          to="/contact-us"
          className={cx(staticStyles.button)}
          onClick={() => {
            handleTracking(
              'page',
              'button-click',
              'your-reports_contact-us-to-learn-more'
            )
          }}
        >
          Contact Us to Learn More
        </Link>
      </div>

      <Typography type="body1" paragraph className={staticStyles.paragraph}>
        FirstWord Reports equip your team with the critical intelligence
        necessary to maintain a competitive edge and make sense of your
        constantly evolving markets. FirstWord can help your company achieve
        accelerated growth by delivering unique data and insights that enable
        you to:
      </Typography>

      <div className={styles.listContainer}>
        <List className={staticStyles.list} listStyle="outside">
          <ListItem>
            Identify and understand market shifts and emerging opportunities
          </ListItem>
          <ListItem>
            Gain KOL perspectives on your product's competitiveness, treatment
            challenges and unmet needs
          </ListItem>
          <ListItem>
            Uncover competitive advantages and gaps to refine your market
            strategies
          </ListItem>
        </List>

        <List className={staticStyles.list} listStyle="outside">
          <ListItem>
            Leverage actionable physician insights on drug performance and
            improvement
          </ListItem>
          <ListItem>
            Prepare for the impact of AI on clinical decision-making processes
          </ListItem>
          <ListItem>
            Integrate trusted research to enhance your strategic planning
          </ListItem>
        </List>
      </div>

      <Typography type="body1" paragraph className={staticStyles.paragraph}>
        FirstWord Reports empowers your team to make smarter, data-driven
        decisions by answering your most challenging business questions about
        your products, competitors, and markets. Access practical solutions and
        actionable intelligence to effectively tackle the complex challenges
        prevalent in your markets and position your brands and company for
        success.
      </Typography>
    </Page>
  )
}
export default NoReportsFound
