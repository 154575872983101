import React, { useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import StoryRiverView from 'src/components/StoryRiverView'
import { actions } from 'src/features/myReports/MyReportsSlice'
import useViewProps from 'src/hooks/useViewProps'

const RecentReports = () => {
  const title = 'Recent Reports'
  const dispatch = useDispatch()
  const {
    loading = null,
    data,
    error
  } = useSelector(state => state.myReportsReducer.recentReports, shallowEqual)
  const { handleViewMoreClick } = useViewProps()

  useEffect(() => {
    dispatch(actions.requestListRecentReports({ size: 5 }))
  }, [])

  return (
    <StoryRiverView
      title={title}
      view={{ stories: data?.data }}
      viewsError={error}
      viewsLoading={loading}
      handleViewMoreClick={() => handleViewMoreClick('home', title)}
      viewMore={{
        to: 'https://firstwordreports.com/river/all-reports',
        show: true,
        title: 'Reports',
        handleMore: 'Reports',
        isExternal: true
      }}
    />
  )
}

export default RecentReports
