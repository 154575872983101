import React, { useContext } from 'react'
import Page from 'src/components/Page'
import SectionHeading from 'src/components/SectionHeading'
import sharedStyles from 'src/features/static/Static.module.sass'
import styles from './FeaturesAndBenefits.module.sass'
import Typography from 'src/lib/ui/Typography'
import { Link } from 'react-router-dom'
import { licenseTypes } from 'src/services/FW5ML/parsers/UserParser'
import { SessionContext } from 'src/features/session/SessionContext'
import Tag from 'src/components/Tag'
import Metadata from 'src/components/Metadata'
import FullImage from 'src/components/FullImage'
import PromotionalVideo from 'src/components/PromotionalVideo'
import cx from 'classnames'

const FeaturesAndBenefits = () => {
  const getImgUrl = imgPath =>
    `${window.location.origin}/assets/features-and-benefits/${imgPath}`
  const { userHasLicense = () => false } = useContext(SessionContext)
  const isPlus = userHasLicense(licenseTypes.LICENSE_PLUS)
  return (
    <Page className={cx(sharedStyles.static, styles.featuresAndBenefits)}>
      <Metadata title="Features &amp; Benefits" />
      <SectionHeading title="Features &amp; Benefits" gutterBottom paragraph />
      <div className={styles.wrapper}>
        <div className={styles.video}>
          <div className={styles.paragraph}>
            <Typography
              type="title"
              gutterBottom
              color={isPlus ? 'primary' : 'tertiary'}
            >
              Welcome to FirstWord Pharma and FirstWord Pharma+
            </Typography>

            <Typography
              type="body1"
              className={cx(styles.body, styles.videoSpans)}
            >
              Providing readers with access to timely, need-to-know industry
              news and insights{' '}
              <span className={styles.colorText}>anywhere</span>,{' '}
              <span className={styles.colorText}>anytime</span>, on{' '}
              <span className={styles.colorText}>any device</span> at the office
              or on the go.
            </Typography>
          </div>
          <PromotionalVideo className={styles.promoVideo}></PromotionalVideo>
        </div>
        <div className={styles.section}>
          <FullImage
            className={styles.image}
            src={getImgUrl('Biosimilar_Index.png')}
          />
          <div className={styles.paragraph}>
            <Typography
              type="title"
              gutterBottom
              color={isPlus ? 'primary' : 'tertiary'}
            >
              <span style={{ marginRight: '10px' }}>
                FirstWord Biosimilar Index
              </span>
              <Tag className={styles.plusTag} label="PLUS" />
              <Tag className={styles.newTag} label="NEW" />
            </Typography>
            <Typography type="body1" className={styles.body}>
              FirstWord monitors over 600 biosimilar and non-comparable biologic
              (NCB) drugs in development from over 160 companies worldwide,
              providing you with a comprehensive drug intelligence database that
              helps you stay up-to-date with key changes to trials. Quickly sort
              and filter through data for a clear window on the world of
              biosimilar drugs and their developers.
            </Typography>
          </div>
        </div>
        <div className={styles.section}>
          <FullImage
            className={styles.image}
            src={getImgUrl('Intuitive_Design.png')}
          />
          <div className={styles.paragraph}>
            <Typography
              type="title"
              gutterBottom
              color={isPlus ? 'primary' : 'tertiary'}
            >
              Intuitive Design
            </Typography>
            <Typography type="body1" className={styles.body}>
              We make it easy for you to discover the issues, stories, and their
              implications in the industry and therapy area markets that you
              operate in.
            </Typography>
          </div>
        </div>
        <div className={styles.section}>
          <FullImage
            className={styles.image}
            src={getImgUrl('Enhanced_Experience.png')}
          />
          <div className={styles.paragraph}>
            <Typography
              type="title"
              gutterBottom
              color={isPlus ? 'primary' : 'tertiary'}
            >
              Enhanced Experience
            </Typography>
            <Typography type="body1" className={styles.body}>
              Our homepage allows you to quickly and easily scan for the latest
              stories and analysis, medical conference news, market intelligence
              reports and more, ensuring you stay up-to-date on critical
              developments.
            </Typography>
          </div>
        </div>
        <div className={styles.section}>
          <FullImage
            className={styles.image}
            src={getImgUrl('Recommended_For_You.png')}
          />
          <div className={styles.paragraph}>
            <Typography
              type="title"
              gutterBottom
              color={isPlus ? 'primary' : 'tertiary'}
            >
              Recommended For You
            </Typography>
            <Typography type="body1" className={styles.body}>
              Highlighting popular news in a convenient location, helping you
              stay up-to-date and never miss important developments.
            </Typography>
          </div>
        </div>
        <div className={styles.section}>
          <FullImage
            className={styles.image}
            src={getImgUrl('Navigation.png')}
          />
          <div className={styles.paragraph}>
            <Typography
              type="title"
              gutterBottom
              color={isPlus ? 'primary' : 'tertiary'}
            >
              Improved Navigation
            </Typography>
            <Typography type="body1" className={styles.body}>
              View more drop down menus allowing you to easily view the latest
              news and switch between sections of interest.
            </Typography>
          </div>
        </div>
        <div className={styles.section}>
          <FullImage
            className={styles.image}
            src={getImgUrl('People_News.png')}
          />
          <div className={styles.paragraph}>
            <Typography
              type="title"
              gutterBottom
              color={isPlus ? 'primary' : 'tertiary'}
            >
              People News
            </Typography>
            <Typography type="body1" className={styles.body}>
              Stay informed of management changes and important developments in
              pharma leadership.
            </Typography>
          </div>
        </div>
        <div className={styles.section}>
          <FullImage
            className={styles.image}
            src={getImgUrl('Recent_Reports.png')}
          />
          <div className={styles.paragraph}>
            <Typography
              type="title"
              gutterBottom
              color={isPlus ? 'primary' : 'tertiary'}
            >
              Recent Reports
            </Typography>
            <Typography type="body1" className={styles.body}>
              Most recent Dossier, FirstView, and Therapy Trends reports
              published by FirstWord’s team of analysts. FirstWord Reports
              deliver timely, need-to-know intelligence about your products,
              your competitors and your markets.
            </Typography>
          </div>
        </div>
        <div className={styles.section}>
          <FullImage
            className={styles.image}
            src={getImgUrl('New_Newsletters.png')}
          />
          <div className={styles.paragraph}>
            <Typography
              type="title"
              gutterBottom
              color={isPlus ? 'primary' : 'tertiary'}
            >
              New Newsletters
            </Typography>
            <Typography type="body1" className={styles.body}>
              New scientific area newsletters covering top fields of interest to
              ensure you stay on top of critical developments.
            </Typography>
          </div>
        </div>
        <div className={cx(styles.section, styles.myfw)}>
          <FullImage className={styles.image} src={getImgUrl('MyFW.png')} />
          <div className={styles.paragraph}>
            <div className={styles.titleWithTag}>
              <Typography
                type="title"
                color={isPlus ? 'primary' : 'tertiary'}
                style={{ marginRight: '10px' }}
              >
                Improved customisation
              </Typography>
              <Tag className={styles.plusTag} label="PLUS" />
            </div>
            <Typography type="body1" className={styles.body}>
              With MyFW+ Stories, effortlessly discover news and analysis in an
              individual news feed containing articles related to the interests
              (products, companies, conditions, medical meetings, regulatory
              areas, and more) of importance to you. Adding and removing
              interests is both quick and easy from MyFW+, story pages, or your
              profile.
            </Typography>
          </div>
        </div>
        <div className={styles.section}>
          <FullImage
            className={styles.image}
            src={getImgUrl('Industry_Events.png')}
          />
          <div className={styles.paragraph}>
            <Typography
              type="title"
              gutterBottom
              color={isPlus ? 'primary' : 'tertiary'}
            >
              Industry Events
            </Typography>
            <Typography type="body1" className={styles.body}>
              Access our upcoming events which provide you with insights into
              key industry topics in the form of roundtable discussions, KOL
              interviews, Q&amp;As with industry experts, presentations of
              survey results, and coverage of major industry conferences. Missed
              the event live? You will have access to view past events
              on-demand.
            </Typography>
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.paragraph}>
            <Typography type="body1" className={styles.body}>
              We have many plans for new content and features that we look
              forward to providing you with in the coming months. If you have
              any unmet needs or suggestions, please{' '}
              <Link className={styles.link} to="/contact-us">
                let us know!
              </Link>
            </Typography>
            <Typography type="body1" className={styles.body}>
              We welcome your ideas, suggestions, and comments to make your
              FirstWord Pharma experience even better. Please contact us with
              your{' '}
              <Link className={styles.link} to="/contact-us">
                feedback
              </Link>
              .
            </Typography>
            <Typography type="title" color={isPlus ? 'primary' : 'tertiary'}>
              FirstWord. Where people who know first, go first.
            </Typography>
          </div>
        </div>
      </div>
    </Page>
  )
}

export default FeaturesAndBenefits
