import { createSlice } from '@reduxjs/toolkit'

const contactUsSlice = createSlice({
  name: 'contactUsReducer',
  initialState: {
    log: []
  },
  reducers: {
    logPush: (state, { payload: { module, message } }) => {
      state.log.push({ module, message })
    },
    logClear: state => {
      state.log = []
    },
    requestSendMessage: () => {},
    successSendMessage: () => {},
    errorSendMessage: () => {},
    requestConsultantContact: () => {}
  }
})

export const { actions, reducer } = contactUsSlice

export const {
  requestSendMessage,
  successSendMessage,
  errorSendMessage,
  logPush,
  logClear,
  requestConsultantContact
} = actions

export default reducer
