import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Page from 'src/components/Page'
import LoginPage from '../../LoginContainer'
import commonStyles from 'src/styles/common.module.sass'
import styles from './LoginLandingPage.module.sass'
import landingCompanyStyles from 'src/features/landingCompanyInfo/components/LandingCompanyInfo.module.sass'
import LandingCompanyInfo from 'src/features/landingCompanyInfo/LandingCompanyInfoContainer'
import cx from 'classnames'
import FullImage from 'src/components/FullImage'
import StorySpinner from 'src/components/StorySpinner'
import AboutUs from 'src/components/AboutUs'
import { ArticlesList } from 'src/features/gatedLandingPage/pages/Landing'
import Contributors from 'src/features/gatedLandingPage/components/Contributors'
import ContributorsList from 'src/features/gatedLandingPage/components/ContributorsList'

class LoginLandingPage extends Component {
  componentDidMount() {
    this.props.loadCompanyContent({
      type: 'login'
    })
  }

  render() {
    const { landingPageCompany = null, busyLandingPage } = this.props
    const asset = landingPageCompany?.asset?.type || { type: 'screenshot' }
    const {
      contributors = [],
      contributorsList = [],
      contributorsExtraItems = 0
    } = landingPageCompany ?? {}

    return (
      <Page
        className={
          !landingPageCompany &&
          cx(commonStyles.content, styles.login, commonStyles.centered)
        }
      >
        {busyLandingPage ? (
          <StorySpinner />
        ) : (
          <>
            <div
              className={
                landingPageCompany && cx(styles.container, commonStyles.content)
              }
            >
              {landingPageCompany && (
                <LandingCompanyInfo data={landingPageCompany} />
              )}
              <section
                className={
                  landingPageCompany
                    ? landingCompanyStyles.landingLogin
                    : styles.login
                }
              >
                <div
                  className={
                    landingPageCompany &&
                    landingCompanyStyles.landingLoginContainer
                  }
                >
                  <LoginPage
                    parentClassName={landingPageCompany && styles.formBorders}
                    landingPageCompany={landingPageCompany}
                  />
                </div>
              </section>

              {landingPageCompany && asset && asset.type === 'screenshot' ? (
                <div className={landingCompanyStyles.largeViewImg}>
                  <FullImage
                    src={landingPageCompany.screenshot}
                    objectFit="cover"
                    width="100%"
                    height="100%"
                  />
                  {contributors && contributors.length > 0 ? (
                    <div className={styles.contributors}>
                      <Contributors contributors={contributors ?? []} />
                    </div>
                  ) : (
                    contributorsList &&
                    contributorsList.length > 0 && (
                      <div className={styles.contributors}>
                        <ContributorsList
                          contributors={contributorsList ?? []}
                          extraItems={contributorsExtraItems}
                        />
                      </div>
                    )
                  )}
                </div>
              ) : (
                landingPageCompany?.asset && (
                  <div className={landingCompanyStyles.largeViewImg}>
                    <ArticlesList
                      articles={landingPageCompany.asset.articles}
                      accesible={false}
                      articlesHtml={landingPageCompany.asset.articlesHtml}
                    />
                    {contributors && contributors.length > 0 ? (
                      <div className={styles.contributors}>
                        <Contributors contributors={contributors ?? []} />
                      </div>
                    ) : (
                      contributorsList &&
                      contributorsList.length > 0 && (
                        <div className={styles.contributors}>
                          <ContributorsList
                            contributors={contributorsList || []}
                            extraItems={contributorsExtraItems}
                          />
                        </div>
                      )
                    )}
                  </div>
                )
              )}
            </div>

            {landingPageCompany?.sponsor && (
              <AboutUs sponsor={landingPageCompany.sponsor} />
            )}
          </>
        )}
      </Page>
    )
  }
}
LoginLandingPage.propTypes = {
  loadCompanyContent: PropTypes.func,
  landingPageCompany: PropTypes.object,
  busyLandingPage: PropTypes.bool
}

export default LoginLandingPage
