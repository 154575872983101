import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Typography, Box, Button } from '@mui/material'
import { useSelector } from 'react-redux'
import styles from './header.module.sass'
import CloseIcon from '@mui/icons-material/Close'
import { getAnonArticleLimitSettings } from 'src/features/config/ConfigSelector'
import { useServices } from 'src/services/ServiceContext.js'
import useMediaSize from 'src/hooks/useMediaSize.js'
const Header = ({
  featureText,
  onClose,
  headerColor,
  showCounter,
  showRegistrationButton,
  register,
  paywallSubtype = 'here'
}) => {
  const [activeColor, setActiveColor] = useState(headerColor)
  const { Session } = useServices(['Session'])
  const [count, setCount] = useState(1)

  useEffect(() => {
    if (headerColor) {
      setActiveColor(headerColor)
    }
  }, [headerColor])

  const mediaSize = useMediaSize()

  const anonArticleLimitSettings = useSelector(getAnonArticleLimitSettings())

  const { anonArticleLimit } = anonArticleLimitSettings
  const viewed = Session.getCookie('viewedArticles')

  useEffect(() => {
    if (viewed?.articles?.length > 0) {
      setCount(viewed?.articles.length)
    }
  }, [viewed?.articles])

  return (
    <Box
      sx={{
        display: 'flex',
        color: 'white',
        fontSize: '0.5rem',
        width: '100%',
        height: '70px',
        background: activeColor,
        '@media (max-width: 920px)': {
          minHeight: '70px',
          height: '100%'
        }
      }}
      data-testid="Paywall_header"
    >
      {mediaSize === 'small' && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            padding: '8px 16px'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              marginBottom: '8px'
            }}
          >
            {showCounter && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px'
                }}
              >
                <Typography variant="h5" className={styles.counter}>
                  {count}/{anonArticleLimit}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: 'left',
                    fontSize: '0.7rem'
                  }}
                >
                  <div>FREE MONTHLY ARTICLES READ</div>
                </Box>
              </Box>
            )}
            {showRegistrationButton && (
              <Button
                variant="contained"
                size="large"
                sx={{
                  backgroundColor: '#ec8756ff',
                  color: 'white',
                  textTransform: 'capitalize'
                }}
                className={styles.requestButton}
                onClick={() => {
                  register()
                }}
              >
                Register
              </Button>
            )}
            {!showRegistrationButton && paywallSubtype !== 'article_limit' && (
              <CloseIcon className={styles.closeButton} onClick={onClose} />
            )}
          </div>
          <Typography
            sx={{
              fontSize: '0.9rem',
              textAlign: 'center',
              width: '100%'
            }}
          >
            {featureText}
          </Typography>
        </div>
      )}
      {mediaSize !== 'small' && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            padding: '8px 16px',
            position: 'relative'
          }}
        >
          <div style={{ flexGrow: 1 }}>
            {showCounter && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px'
                }}
              >
                <Typography variant="h5" className={styles.counter}>
                  {count}/{anonArticleLimit}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    fontSize: '0.7rem',
                    textAlign: 'left'
                  }}
                >
                  <div className={styles.legendContainer}>FREE MONTHLY</div>
                  <div className={styles.legend}>ARTICLES READ</div>
                </Box>
              </Box>
            )}
          </div>
          <Typography
            sx={{
              fontSize: '1.125rem',
              textAlign: 'center',
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)'
            }}
          >
            {featureText}
          </Typography>
          <div>
            {showRegistrationButton && (
              <Button
                variant="contained"
                size="large"
                sx={{
                  backgroundColor: '#ec8756ff',
                  color: 'white',
                  textTransform: 'capitalize'
                }}
                className={styles.requestButton}
                onClick={() => {
                  register()
                }}
              >
                Register
              </Button>
            )}
            {!showRegistrationButton && paywallSubtype !== 'article_limit' && (
              <CloseIcon className={styles.closeButton} onClick={onClose} />
            )}
          </div>
        </div>
      )}
    </Box>
  )
}

Header.propTypes = {
  featureText: PropTypes.string,
  onClose: PropTypes.func,
  headerColor: PropTypes.string,
  showCounter: PropTypes.bool,
  showRegistrationButton: PropTypes.bool,
  register: PropTypes.func,
  paywallSubtype: PropTypes.string
}

export default Header
