import React from 'react'
import PropTypes from 'prop-types'
import { Box, Divider, styled, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CaratRightLightIcon from 'src/static/icons/caratRightLight'
/**
 * Follow Up Prompts Container
 * theme path: FollowUpPrompts.styleOverrides.container
 */
const PromptsContainer = styled(Box, {
  name: 'FollowUpPrompts',
  label: 'followUpPromptsContainer',
  overridesResolver: (props, styles) => [styles.container]
})(({ theme }) => ({
  marginTop: '10px',
  '& h1': {
    fontSize: '16px',
    fontFamily: 'Inter',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: theme?.palette?.followUpPrompts?.title
  },
  '> svg': { height: '25px', width: '127px', marginBottom: '3px' },
  '@media all and (max-width: 560px)': { margin: '0px 0px 30px' }
}))

/**
 * Follow Up Single Prompt Container
 * theme path: FollowUpPrompts.styleOverrides.promptContainer
 */
const SinglePromptContainer = styled(Box, {
  name: 'FollowUpPrompts',
  label: 'followUpPromptsPromptContainer',
  overridesResolver: (props, styles) => [styles.promptContainer]
})(({ theme }) => ({
  '> div': {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '14px 0px'
  },
  '& p': {
    fontSize: '14px',
    fontFamily: 'Inter',
    lineHeight: 1.43,
    color: theme?.palette?.followUpPrompts?.text
  },
  '& svg': { margin: '0px 10px' }
}))

const FollowUpPrompts = ({ isFetching, prompts = [], onPromptClick }) => {
  const { t } = useTranslation()
  if (!(!isFetching && prompts && prompts.length > 0)) return <></>

  return (
    <PromptsContainer data-testid="follow-up-prompts">
      <Typography variant="h1">{t('relatedPrompt')}</Typography>
      {prompts.map((prompt, idx) => (
        <SinglePromptContainer key={idx}>
          <Box onClick={() => onPromptClick({ prompt, pos: idx + 1 })}>
            <Typography>{prompt}</Typography>
            <CaratRightLightIcon sx={{ fontSize: '15px', fill: '#8f9bb3' }} />
          </Box>
          <Divider sx={{ borderColor: '#e6eafb' }} />
        </SinglePromptContainer>
      ))}
    </PromptsContainer>
  )
}

FollowUpPrompts.propTypes = {
  /**
   * Follow Up prompts fetching
   */
  isFetching: PropTypes.bool,
  /**
   * Follow Up prompts to display
   */
  prompts: PropTypes.array,
  /**
   * On Follow Up prompt click
   */
  onPromptClick: PropTypes.func
}

export default FollowUpPrompts
