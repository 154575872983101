import React from 'react'
import PropTypes from 'prop-types'
import Typography from '../../lib/ui/Typography'
import Page from '../../components/Page'
import Link from '../../components/Link'
import styles from './OneClickSubscribe.module.sass'
import { useHistory } from 'react-router-dom'
import { historyHandler } from '../../utils'

const OneClickSubscribePage = ({ values }) => {
  // Redirect to home if we try to access the page outside the one click subscription logic
  const history = useHistory()
  if (!values) {
    historyHandler('/', '', history, 'push')
    return null
  }
  const { result } = values
  const renderView = result => {
    switch (result) {
      case 'subscribed':
        return (
          <>
            <Typography
              className={styles.newsletterHeading}
              type="subheading"
              gutterBottom
            >
              Thank you for subscribing to the {values.nlName} newsletter.
            </Typography>
            <Typography type="body1">
              To see the other newsletters we offer, click the button below.
            </Typography>
            <Link
              to={'/my-account/newsletters'}
              className={styles.newsletterButton}
            >
              My Newsletters
            </Link>
          </>
        )
      case 'already-subscribed':
        return (
          <>
            <Typography
              className={styles.newsletterHeading}
              type="subheading"
              gutterBottom
            >
              It appears you are already subscribed to the {values.nlName}{' '}
              newsletter.
            </Typography>
            <Typography>
              Click on the button below to see the other newsletters you are
              currently subscribed to.
            </Typography>
            <Link
              to={'/my-account/newsletters'}
              className={styles.newsletterButton}
            >
              My Newsletters
            </Link>
          </>
        )
      default:
        return (
          <>
            <Typography
              className={styles.newsletterHeading}
              type="subheading"
              gutterBottom
            >
              Oops! Something went wrong. (Error Code: {values.errorCode}.).
            </Typography>
            <Typography>
              Please click below to contact us about the issue.
            </Typography>
            <Link to={'/contact-us'} className={styles.newsletterButton}>
              Contact Us
            </Link>
          </>
        )
    }
  }
  return <Page className={styles.content}>{renderView(result)}</Page>
}

OneClickSubscribePage.propTypes = {
  values: PropTypes.object
}

export default OneClickSubscribePage
