import { takeEvery, takeLeading, call, put, all } from 'redux-saga/effects'
import { anonFlow } from 'src/features/bootstrap/BootstrapSaga'
import { push } from 'connected-react-router'
import { notificationsEnqueue } from 'src/features/notifications/NotificationsSlice'
import { logPush } from 'src/features/contactUs/ContactUsSlice'
import { logoutSuccess } from './SessionActions'

function* logout(services, { payload = {} }) {
  const { reload = false, redirect = '/', refresh = false } = payload
  const Session = services('Session')
  const Piwik = services('Piwik')
  const UserRepository = services('UserRepository')
  const LogRocketService = services('LogRocketService')
  try {
    yield call([Session, 'destroySession'])
    yield call(anonFlow, services)
    // Remove custom variables set on login and force a new visit
    yield call([Piwik, 'forceNewVisit'])
    yield call([Piwik, 'updateCustomVariable'], 'MemberID', 0)
    yield call([Piwik, 'updateCustomVariable'], 'EmailID', 0)
    yield call([Piwik, 'updateCustomVariable'], 'site_uid', 0)
    yield put(logoutSuccess())
    yield call([LogRocketService, 'reset'])
    yield call([UserRepository, 'resetUserFlow'])
    if (refresh) {
      window.location.href = redirect
      return
    } else if (reload) {
      return window.location.reload()
    } else if (redirect) {
      yield put(push(redirect))
      return
    }
    return
  } catch (e) {
    console.log(e)
    yield put(logPush('Logout', `Error while logging out: ${e.message}`))
    yield put(
      notificationsEnqueue({
        message: 'Something went wrong! Please refresh the page and try again.'
      })
    )
    yield call([Piwik, 'track'], 'notification', 'error', 'could-not-log-out')
    yield put(push('/contact-us'))
  }
}

function* afterLogout(services) {
  const Piwik = services('Piwik')
  yield call([Piwik, 'cleanForceNewVisit'])
}

export default function* watchLogin(services) {
  yield all([
    takeLeading('LOGOUT', logout, services),
    takeEvery('LOGOUT__SUCCESS', afterLogout, services)
  ])
}
