import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  fetching: false,
  active: '',
  list: []
}

const themeProviderSlice = createSlice({
  name: 'themeProviderReducer',
  initialState,
  reducers: {
    themeRequest: state => {
      state.fetching = true
    },
    themeRequestSuccess: (state, { payload: { active = '', list = [] } }) => {
      state.fetching = false
      state.list = list
      state.active = active
    },
    themeRequestChange: (state, { payload: { active = '' } }) => {
      state.fetching = false
      state.active = active
    },
    themeRequestFail: state => {
      state.fetching = false
      state.active = undefined
    }
  }
})

export const { actions, reducer } = themeProviderSlice

export const {
  themeRequest,
  themeRequestSuccess,
  themeRequestChange,
  themeRequestFail
} = actions

export default reducer
