import { compose } from 'redux'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { actions } from './PaymentSlice'
import PaymentPage from './components/PaymentPage'
import validate from './components/PaymentForm/validate'

const mapStateToProps = ({
  session: { user },
  paymentReducer: {
    loading,
    success,
    coupon,
    price,
    priceWithDiscount,
    currency
  }
}) => {
  return {
    loading,
    success,
    initialValues: {
      country: 'ca',
      coupon: coupon.name,
      email: user.email
    },
    price,
    priceWithDiscount,
    coupon,
    currency
  }
}

const mapDispatchToProps = dispatch => ({
  clearCoupon: () => dispatch(actions.clearCoupon()),
  getPrice: () => dispatch(actions.requestGetPrice()),
  setCoupon: coupon => dispatch(actions.setCoupon({ coupon })),
  applyCoupon: coupon => dispatch(actions.requestApplyCoupon({ coupon })),
  onSubmit: (values, dispatch) => {
    dispatch(actions.requestProcessPayment(values))
  }
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'payment',
    validate,
    touchOnBlur: false
  })
)(PaymentPage)
