import React, { useRef, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import Button from 'src/lib/ui/Buttons/Button'
import styles from './RequestAccessForm.module.sass'
import _get from 'lodash/get'
import {
  Typography,
  Grid,
  Box,
  TextField,
  styled,
  Autocomplete
} from '@mui/material'
import { siteName } from 'src/utils/oneCodeBase'
import { SessionContext } from 'src/features/session/SessionContext'
import ReCAPTCHA from 'react-google-recaptcha'
import {
  getExtraData,
  getSubtype,
  getType
} from 'src/features/paywall/PaywallSlice'
import { useSelector } from 'react-redux'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ZOHO_INTERNALL_CALL } from '../../constants/featureFlagConstants'

const InputFormText = styled(TextField, {
  name: 'Form Text',
  label: 'form'
})(() => ({
  '& .MuiInputLabel-root': {
    fontWeight: 600
  },
  width: '100%'
}))

const FormText = ({
  input,
  label,
  meta: { touched, error },
  readOnly,
  ...custom
}) => (
  <InputFormText
    label={label}
    {...input}
    {...custom}
    autoComplete="off"
    error={touched && error}
    helperText={touched && error}
    InputProps={{
      readOnly: readOnly
    }}
  />
)

FormText.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  readOnly: PropTypes.bool
}

const renderAutocompleteField = ({
  input,
  label,
  meta: { touched, error },
  children,
  readOnly,
  ...custom
}) => {
  return (
    <Autocomplete
      {...input}
      isOptionEqualToValue={(option, value) => {
        if (!value) return true
        return option?.value === value
      }}
      onChange={(event, valueObj) => input.onChange(valueObj?.value)}
      renderInput={params => (
        <InputFormText
          {...params}
          label={label}
          variant="standard"
          error={touched && error}
          helperText={touched && error}
        />
      )}
      {...custom}
    >
      {children}
    </Autocomplete>
  )
}
const RequestAccessForm = ({
  handleSubmit,
  busy,
  change,
  catalogs = {},
  traditional = false,
  buttonLabel = 'Submit',
  requestUserRegistration = () => {},
  requestConsultantContact = () => {},
  onSuccess = () => {},
  disableConsultantContact = false,
  withSubmitTracking = () => {},
  notifySettings = null
}) => {
  const session = useContext(SessionContext)
  const recaptchaRef = useRef()
  const location = useLocation()
  const flags = useFlags()
  const subType = useSelector(getSubtype)
  const paywallType = useSelector(getType)
  const extraInfo = useSelector(getExtraData)

  const jobFunctionOptions = _get(catalogs, 'jobFunctions.options', {})
  const therapyOptions = _get(catalogs, 'therapy.options', {})
  const countryOptions = _get(catalogs, 'country.options', {})
  const zohoInternalCall = flags[ZOHO_INTERNALL_CALL]
  const getFullPageURL = () => window.location?.href
  const getPageURL = () => location.pathname

  const handleRecaptcha = value => {
    change('cap', value)
  }
  const recaptchaHandler = async data => {
    const recaptchaValue = await recaptchaRef.current.executeAsync()
    handleRecaptcha(recaptchaValue)
    data.cap = recaptchaValue
    recaptchaRef.current.reset()
  }
  const handleTracking = () => {
    if (withSubmitTracking) withSubmitTracking()
  }
  const isAuthenticated = session.authenticated

  const handleSubmitWithRecaptcha = async values => {
    handleTracking()
    let data = { ...values }
    data.originUrl = getPageURL()
    data.fullPageUrl = getFullPageURL()
    data.isAuthenticated = isAuthenticated
    data.extraInfo = extraInfo

    if (siteName !== 'Reports') {
      await recaptchaHandler(data)
      if (paywallType === 'ai_paywall') {
        data.subType = paywallType
      } else {
        data.subType = subType
      }
    }

    if (!disableConsultantContact) {
      requestConsultantContact({
        ...data,
        isFeatureEnabled: zohoInternalCall
      })
    }
    if (!session.checked || !isAuthenticated) {
      if (notifySettings) {
        data.notifySettings = notifySettings
      }
      requestUserRegistration(data)
    }
    if (onSuccess) {
      onSuccess()
    }
  }

  return (
    <form onSubmit={handleSubmit(handleSubmitWithRecaptcha)}>
      <Box
        sx={{
          maxHeight: 'calc(100vh - 200px)',
          overflowY: 'auto',
          padding: '16px',
          '@media (max-width: 600px)': {
            maxHeight: 'calc(100vh - 250px)'
          }
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Field
              component={FormText}
              label="First Name"
              name="first_name"
              variant="standard"
              errorAlign="center"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={FormText}
              label="Last Name"
              name="last_name"
              variant="standard"
              errorAlign="center"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={FormText}
              label="Work Email"
              variant="standard"
              name="email"
              errorAlign="center"
              type="email"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="country"
              component={renderAutocompleteField}
              label="Country"
              autoComplete="disable"
              errorAlign="center"
              options={Object.entries(countryOptions).map(([key, value]) => ({
                label: key,
                value: value
              }))}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={FormText}
              label="Company"
              name="company"
              errorAlign="center"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={FormText}
              label="Job Title"
              variant="standard"
              name="job_title"
              errorAlign="center"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="job_function"
              component={renderAutocompleteField}
              label="Job Function"
              errorAlign="center"
              autoComplete="disable"
              options={Object.entries(jobFunctionOptions).map(
                ([key, value]) => ({
                  label: key,
                  value: value
                })
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={FormText}
              variant="standard"
              label="Phone Number"
              name="phone_number"
              errorAlign="center"
              traditional={traditional}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={renderAutocompleteField}
              label="What therapy area do you work in?"
              variant="standard"
              name="therapy_area"
              errorAlign="center"
              autoComplete="disable"
              options={Object.entries(therapyOptions).map(([key, value]) => ({
                label: key,
                value: value
              }))}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={FormText}
              label="What products are you responsible for?"
              name="products"
              variant="standard"
              errorAlign="center"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Field
              component={FormText}
              label="Comments"
              name="comments"
              errorAlign="center"
              rows={8}
              multiline
            />
          </Grid>
        </Grid>
        <Field
          name="cap"
          component={() => null} // no need to render anything
        />

        <div className={styles.bottomContainer}>
          <Button
            className={styles.submitButton}
            type="submit"
            style={{
              backgroundColor: '#e27746',
              color: 'white',
              textTransform: 'none !important'
            }}
            disabled={busy}
          >
            {buttonLabel}
          </Button>
        </div>
        <Box>
          <Typography variant="body2" className={styles.termsText}>
            By creating a FirstWord account, you agree to our Terms of Service
            and Privacy Policy. You may also receive promotional emails from
            FirstWord. You can opt out at any time.
          </Typography>
        </Box>
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
          onChange={handleRecaptcha}
        />
      </Box>
    </form>
  )
}

RequestAccessForm.propTypes = {
  handleSubmit: PropTypes.func,
  busy: PropTypes.bool,
  change: PropTypes.func,
  catalogs: PropTypes.object,
  traditional: PropTypes.bool,
  buttonLabel: PropTypes.string,
  requestUserRegistration: PropTypes.func,
  requestConsultantContact: PropTypes.func,
  onSuccess: PropTypes.func,
  disableConsultantContact: PropTypes.bool,
  withSubmitTracking: PropTypes.func,
  notifySettings: PropTypes.object
}

export default RequestAccessForm
