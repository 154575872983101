import { compose } from 'redux'
import { connect } from 'react-redux'
import { actions } from './MyInterestsSlice'
import MyInterests from './components/MyInterests'
import { actions as settingsActions } from 'src/features/mySettings/MySettingsSlice'
import * as flags from 'src/features/asyncFlags/AsyncFlagsSelector'
import _get from 'lodash/get'

const mapStateToProps = state => ({
  interests: _get(state, 'session.user.interests.stated', {}),
  suggestionsMessage: state.myInterestsReducer.message,
  suggestions: state.myInterestsReducer.suggestions,
  pendingForDeletion: state.myInterestsReducer.pendingForDeletion,
  pendingForInsertion: state.myInterestsReducer.pendingForInsertion,
  myInterestsConfig:
    state?.configReducer?.my_account_settings?.data?.my_interests,
  busy: flags.isBusy('suggestInterest')(state),
  settings: state.mySettingsReducer.settings,
  settingsBusy:
    flags.isBusy('accountSettingsLoad')(state) ||
    flags.isBusy('accountSettingsUpdate')(state)
})

const mapDispatchToProps = dispatch => ({
  requestDeleteInterest: interest =>
    dispatch(actions.requestDeleteInterest(interest)),
  requestInsertInterest: interest =>
    dispatch(actions.requestInsertInterest(interest)),
  requestSuggestInterest: query =>
    dispatch(actions.requestSuggestInterest(query)),
  clearSuggestInterest: () => dispatch(actions.clearSuggestInterest()),
  updateSettings: settings =>
    dispatch(settingsActions.requestAccountSettingsUpdate(settings))
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  MyInterests
)
