import { notificationsEnqueue } from 'src/features/notifications/NotificationsSlice'
import FW5MLError from 'src/services/FW5ML/FW5MLError'

export const catchEmptyResponse = async ({
  request,
  response,
  error,
  dispatch
}) => {
  if (request && !response?.status) {
    console.error('The MiddleLayer is unreachable.', error)
    dispatch(
      notificationsEnqueue({
        message: 'Something went wrong! Please refresh the page and try again.'
      })
    )
  }
}

export const detectMLfailuresOnFail = async ({ error, response }) => {
  if (error?.data?.error) {
    const requestError = error.data.error
    console.error('MiddleLayer error:', requestError)
    const message = requestError?.message || requestError?.msg
    throw new FW5MLError(message, requestError?.code)
  }

  if (!response?.ok) {
    throw new Error(
      'Something went wrong! Please refresh the page and try again.'
    )
  }
}
