import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  pendingForDeletion: [],
  pendingForInsertion: [],
  suggestions: [],
  message: ''
}

const myInterestsSlice = createSlice({
  name: 'myInterestsReducer',
  initialState,
  reducers: {
    requestDeleteInterest: (state, { payload: interest }) => {
      state.pendingForDeletion.push(interest)
    },
    errorDeleteInterest: (state, { payload: { interest } }) => {
      state.pendingForDeletion = state.pendingForDeletion.filter(
        ({ category, tag }) =>
          category !== interest.category || tag !== interest.tag
      )
    },
    successDeleteInterest: (state, { payload: interest }) => {
      state.pendingForDeletion = state.pendingForDeletion.filter(
        ({ category, tag }) =>
          category !== interest.category || tag !== interest.tag
      )
    },
    requestInsertInterest: (state, { payload: interest }) => {
      state.pendingForInsertion.push(interest)
    },
    errorInsertInterest: (state, { payload: { interest, error } }) => {
      state.pendingForInsertion = state.pendingForInsertion.filter(
        ({ category, tag }) =>
          category !== interest.category || tag !== interest.tag
      )
    },
    successInsertInterest: (state, { payload: interest }) => {
      state.suggestions = []
      state.pendingForInsertion = state.pendingForInsertion.filter(
        ({ category, tag }) =>
          category !== interest.category || tag !== interest.tag
      )
    },
    requestSuggestInterest: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          asyncKey: 'suggestInterest',
          method: 'request'
        }
      }),
      reducer: state => {
        state.message = ''
      }
    },
    errorSuggestInterest: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          asyncKey: 'suggestInterest',
          method: 'error'
        }
      }),
      reducer: (state, { payload: { query, error } }) => {
        state.suggestions = []
      }
    },
    successSuggestInterest: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          asyncKey: 'suggestInterest',
          method: 'success'
        }
      }),
      reducer: (state, { payload: { suggestions, message } }) => {
        state.suggestions = suggestions
        state.message = message
      }
    },
    clearSuggestInterest: state => {
      state.suggestions = []
    }
  }
})

export const { actions, reducer } = myInterestsSlice

export const {
  requestDeleteInterest,
  errorDeleteInterest,
  successDeleteInterest,
  requestInsertInterest,
  errorInsertInterest,
  successInsertInterest,
  requestSuggestInterest,
  errorSuggestInterest,
  successSuggestInterest,
  clearSuggestInterest
} = actions

export default reducer
