import FWPerspectiveLive from 'src/assets/images/media-event/fw-perspectives-live.png'
import SponsorEvent from 'src/assets/images/media-event/sponsor-event.png'
import IAVLive from 'src/assets/images/media-event/iav-live.png'
import FWDefault from 'src/assets/images/featured/firstword.png'

export default {
  'FW Perspectives Live': FWPerspectiveLive,
  'Sponsor Event': SponsorEvent,
  'IAV Live': IAVLive,
  'FW Default': FWDefault
}
