import React from 'react'
import { useSelector } from 'react-redux'
import Typography from 'src/lib/ui/Typography'
import Footnote from 'src/lib/ui/Footnote'
import Button from 'src/lib/ui/Buttons/Button.js'
import styles from './PaymentPage.module.sass'
import { getCurrentYear } from 'src/utils'
import { siteName } from 'src/utils/oneCodeBase.js'
import PayWallPageStyles from 'src/features/benefits/components/PayWallPage/PayWallPage.module.sass'
import PayWallSection from 'src/features/benefits/components/PayWallPage/PayWallSection.js'
import usePayment from 'src/features/payment/usePayment'
import ReactHtmlParser from 'react-html-parser'
import { getConfig } from 'src/features/config/ConfigSelector'

const PaymentPage = props => {
  const { priceWithCurrency = null } = usePayment()

  const webpageContentConfig = useSelector(getConfig('webpage_content')) ?? {}
  const paywallConfig = webpageContentConfig?.checkout?.paywall ?? {}
  const descriptionListItems = paywallConfig?.list_description ?? []
  const contactInfo = paywallConfig?.contact_description ?? null
  const confirmationDescription =
    paywallConfig?.confirmation_description ?? null

  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <div className={styles.container}>
          <PayWallSection
            plus
            subheading={priceWithCurrency ? `${priceWithCurrency} / Year` : ''}
          >
            <ul className={PayWallPageStyles.list}>
              {descriptionListItems.map((item, index) => (
                <li
                  className={PayWallPageStyles.item}
                  key={`checkout-desc-item-${index}`}
                >
                  {item}
                </li>
              ))}
            </ul>
          </PayWallSection>

          <Footnote textClassName="Footnote" className={styles.containerCenter}>
            <div className={styles.footnotes}>
              {contactInfo && ReactHtmlParser(contactInfo)}
              <p>Conditions of Use | Privacy Notice</p>
              <p>&copy; {getCurrentYear()} FirstWord. All rights reserved</p>
            </div>
          </Footnote>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.container}>
          <Typography
            type="display1"
            className={styles.formCountryOrRegionLabel}
          >
            Subscription Confirmed
          </Typography>
          <br />
          <Footnote
            textClassName="Footnote-stories-module__footnote___3HH5M"
            className={styles.containerCenter}
          >
            {confirmationDescription &&
              ReactHtmlParser(confirmationDescription)}
          </Footnote>
          <br />
          <Button
            type="submit"
            tier="secondary"
            className={styles.button}
            textCase="none"
            onClick={() => {
              window.location.href = '/'
            }}
          >
            Start Exploring FirstWord {siteName}+
          </Button>
        </div>
      </div>
    </div>
  )
}

export default PaymentPage
