import { useEffect } from 'react'
import MainContent from './components/SingleStoryMainContent'
import SidebarContent from './components/SingleStorySidebarContent'
import {
  actions as paywallActions,
  getType,
  getBlur
} from 'src/features/paywall/PaywallSlice'
import commonStyles from 'src/styles/common.module.sass'
import { useDispatch, useSelector } from 'react-redux'
import useScrollBlock from 'src/hooks/useScrollBlock'
import Page from 'src/components/Page'
import { actions as viewActions } from 'src/features/views/ViewsSlice'
import cx from 'classnames'

const SingleStoryPage = ({ match }) => {
  const dispatch = useDispatch()
  const paywallType = useSelector(getType)
  const blurArticle = useSelector(getBlur)
  const [blockScroll, allowScroll] = useScrollBlock()
  const id = match?.params?.id

  const getViewsPayload = id => {
    if (!id) return []

    const extraViewsParams = {
      article_id: [parseInt(id, 10)],
      related_to_article_id: [parseInt(id, 10)]
    }

    const keys = ['recent news', 'related analysis', 'related news']
    return keys.map(key => ({ name: key, ...extraViewsParams }))
  }

  useEffect(() => {
    // Get Views for Single Story Page since they are used in main and sidebar content
    const views = getViewsPayload(id)
    dispatch(viewActions.requestViews(views))

    return () => {
      dispatch(viewActions.cleanViews())
      if (paywallType !== 'ai_paywall' || paywallType !== 'free_feature') {
        dispatch(paywallActions.removePaywall())
      }
    }
  }, [id])

  useEffect(() => {
    if (blurArticle) {
      blockScroll()
    }

    return () => {
      allowScroll()
    }
  }, [blurArticle])

  return (
    <Page>
      <div
        className={cx(
          commonStyles.content,
          blurArticle ? commonStyles.blurPage : ''
        )}
      >
        <div className={commonStyles.main}>
          <MainContent id={id} />
        </div>
        <div className={commonStyles.sidebar}>
          <SidebarContent id={id} />
        </div>
      </div>
    </Page>
  )
}

export default SingleStoryPage
