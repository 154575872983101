import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { actions } from 'src/features/mobileMenu/MobileMenuSlice'
import Link from '../Link'
import Icon from 'src/lib/ui/Icon'
import List from 'src/lib/ui/List'
import ListItem from 'src/lib/ui/List/ListItem.js'
import Tag from '../Tag'
import styles from './HeaderItem.module.sass'
import cx from 'classnames'

export class HeaderItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
  }
  handleCloseMenuIfOpen = () => {
    const { open } = this.state
    if (open) {
      this.setState({
        open: false
      })
    }
  }
  handleItemToggle = () => {
    const { open } = this.state
    const {
      leftMenuOpen,
      rightMenuOpen,
      handleLeftSlideMenu,
      handleSlideMenu
    } = this.props
    if (rightMenuOpen) handleSlideMenu()
    if (leftMenuOpen) handleLeftSlideMenu()
    this.setState({
      open: !open
    })
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleCloseMenuIfOpen)
  }

  render() {
    const { open } = this.state
    const { children, links = [], icon, className, register } = this.props
    const hasLinks = Boolean(links.length)
    return (
      <li className={cx(styles.item, icon && styles.icon, className)}>
        <div
          onClick={hasLinks ? this.handleItemToggle : undefined}
          className={styles.button}
        >
          {children}
          {!icon && !register && (
            <div className={styles.spacer}>
              {hasLinks && (
                <Icon icon="svg/material-design-icons/navigation/arrow_drop_down" />
              )}
            </div>
          )}
        </div>
        {open && (
          <div className={styles.close} onClick={this.handleCloseMenuIfOpen} />
        )}
        {hasLinks && open && (
          <List ripple divider dense className={styles.itemMenu}>
            <div className={styles.itemsContainer}>
              {links.map(({ label, tag, ...rest }) => (
                <ListItem
                  {...rest}
                  component={Link}
                  onClick={this.handleItemToggle}
                  key={label}
                  data-piwik-class="menu-item"
                >
                  {label}
                  {tag && <Tag className={styles.auxTag} label={tag} />}
                </ListItem>
              ))}
            </div>
          </List>
        )}
      </li>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  handleSlideMenu: () => dispatch(actions.toggleRightMenu()),
  handleLeftSlideMenu: () => dispatch(actions.toggleLeftMenu())
})

const mapStateToProps = ({ mobileMenuReducer }) => ({
  leftMenuOpen: mobileMenuReducer.leftMenuOpen,
  rightMenuOpen: mobileMenuReducer.rightMenuOpen
})

HeaderItem.propTypes = {
  children: PropTypes.node,
  links: PropTypes.array,
  icon: PropTypes.bool,
  className: PropTypes.string,
  register: PropTypes.bool,
  leftMenuOpen: PropTypes.bool,
  rightMenuOpen: PropTypes.bool,
  handleLeftSlideMenu: PropTypes.func,
  handleSlideMenu: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderItem)
