import { useDispatch } from 'react-redux'
import { piwikRequest } from 'src/features/piwik/PiwikSlice'
import { useLocation } from 'react-router-dom'

const usePiwik = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const updateCustomVar = (key, value) => {
    dispatch(
      piwikRequest({
        customVarUpdate: {
          key,
          value
        }
      })
    )
  }

  const handleTracking = (category, action, name, customData = null) => {
    dispatch(
      piwikRequest({
        tracking: {
          category,
          action,
          name,
          customData
        }
      })
    )
  }

  const trackArticleId = () => {
    if (location.pathname.includes('story')) {
      const splitUrl = location.pathname.match(/story\/(\d+)/)
      if (splitUrl) {
        updateCustomVar('article_id', splitUrl[1])
      }
    }
  }

  const handleTrackingWithArticleId = (category, action, name) => {
    trackArticleId()
    handleTracking(category, action, name)
  }

  const handleTrackingWithArticleIdProp = (
    category,
    action,
    name,
    articleId
  ) => {
    updateCustomVar('article_id', articleId)
    handleTracking(category, action, name)
  }

  const handleTrackingWithCustomData = (category, action, name, data = {}) => {
    const trackingData = {
      ...data,
      timestamp: new Date().toISOString()
    }

    handleTracking(category, action, name, trackingData)
  }

  //Tracks action_name attributes. ex: 'Site/Click/Tracking'
  const handleActionTracking = (actionName, customData = null) => {
    dispatch(
      piwikRequest({
        tracking: {
          actionName,
          customData
        }
      })
    )
  }

  return {
    updateCustomVar,
    handleTracking,
    trackArticleId,
    handleTrackingWithArticleId,
    handleTrackingWithArticleIdProp,
    handleTrackingWithCustomData,
    handleActionTracking
  }
}

export default usePiwik
