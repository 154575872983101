import React from 'react'
import PropTypes from 'prop-types'
import StoryRiver from '../StoryRiver'
import View from '../View'
import ViewMoreBox from '../ViewMoreBox'
import StoryExcerpt from '../StoryExcerpt'
import { ProtectedArea, ShowFor } from '../LicenseProtected'
import { Link } from 'react-router-dom'
import Teaser from '../Teaser'
import Title from '../RecommendedStoriesTitle'
import styles from '../../styles/common.module.sass'
import { siteName } from '../../utils/oneCodeBase'

const MyStoriesRiver = ({
  view,
  viewsError,
  viewsLoading,
  imageSize,
  onTypeClick,
  flex = 'column'
}) => {
  const teaserSource =
    imageSize === 'small'
      ? '/assets/blurred-recommended-mobile.jpg'
      : '/assets/blurred-recommended.jpg'

  return (
    <StoryRiver title={<Title />} titleType="title">
      <ProtectedArea>
        <ShowFor authenticated>
          <View
            view={view}
            error={viewsError}
            loading={viewsLoading}
            render={(story, i) => (
              <StoryExcerpt
                key={story.id}
                id={story.id}
                to={`/story/${story.id}`}
                tag={story.tag}
                title={story.title}
                body={story.teaser}
                published={story.published}
                type={story.type}
                typeTo={`/river/type/${story.type}`}
                congress={story.congress}
                plus={story.flags.isPaid}
                image={i === 0 ? story.image : null}
                onTypeClick={onTypeClick}
                last={i === view.stories.length - 1}
                likes={story.likes}
                likedByProfile={story.likedByProfile}
              />
            )}
          />
          <ViewMoreBox to="/river/my-fw" data-piwik-class="view-more-link">
            View More <Title />
          </ViewMoreBox>
        </ShowFor>
        {flex === 'column' && (
          <ShowFor anonymous>
            <Teaser src={teaserSource}>
              <Link to="/register" className={styles.link}>
                Register
              </Link>{' '}
              in order to benefit from FirstWord {siteName}'s recommendation
              technology helping you discover news and content of interest to
              you.
            </Teaser>
          </ShowFor>
        )}
      </ProtectedArea>
    </StoryRiver>
  )
}

MyStoriesRiver.propTypes = {
  view: PropTypes.object,
  viewsError: PropTypes.string,
  viewsLoading: PropTypes.bool,
  imageSize: PropTypes.string,
  getArticleLink: PropTypes.func,
  onTypeClick: PropTypes.func,
  flex: PropTypes.string
}

export default MyStoriesRiver
