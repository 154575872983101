import { useContext } from 'react'
import { SessionContext } from 'src/features/session/SessionContext'
import { licenseTypes } from 'src/services/FW5ML/parsers/UserParser'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { actions } from 'src/features/paywall/PaywallSlice'

import { useDispatch } from 'react-redux'

const useProtectedRender = () => {
  const flags = useFlags()
  const session = useContext(SessionContext)

  const showForNotReady = element => {
    const { checked: sessionReady } = session
    const { notReady } = element.props
    return notReady && !sessionReady
  }

  const showForAnons = element => {
    const { checked: sessionReady, authenticated: userIsAuth } = session
    const { anonymous } = element.props
    return sessionReady && anonymous && !userIsAuth
  }

  const showForAuths = element => {
    const { checked: sessionReady, authenticated: userIsAuth } = session
    const { authenticated } = element.props
    return sessionReady && authenticated && userIsAuth
  }

  const showForLicense = element => {
    const { checked: sessionReady, user } = session
    const { license } = element.props
    return (
      sessionReady &&
      license &&
      (license === user.license ||
        (Array.isArray(license) && license.indexOf(user.license) > -1))
    )
  }

  const showForFeatureFlag = element => {
    if (process.env.NODE_ENV === 'development') {
      return true
    }
    const featureFlag = element?.props?.featureFlag
    return flags[featureFlag]
  }

  const showForNotPlus = element => {
    const {
      checked: sessionReady,
      user: { license = '' }
    } = session
    const { notPlus } = element.props
    return sessionReady && notPlus && license !== licenseTypes.LICENSE_PLUS
  }

  const showForLicenseType = element => {
    const { checked: sessionReady, user } = session
    const { licenseType = null } = element.props
    return (
      sessionReady &&
      user?.license_types?.find(item => item?.name === licenseType)
    )
  }

  const validProps = [
    'notReady',
    'anonymous',
    'authenticated',
    'license',
    'notPlus',
    'featureFlag',
    'licenseType'
  ]

  const checkProp = (prop, element) => {
    switch (prop) {
      case 'notReady':
        return showForNotReady(element)
      case 'anonymous':
        return showForAnons(element)
      case 'authenticated':
        return showForAuths(element)
      case 'license':
        return showForLicense(element)
      case 'notPlus':
        return showForNotPlus(element)
      case 'featureFlag':
        return showForFeatureFlag(element)
      case 'licenseType':
        return showForLicenseType(element)
      default:
        return false
    }
  }

  const shouldElementBeRendered = element => {
    const { checked: sessionReady } = session
    const dispatch = useDispatch()
    const requireAllProps = element.props.requireAll || false
    // Get the valid props passed to the element
    const elementProps = Object.keys(element.props).filter(prop =>
      validProps.includes(prop)
    )

    const shouldRender = requireAllProps
      ? elementProps.every(prop => checkProp(prop, element))
      : elementProps.some(prop => checkProp(prop, element))

    // If the element should be rendered, return the element
    if (shouldRender) {
      return element
    }
    if (element.props.paywall) {
      dispatch(
        actions.updatePaywallVisible({
          type: element.props.paywall,
          visible: true,
          subtype: element.props?.subtype || null
        })
      )
    }

    // Otherwise, wait for session: needed since we have showForNotReady so it has to happen after
    if (!sessionReady) return null

    return null
  }

  return shouldElementBeRendered
}

export default useProtectedRender
