import { useSelector } from 'react-redux'
import TopStories from './children/TopStories'
import ConferenceNews from './children/ConferenceNews'
import RecentReports from './children/RecentReports'
import RecommendedForYou from './children/RecommendedForYou'
import LatestResearch from './children/LatestResearch'
import ResearchWithKOL from './children/ResearchWithKOL'
import ResearchWithIndustryExperts from './children/ResearchWithIndustryExperts'
import ResearchWithTreatingPhysicians from './children/ResearchWithTreatingPhysicians'
import YourReportsView from 'src/components/YourReportsView'
import Ad from 'src/features/ads/components/Ad'
import ShowForMediaSize from 'src/components/Responsive/ShowForMediaSize'
import styles from '../../HomePage.module.sass'
import IAV from '../HomeSidebarContent/children/IAV'
import { siteName } from 'src/utils/oneCodeBase'

const ReportsHomeMainContent = () => {
  return (
    <>
      <LatestResearch />
      <YourReportsView />
      <ResearchWithKOL />
      <ResearchWithIndustryExperts />
      <ResearchWithTreatingPhysicians />
    </>
  )
}

const Home = () => {
  const homeLeaderboard2Ad = useSelector(
    state => state?.adsReducer.homeLeaderboard2 || null
  )

  const homeLeaderboard2MobileAd = useSelector(
    state => state?.adsReducer.homeLeaderboard2Mobile || null
  )

  const homeMediumRectangle3Ad = useSelector(
    state => state?.adsReducer.homeMediumRectangle3 || null
  )

  return (
    <>
      <TopStories />

      {homeLeaderboard2Ad && (
        <ShowForMediaSize showFor={['large']}>
          <Ad {...homeLeaderboard2Ad} className={styles.homeRectangleAd} />
        </ShowForMediaSize>
      )}

      <ShowForMediaSize showFor={['small', 'medium']}>
        {homeLeaderboard2MobileAd && (
          <Ad
            {...homeLeaderboard2MobileAd}
            className={styles.homeRectangleAd}
          />
        )}

        <IAV />
      </ShowForMediaSize>

      <RecommendedForYou />

      <ShowForMediaSize showFor={['small', 'medium']}>
        {homeMediumRectangle3Ad && (
          <Ad {...homeMediumRectangle3Ad} className={styles.homeRectangleAd} />
        )}
      </ShowForMediaSize>

      <ConferenceNews />
      <RecentReports />
    </>
  )
}

const mapping = {
  Reports: ReportsHomeMainContent,
  default: Home
}

export default mapping[siteName] || mapping.default
