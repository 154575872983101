import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider } from 'react-hook-form'
import PropTypes from 'prop-types'
import { isEmpty, buildStoryLinkFromArticleId } from 'src/utils'
import queryString from 'query-string'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

import { getPathToRedirect } from 'src/features/fw-ai/children/loading/LoadingSelector'
import {
  getIsFetching,
  getRawHistory
} from 'src/features/fw-ai/children/history/HistorySelector'
import { actions as historyActions } from 'src/features/fw-ai/children/history/HistorySlice'
import useConfig from 'src/hooks/useConfig'
import useMediaSize from 'src/hooks/useMediaSize'
import { usePromptValidation, useUrlPrompts, useChat, useTopic } from './hooks'
import { useServices } from 'src/services/ServiceContext'

import PromptBar from 'src/components/PromptBar/PromptBar'
import MessageStream from 'src/components/MessageStream/MessagesStream'
import LiveFeedComponent from 'src/features/fw-ai/children/liveFeed/LiveFeed'
import HayStackSelector from 'src/features/fw-ai/children/dougallFW/sections/HaystackSelector'

import StrippedFooter from 'src/components/Footer/strippedFooter'
import usePiwik from 'src/hooks/usePiwik'
import ActionModals from 'src/components/ActionModals/ActionModals'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { FIRST_WORD_FEEDBACK } from 'src/constants/featureFlagConstants'
/**
 * Chat Form Container
 * theme path: Chat.styleOverrides.formContainer
 */
const ChatFormContainer = styled('form', {
  name: 'Chat',
  label: 'chatFormContainer',
  overridesResolver: (props, styles) => [styles.formContainer]
})(() => ({
  height: '100%'
}))

/**
 * Chat Container
 * theme path: Chat.styleOverrides.container
 */
const ChatContainer = styled(Box, {
  name: 'Chat',
  label: 'chatContainer'
  // overridesResolver: (props, styles) => [styles.container]
})(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
}))

/**
 * Chat Stream Container
 * theme path: Chat.styleOverrides.streamContainer
 */
const ChatStreamContainer = styled(Box, {
  name: 'Chat',
  label: 'chatStreamContainer',
  overridesResolver: (props, styles) => [styles.streamContainer]
})(() => ({
  paddingBottom: '20px',
  overflowY: 'auto',
  /* Scrollbar Hidden for IE and Edge */
  msOverflowStyle: 'none',
  /* Scrollbar Hidden for Firefox */
  scrollbarWidth: 'none',
  /* Scrollbar Hidden for Chrome, Safari and Opera */
  '&::-webkit-scrollbar': {
    display: 'none'
  }
}))

/**
 * Chat Mobile Footer Container
 * theme path: Chat.styleOverrides.mobileFooterContainer
 */
const ChatMobileFooterContainer = styled(Box, {
  name: 'Chat',
  label: 'chatMobileFooterContainer',
  overridesResolver: (props, styles) => [styles.mobileFooterContainer]
})(() => ({
  '> section': {
    backgroundColor: 'transparent'
  }
}))

/**
 * Chat Bottom Container
 * theme path: Chat.styleOverrides.bottomContainer
 */
const ChatBottomContainer = styled(Box, {
  name: 'Chat',
  label: 'chatBottomContainer',
  overridesResolver: (props, styles) => [styles.bottomContainer]
})(() => ({
  position: 'relative'
}))

/**
 * Chat Scroll Fade
 * theme path: Chat.styleOverrides.scrollFade
 */
const ChatScrollFade = styled(Box, {
  name: 'Chat',
  label: 'chatScrollFade',
  overridesResolver: (props, styles) => [styles.scrollFade]
})(() => ({
  height: '20px',
  background: 'linear-gradient(to top, #f8f9fc 10%, transparent)',
  backgroundColor: 'transparent',
  position: 'absolute',
  top: '-20px',
  width: '100%',
  '@media all and (max-width: 860px)': {
    width: 'calc(100% - 1px)',
    marginRight: '1px'
  }
}))

/**
 * Chat Warning
 * theme path: Chat.styleOverrides.warning
 */
const ChatWarning = styled(Typography, {
  name: 'Chat',
  label: 'chatWarning',
  overridesResolver: (props, styles) => [styles.warning]
})(() => ({
  fontSize: '12px',
  fontWeight: 'bold',
  color: '#667085',
  textAlign: 'center'
}))

const Chat = ({ empty = true, footer = <div></div> }) => {
  // Hooks
  const { handleTracking, handleActionTracking } = usePiwik()
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const { SessionService } = useServices(['SessionService'])
  const path = useSelector(getPathToRedirect()) || false
  const {
    isFetching,
    formState,
    messages,
    isTyping,
    actionSubmit,
    validatePromptAccess,
    promptRequest,
    addMessages,
    setCurrentPrompt,
    setFormState,
    setAction,
    setActiveModal,
    setTyping
  } = useChat()
  useUrlPrompts() // URL prompt handling hook
  usePromptValidation() // Prompt validation hook
  const { handleNewTopic } = useTopic()
  const flags = useFlags()
  const showLikes = flags[FIRST_WORD_FEEDBACK]
  const mediaSize = useMediaSize({
    mediaQueries: {
      small: '(max-width: 860px)'
    }
  })
  const { getSiteConfig } = useConfig()
  // Selectors
  const isHistoryFetching = useSelector(getIsFetching())
  const rawHistory = useSelector(getRawHistory())
  const sawLanding = SessionService.getFromCache('sawLanding', false)
  const initialForm = {
    prompt: ''
  }
  const formStatePr = isEmpty(formState) ? initialForm : formState
  const messagesStream = [...messages]
  const {
    source = undefined,
    prompt = undefined,
    promptNum = undefined,
    dspId = undefined,
    pId = undefined
  } = queryString.parse(path)
  const landingRoute = getSiteConfig('routes.landing', false)

  // Form hook
  const methods = useForm({
    mode: 'onTouched',
    defaultValues: formStatePr
  })
  const { control, handleSubmit: handleFormSubmit, reset, setValue } = methods

  // Load last thread
  useEffect(() => {
    if (
      !((dspId && prompt && promptNum) || (pId && source) || (prompt && source))
    ) {
      if (!isHistoryFetching && rawHistory.length > 0 && !messages.length) {
        const lastThreadId = rawHistory[0].applicationLinkedPromptId
        dispatch(
          historyActions.requestPromptHistory({ promptId: lastThreadId })
        )
        dispatch(historyActions.setSelectedThread({ id: lastThreadId }))
      }
    }
    // eslint-disable-next-line
  }, [isHistoryFetching])

  // First Render
  useEffect(() => {
    // if landing page route is defined
    if (landingRoute) {
      // Redirect to landingPage !sawLanding, skip if dspId & prompt on url
      if (!sawLanding && !dspId && !prompt && !promptNum && !pId) {
        history.replace(landingRoute.path, {
          from: history.location.pathname
        })
      }
    }
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (messages && !messages.length && !empty) {
      // Add initial prompt only if there are no messages in the store
      addMessages([
        {
          isAnswer: false,
          message: t('dougallGptInitialPrompt'),
          editable: false
        },
        {
          isAnswer: true,
          showWarning: false,
          answer: t('dougallGptInitialAnswer'),
          showSocial: false
        }
      ])
    }
    // If the user is coming from an email do not validate prompt access on first render
    if (!(dspId && prompt && promptNum) && !source) {
      validatePromptAccess()
    }
    // eslint-disable-next-line
  }, [])

  // Update formState on store change
  useEffect(() => {
    reset(formState)
    // eslint-disable-next-line
  }, [formState])

  /**
   * Prompt Submit handler
   */
  const handleSubmit = values => {
    const { prompt } = values

    if (prompt?.trim() && !isFetching) {
      // track submit on prompt submit.
      handleTracking('firstword-ai', 'button-click', 'execute-prompt')
      handleReset()
      promptRequest({ hcpPrompt: prompt, type: 'regular' })
    }
  }

  /**
   * Message edit hanlder
   */
  const handleEdit = prompt => {
    setFormState({
      prompt
    })
    handleTracking('firstword-ai', 'click', 'edit-prompt')
  }

  /**
   * Like or Dislike Handler
   */
  const handleAction = ({ action, prompt, promptId, answerId, undo }) => {
    if (action === 'share') {
      setActiveModal('share')
      setAction({ action, prompt, promptId, answerId })
      return
    }

    if (undo) {
      actionSubmit({
        action,
        promptId,
        answerId,
        undo
      })
    } else {
      setAction({ action, promptId, answerId })
      setActiveModal('feedback')
    }
  }

  /**
   * On Typing finish handler
   */
  const handleTypingFinish = () => setTyping(false)

  /**
   * Reset Chat form hanlder
   */
  const handleReset = () => reset({ prompt: '' })

  /** Gets the last prompt available when selecting a new option from Haystack */
  const handleSelectorChange = () => {
    const prompts = messages.filter(item => item.message && !item.isAnswer)

    if (prompts.length > 0) {
      const lastPrompt = prompts[prompts.length - 1]

      setFormState({
        prompt: lastPrompt?.message
      })
    }
  }

  const handleConsultedSourcesClick = ({
    urls = [],
    element = {},
    onClickEvent,
    ...tag
  }) => {
    const { count, links } = element
    const trackedData = {
      promptId: tag?.promptId,
      pos: tag?.pos,
      answerId: tag?.answerId,
      sourceName: tag?.btnId
    }
    handleActionTracking('Site/Click/SourcesConsulted', trackedData)
    // when clicking tag with single sources
    if (count === 1) {
      setActiveModal(null)
      const url = buildStoryLinkFromArticleId(links[0]?.articleId)
      window.open(url, '_blank', 'noopener,noreferrer')
      // when clicking tag with multiple sources
    } else {
      onClickEvent.stopPropagation()
      setCurrentPrompt({
        promptId: tag?.promptId,
        answerId: tag?.answerId,
        urls: element,
        selectedUrl: tag?.btnId
      })
      setActiveModal('consulted-sources')
    }
  }

  const handleFollowUpClick = useCallback(({ prompt, pos, promptId }) => {
    setValue('prompt', prompt)
    handleFormSubmit(handleSubmit)()
    handleActionTracking('Site/Click/Answer/followup-prompt', {
      pos,
      promptId
    })
  }, [])

  return (
    <>
      <FormProvider {...methods}>
        <ChatFormContainer
          data-testid="chatContainer"
          autoComplete="off"
          onSubmit={handleFormSubmit(handleSubmit)}
        >
          <ChatContainer data-testid="chat" data-private>
            <ChatStreamContainer>
              <MessageStream
                messages={messagesStream}
                isLoading={isFetching}
                isTyping={isTyping}
                onEditClick={prompt => handleEdit(prompt)}
                onAction={handleAction}
                onTypingFinish={handleTypingFinish}
                showLikes={showLikes}
                onConsultedSourceClick={handleConsultedSourcesClick}
                onFollowUpClick={handleFollowUpClick}
              />
              {mediaSize === 'small' && (
                <>
                  <LiveFeedComponent />
                  <ChatMobileFooterContainer>
                    {footer}
                  </ChatMobileFooterContainer>
                </>
              )}
            </ChatStreamContainer>
            <ChatBottomContainer>
              <ChatScrollFade />
              <PromptBar
                control={control}
                name="prompt"
                placeholder={t('chatWithGptPlaceHolder')}
                onNewTopicClick={() => {
                  handleTracking('firstword-ai', 'button-click', 'new-topic')
                  handleNewTopic()
                }}
                onChange={setValue}
                onSubmit={handleSubmit}
              />
              <HayStackSelector onChange={handleSelectorChange} />
              <ChatWarning>{t('warningPromptBar')}</ChatWarning>
              {mediaSize !== 'small' && <StrippedFooter />}
            </ChatBottomContainer>
          </ChatContainer>
        </ChatFormContainer>
      </FormProvider>
      <ActionModals />
    </>
  )
}

Chat.propTypes = {
  empty: PropTypes.bool,
  footer: PropTypes.node
}

export default Chat
