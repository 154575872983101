import React from 'react'
import { useSelector } from 'react-redux'
import useMediaSize from 'src/hooks/useMediaSize'
import FeaturedRiver from 'src/components/FeaturedRiver'
import RiverHeader from 'src/components/RiverHeader'
import styles from './FeaturedContent.module.sass'
import commonStyles from 'src/styles/common.module.sass'
import classNames from 'classnames/bind'
import * as selectors from 'src/features/river/RiverSelector'
import { useParams } from 'react-router'

const cx = classNames.bind(styles)

const FeaturedContent = ({ single = false }) => {
  const isFeaturedLoading = useSelector(selectors.getFeaturedLoading)
  const featuredResults = useSelector(selectors.getFeaturedResults)
  const { key: searchTerm } = useParams()
  const mediaSize = useMediaSize()

  if (!isFeaturedLoading && featuredResults?.length === 0) {
    return null
  }

  return (
    <div className={styles.container}>
      <section
        className={cx('content', {
          [commonStyles.content]: !single
        })}
      >
        {!single && <RiverHeader title={searchTerm} showFilterMenu={false} />}
        <FeaturedRiver
          view={featuredResults}
          loading={isFeaturedLoading}
          imageSize={mediaSize}
          getArticleLink={story => `/story/${story.id}`}
          single={single}
        />
      </section>
    </div>
  )
}

export default FeaturedContent
