import { compose } from 'redux'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { actions } from '../MySavedSearchesSlice'
import * as flags from 'src/features/asyncFlags/AsyncFlagsSelector'
import SaveSearchForm from '../components/SaveSearch'
import validate from '../components/SaveSearch/validate'
import { push } from 'connected-react-router'

const mapStateToProps = state => {
  const alert =
    state?.mySavedSearchesReducer?.saving?.length > 0 &&
    state.mySavedSearchesReducer.saving[0]?.search?.search_id
      ? state.mySavedSearchesReducer.saving[0].search.alert_frequency || 'none'
      : 'none'
  return {
    error: flags.getError('saveSearchAlert')(state),
    busy: flags.isBusy('saveSearchAlert')(state),
    search: state.mySavedSearchesReducer.saving[0] || null,
    initialValues: {
      alert
    }
  }
}

const mapDispatchToProps = dispatch => ({
  navigateToSearch: () => dispatch(push('/search'))
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'save-search-form',
    validate,
    touchOnBlur: false,
    onSubmit: (values, dispatch) =>
      dispatch(actions.requestSaveSearchAlert(values))
  })
)(SaveSearchForm)
