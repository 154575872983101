import {
  takeLatest,
  put,
  call,
  all,
  takeEvery,
  delay
} from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { actions } from './SingleStorySlice'
import { notificationsEnqueue } from 'src/features/notifications/NotificationsSlice'
import { logPush } from 'src/features/contactUs/ContactUsSlice'
import FW5MLError from 'src/services/FW5ML/FW5MLError'
import { notificationDuration as duration } from 'src/constants'

import { SERVER_ERROR } from 'src/services/FW5ML/errorCodes'

function* trackFWRecentReport(services, action) {
  const {
    payload: { id, url }
  } = action
  if (!id || !url) return
  const Piwik = services('Piwik')
  try {
    const Session = services('Session')
    const user = yield call([Session, 'userLoad'])
    // user's ids are tracked under site_uid custom variable
    if (user) {
      yield call([Piwik, 'updateCustomVariable'], 'site_uid', user.profile_id)
    }
    yield call([Piwik, 'updateCustomVariable'], 'article_id', id)
    yield put(
      notificationsEnqueue({
        message: 'Opening Report in a new tab.',
        duration
      })
    )
    // delay to show notification
    yield delay(1000)
    window.open(url, '_blank', 'noopener,noreferrer')
    yield call([Piwik, 'track'], 'page', 'section-click_home', 'recent-reports')
  } catch (e) {
    console.log(e)
    if (e instanceof FW5MLError) {
      const code = e.getCode()
      if (code === SERVER_ERROR) {
        yield put(
          logPush(
            'Single Story',
            `Error while loading article ${id}: ${e.message}`
          )
        )
        yield put(
          notificationsEnqueue({
            message:
              'Unable to load article. Please refresh the page and try again.'
          })
        )
        yield call(
          [Piwik, 'track'],
          'notification',
          'error',
          'could-not-request-single-story'
        )
        yield put(push('/contact-us'))
      }
    }
    yield put(actions.errorSingleStory(e.message))
  }
}

function* notifyMeResultsRequest(services, action) {
  const StoriesRepository = services('StoriesRepository')
  const { payload } = action
  const { resultType, article } = payload
  try {
    let story = null

    if (article?.id && article?.title && resultType) {
      const searchConfig = {
        source: article,
        type: [resultType]
      }

      story = yield call(
        [StoriesRepository, 'searchNotifyMeResults'],
        searchConfig
      )
    }

    yield put(actions.successNotifyMeResults({ story }))
  } catch (e) {
    console.log(e)
    yield put(actions.errorNotifyMeResults(e.message))
  }
}

function* notifyMeSubscribeRequest(services, action) {
  const Session = services('Session')
  const user = yield call([Session, 'userLoad'])
  const { payload } = action
  const { newsletterUuid, articleId } = payload
  const cookie = `notify-me-subscribe|${articleId}|${newsletterUuid}`

  if (!user) {
    yield call([Session, 'setCookie'], 'current_flow', cookie)
  }
  yield call(notifyMeSubscribe, services, { payload: { cookie } })
}

function* notifyMeSubscribe(services, action) {
  const Session = services('Session')
  const Piwik = services('Piwik')
  const UserRepository = services('UserRepository')
  const { payload = {} } = action || {}

  let prevCookie = payload?.cookie

  if (!prevCookie) {
    prevCookie = yield call([Session, 'getCookie'], 'current_flow')
  }

  const user = yield call([Session, 'userLoad'])

  if (!user || !prevCookie?.includes('notify-me-subscribe')) return

  yield call([Session, 'removeCookie'], 'current_flow')

  const cookieSplit = prevCookie.split('|')
  const articleId = cookieSplit[1]
  const newsletterUuid = cookieSplit[2]
  let { notifyMe = [] } = user

  try {
    if (!articleId) {
      throw new Error('No article id defined.')
    }

    if (!newsletterUuid) {
      throw new Error('No newsletter defined.')
    }

    if (
      notifyMe?.find(item => item?.preview_article_ids?.includes(articleId))
    ) {
      yield put(
        notificationsEnqueue({
          message:
            'It appears that you are already subscribed to be notified of the results.'
        })
      )
      return
    }

    // ML call
    yield call([UserRepository, 'notifyMeSubscribe'], newsletterUuid, articleId)
    yield put(
      notificationsEnqueue({
        message:
          'Thank you. We will notify you via email when the results are published'
      })
    )

    // Update user info for notifyMe
    const currentItemIndex = notifyMe.findIndex(
      item => item.newsletter_uuid === newsletterUuid
    )
    if (currentItemIndex >= 0) {
      const { preview_article_ids } = notifyMe[currentItemIndex]
      notifyMe[currentItemIndex].preview_article_ids = [
        ...preview_article_ids,
        articleId
      ]
    } else {
      notifyMe = [
        ...notifyMe,
        { newsletter_uuid: newsletterUuid, preview_article_ids: [articleId] }
      ]
    }

    yield call([Session, 'userUpdate'], {
      notifyMe
    })
  } catch (e) {
    yield put(
      notificationsEnqueue({
        message:
          'Unable to subscribe to be notified of the results. Please refresh the page and try again.'
      })
    )
    yield call(
      [Piwik, 'track'],
      'notification',
      'error',
      `could-not-update-${articleId}-notify-me`
    )
  }
}

function* simpleNotifyMeSubscribeRequest(
  services,
  { payload: subscriptions = [] }
) {
  const Session = services('Session')
  const Piwik = services('Piwik')
  const UserRepository = services('UserRepository')

  for (const subscription of subscriptions) {
    const { newsletterUuid, articleId } = subscription

    const user = yield call([Session, 'userLoad'])

    if (!user || !articleId || !newsletterUuid) {
      throw new Error('Missing payload to be notified.')
    }

    let { notifyMe = [] } = user

    try {
      // ML call
      yield call(
        [UserRepository, 'notifyMeSubscribe'],
        newsletterUuid,
        articleId
      )

      // Update user info for notifyMe
      const currentItemIndex = notifyMe.findIndex(
        item => item?.newsletter_uuid === newsletterUuid
      )
      if (currentItemIndex >= 0) {
        const { preview_article_ids } = notifyMe[currentItemIndex]
        let updatedArticleIds = []

        if (preview_article_ids?.includes(articleId)) {
          updatedArticleIds = preview_article_ids.filter(id => id !== articleId)
        } else {
          updatedArticleIds = [...preview_article_ids, articleId]
        }

        notifyMe[currentItemIndex].preview_article_ids = updatedArticleIds
      } else {
        notifyMe = [
          ...notifyMe,
          { newsletter_uuid: newsletterUuid, preview_article_ids: [articleId] }
        ]
      }

      yield call([Session, 'userUpdate'], {
        notifyMe
      })
    } catch (e) {
      yield put(
        notificationsEnqueue({
          message:
            'Unable to subscribe to be notified. Please refresh the page and try again.'
        })
      )
      yield call(
        [Piwik, 'track'],
        'notification',
        'error',
        `could-not-update-${articleId}-notify-me`
      )
    }
  }
}

function* relatedUpdatesRequest(services, { payload: story }) {
  const StoriesRepository = services('StoriesRepository')

  try {
    if (!story?.reportSettings) {
      throw new Error('No report settings defined.')
    }
    const { reportType, productCode, parentProductCode, reportBrand } =
      story.reportSettings

    const payload = {
      id: story.id,
      report_brand: reportBrand,
      product_code: productCode,
      report_type: reportType,
      parent_product_code: parentProductCode
    }

    const data = yield call([StoriesRepository, 'getRelatedReports'], payload)
    yield put(actions.successRelatedUpdates(data))
  } catch (e) {
    console.log(e)
    yield put(actions.errorRelatedUpdates(e.message))
  }
}

export default function* watchSingleStory(services) {
  yield all([
    // takeLatest(actions.requestSingleStory, requestSingleStory, services),
    takeLatest(actions.requestFWReportTracking, trackFWRecentReport, services),
    takeLatest(
      actions.requestNotifyMeResults,
      notifyMeResultsRequest,
      services
    ),
    takeEvery(
      actions.requestSimpleNotifyMeSubscribe,
      simpleNotifyMeSubscribeRequest,
      services
    ),
    takeLatest(
      actions.requestNotifyMeSubscribe,
      notifyMeSubscribeRequest,
      services
    ),
    takeLatest(actions.requestRelatedUpdates, relatedUpdatesRequest, services)
  ])
}
