import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  log: [],
  loading: false,
  success: false,
  coupon: {
    name: '',
    isLoading: false,
    isApplied: false,
    error: null
  },
  price: 0,
  priceWithDiscount: 0,
  currency: 'usd'
}

const paymentSlice = createSlice({
  name: 'paymentReducer',
  initialState,
  reducers: {
    logPush: (state, { payload: { module, message } }) => {
      state.log.push({ module, message })
    },
    logClear: state => {
      state.log = []
    },
    requestProcessPayment: state => {
      state.loading = true
      state.success = false
    },
    requestGetPrice: state => {
      state.loading = true
      state.success = false
    },
    successGetPrice: (
      state,
      {
        payload: {
          data: { currency, price }
        }
      }
    ) => {
      state.loading = false
      state.currency = currency
      state.price = price
    },
    requestApplyCoupon: (state, { payload: { coupon } }) => {
      state.coupon.name = coupon
      state.coupon.isLoading = true
      state.coupon.isApplied = false
    },
    successApplyCoupon: (
      state,
      {
        payload: {
          data: { newPrice, currency }
        }
      }
    ) => {
      state.coupon.isLoading = false
      state.coupon.isApplied = true
      state.priceWithDiscount = newPrice
      state.currency = currency
    },
    errorApplyCoupon: (state, { payload }) => {
      state.coupon.isLoading = false
      state.coupon.isApplied = false
      state.coupon.error = payload
      state.coupon.name = ''
      state.priceWithDiscount = 0
    },
    successProcessPayment: state => {
      state.loading = false
      state.success = true
    },
    errorProcessPayment: state => {
      state.loading = false
      state.success = false
    },
    clearCoupon: state => {
      state.coupon = {
        name: '',
        isLoading: false,
        isApplied: false,
        error: ''
      }
      state.priceWithDiscount = 0
    },
    setCoupon: (state, { payload: { coupon } }) => {
      state.coupon = coupon
    },
    errorGetPrice: () => {}
  }
})

export const { reducer, actions } = paymentSlice

export const {
  requestProcessPayment,
  successProcessPayment,
  errorProcessPayment,
  logPush,
  logClear,
  clearCoupon,
  setCoupon,
  requestApplyCoupon,
  successApplyCoupon,
  errorApplyCoupon,
  requestGetPrice,
  errorGetPrice,
  successGetPrice
} = actions

export default reducer
