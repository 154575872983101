import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  display: 'chat'
}

const chatPageSlice = createSlice({
  name: 'chatPageReducer',
  initialState,
  reducers: {
    displayContainer: (state, { payload: { container } }) => {
      // Make sure only popular, chat, history or faqs are available options
      // This only applies to small screens
      const display = ['popular', 'chat', 'history', 'faqs'].includes(container)
        ? container
        : 'chat'
      state.display = display
    }
  }
})

export const { actions, reducer } = chatPageSlice
export const { displayContainer } = actions
export default reducer
