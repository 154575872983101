export const addInternalToken = (url, token, user) => {
  if (!url.includes('{it}')) return url

  const separator = url.includes('?') ? '&' : '?'
  return token && user
    ? url.replace('{it}', `${separator}it=${token}`)
    : url.replace('{it}', '')
}

export const recursiveAddTokenToMenus = (dataSet, token, user) => {
  // Deep copy the dataset to ensure immutability
  const newDataSet = Array.isArray(dataSet) ? [...dataSet] : { ...dataSet }

  for (let key of Object.keys(newDataSet)) {
    const data = newDataSet[key]

    if (typeof data === 'object' && data !== null) {
      if (data?.to || data?.featureFlagFallback?.to) {
        // Add internal token to the URL
        if (data?.to) {
          const to = addInternalToken(data?.to, token, user)
          newDataSet[key] = { ...data, to } // Create a new object with the updated property
        }

        // Add internal token to featureFlagFallback.to
        if (data?.featureFlagFallback?.to) {
          const to = addInternalToken(data.featureFlagFallback.to, token, user)
          newDataSet[key] = {
            ...data,
            featureFlagFallback: {
              ...data.featureFlagFallback,
              to
            }
          }
        }
      } else {
        // Recursively process nested objects or arrays
        newDataSet[key] = recursiveAddTokenToMenus(data, token, user)
      }
    }
  }

  return newDataSet
}
