import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import styles from '../Ad/Ad.module.sass'

const ImageAd = ({
  click,
  src,
  alt,
  imgId,
  className,
  apiClassName,
  imgStyle = {},
  viewPercent,
  viewTime,
  viewUrl,
  width
}) => {
  return (
    <>
      {src && (
        <div className={cx(styles.ad, className)}>
          <a href={click} target="_blank" rel="noopener noreferrer">
            <img
              id={imgId}
              className={apiClassName}
              width={width}
              style={{
                ...imgStyle
              }}
              src={src}
              alt={alt}
              data-sasia-view-time={viewTime}
              data-sasia-view-percent={viewPercent}
              data-sasia-view-url={viewUrl}
            />
          </a>
        </div>
      )}
    </>
  )
}

ImageAd.propTypes = {
  /**
   * Url that the image ad will redirect when clicked
   */
  click: PropTypes.string,
  /**
   * Source of the image ad
   */
  src: PropTypes.string,
  /**
   * Alt for the img element
   */
  alt: PropTypes.string,
  /**
   * Used as the image id for viewability purposes
   */
  imgId: PropTypes.string,
  /**
   * Used for setting div container styles
   */
  className: PropTypes.string,
  /**
   * Used for ad the image class for viewability purposes
   */
  apiClassName: PropTypes.string,
  /**
   * Used for setting the img styles
   */
  imgStyle: PropTypes.object,
  /**
   * Used as data-sasia-view-url image prop for viewability purposes
   */
  viewUrl: PropTypes.string,
  /**
   * Used as data-sasia-view percent image prop for viewability purposes
   */
  viewPercent: PropTypes.string,
  /**
   * Used as data-sasia-view-time image prop for viewability purposes
   */
  viewTime: PropTypes.string,
  /**
   * Image width
   */
  width: PropTypes.number
}

export default ImageAd
