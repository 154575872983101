// React
import React from 'react'
import { useSelector } from 'react-redux'
import ReactHtmlParser from 'react-html-parser'

//Functions
import { arrayify } from 'src/utils'

// Components
import Ad from 'src/features/ads/components/Ad'
import SectionHeading from 'src/components/SectionHeading'
import Typography from 'src/lib/ui/Typography'
import List from 'src/lib/ui/List/List'
import ListItem from 'src/lib/ui/List/ListItem'
import FullImage from 'src/components/FullImage'
import Heading from 'src/components/Heading'
import SponsoredLines from 'src/components/SponsoredLines'
import StaticComponentLoop from './index'
import ContactUsForm from 'src/features/contactUs/components/ContactUsForm/ContactUsFormContainer'
import FeaturedComponent from 'src/components/FeaturedComponent'
import FeaturedArticle from 'src/components/FeaturedArticle'

/*
  ##################################
    BEGIN COMPONENTS DECLARATION
  ##################################
*/
export const LoopItem_ad = props => {
  const { item = {}, className = '' } = props
  const ad = useSelector(state => state?.adsReducer[item?.name] || null)
  if (!ad) return <></>
  return <Ad className={className} {...ad} style={item?.customStyles} />
}

export const LoopItem_link = props => {
  const { item = {}, className = '' } = props
  return (
    <a
      className={className}
      href={item?.href}
      style={item?.customStyles}
      target={item?.target || '_blank'}
    >
      {ReactHtmlParser(item?.content)}
    </a>
  )
}

export const LoopItem_timestamp = props => {
  const { item = {}, className = '' } = props
  return (
    <SponsoredLines
      title={item?.content}
      className={className}
      style={item?.customStyles}
    />
  )
}

export const LoopItem_sponsor_line = props => {
  const { item = {}, className = '' } = props
  return (
    <SponsoredLines
      title={item?.content}
      className={className}
      style={item?.customStyles}
    />
  )
}

export const LoopItem_section_heading = props => {
  const { item = {}, className = '' } = props
  return (
    <SectionHeading
      className={className}
      style={item?.customStyles}
      title={item?.content}
      gutterBottom
      paragraph
    />
  )
}

export const LoopItem_heading = props => {
  const { item = {}, className = '' } = props
  return (
    <Heading
      title={item?.content}
      className={className}
      style={item?.customStyles}
    />
  )
}

export const LoopItem_image = props => {
  const { item = {}, className = '' } = props
  return (
    <FullImage
      src={item?.url}
      width="auto"
      height="auto"
      objectFit="cover"
      className={className}
      style={item?.customStyles}
    />
  )
}

export const LoopItem_contact_us_form = props => {
  const { item = {} } = props
  return <ContactUsForm {...props} style={item?.customStyles} />
}

export const LoopItem_container = props => {
  const { item = {}, className = '' } = props
  return (
    <StaticComponentLoop
      items={item?.children}
      className={className}
      customStyles={item?.customStyles}
    />
  )
}

export const LoopItem_resourceArticle = props => {
  const { item = {}, className = '' } = props
  return (
    <StaticComponentLoop
      items={item?.children}
      className={className}
      customStyles={item?.customStyles}
    />
  )
}

export const LoopItem_typography = props => {
  const { item = {}, className = '', parentKey = '' } = props
  const typographies = arrayify(item?.content)
  return typographies.map((values, index) => {
    return (
      <Typography
        key={`${parentKey}-typographyItem-${index}`}
        type="body1"
        paragraph
        className={className}
        style={item?.customStyles}
      >
        {ReactHtmlParser(values)}
      </Typography>
    )
  })
}

export const LoopItem_video = props => {
  const { item = {}, className = '' } = props
  return (
    <video
      className={className}
      style={item?.customStyles}
      id={item?.name}
      preload="auto"
      autoPlay
      controls
      playsInline
      controlsList="nodownload"
      muted
    >
      <source
        src={item?.multimediaFiles[0].url}
        type={item?.multimediaFiles[0].component}
      ></source>
      Your browser does not support the video tag.
    </video>
  )
}

export const LoopItem_list = props => {
  const { item = {}, className = '', parentKey = '' } = props
  const list = arrayify(item?.content)
  return (
    <List className={className} style={item?.customStyles}>
      {list.map((item, index) => (
        <ListItem key={`${parentKey}-listItem-${index}`}>
          {ReactHtmlParser(item)}
        </ListItem>
      ))}
    </List>
  )
}

export const LoopItem_featured_component = props => {
  const { item = {} } = props
  return <FeaturedComponent content={item?.content} />
}

export const LoopItem_featured_article = props => {
  const { item = {} } = props
  return <FeaturedArticle content={item?.content} />
}
