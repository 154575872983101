import { Base64 } from 'js-base64'
import { contentRiversConfigModule } from 'src/config/sites/river.js'

const contentRivers = contentRiversConfigModule?.default || {}

class ContentRiverBuilder {
  constructor(Session) {
    this.Session = Session
    this.defaultContentRiver = contentRivers
    if (!process.env.REACT_APP_SITE_ID) {
      console.warn(
        'Warning! No Site Id was set. The default configuration will be used.\n\nHow to fix:\n* Check the .env file for the "REACT_APP_SITE_ID" missing configuration'
      )
    }
  }

  decodeArgs(encoded) {
    if (encoded === '') return undefined
    const str = Base64.decode(encoded)
    const obj = JSON.parse(str)
    return obj
  }

  encodeArgs(args) {
    const json = JSON.stringify(args)
    return Base64.encode(json)
  }

  getContentRivers() {
    return this.defaultContentRiver
  }

  getContentRiverByKey(key) {
    const contentRivers = this.getContentRivers()

    if (typeof contentRivers[key] === 'function') {
      if (contentRivers[key].length > 0) {
        return contentRivers[key]
      } else {
        return () => JSON.parse(JSON.stringify(contentRivers[key]()))
      }
    }

    if (typeof this.defaultContentRiver[key] === 'function') {
      if (this.defaultContentRiver[key].length > 0) {
        return this.defaultContentRiver[key]
      } else {
        return () => JSON.parse(JSON.stringify(this.defaultContentRiver[key]()))
      }
    }

    throw new Error(`Unknown content river key: ${key}`)
  }

  build(key, pathArgs, qsArgs = {}) {
    const contentRiver = this.getContentRiverByKey(key)
    return contentRiver({ Session: this.Session }, pathArgs, qsArgs)
  }
}

export default ContentRiverBuilder
