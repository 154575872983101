import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  error: '',
  busy: false,
  values: {},
  like: {},
  confirmationModal: false
}

const actionButtonsSlice = createSlice({
  name: 'actionButtonsReducer',
  initialState,
  reducers: {
    requestArticleShare: state => {
      state.busy = true
    },
    successArticleShare: state => {
      state.busy = false
      state.confirmationModal = true
    },
    errorArticleShare: (state, { payload }) => {
      state.busy = false
      state.error = payload.error
    },
    requestSiteShare: state => {
      state.busy = true
    },
    successSiteShare: state => {
      state.busy = false
      state.confirmationModal = true
    },
    errorSiteShare: (state, { payload }) => {
      state.busy = false
      state.error = payload.error
    },
    closeConfirmationModal: state => {
      state.confirmationModal = false
    },
    updateLikeRequest: state => {
      state.like.error = null
      state.like.success = null
      state.like.busy = true
    },
    updateLikeSuccess: state => {
      state.like.error = null
      state.like.success = true
      state.like.busy = false
    },
    updateLikeError: (state, { payload }) => {
      state.like.error = payload.error
      state.like.success = false
      state.like.busy = false
    },
    reportDownloadRequest: state => {
      state.busy = true
      state.error = null
    },
    reportDownloadSuccess: state => {
      state.busy = false
      state.error = null
    },
    reportDownloadError: (state, { payload }) => {
      state.busy = false
      state.error = payload
    }
  }
})

export const { actions, reducer } = actionButtonsSlice

export const {
  requestArticleShare,
  successArticleShare,
  errorArticleShare,
  reportDownloadRequest,
  reportDownloadSuccess,
  reportDownloadError,
  requestSiteShare,
  successSiteShare,
  errorSiteShare,
  updateLikeRequest,
  updateLikeSuccess,
  updateLikeError,
  closeConfirmationModal
} = actions

export default reducer
