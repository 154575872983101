import { memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { isEqual } from 'lodash'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { Box, Modal as MuiModal, IconButton, styled } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import Typography from 'src/lib/ui/Typography'
import { buildStoryLinkFromArticleId } from '../../utils'

/**
 * Modal Content Container
 * theme path: ConsultedSourcesModal.styleOverrides.contentContainer
 */
const ContentContainer = styled(Box, {
  name: 'ConsultedSourcesModal',
  label: 'consultedSourcesModalContainer',
  overridesResolver: (props, styles) => [styles.contentContainer]
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette?.consultedSourcesModal?.background || '#fff',
  position: 'absolute',
  top: '50%',
  left: '50%',
  maxHeight: '90%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '12px',
  width: '50%',
  maxWidth: '672px',
  padding: '0 8px 5px 20px',
  '& .title': {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    marginTop: '16px',
    color: theme.palette?.consultedSourcesModal?.title || '#101828'
  },
  // Width calculated as 700px + 16px both sides margin
  '@media all and (max-width: 820px)': {
    width: 'calc(100% - 64px)'
  }
}))

/**
 * Modal Header Container
 * theme path: ConsultedSourcesModal.styleOverrides.header
 */
const HeaderContainer = styled(Box, {
  name: 'ConsultedSourcesModal',
  label: 'consultedSourcesModalHeaderContainer',
  overridesResolver: (props, styles) => [styles.headerContainer]
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '8px',
  backgroundColor: theme.palette?.consultedSourcesModal?.background || '#fff'
}))

/**
 * Modal Header
 * theme path: ConsultedSourcesModal.styleOverrides.header
 */
const Header = styled(Box, {
  name: 'ConsultedSourcesModal',
  label: 'consultedSourcesModalHeader',
  overridesResolver: (props, styles) => [styles.header]
})(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  marginBottom: '18px',
  '> button': {
    height: '36px',
    width: '36px',
    ' > svg': {
      height: '20px',
      width: '20px'
    }
  }
}))

/**
 * Ordered List
 * theme path: ConsultedSourcesModal.styleOverrides.list
 */
const OrderedList = styled('ol', {
  name: 'ConsultedSourcesModal',
  label: 'consultedSourcesModalList',
  overridesResolver: (props, styles) => [styles.list]
})(({ theme }) => ({
  marginTop: '0px',
  paddingRight: '45px',
  paddingInlineStart: '20px',
  overflowY: 'auto',
  marginBottom: '0px',
  '& li': {
    paddingLeft: '12px'
  },
  '& li::marker': {
    color: theme.palette?.consultedSourcesModal?.listNumber || '#344054',
    fontSize: '16px',
    fontFamily: theme.typography?.fontFamily
  },
  '& .sourceLabel': {
    fontSize: '16px',
    fontWeight: 'normal',
    lineHeight: 1.5,
    color: theme.palette?.consultedSourcesModal?.sourceLabel || '#344054',
    marginBottom: '18px',
    marginLeft: '-20px',
    transition: 'color 0.3s ease-in-out',
    '&:hover': {
      color: '#323a6a'
    }
  },
  '& .sourceLink': {
    textDecoration: 'underline',
    fontSize: '16px',
    fontWeight: 'normal',
    lineHeight: 1.5,
    color: theme.palette?.consultedSourcesModal?.sourceLink || '#4e5ba6',
    marginBottom: '3px',
    transition: 'color 0.3s ease-in-out',
    '&:hover': {
      color: '#323a6a'
    }
  },
  '& .sourceAutors': {
    fontSize: '16px',
    fontWeight: 'normal',
    lineHeight: 1.5,
    color: theme.palette?.consultedSourcesModal?.sourceAutors || '#919191',
    marginBottom: '5px',
    textDecoration: 'none'
  },
  '& .sourceType, .sourceDate': {
    textDecoration: 'none',
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: 1.43,
    color: theme.palette?.consultedSourcesModal?.sourceType || '#919191'
  },
  '& .sourceType, .sourceAutors, .sourceDate': {
    transition: 'color 0.3s ease-in-out',
    '&:hover': {
      color: '#667085'
    }
  }
}))
const SourceListItem = ({
  text = '',
  to = '',
  className = '',
  source,
  pos,
  onSourceClick = () => {}
}) => {
  if (!text || !to) return null
  return (
    <Typography>
      {
        <a
          href={to}
          target="_blank"
          rel="noopener noreferrer"
          className={className}
          onClick={() => onSourceClick({ ...source, pos })}
        >
          {text}
        </a>
      }
    </Typography>
  )
}

SourceListItem.propTypes = {
  text: PropTypes.string,
  to: PropTypes.string,
  className: PropTypes.string,
  source: PropTypes.object,
  onSourceClick: PropTypes.func,
  pos: PropTypes.number
}

const SourceListItems = ({ links, onSourceClick }) => (
  <Box sx={{ scrollMarginTop: '20px' }}>
    {links.map((source, key) => {
      const { title, citation, articleId, articleType, createdDate } = source
      const [, author] = citation.split('\n')

      const to = buildStoryLinkFromArticleId(articleId)

      let date = ''
      if (createdDate) {
        date = moment.unix(createdDate).format('YYYY-MM-DD')
      }

      return (
        <Box
          component="li"
          sx={{ marginBottom: '19px' }}
          key={`${title}-${key}`}
          data-testid="consulted-source-list-item"
        >
          <SourceListItem
            text={title}
            to={to}
            className="sourceLink"
            onSourceClick={onSourceClick}
            source={source}
            pos={key + 1}
          />
          <SourceListItem
            text={author}
            to={to}
            className="sourceAutors"
            onSourceClick={onSourceClick}
            source={source}
            pos={key + 1}
          />
          <SourceListItem
            text={articleType}
            to={to}
            className="sourceType"
            onSourceClick={onSourceClick}
            source={source}
            pos={key + 1}
          />
          <SourceListItem
            text={date}
            to={to}
            className="sourceDate"
            onSourceClick={onSourceClick}
            source={source}
            pos={key + 1}
          />
        </Box>
      )
    })}
  </Box>
)

SourceListItems.propTypes = {
  links: PropTypes.array,
  onSourceClick: PropTypes.func
}

const ConsultedSourcesModal = ({
  open,
  onCancel,
  source = {},
  onView = () => {},
  onSourceClick = () => {}
}) => {
  const { name = '', links = [] } = source

  useEffect(() => {
    if (open) onView({})
  }, [open])

  const { t } = useTranslation()
  return (
    <MuiModal
      open={open}
      id="consulted-sources"
      onClose={onCancel}
      data-testid="consulted-sources-modal"
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(155, 155, 155, 0.5)',
          backdropFilter: 'blur(5.5px)'
        }
      }}
    >
      <ContentContainer>
        <HeaderContainer>
          <Header>
            <Typography className="title">
              {`${t('consultedSourcesTitle')} - ${name}:`}
            </Typography>
            <IconButton onClick={onCancel}>
              <CloseIcon />
            </IconButton>
          </Header>
        </HeaderContainer>
        <OrderedList>
          <SourceListItems
            links={links}
            onSourceClick={({ ...sourceData }) =>
              onSourceClick({ ...sourceData })
            }
          />
        </OrderedList>
      </ContentContainer>
    </MuiModal>
  )
}

ConsultedSourcesModal.propTypes = {
  /**
   * Display modal
   */
  open: PropTypes.bool,
  /**
   * Function excecuted when the modal is closed
   */
  onCancel: PropTypes.func,
  /**
   * List of sources grouped by source label
   */
  source: PropTypes.object,
  /**
   * Function excecuted when the modal is opened
   */
  onView: PropTypes.func,
  /*+
   * Function excecuted when a source is clicked
   */
  onSourceClick: PropTypes.func
}

export default memo(ConsultedSourcesModal, (props, newProps) => {
  const objProps = {
    open: props.open,
    source: props.source,
    selectedSource: props.selectedSource
  }
  const objNewProps = {
    open: newProps.open,
    source: newProps.source,
    selectedSource: newProps.selectedSource
  }
  return isEqual(objProps, objNewProps)
})
