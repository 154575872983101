// React
import React from 'react'
import PropTypes from 'prop-types'

// Components
import Heading from 'src/components/Heading'
import styles from './FeaturedComponent.module.sass'
import FullImage from 'src/components/FullImage'
import FullImageLinked from 'src/components/FullImageLinked'
import Typography from 'src/lib/ui/Typography'
import SponsoredLines from 'src/components/SponsoredLines'

const FeaturedComponent = ({ content = {} }) => {
  let styleArea = ''
  const {
    thumbnail,
    title,
    tagline,
    description,
    type = 'left',
    sponsor,
    url = ''
  } = content

  const imageProps = {
    url,
    width: '100%',
    height: 'auto',
    src: thumbnail,
    objectFit: 'cover'
  }

  const style = type === 'left' ? styles.left : styles.right
  if (type != null) {
    styleArea = type === 'left' ? styles.textAreaL : styles.textAreaR
  } else {
    styleArea = styles.textAreaJ
  }
  return (
    <div className={style}>
      <div className={styleArea}>
        <div>
          {title && url ? (
            <a href={url} className={styles.title}>
              <Heading title={title} />
            </a>
          ) : (
            <Heading title={title} />
          )}
          {tagline && <SponsoredLines sponsor={tagline}></SponsoredLines>}
        </div>
        {description && <Typography type="body1">{description}</Typography>}
        {sponsor && <SponsoredLines sponsor={sponsor}></SponsoredLines>}
      </div>
      <div className={styles.imageContainer}>
        {url ? (
          <FullImageLinked {...imageProps} />
        ) : (
          <FullImage {...imageProps} />
        )}
      </div>
    </div>
  )
}

FeaturedComponent.propTypes = {
  content: PropTypes.object
}

export default FeaturedComponent
