import { all } from 'redux-saga/effects'
import { default as singleStorySaga } from 'src/features/singleStory/SingleStorySaga'
import { default as viewsSaga } from 'src/features/views/ViewsSaga'
import { default as bootstrappingSaga } from 'src/features/bootstrap/BootstrapSaga'
import { default as adsSaga } from 'src/features/ads/AdsSaga'
import { default as catalogsSaga } from 'src/features/catalogs/CatalogsSaga'
import { default as loginSaga } from 'src/features/login/LoginSaga'
import { default as contentRiverSaga } from 'src/features/river/RiverSaga'
import { default as contactUsSaga } from 'src/features/contactUs/ContactUsSaga'
import { default as paymentSaga } from 'src/features/payment/PaymentSaga'
import { default as trackingSaga } from 'src/features/tracking/TrackingSaga'
import { default as registerSaga } from 'src/features/register/RegisterSaga'
import { default as myInterestsSaga } from 'src/features/myInterests/MyInterestsSaga'
import { default as myNewslettersSaga } from 'src/features/myNewsletters/MyNewslettersSaga'
import { default as myAccountSaga } from 'src/features/myAccount/MyAccountSaga'
import { default as mySavedSearchesSaga } from 'src/features/mySavedSearches/MySavedSearchesSaga'
import { default as mySettingsSaga } from 'src/features/mySettings/MySettingsSaga'
import { default as myReportsSaga } from 'src/features/myReports/MyReportsSaga'
import { default as sessionSaga } from 'src/features/session/SessionSaga'
import { default as benefitsSaga } from 'src/features/benefits/BenefitsSaga'
import { default as captchaSaga } from 'src/features/captcha/CaptchaSaga'
import { default as viewabilitySaga } from 'src/features/viewability/ViewabilitySaga'
import { default as landingPageSaga } from 'src/features/gatedLandingPage/GatedLandingPageSaga'
import { default as configSaga } from 'src/features/config/ConfigSaga'
import { default as piwikSaga } from 'src/features/piwik/PiwikSaga'
import { default as translationSaga } from 'src/features/fw-ai/children/translation/TranslationSaga'
import { default as themeProviderSaga } from 'src/features/fw-ai/children/themeProvider/ThemeProviderSaga'
import { default as chatSaga } from 'src/features/fw-ai/children/dougallChat/ChatSaga'
import { default as historySaga } from 'src/features/fw-ai/children/history/HistorySaga'
import { default as faqSaga } from 'src/features/fw-ai/children/faq/FaqSaga'
import { default as liveFeedSaga } from 'src/features/fw-ai/children/liveFeed/LiveFeedSaga'
import { default as actionButtonsSaga } from 'src/features/actionButtons/ActionButtonsSaga'
import { default as followUpPromptsSaga } from 'src/features/fw-ai/children/followUpPrompts/FollowUpPromptsSaga'

export default function* rootSaga(services) {
  yield all([
    contentRiverSaga(services),
    singleStorySaga(services),
    viewsSaga(services),
    bootstrappingSaga(services),
    adsSaga(services),
    catalogsSaga(services),
    loginSaga(services),
    contactUsSaga(services),
    paymentSaga(services),
    trackingSaga(services),
    registerSaga(services),
    myAccountSaga(services),
    myInterestsSaga(services),
    myNewslettersSaga(services),
    mySavedSearchesSaga(services),
    mySettingsSaga(services),
    myReportsSaga(services),
    sessionSaga(services),
    benefitsSaga(services),
    captchaSaga(services),
    viewabilitySaga(services),
    landingPageSaga(services),
    configSaga(services),
    piwikSaga(services),
    themeProviderSaga(services),
    translationSaga(services),
    chatSaga(services),
    historySaga(services),
    faqSaga(services),
    liveFeedSaga(services),
    actionButtonsSaga(services),
    followUpPromptsSaga(services)
    // ...Add more sagas
  ])
}
