import { connect } from 'react-redux'
import { actions } from 'src/features/mobileMenu/MobileMenuSlice'
import { actions as configActions } from 'src/features/config/ConfigSlice'
import MobileMenu from '../components/MobileMenu'

const mapDispatchToProps = dispatch => ({
  handlePageScroll: data => dispatch(configActions.addConfigRequest(data)),
  handleSlideMenu: () => dispatch(actions.toggleRightMenu())
})

const mapStateToProps = state => ({
  open: state.mobileMenuReducer.rightMenuOpen,
  position: 'right'
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu)
