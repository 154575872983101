import { createSlice } from '@reduxjs/toolkit'

const catalogsSlice = createSlice({
  name: 'catalogsReducer',
  initialState: {},
  reducers: {
    registerCatalogs: (state, { payload: catalogs }) => {
      Object.keys(catalogs).forEach(key => {
        state[key] = {
          ready: false,
          error: null,
          options: {}
        }
      })
    },
    successCatalogs: (state, { payload: { key, catalog } }) => {
      state[key] = {
        ready: true,
        error: null,
        options: catalog
      }
    },
    errorCatalogs: (state, { payload: { key, error } }) => {
      state[key] = {
        ready: true,
        error,
        options: {}
      }
    },
    cleanCatalogs: (state, { payload: catalogs }) => {
      if (!catalogs) return {}
      Object.keys(catalogs).forEach(key => {
        delete state[key]
      })
    },
    requestCatalogs: () => {}
  }
})

export const { reducer, actions } = catalogsSlice
export const { registerCatalogs, cleanCatalogs } = actions

export default reducer
