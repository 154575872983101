import React from 'react'
import { useSelector } from 'react-redux'
import styles from '../Article.module.sass'
import TextToSpeech from 'src/components/TextToSpeech'
import { siteName, otherSitesDomains } from 'src/utils/oneCodeBase'
import Advertorial from 'src/components/Advertorial'
import {
  getConfig,
  getTextToSpeechConfig
} from 'src/features/config/ConfigSelector'
import { useFlags } from 'launchdarkly-react-client-sdk'
import ReactHtmlParser from 'react-html-parser'
import AdInjector from 'src/services/FW5ML/parsers/AdInjector'
import Ad from 'src/features/ads/components/Ad'
import { sponsoredHeadlines } from 'src/config/sites/assets'
import cx from 'classnames'
import { READ_ARTICLE } from 'src/constants/featureFlagConstants'

const adInjector = new AdInjector()

const AdvertorialAd = ({ id }) => {
  const ad = useSelector(
    state => state?.adsReducer?.singleStoryLeaderboard2 || null
  )

  if (ad && !ad?.emptyContent) {
    return (
      <Ad
        {...ad}
        className={cx(styles.ad, styles.midAd)}
        key={`${id}-story_page_advertorial`}
      />
    )
  }
  return (
    <Advertorial
      key={`${id}-advertorial`}
      to="/my-account/newsletters"
      body={sponsoredHeadlines?.singleStorySponsoredHeadline ?? ''}
      sponsor={`FirstWord ${siteName}`}
    />
  )
}

const ArticleBody = ({ story, simplePage = false, configSettings }) => {
  const internalToken = useSelector(getConfig('internal_token')) || null
  const textToSpeechConfig = useSelector(getTextToSpeechConfig())

  const flags = useFlags()
  const ArticleWrapper = flags[READ_ARTICLE] ? TextToSpeech : React.Fragment

  const renderArticle = () => {
    const options = simplePage ? {} : { transform }
    return ReactHtmlParser(adInjector.injectAd(story.body), options)
  }

  const transform = node => {
    if (node.type === 'tag') {
      // Render advertorial
      if (configSettings?.showAd && node.name === 'ad-placeholder') {
        return <AdvertorialAd id={story?.id} />
      }

      // Remove Notify Me button
      if (
        node.name === 'a' &&
        (node?.attribs?.href?.includes(
          'info.firstwordreports.com/physician_view_alert/'
        ) ||
          node.children.find(item =>
            item?.data?.toLowerCase()?.includes('notify me of the results')
          ))
      ) {
        return <></>
      }

      // Check if there is a link to other FW sites
      // Add internal token to autologin to other FW sites
      if (
        node.name === 'a' &&
        node?.attribs?.href &&
        internalToken &&
        otherSitesDomains.find(item => node?.attribs?.href.includes(item))
      ) {
        const separator = node?.attribs?.href.includes('?') ? '&' : '?'
        node.attribs.href = `${node?.attribs?.href}${separator}it=${internalToken}`
      }
    }
  }

  return (
    <main className={styles.body}>
      <ArticleWrapper config={textToSpeechConfig} id={story?.id}>
        {renderArticle()}
      </ArticleWrapper>
    </main>
  )
}

export default ArticleBody
