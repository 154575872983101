import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import StoryLoader from 'src/components/StoryLoader'
import Article from 'src/components/Article'
import {
  getStory,
  getLoading, //Used for Pharma and HT
  getCombinedLoading //Used for Reports
} from 'src/features/singleStory/SingleStorySelector'
import useSingleStoryRequest from 'src/features/singleStory/hooks/queries/useSingleStoryRequest'
import { siteName } from 'src/utils/oneCodeBase'
import { actions } from 'src/features/singleStory/SingleStorySlice'

const SingleStory = ({ id, ...props }) => {
  const dispatch = useDispatch()
  const { requestSingleStory } = useSingleStoryRequest({ id })

  const story = useSelector(getStory())

  const isLoading =
    siteName === 'Reportss'
      ? useSelector(getCombinedLoading()) // Use combinedLoading for Reports
      : useSelector(getLoading()) // Use conventional loading otherwise

  useEffect(() => {
    if (id) {
      requestSingleStory(id)
    }

    return () => {
      dispatch(actions.cleanSingleStory())
    }
  }, [id])

  if (isLoading || !story?.id) {
    return <StoryLoader />
  }

  return <Article {...props} story={story} />
}

export default SingleStory
