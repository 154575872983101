import { groupBy, flatten, map } from 'lodash'
import moment from 'moment'

import siteConfig from 'src/config/fw-ai/site.config.json'
import { isEmpty } from '../../utils'

const { sections } = siteConfig
const showSocial = !!sections?.chat?.answer?.showSocial

const requiredCategories = sections?.chat?.requiredCategories || []

const DougallParser = SessionService => ({
  /**
   * Replaces tagName in references urls (refUrl)
   * @param {#} answerId
   * @param {#} promptId
   * @param {''} references
   * @param {''} tagName
   * @param {''} tagId
   * @param {''} tagSource
   * @param {''} tagType
   * @param {''} tagCategory
   * @returns
   */
  queryReplace({ reference = '', tagName = '' }) {
    const encodedTag = encodeURIComponent(tagName)

    const replacedUrl = reference.replace('${tagName}', encodedTag)

    return replacedUrl
  },
  /**
   * Parses each tag references for Dougall.MD answer block
   * @param {#} answerId
   * @param {#} promptId
   * @param {[]} tags
   * @returns
   */
  referencesParser({ answerId = '', promptId = '', tags = [] }) {
    const references = []
    const categories = []
    const tagCategories = groupBy(tags, 'tagCategory')

    if (tags.length > 0) {
      Object.entries(tagCategories).forEach(([key, value]) => {
        const elements = []

        // If the category is not in the list do not add it
        const allRequiredCatergories = flatten(
          map(requiredCategories, 'categories')
        )
        if (!allRequiredCatergories.includes(key)) return

        value.forEach(val => {
          const { tagName, tagNameLanguageUsed } = val
          const refUrl = process.env.REACT_APP_URL
          const name =
            tagNameLanguageUsed && tagNameLanguageUsed !== 'None'
              ? tagNameLanguageUsed
              : tagName
          const query = this.queryReplace({
            reference: refUrl + '/search/${tagName}?rst=1&sort=relevancy',
            tagName
          })

          elements.push({
            name,
            refUrl: query
          })
        })

        categories.push({
          section: key,
          elements
        })
      })

      // Build references array (Conditions & Medications)
      requiredCategories.forEach(requiredCategory => {
        // Create category section
        let categorySection = {
          section: requiredCategory.section,
          elements: []
        }
        // Update category section elements with required tags
        categories.forEach(category => {
          if (requiredCategory.categories.includes(category.section)) {
            categorySection.elements = [
              ...categorySection.elements,
              ...category.elements
            ]
          }
        })
        // Push final category to references
        references.push(categorySection)
      })
    }
    return references
  },
  /**
   * Parses each tags' tagSource for Answer Highlights
   * @param {#} answerId
   * @param {#} promptId
   * @param {[]} tags
   * @returns
   */
  replaceTagSource({ answerId = '', promptId = '', tags = [] }) {
    const refUrl = process.env.REACT_APP_URL

    const replacedTags = []

    tags.forEach(tag => {
      const { tagName } = tag

      const reference = this.queryReplace({
        reference: refUrl + '/search/${tagName}?rst=1&sort=relevancy',
        tagName
      })

      replacedTags.push({ ...tag, tagSource: reference })
    })

    return replacedTags
  },
  /**
   * Returns answer with span tags on textFound in answer
   * @param {''} answer
   * @param {[]} tags
   * @returns Highlighted Answer
   */
  highlightAnswer({ answer = '', tags = [] }) {
    let highlightedAnswer = answer

    tags.forEach(tag => {
      // If the tag category is not in the list do not add it
      const allRequiredCatergories = flatten(
        map(requiredCategories, 'categories')
      )
      if (!allRequiredCatergories.includes(tag.tagCategory)) return

      tag.textFound.forEach(text => {
        highlightedAnswer = highlightedAnswer.replace(
          new RegExp(`\\b${text.replace(' ', '(\\*\\*)? (\\*\\*)?')}\\b`, 'i'),
          match => `<span id="${tag.tagId}">${match}</span>`
        )
      })
    })

    return highlightedAnswer
  },
  /**
   * Returns parsed prompt history
   * @param {*} history
   * @returns
   */
  historyParser({ history }) {
    // If createdDate is not from the current month use MMM YYYY format
    const newHistory = history.map(h => {
      if (moment(h.createdDate).isSame(new Date(), 'month')) {
        return h
      }
      return {
        ...h,
        createdDate: moment(h.createdDate).format('MMM YYYY')
      }
    })
    // Group By Date
    const groups = groupBy(newHistory, 'createdDate')
    // Parse to expected UI array
    const parsedHistory = Object.keys(groups).reduce((acc, curr) => {
      return [
        ...acc,
        {
          key: curr,
          caption: moment(curr).isSame(new Date(), 'month')
            ? moment(curr).format('DD MMM YYYY')
            : curr,
          children: groups[curr].map(p => ({
            id: p.applicationPromptId,
            caption: p.title || p.hcpPrompt
          }))
        }
      ]
    }, [])
    return parsedHistory
  },
  /**
   * Parses popular prompts to be parent-children referenced
   * @param {*} data
   * @returns
   */
  popularPromptsParser(data) {
    const prompts = []
    // Filter only answer page related prompts && groupBy headingId
    const headings = groupBy(
      data.filter(
        d => d.headingName !== 'LANDING' && d.subHeadingName !== 'LANDING'
      ),
      'headingId'
    )

    Object.values(headings).forEach(headingArray => {
      const { headingId, headingName } = headingArray[0]
      const subHeadings = groupBy(headingArray, 'subHeadingId')
      const headingChildren = []

      Object.values(subHeadings).forEach(subHeadingArray => {
        const { subHeadingId, subHeadingName } = subHeadingArray[0]
        const children = []

        subHeadingArray.forEach(subHeading => {
          const {
            popularPromptId,
            popularPromptName,
            popularPromptValue,
            headingId,
            subHeadingId
          } = subHeading
          children.push({
            id: popularPromptId,
            caption: popularPromptName,
            promptOnClick: popularPromptValue,
            headingId,
            subHeadingId
          })
        })

        headingChildren.push({
          key: subHeadingId,
          caption: subHeadingName,
          children
        })
      })

      prompts.push({
        key: headingId,
        caption: headingName,
        children: headingChildren
      })
    })

    return prompts
  },
  /**
   * Parses FAQs to how PromptList component use it
   * @param {*} data
   */
  faqParser(data) {
    const faqs = []

    data.forEach((faq, idx) => {
      const question = faq.question
      const answer = faq.answer
      faqs.push({
        id: idx,
        caption: question,
        children: [{ id: idx + data.length, caption: answer }]
      })
    })

    return faqs
  },

  /**
   * Parse single prompt history
   * @param {*} data Array of prompts
   * @returns { messages, tags }Object with parsed messages & lastAnswer tags
   */
  promptHistoryParser(data = []) {
    // Build messages Array
    let messages = []
    data.reverse().forEach(prompt => {
      const {
        answerId,
        hcpPrompt,
        applicationPromptId: promptId,
        tags,
        answer,
        gptSources
      } = prompt

      // Add prompt message
      messages.push({
        message: hcpPrompt,
        isAnswer: false,
        date: moment().format()
      })

      const replacedTags = this.replaceTagSource({ answerId, promptId, tags })
      const references = this.referencesParser({ answerId, promptId, tags })
      messages.push({
        ...prompt,
        promptId,
        message: this.highlightAnswer({ answer, tags }),
        tags: tags && tags.length > 0 ? replacedTags : [],
        urls: references ? references : [],
        showWarning: false,
        isAnswer: true,
        showSocial,
        date: moment().format(),
        consultedSources: this.consultedSourcesParser({
          sources: gptSources
        })
      })
    })

    // Build lastAnswer tags to display feed
    const lastAnswer = data
      .slice()
      .reverse()
      .find(prompt => prompt.liveFeed?.length)

    return {
      messages,
      tags: lastAnswer ? lastAnswer.liveFeed.map(tag => tag.tagId) : [],
      lastAnswerTags: lastAnswer
        ? lastAnswer.tags.map(tag => tag.tagId).join(',')
        : ''
    }
  },
  /**
   * Extract Example Prompts from Popular Prompts
   * @param {[]} data Array of prompts multilevel
   * @returns { [] } Array of Example Prompts
   */
  getExamplePrompts({ prompts = [] }) {
    const examplePrompts = []

    prompts.forEach(prompt => {
      if (
        prompt.landingPageOrder &&
        (prompt.headingName === 'LANDING' ||
          prompt.subHeadingName === 'LANDING')
      ) {
        examplePrompts.push(prompt)
      }
    })

    examplePrompts.sort((a, b) => a.landingPageOrder - b.landingPageOrder)

    return examplePrompts
  },

  /**
   * Parse answer feedback request to support new standard email notification
   * For DougallService.answerFeedbackSubmit
   * @param {*} payload Payload to be sent
   * @returns {} parsed payload
   */
  feedbackRequestParser(payload) {
    const { actionId, feedback, ...rest } = payload
    return {
      actionId,
      feedback,
      device: navigator.userAgent,
      standard: true,
      notifyCS: true,
      ...rest
    }
  },

  /**
   * Parse share prompt request to support new standard email notification
   * For DougallService.promptShareRequest
   * @param {*} payload Payload to be sent
   * @returns {} parsed payload
   */
  shareRequestParser(payload) {
    const { name, email, toEmail, message, pId } = payload
    const deviceId = SessionService.getFromCache('id_key', '')
    return {
      recipient: toEmail,
      sender: {
        applicationPromptId: pId,
        email,
        name,
        message
      },
      device: navigator.userAgent,
      deviceId,
      standard: true,
      notifyCS: true
    }
  },
  /**
   * Parses and limits the number of sources, grouping them by their labels.
   *
   * @param {Object} params - The parameters for the parser.
   * @param {Array} params.sources - The array of source objects to be parsed.
   * @param {number} params.numberOfSources - The maximum number of sources to include.
   * @returns {Array} An array of elements, each containing a name, count, and links to the sources.
   */
  limitedSourcesParser({ sources, numberOfSources }) {
    const elements = []

    // Get Sources Limit from config
    const limit = numberOfSources || 0

    // Limit Sources if more than limit
    const limitedSources =
      limit < sources.length ? sources.slice(0, limit) : sources

    limitedSources.forEach(source => {
      if (source.citation === null) return // DOUGGPT-2111 - if no citation available skip source
      const { sourceLabel } = source
      const name = sourceLabel || 'Dougall MD'

      // Check if source with same label already exists
      const existingSource = elements.find(e => e.name === name)

      if (existingSource) {
        existingSource.count++
        existingSource.links.push(source)
      } else {
        elements.push({
          name: name,
          count: 1,
          links: [source]
        })
      }
    })
    return elements
  },
  /**
   * Trusted Sources Tag parser for Tags display format
   * @param sources
   * @returns {object}
   */
  consultedSourcesParser({ sources }) {
    const numberOfSources = 10
    if (!sources || isEmpty(sources)) return {}
    const parsedSources = []
    Object.entries(sources).forEach(([key, values]) => {
      if (!isEmpty(values)) {
        const elements = this.limitedSourcesParser({
          sources: values,
          numberOfSources
        })

        if (elements.length > 0) parsedSources.push({ section: key, elements })
      }
    })

    return parsedSources
  }
})

export default DougallParser
