import React from 'react'
import PropTypes from 'prop-types'
import styles from '../../pages/Landing/Landing.module.sass'
import Button from '../../../../lib/ui/Buttons/Button'

const PromoButton = ({
  sponsor: { promoButtonUrl = '', promoButtonText = '' }
}) => {
  return (
    <a
      href={promoButtonUrl}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.promoButton}
    >
      <Button
        text={promoButtonText}
        type="submit"
        tier="secondary"
        textCase="none"
        minWidth="100%"
      ></Button>
    </a>
  )
}

PromoButton.propTypes = {
  sponsor: PropTypes.object
}

export default PromoButton
