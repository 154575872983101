import React from 'react'
import { useSelector } from 'react-redux'
import HeaderItem from '../HeaderItem'
import Link from '../Link'
import styles from './HeaderLinks.module.sass'
import { getConfig } from 'src/features/config/ConfigSelector'
import Tag from '../Tag'
import useLaunchDarkly from 'src/features/launchDarkly/useLaunchDarkly'

const HeaderLinks = () => {
  const menuConfig = useSelector(getConfig('menus'))
  const menuItems = menuConfig?.main || []
  const { getFeatureFlagMenuItem } = useLaunchDarkly()

  return menuItems.map((menuItem, index) => {
    const item = getFeatureFlagMenuItem(menuItem)

    return (
      item &&
      (item.group ? (
        <HeaderItem key={index} links={item.content}>
          {item.group}
        </HeaderItem>
      ) : (
        <HeaderItem key={index}>
          <Link className={styles.link} to={item.to}>
            {item.label}
          </Link>
          {item.tag && <Tag className={styles.auxTag} label={item.tag} />}
        </HeaderItem>
      ))
    )
  })
}

export default HeaderLinks
