// HELPERS
import i18next from 'i18next'
import moment from 'moment'

// ACTIONS
import { actions as chatActions } from 'src/features/fw-ai/children/dougallChat/ChatSlice'
import { piwikRequest } from 'src/features/piwik/PiwikSlice'

// CONFIG
import siteConfig from 'src/config/fw-ai/site.config.json'
const { sections } = siteConfig

// CONSTANTS
import { DATA_RESPONSE_DONE } from './constants'

const DougallController = (dispatch, DougallParser, SessionService) => ({
  /**
   * Function handler for stream response through SSE
   * @param {*} response
   * @param {#} linkedPromptId
   * @param {''} hcpPrompt
   */
  handleStreamAnswer({ response, linkedPromptId, hcpPrompt }) {
    const showSocial = !!sections?.chat?.answer?.showSocial
    const haystack = localStorage.getItem('knowledge_setting')
    const haystackTrackingKey =
      haystack === 'fwHaystackPlusBase' ? 'fw-news-and-dougall' : 'fw-news-only'

    // Answer
    let streamAnswer = ''
    response.addEventListener('gpt_answer', e => {
      if (e.data && e.data !== DATA_RESPONSE_DONE) {
        // If first chunk
        if (streamAnswer === '') {
          // Adds message to be edited
          dispatch(
            chatActions.addMessage({
              answer: '',
              tags: [],
              showWarning: true,
              isAnswer: true,
              showSocial: false,
              typewriter: true,
              date: moment().format(),
              hcpPrompt
            })
          )
          // Start typing
          dispatch(chatActions.setTyping(true))
        }

        const data = e.data
          .substring(1, e.data.length - 1)
          .replace(/\\n/g, '\n')
          .replace('\\"', '"')
        streamAnswer = streamAnswer + `${data}`

        dispatch(
          chatActions.editLastMessage({
            answer: streamAnswer
          })
        )
      } else if (e.data === DATA_RESPONSE_DONE) {
        dispatch(
          chatActions.editLastMessage({
            done: true,
            answer: streamAnswer
          })
        )
      }
    })

    // Metadata
    let answerId = ''
    let promptId = ''
    response.addEventListener('gpt_metadata', e => {
      if (e.data && e.data !== DATA_RESPONSE_DONE) {
        const metadata = JSON.parse(e.data)
        answerId = metadata.answerId
        promptId = metadata.applicationPromptId
        dispatch(chatActions.editLastMessage({ answerId, promptId }))
      }
    })

    // Live Feed
    const liveFeed = []
    response.addEventListener('gpt_liveFeed', e => {
      if (e.data && e.data !== DATA_RESPONSE_DONE) {
        liveFeed.push(JSON.parse(e.data))
      } else if (e.data === DATA_RESPONSE_DONE) {
        dispatch(chatActions.editLastMessage({ liveFeed }))
        dispatch(chatActions.addTags({ tags: liveFeed.map(tag => tag.tagId) }))
      }
    })

    // Tags
    const tags = []
    response.addEventListener('gpt_tags', e => {
      if (e.data && e.data !== DATA_RESPONSE_DONE) {
        tags.push(JSON.parse(e.data))
      } else if (e.data === DATA_RESPONSE_DONE) {
        SessionService.saveToCache('tags', tags.map(tag => tag.tagId).join(','))
      }
    })

    //Follow Up Prompts
    let followUpPrompts = []
    response.addEventListener('gpt_followups', e => {
      if (e.data && e.data !== DATA_RESPONSE_DONE) {
        const followUps = JSON.parse(e.data)
        followUpPrompts = followUps?.followUpPrompts || []
      } else if (e.data === DATA_RESPONSE_DONE) {
        dispatch(chatActions.editLastMessage({ followUpPrompts }))
      }
    })

    let consultedSources = {}
    response.addEventListener('gpt_sources', e => {
      if (e.data && e.data !== DATA_RESPONSE_DONE) {
        const sources = JSON.parse(e.data)
        consultedSources =
          DougallParser.consultedSourcesParser({ sources }) || {}
      } else if (e.data === DATA_RESPONSE_DONE) {
        // Add consulted Sources to answer
        dispatch(chatActions.editLastMessage({ consultedSources }))
      }
    })

    // On Stream Finished
    let finished = false
    response.addEventListener('readystatechange', e => {
      if (e.readyState >= 2 && !finished) {
        finished = true
        if (answerId && promptId) {
          // Enable socials if true in config
          dispatch(chatActions.editLastMessage({ showSocial }))

          // Set last(current) Prompt and Answer
          dispatch(chatActions.setCurrentPrompt({ answerId, promptId }))

          // If no linkedPromptId then this is first prompt
          // so its promptId would be the linkedPromptId
          if (!linkedPromptId) {
            dispatch(chatActions.editLastMessage({ linkedPromptId: promptId }))
            dispatch(chatActions.setLinkedPromptId(promptId))
          }

          if (tags.length > 0) {
            const replacedTags = DougallParser.replaceTagSource({
              answerId,
              promptId,
              tags
            })
            dispatch(
              chatActions.editLastMessage({
                tags: tags && tags.length > 0 ? replacedTags : []
              })
            )

            // Highlight Answer
            const highlightedAnswer = DougallParser.highlightAnswer({
              answer: streamAnswer,
              tags
            })
            dispatch(chatActions.editLastMessage({ answer: highlightedAnswer }))
            dispatch(chatActions.showInterests())
          }

          dispatch(
            chatActions.addMessage({
              hcpPrompt,
              answer: streamAnswer,
              answerId,
              promptId,
              linkedPromptId,
              liveFeed,
              isDougall: true,
              isAnswer: true,
              showWarning: false,
              tags: tags && tags.length > 0 ? tags : [],
              showSocial: false,
              date: moment().format(),
              consultedSources,
              followUpPrompts
            })
          )

          const references = DougallParser.referencesParser({
            answerId,
            promptId,
            tags
          })

          if (references) {
            dispatch(chatActions.editLastMessage({ urls: references }))
          }

          dispatch(chatActions.promptSuccess())

          dispatch(
            piwikRequest({
              tracking: {
                category: 'firstword-ai',
                action: 'loaded',
                name: `prompt-answer_${haystackTrackingKey}`
              }
            })
          )
        }
        // If connection ends with no answer
        else if (streamAnswer === '') {
          dispatch(
            chatActions.editLastMessage({
              answer: i18next.t('dougallGptTimeoutAnswer'),
              showSocial: false,
              isError: true,
              showWarning: false
            })
          )
          dispatch(chatActions.promptFail())
          dispatch(
            piwikRequest({
              tracking: {
                category: 'firstword-ai',
                action: 'failed',
                name: `prompt-answer_${haystackTrackingKey}`
              }
            })
          )
        }
        // If only answer arrived with no Ids
        else if (streamAnswer) {
          dispatch(chatActions.promptSuccess())
          dispatch(
            piwikRequest({
              tracking: {
                category: 'firstword-ai',
                action: 'loaded',
                name: `prompt-answer_${haystackTrackingKey}`
              }
            })
          )
        }
      }
    })

    response.stream()
  }
})

export default DougallController
