import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  reports: {
    loading: null,
    error: null,
    data: null
  },
  count: {
    loading: true,
    error: null,
    data: null
  },
  recentReports: {
    loading: true,
    error: null,
    data: null
  }
}

const myReportsSlice = createSlice({
  name: 'myReportsReducer',
  initialState,
  reducers: {
    requestListReports: state => {
      state.reports.loading = true
      state.reports.error = null
      state.reports.data = null
    },
    successListReports: (state, { payload: reports }) => {
      state.reports.loading = false
      state.reports.error = null
      state.reports.data = reports
    },
    errorListReports: (state, { payload: error }) => {
      state.reports.loading = false
      state.reports.error = error
      state.reports.data = null
    },
    requestReportsCount: state => {
      state.count.loading = true
      state.count.error = null
      state.count.data = null
    },
    successReportsCount: (state, { payload: count }) => {
      state.count.loading = false
      state.count.error = null
      state.count.data = count
    },
    errorReportsCount: (state, { payload: error }) => {
      state.count.loading = false
      state.count.error = error
      state.count.data = null
    },
    requestListRecentReports: state => {
      state.recentReports.loading = true
      state.recentReports.error = null
      state.recentReports.data = null
    },
    successListRecentReports: (state, { payload }) => {
      const parsedData = {
        name: payload.name,
        data: payload.data.map(story => ({ ...story, isReport: true }))
      }
      state.recentReports.loading = false
      state.recentReports.error = null
      state.recentReports.data = parsedData
    },
    errorListRecentReports: (state, { payload: error }) => {
      state.recentReports.loading = false
      state.recentReports.error = error
      state.recentReports.data = null
    }
  }
})

export const { reducer, actions } = myReportsSlice

export const {
  requestListReports,
  successListReports,
  errorListReports,
  requestReportsCount,
  successReportsCount,
  errorReportsCount,
  requestListRecentReports,
  successListRecentReports,
  errorListRecentReports
} = actions

export default reducer
