import { reduxForm } from 'redux-form'
import { compose } from 'redux'
import PaymentPage from './components/PaymentPage/Confirmation'
import validate from './components/PaymentForm/validate'
import { actions } from './PaymentSlice'
import { connect } from 'react-redux'

const mapStateToProps = ({ paymentReducer: { loading } }) => {
  return {
    loading
  }
}

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'payment',
    validate,
    touchOnBlur: false,
    onSubmit: (values, dispatch) => {
      dispatch(actions.requestProcessPayment(values))
    }
  })
)(PaymentPage)
