import React from 'react'
import { Provider } from 'react-redux'
import createContainer from '../services'
import createStore from '../rootStore'
import Router from './Router'
import { history } from 'src/app/Router'
import { createServiceProvider } from 'src/services/ServiceContext'
import Session from 'src/features/session/SessionProvider'

import Translation from 'src/features/fw-ai/children/translation/Translation'
import Title from 'src/features/title/TitleContainer'
import Bootstrapping from 'src/features/bootstrap/BootstrapPage'
import LaunchDarklyContainer from 'src/features/launchDarkly/LaunchDarklyContainer'
import Theme from 'src/features/theme/ThemeContainer'
import { ThemeProvider } from 'src/features/fw-ai/children/themeProvider'

const container = createContainer()
const store = createStore(history, container)
container.registerStore(store)

if (process.env.NODE_ENV === 'development') {
  if (window.Cypress) {
    window.store = store
  }
}

const ServiceProvider = createServiceProvider(container)

const App = () => {
  return (
    <Provider store={store}>
      <ServiceProvider>
        <Bootstrapping>
          <Session>
            <Title history={history}>
              <Theme>
                <ThemeProvider>
                  <Translation>
                    <LaunchDarklyContainer>
                      <Router />
                    </LaunchDarklyContainer>
                  </Translation>
                </ThemeProvider>
              </Theme>
            </Title>
          </Session>
        </Bootstrapping>
      </ServiceProvider>
    </Provider>
  )
}

export default App
