import { createSlice } from '@reduxjs/toolkit'

const followUpPromptsSlice = createSlice({
  name: 'followUpPrompts',
  initialState: {
    fetching: true,
    prompts: []
  },
  reducers: {
    requestPrompts: state => {
      state.fetching = true
    },
    requestSuccess: (state, { payload: { prompts } }) => {
      state.fetching = false
      state.prompts = prompts
    },
    requestFail: state => {
      state.fetching = false
      state.prompts = []
    }
  }
})

export const { actions, reducer } = followUpPromptsSlice
export const { requestPrompts, requestSuccess, requestFail } = actions
export default reducer
