import { useEffect, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { SessionContext } from 'src/features/session/SessionContext'
import { actions as viewActions } from 'src/features/views/ViewsSlice'
import TopContent from './components/HomeTopContent'
import MainContent from './components/HomeMainContent'
import SidebarContent from './components/HomeSidebarContent'
import commonStyles from 'src/styles/common.module.sass'
import Page from 'src/components/Page'
import { siteName } from 'src/utils/oneCodeBase'

const getReportsViewsPayload = () => {
  return [
    {
      name: 'lead article',
      include_likes: true
    },
    {
      name: 'latest research',
      include_likes: true,
      size: 3
    },
    {
      name: 'research with kols',
      include_likes: true,
      skip_exclusion: true,
      tags: {
        fw_report_stakeholders: ['KOLs']
      },
      size: 3
    },
    {
      name: 'research with industry experts',
      include_likes: true,
      skip_exclusion: true,
      tags: {
        fw_report_stakeholders: ['Industry Experts']
      },
      size: 3
    },
    {
      name: 'research with treating physicians',
      include_likes: true,
      skip_exclusion: true,
      tags: {
        fw_report_stakeholders: ['Treating Physicians']
      },
      size: 3
    }
  ]
}

const getViewsPayload = interests => {
  const tags = interests ? { tags: interests } : {}

  const keys = [
    'lead article',
    'top stories',
    'conference news',
    'insight analysis and views'
  ]
  const views = keys.map(key => ({ name: key }))

  views.push({
    name: 'recommended for you',
    include_related_tags: true,
    ...tags
  })

  return views
}

const HomePage = () => {
  const { getInterests } = useContext(SessionContext)
  const interests = getInterests()

  const dispatch = useDispatch()

  useEffect(() => {
    // Get Views for Home Page
    const views =
      siteName === 'Reports'
        ? getReportsViewsPayload()
        : getViewsPayload(interests)

    dispatch(viewActions.requestViews(views))

    return () => {
      dispatch(viewActions.cleanViews())
    }
  }, [])

  return (
    <Page>
      <div className={commonStyles.top}>
        <TopContent />
      </div>
      <div className={commonStyles.content}>
        <div
          className={
            siteName === 'Reports'
              ? commonStyles.mainWithoutSidebar
              : commonStyles.main
          }
        >
          <MainContent />
        </div>

        {/* No sidebar for Reports */}
        {siteName !== 'Reports' && (
          <div className={commonStyles.sidebar}>
            <SidebarContent />
          </div>
        )}
      </div>
    </Page>
  )
}

export default HomePage
