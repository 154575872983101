import { withProtectionAndRedirection } from 'src/components/LicenseProtected'
import Login from 'src/features/login/components/LoginLandingPage/LoginLandingPageContainer'
import Logout from 'src/features/session/LogoutPage'
import Register from 'src/features/register/components/RegisterLandingPage/RegisterLandingPageContainer'
import MyAccount from 'src/features/myAccount/MyAccountPage'
import RiverRoutes from 'src/features/river/RiverRoutes'
import StaticPage from 'src/features/static/StaticPage'
import Captcha from 'src/features/captcha/CaptchaContainer'
import Landing from 'src/features/gatedLandingPage/containers/LandingPageContainer'
import Consent from 'src/features/gatedLandingPage/containers/ConsentPageContainer'
import OneClickSubscribe from 'src/features/oneClickSubscribe/OneClickSubscribeContainer'
import ContactUs from 'src/features/contactUs/ContactUsPage'
import FW4MapComponent from 'src/features/views/RedirectFw4ToFw5Page'
import Payment from 'src/features/payment/PaymentContainer'
import ConfirmationPayment from 'src/features/payment/Confirmation'
import SaveSearch from 'src/features/mySavedSearches/containers/SaveSearchFormContainer'
import siteRoutes from 'src/config/sites/routes'
import DougallFW from 'src/features/fw-ai/children/dougallFW/DougallFW'
import OnboardingFlow from 'src/features/onboardingFlow/OnboardingFlowPage'
import Home from 'src/features/home/HomePage'
import SingleStory from 'src/features/singleStory/SingleStoryPage'
import DynamicStaticPage from 'src/features/dynamicStatic/DynamicStaticPage'
import ResourcesStaticPage from 'src/features/dynamicStatic/ResourcesStaticPage'

export const getDynamicRoutes = (pages = []) => {
  // TODO: Remove this validation for next release
  return pages
    .filter(
      page =>
        !page.exclude_from_routes &&
        page.uri_path !== '/' &&
        !page.uri_path.includes('/story/')
    )
    .map(page => {
      let component = DynamicStaticPage
      if (page?.withProtectionAndRedirection) {
        component = withProtectionAndRedirection(component, {
          ...page?.withProtectionAndRedirection
        })
      }
      return { path: page.uri_path, component, exact: true }
    })
}

const sharedRoutes = [
  {
    path: '/sign-in-email-sent',
    component: StaticPage('loginEmailSent'),
    exact: true
  },
  {
    path: '/confirmation-email-sent',
    component: StaticPage('deviceConfirmationSent'),
    exact: true
  },
  {
    path: '/register-email-sent',
    component: StaticPage('registerEmailSent'),
    exact: true
  },
  {
    path: '/device-limit',
    component: StaticPage('deviceLimit'),
    exact: true
  },
  {
    path: '/story/:id',
    component: SingleStory,
    exact: true
  },
  {
    path: '/',
    component: Home,
    exact: true
  },
  {
    path: '/log-out',
    component: Logout,
    exact: true
  },
  {
    path: '/resources',
    component: ResourcesStaticPage,
    exact: true
  },
  {
    path: '/sign-in',
    component: withProtectionAndRedirection(Login, {
      showFor: { anonymous: true },
      redirectForOthers: '/'
    }),
    exact: true
  },
  {
    path: '/device-sign-in',
    component: withProtectionAndRedirection(Login, {
      showFor: { anonymous: true },
      redirectForOthers: '/'
    }),
    exact: true
  },
  {
    path: '/register',
    component: withProtectionAndRedirection(Register, {
      showFor: { anonymous: true },
      redirectForOthers: '/'
    }),
    exact: true
  },
  {
    path: '/my-account/:section?',
    component: withProtectionAndRedirection(MyAccount, {
      showFor: { authenticated: true },
      redirectForOthers: '/sign-in'
    }),
    exact: true
  },
  {
    path: '/search/:term?',
    component: withProtectionAndRedirection(RiverRoutes, {
      showFor: {
        authenticated: true,
        paywall: 'free_feature',
        subtype: 'search'
      }
    }),
    exact: true
  },
  {
    path: '/(stories|river)/:key/:args*',
    component: RiverRoutes,
    exact: true
  },
  {
    path: '/contact-us/:why',
    component: ContactUs,
    exact: true
  },
  {
    path: '/cookie-policy',
    component: StaticPage('cookies'),
    exact: true
  },
  {
    path: '/captcha',
    component: withProtectionAndRedirection(Captcha, {
      showFor: { anonymous: true },
      redirectForOthers: '/'
    }),
    exact: true
  },
  {
    path: '/firstword-ai',
    component: withProtectionAndRedirection(DougallFW, {
      showFor: {
        requireAll: true,
        authenticated: true,
        licenseType: 'fw-ai',
        paywall: 'ai_paywall',
        paywallFeature: 'Firstword AI'
      }
    }),
    exact: true
  },
  {
    path: '/register-newsletter-subscribe',
    component: withProtectionAndRedirection(OnboardingFlow, {
      showFor: { authenticated: true },
      redirectForOthers: '/sign-in'
    }),
    exact: true
  },
  {
    path: '/SEEAUKPrivacy',
    component: StaticPage('SEEAUKPrivacy'),
    exact: true
  },
  {
    path: '/CCPAPrivacyPolicy',
    component: StaticPage('CCPAPrivacyPolicy'),
    exact: true
  },
  {
    path: '/checkout',
    component: Payment,
    exact: true
  },
  {
    path: '/checkout/confirmation',
    component: ConfirmationPayment,
    exact: true
  },
  {
    path: '/newsletter-subscribe',
    component: OneClickSubscribe,
    exact: true
  },
  {
    path: '/save-search',
    component: withProtectionAndRedirection(SaveSearch, {
      showFor: { authenticated: true },
      redirectForOthers: '/sign-in'
    }),
    exact: true
  },
  {
    path: '/story/map/:fw4_story_id',
    component: FW4MapComponent,
    exact: true
  },
  {
    path: '/not-found',
    component: StaticPage('notFound'),
    exact: true
  },
  {
    path: '/consent',
    component: Consent,
    exact: true
  },
  {
    path: '/:landing_page_route',
    component: Landing,
    exact: true
  }
  // {
  //   component: () => {
  //     return window.location.replace('/not-found')
  //   }
  // }
]

const routes = [...siteRoutes, ...sharedRoutes]
export default routes
