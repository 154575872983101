import { createSelector } from 'reselect'

export const getViews = state => state.viewsReducer
export const getCurrentViews = state => state.viewsReducer?.views || {}
export const getViewsError = state => state.viewsReducer?.error || ''
export const getViewsLoading = state => state.viewsReducer?.loading || false

export const getViewByName = name =>
  createSelector(getViews, state => {
    return state?.views[name] || null
  })
