import React from 'react'
import * as staticComponents from './components'
import { mappedComponents } from './utils'
import styles from '../../DynamicStaticPage.module.sass'
import cx from 'classnames'

const mapping = {
  ...mappedComponents(staticComponents)
}

const StaticComponentLoopItem = ({ item, parentKey }) => {
  const className = item?.css?.length
    ? cx(item.css.map(style => styles[style]))
    : ''

  const Component = mapping[item?.component] ?? null
  return Component ? (
    <Component item={item} parentKey={parentKey} className={className} />
  ) : null
}

const StaticComponentLoop = ({ items, className, customStyles }) => {
  return (
    <div className={className} style={customStyles}>
      {items?.map((item, index) => {
        const key = `${item.component}-${index}`
        return <StaticComponentLoopItem key={key} parentKey={key} item={item} />
      })}
    </div>
  )
}

export default StaticComponentLoop
