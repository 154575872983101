import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Ripple from '../Ripple'
import styles from './Tab.module.sass'
import icons from '../../../assets/icons/listIcons'
import Icon from '../Icon'
import cx from 'classnames'

class Tab extends Component {
  static displayName = 'Tab'
  static propTypes = {
    /**
     * An additional custom className for the root element
     */
    className: PropTypes.string,
    /** You can provide your own value. Otherwise, we fall back to the child position index. */
    value: PropTypes.any,
    /** Is the tab disabled */
    disabled: PropTypes.bool,
    /** The tab ID */
    id: PropTypes.string,
    /** The tab background color */
    color: PropTypes.string,
    /** The function to fire when the tab is changed */
    tabChange: PropTypes.func,
    /** The function to fire when the tab is clicked */
    onClick: PropTypes.func,
    /** The component children (Contains the tab label text and any links) */
    children: PropTypes.node,
    /** Whether or not the tab is selected */
    selected: PropTypes.bool,
    /** An svg icon to show to the left of the button text */
    iconLeft: PropTypes.oneOf(['', ...icons]),
    /** An svg icon to show to the right of the button text */
    iconRight: PropTypes.oneOf(['', ...icons]),
    /** The icon color where the tier colors are determined by the theme */
    iconColorTier: PropTypes.oneOf([
      '',
      'icon',
      'action',
      'primary',
      'secondary',
      'tertiary',
      'knockout',
      'default'
    ]),
    /** If `true`, the tabs will show an active indication when selected */
    showsActiveBar: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    /** A CSS modules style object to override default theme */
    altTheme: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
  }
  tabClick = event => {
    const { tabChange, value, onClick } = this.props

    if (tabChange) {
      tabChange(event, value)
    }

    if (onClick) {
      onClick(event)
    }
  }
  addChildClass(child) {
    const className = cx(child.props.className, styles.child)
    return React.cloneElement(child, { className })
  }
  render = () => {
    const {
      disabled = false,
      id,
      children,
      selected = false,
      color,
      altTheme,
      tabChange,
      iconLeft,
      iconRight,
      iconColorTier,
      showsActiveBar,
      className,
      ...other
    } = this.props

    const ParentTag = disabled ? 'div' : Ripple
    const child =
      typeof children === 'string' ? (
        <div>
          <span className={styles.center}>{children}</span>
        </div>
      ) : (
        children
      )
    const themeStyles = { ...styles, ...altTheme }
    return (
      <ParentTag
        {...other}
        id={id}
        aria-selected={selected}
        style={{
          ...(color && { backgroundColor: color }),
          ...(iconLeft && { paddingRight: '10px' })
        }}
        onClick={this.tabClick}
        className={cx({
          [themeStyles.item]: true,
          [themeStyles.itemDisabled]: disabled,
          [themeStyles.itemActive]: selected && !disabled,
          [themeStyles.showsActiveBar]: showsActiveBar,
          [className]: className
        })}
      >
        <div className={themeStyles.container}>
          {iconLeft && (
            <div className={styles.iconContainer}>
              <Icon
                className={themeStyles.icon}
                icon={iconLeft}
                colorTier={iconColorTier}
              />
            </div>
          )}
          {this.addChildClass(child)}
          {iconRight && (
            <div className={styles.iconContainer}>
              <Icon
                className={themeStyles.icon}
                icon={iconRight}
                colorTier={iconColorTier}
              />
            </div>
          )}
        </div>
      </ParentTag>
    )
  }
}

export default Tab
