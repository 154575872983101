import React, { useState, useEffect, Fragment, useRef } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import styles from './Filters.module.sass'
import { filterLabelsConfigModule } from 'src/config/sites/river'
import Icon from 'src/lib/ui/Icon'
import Modal from 'react-overlays/Modal'
import FilterSection from './FilterSection'
import SectionHeading from 'src/components/SectionHeading'
import Teaser from 'src/components/Teaser'
import { getConfig } from 'src/features/config/ConfigSelector'
import { siteName } from 'src/utils/oneCodeBase'
import { paywallRedirectFlow } from 'src/features/benefits/BenefitsSlice'

// Commented, for now is not used but could be in the future
// import FilterPlaceholder from './FiltersPlaceholder'

const { orderedFilters } = filterLabelsConfigModule

const modalBackdrop = backdropProps => {
  return <div {...backdropProps} className={styles.backdrop} />
}

const ConditionalModal = ({ condition, wrap, children }) =>
  condition ? wrap(children) : <Fragment>{children}</Fragment>

ConditionalModal.propTypes = {
  condition: PropTypes.bool,
  wrap: PropTypes.func,
  children: PropTypes.node
}

const Filters = ({
  handleCloseFilters,
  filtersOpen,
  large,
  isPlus,
  busy,
  showTitle = true,
  showForAll = false,
  flex = 'column',
  riverId
}) => {
  const dispatch = useDispatch()
  const { excludedCategories = [] } = useSelector(
    getConfig('filter_input_config')
  )

  const filtersRef = useRef()
  const [shownFilters, setShownFilters] = useState([])
  const [openFilters, setOpenFilters] = useState(null)
  const title = 'Refine your search by:'
  const { pathname } = useLocation()

  useEffect(() => {
    const shownFilters = filtersCleanCategory()
    setShownFilters(shownFilters)
  }, [pathname])

  useEffect(() => {
    const mappedOpenFilters = shownFilters.map(filter => ({
      category: filter,
      open: false
    }))

    setOpenFilters(mappedOpenFilters)
  }, [shownFilters])

  useEffect(() => {
    const handleOutSideClick = event => {
      if (siteName !== 'Reports') return
      if (!filtersRef.current?.contains(event.target)) {
        setOpenFilters(mappedOpenFilters =>
          mappedOpenFilters.map(filter => ({ ...filter, open: false }))
        )
      }
    }

    window.addEventListener('mousedown', handleOutSideClick)

    return () => {
      window.removeEventListener('mousedown', handleOutSideClick)
    }
  }, [filtersRef])

  const filtersCleanCategory = () => {
    let shownFilters = orderedFilters.filter(
      filter => !excludedCategories.includes(filter)
    )
    if (pathname.includes('type')) {
      shownFilters = shownFilters.filter(filter => filter !== 'type')
    }
    return shownFilters
  }

  const updateOpenFilter = (filterCategory, value) => {
    setOpenFilters(openFilters =>
      openFilters.map(filter => {
        if (filter.category !== filterCategory) {
          if (siteName !== 'Reports') {
            return filter
          }

          // Hide other filters
          return { ...filter, open: false }
        }

        return {
          category: filter.category,
          open: value
        }
      })
    )
  }

  const dispatchPaywall = () => {
    dispatch(
      paywallRedirectFlow({
        type: 'plus_feature',
        subtype: 'add_filters'
      })
    )
  }

  return (
    (busy || shownFilters.length > 0) &&
    pathname !== '/search' && (
      <ConditionalModal
        condition={!large}
        wrap={children => (
          <Modal
            // `handleOnEscapeKeyDown` is removed by codemod.

            aria-labelledby="filters"
            renderBackdrop={modalBackdrop}
            show={filtersOpen}
            className={styles.modalContainer}
            onBackdropClick={handleCloseFilters}
          >
            <div className={styles.modal}>
              {children}
              <Icon
                className={styles.icon}
                onClick={handleCloseFilters}
                icon="svg/material-design-icons/navigation/close"
                button
              />
            </div>
          </Modal>
        )}
      >
        {/* Only the elements below are rendered for SearchFiltersBox when condition is not large */}
        <div className={flex === 'column' ? styles.section : ''}>
          {showTitle && (
            <SectionHeading
              className={flex === 'row' ? styles.reportsTitle : ''}
              title={<span className={styles.title}>{title}</span>}
            />
          )}
          {showForAll || isPlus ? (
            <div
              className={flex === 'row' ? styles.rowFilters : ''}
              ref={filtersRef}
            >
              {openFilters?.length > 0 &&
                shownFilters.map(key => (
                  <FilterSection
                    key={key}
                    index={key}
                    openFilters={openFilters}
                    updateOpen={updateOpenFilter}
                    riverId={riverId}
                  />
                ))}
            </div>
          ) : (
            <div>
              {flex === 'column' && (
                <Teaser src="/assets/blurred-filters.jpg">
                  Access these filters and other features by upgrading your
                  subscription
                  <br />
                  <button
                    className={large ? styles.sidebarButton : styles.button}
                    onClick={dispatchPaywall}
                  >
                    Subscribe Now
                  </button>
                </Teaser>
              )}
            </div>
          )}
        </div>
      </ConditionalModal>
    )
  )
}

Filters.propTypes = {
  filters: PropTypes.object,
  handleCloseFilters: PropTypes.func,
  filtersOpen: PropTypes.bool,
  large: PropTypes.bool,
  isPlus: PropTypes.bool,
  busy: PropTypes.bool,
  showTitle: PropTypes.bool,
  flex: PropTypes.string,
  showForAll: PropTypes.bool
}

export default Filters
