import React from 'react'
import Ripple from '../../lib/ui/Ripple'
import ViewMoreLink from '../ViewMoreLink'
import styles from './ViewMoreBox.module.sass'

const ViewMoreBox = props => (
  <div className={styles.container}>
    <Ripple className={styles.ripple}>
      <ViewMoreLink {...props} />
    </Ripple>
  </div>
)

export default ViewMoreBox
