import React, { Fragment } from 'react'
import RoutedLink from 'src/components/RoutedLink'
import { takeEvery, call, all, put } from 'redux-saga/effects'
import { actions } from './MyInterestsSlice'
import {
  disableSingleStoryTag,
  enableSingleStoryTag
} from 'src/features/singleStory/SingleStorySlice'
import { notificationsEnqueue } from 'src/features/notifications/NotificationsSlice'
import { logPush } from 'src/features/contactUs/ContactUsSlice'
import upperFirst from 'lodash/upperFirst'
import { notificationDuration as duration } from 'src/constants'
import _get from 'lodash/get'
import { logout } from 'src/features/session/SessionActions'
import FW5MLError from 'src/services/FW5ML/FW5MLError'
import { ACCESS_DENIED } from 'src/services/FW5ML/errorCodes'

function* insertInterest(services, action) {
  const UserRepository = services('UserRepository')
  const Piwik = services('Piwik')
  const Session = services('Session')
  const { payload: interest } = action

  try {
    // Check duplicates
    const currentInterests = yield call(
      [Session, 'getUserField'],
      `interests.stated.${interest.category}`,
      []
    )
    if (currentInterests.indexOf(interest.tag) > -1) {
      yield put(
        notificationsEnqueue({
          message: `${interest.tag} is already in your monitored interests list`,
          duration
        })
      )
      yield call(
        [Piwik, 'track'],
        'notification',
        'error',
        `interest-already-exists-in-the-user's-profile_${interest.tag}`
      )
      yield put(actions.errorInsertInterest({ interest, error: 'Duplicated' }))
      return
    }
    const lowerCurrentInterests = currentInterests.map(interest =>
      interest.toLowerCase()
    )
    const relatedTagsFound = _get(interest, 'related_tags', '')
      .split(',')
      .find(relatedTag =>
        lowerCurrentInterests.includes(relatedTag.toLowerCase())
      )

    if (relatedTagsFound) {
      const errorMsg = `"${interest.tag}" is already present under the name: "${relatedTagsFound}"`
      yield put(
        notificationsEnqueue({
          message: errorMsg,
          duration
        })
      )
      yield call([Piwik, 'track'], 'notification', 'error', errorMsg)
      yield put(
        actions.errorInsertInterest({
          interest,
          error: 'RelatedInterestAlreadyExists'
        })
      )
      return
    }

    yield call([UserRepository, 'insertStatedInterest'], interest)
    yield put(actions.successInsertInterest(interest))
    yield put(disableSingleStoryTag(interest))
    yield call([Session, 'userInsertInterest'], interest)
    yield call(
      [Piwik, 'track'],
      'account',
      'insert-interest',
      `${interest.category}-${interest.tag}`
    )
    yield put(
      notificationsEnqueue({
        message: `${upperFirst(
          interest.tag
        )} has been added to your interests.`,
        duration
      })
    )
  } catch (e) {
    console.log(e)
    if (e instanceof FW5MLError) {
      const code = e.getCode()
      if (code === ACCESS_DENIED) {
        yield put(logout({ reload: false, redirect: '/sign-in' }))
        yield put(
          notificationsEnqueue({
            message: 'Please sign in and try again.'
          })
        )
        return
      }
    }
    yield put(
      logPush(
        'Account Update',
        `Error while inserting interest in: ${e.message}`
      )
    )
    yield put(
      notificationsEnqueue({
        message: `Unable to add ${interest.tag} to your interests. Please refresh the page and try again.`,
        duration
      })
    )
    yield call(
      [Piwik, 'track'],
      'notification',
      'error',
      `could-not-add-interest_${interest.tag}`
    )
    yield put(actions.errorInsertInterest({ interest, error: e.message }))
  }
}

function* deleteInterest(services, action) {
  const UserRepository = services('UserRepository')
  const Piwik = services('Piwik')
  const Session = services('Session')
  const { payload: interest } = action
  try {
    yield call([UserRepository, 'deleteStatedInterest'], interest)
    yield put(actions.successDeleteInterest(interest))
    yield put(enableSingleStoryTag(interest))
    yield call([Session, 'userRemoveInterest'], interest)
    yield call(
      [Piwik, 'track'],
      'account',
      'remove-interest',
      `${interest.category}-${interest.tag}`
    )
    yield put(
      notificationsEnqueue({
        message: `${upperFirst(
          interest.tag
        )} has been removed from your interests.`,
        duration
      })
    )
  } catch (e) {
    console.log(e)
    if (e instanceof FW5MLError) {
      const code = e.getCode()
      if (code === ACCESS_DENIED) {
        yield put(logout({ reload: false, redirect: '/sign-in' }))
        yield put(
          notificationsEnqueue({
            message: 'Please sign in and try again.'
          })
        )
        return
      }
    }
    yield put(
      logPush(
        'Account Update',
        `Error while deleting interest in: ${e.message}`
      )
    )
    yield put(
      notificationsEnqueue({
        message: `Unable to remove ${interest.tag} from your interests. Please refresh the page try again.`,
        duration
      })
    )
    yield call(
      [Piwik, 'track'],
      'notification',
      'error',
      `could-not-remove-interest_${interest.tag}`
    )
    yield put(actions.errorDeleteInterest({ interest, error: e.message }))
  }
}

function* suggestInterest(services, action) {
  const InterestsRepository = services('InterestsRepository')
  const Piwik = services('Piwik')
  const { payload: query } = action
  try {
    const data = yield call([InterestsRepository, 'getSuggestion'], query)
    const { tags: suggestions } = data
    yield put(
      actions.successSuggestInterest({
        suggestions,
        message: !suggestions.length ? (
          <Fragment>
            Interest not found.{' '}
            <RoutedLink
              label="Contact Us"
              to="/contact-us"
              style={{ color: 'inherit', marginRight: '0.3em' }}
            />
            to add it to our database.
          </Fragment>
        ) : null
      })
    )
  } catch (e) {
    console.log(e)
    if (e instanceof FW5MLError) {
      const code = e.getCode()
      if (code === ACCESS_DENIED) {
        yield put(logout({ reload: false, redirect: '/sign-in' }))
        yield put(
          notificationsEnqueue({
            message: 'Please sign in and try again.'
          })
        )
        return
      }
    }
    yield put(
      logPush(
        'Account Update',
        `Error while requesting interest suggestions. Error: ${e.message}`
      )
    )
    yield put(
      notificationsEnqueue({
        message: `Error while requesting interest suggestions. Please refresh the page and try again.`
      })
    )
    yield call(
      [Piwik, 'track'],
      'notification',
      'error',
      'could-not-get-interests'
    )
    yield put(actions.errorSuggestInterest({ query, error: e.message }))
  }
}

export default function* watchUpdate(services) {
  yield all([
    takeEvery(actions.requestInsertInterest, insertInterest, services),
    takeEvery(actions.requestDeleteInterest, deleteInterest, services),
    takeEvery(actions.requestSuggestInterest, suggestInterest, services)
  ])
}
