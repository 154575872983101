import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  views: {},
  loading: false,
  error: '',
  fw5_story_id: null
}

const viewsSlice = createSlice({
  name: 'viewsReducer',
  initialState,
  reducers: {
    requestViews: state => {
      state.loading = true
    },
    successViews: (state, { payload }) => {
      state.views = { ...state.views, ...payload }
      state.loading = false
    },
    errorViews: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    cleanViews: state => {
      state.loading = false
      state.error = ''
      state.views = {}
    },
    redirectRequest: state => {
      state.loading = true
      state.error = ''
      state.views = {}
      state.fw5_story_id = null
    },
    redirectSuccess: (state, { payload }) => {
      state.loading = false
      state.error = ''
      state.views = {}
      state.fw5_story_id = payload
    }
  }
})

export const { actions, reducer } = viewsSlice

export const {
  requestViews,
  successViews,
  errorViews,
  cleanViews,
  redirectRequest,
  redirectSuccess
} = actions

export default reducer
