import 'react-app-polyfill/stable'
import { createRoot } from 'react-dom/client'
import * as serviceWorker from './serviceWorker'
import loadPolyfills from './polyfills'
import 'src/themes/reset.sass'
import 'src/themes/scrollbar.sass'
import './index.css'

import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import App from './app/App'

const render = async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
    context: {
      kind: 'user',
      key: 'user-key-anonymous'
    }
  })

  const root = createRoot(document.getElementById('root'))
  root.render(
    <LDProvider>
      <App />
    </LDProvider>
  )
}

loadPolyfills().then(() => render())
serviceWorker.unregister()

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./app/App', render)
}
