import StaticComponentLoop from '../StaticComponentLoop'
import commonStyles from 'src/styles/common.module.sass'
import styles from './ResourcesPageContent.module.sass'

const ResourcesPageContent = ({ content }) => {
  return (
    <div>
      {/* Top Section */}
      <div className={commonStyles.top}>
        <StaticComponentLoop section="top" items={content?.top} />
      </div>

      {/* Main Section*/}
      <div className={styles.mainFullSection}>
        <div className={styles.main}>
          <StaticComponentLoop section="main" items={content?.main} />
        </div>
      </div>
    </div>
  )
}

export default ResourcesPageContent
