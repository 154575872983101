import React from 'react'
import PropTypes from 'prop-types'
import StorySpinner from 'src/components/StorySpinner'
import MediaEventWrapper from '../MediaEventWrapper'

const RiverMediaEventBody = ({ loading, results, config }) => {
  if (loading) {
    return <StorySpinner />
  }

  if (results) return <MediaEventWrapper events={results} config={config} />

  return null
}

RiverMediaEventBody.propTypes = {
  loading: PropTypes.bool,
  results: PropTypes.array,
  config: PropTypes.object
}

export default RiverMediaEventBody
