import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import { history } from 'src/app/Router'
import styles from './Advertorial.module.sass'
import classNames from 'classnames/bind'
import { historyHandler } from 'src/utils'

const cx = classNames.bind(styles)

const Advertorial = ({ to, body = '', sponsor }) => {
  const handleAdClick = event => {
    event.preventDefault()
    historyHandler(to, '', history, 'push')
  }
  return (
    <article
      onClick={to && handleAdClick}
      className={cx(styles.container, { to })}
    >
      <ReactMarkdown className={styles.body} children={body} />
      {sponsor && (
        <footer className={styles.footer}>Sponsored by {sponsor}</footer>
      )}
    </article>
  )
}

Advertorial.propTypes = {
  /**
   * Ad content. Accepts Markdown
   */
  body: PropTypes.string,
  /**
   * Path to redirect the user to when clicked
   */
  to: PropTypes.string,
  /**
   * Sponsot
   */
  sponsor: PropTypes.string
}

export default Advertorial
