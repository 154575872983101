import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  busy: false,
  error: null
}

const piwikSlice = createSlice({
  name: 'piwikReducer',
  initialState,
  reducers: {
    piwikRequest: state => {
      state.busy = true
    },
    piwikSuccess: state => {
      state.busy = false
    },
    piwikError: (state, { payload }) => {
      state.busy = false
      state.error = payload.error
    },
    newsletterPiwikRequest: () => {}
  }
})

export const { actions, reducer } = piwikSlice

export const {
  piwikRequest,
  piwikSuccess,
  piwikError,
  newsletterPiwikRequest
} = actions

export default reducer
