import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  history: [],
  rawHistory: [],
  fetching: true,
  deleting: false,
  deletingId: null,
  clearAllOpen: false,
  selectedThreadId: null
}

const historySlice = createSlice({
  name: 'historyReducer',
  initialState,
  reducers: {
    requestHistory: state => {
      state.fetching = true
    },
    requestHistorySuccess: (state, { payload: { history, rawHistory } }) => {
      state.fetching = false
      state.history = history
      state.rawHistory = rawHistory
    },
    requestHistoryFail: state => {
      state.fetching = false
      state.history = []
      state.rawHistory = []
    },
    deleteHistory: (state, { payload: { id } }) => {
      state.deleting = true
      state.deletingId = id
    },
    deleteHistorySuccess: state => {
      state.deleting = false
      state.deletingId = null
    },
    deleteHistoryFail: state => {
      state.deleting = false
      state.deletingId = null
    },
    setClearAllOpen: (state, { payload: { open } }) => {
      state.clearAllOpen = open
    },
    setSelectedThread: (state, { payload: { id } }) => {
      state.selectedThreadId = id
    },
    resetSelectedThread: state => {
      state.selectedThreadId = null
    },
    requestPromptHistory: () => {},
    requestTitleChange: () => {}
  }
})

export const { actions, reducer } = historySlice

export const {
  requestHistory,
  requestHistorySuccess,
  requestHistoryFail,
  deleteHistory,
  deleteHistorySuccess,
  deleteHistoryFail,
  setClearAllOpen,
  setSelectedThread,
  resetSelectedThread,
  requestPromptHistory,
  requestTitleChange
} = actions

export default reducer
