import { compose } from 'redux'
import { connect } from 'react-redux'
import * as flags from 'src/features/asyncFlags/AsyncFlagsSelector'
import {
  getDeletingSearches,
  getSavedSearches
} from 'src/features/mySavedSearches/MySavedSearchesSelector'
import { actions } from '../MySavedSearchesSlice'
import { actions as searchActions } from 'src/features/river/RiverSlice'
import SavedSearches from '../components/SavedSearches'

const mapStateToProps = state => ({
  searches: getSavedSearches(state),
  deleting: getDeletingSearches(state),
  loadingSavedSearches: flags.isBusy('savedSearchList')(state),
  savedSearchesConfig:
    state?.configReducer?.my_account_settings?.data?.saved_searches
})

const mapDispatchToProps = dispatch => ({
  requestSavedSearches: () => dispatch(actions.requestSavedSearchList()),
  onReplay: ({ search_request }) => {
    dispatch(searchActions.redirectSearchFromSaved(search_request))
  },
  onDelete: search => dispatch(actions.requestSavedSearchDelete(search)),
  onUpdate: search =>
    dispatch(
      actions.requestSavedSearchSave({
        name: search.name,
        search: search
      })
    )
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SavedSearches
)
