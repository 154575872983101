import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const getDefaultValues = (state, area, id) => {
  const initialTrackRequestStatus =
    state.trackRequestStatus === undefined
      ? {}
      : { ...state.trackRequestStatus }

  const initialTrackRequestStatusArea =
    initialTrackRequestStatus[area] === undefined
      ? {}
      : { ...state.trackRequestStatus[area] }

  const initialTrackRequestStatusId =
    initialTrackRequestStatusArea[id] === undefined
      ? {}
      : { ...state.trackRequestStatus[area][id] }

  return {
    initialTrackRequestStatus,
    initialTrackRequestStatusArea,
    initialTrackRequestStatusId
  }
}

const viewabilitySlice = createSlice({
  name: 'viewabilityReducer',
  initialState,
  reducers: {
    adsViewTrackRequest: (state, { payload: { area, id } }) => {
      const {
        initialTrackRequestStatus,
        initialTrackRequestStatusArea,
        initialTrackRequestStatusId
      } = getDefaultValues(state, area, id)

      state.trackRequestStatus = {
        ...initialTrackRequestStatus,
        [area]: {
          ...initialTrackRequestStatusArea,
          [id]: {
            ...initialTrackRequestStatusId
          }
        }
      }
    },
    adsViewTrackSuccess: (state, { payload: { area, id, src } }) => {
      const {
        initialTrackRequestStatus,
        initialTrackRequestStatusArea,
        initialTrackRequestStatusId
      } = getDefaultValues(state, area, id)
      state.trackRequestStatus = {
        ...initialTrackRequestStatus,
        [area]: {
          ...initialTrackRequestStatusArea,
          [id]: {
            ...initialTrackRequestStatusId,
            [src]: true
          }
        }
      }
    },
    adsViewTrackError: (state, { payload: { area, id, src } }) => {
      const {
        initialTrackRequestStatus,
        initialTrackRequestStatusArea,
        initialTrackRequestStatusId
      } = getDefaultValues(state, area, id)
      state.trackRequestStatus = {
        ...initialTrackRequestStatus,
        [area]: {
          ...initialTrackRequestStatusArea,
          [id]: {
            ...initialTrackRequestStatusId,
            [src]: false
          }
        }
      }
    }
  }
})

export const { actions, reducer } = viewabilitySlice
export const { adsViewTrackRequest, adsViewTrackSuccess, adsViewTrackError } =
  actions
export default reducer
