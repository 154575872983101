import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { logout } from './SessionActions'
import SpinnerPage from 'src/components/SpinnerPage'

const LogoutPage = () => {
  const dispatch = useDispatch()
  useEffect(() => dispatch(logout({ refresh: true })), [])
  return <SpinnerPage title="Logging out, please wait..." />
}

export default LogoutPage
