import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useChat } from 'src/features/fw-ai/children/dougallChat/hooks'
import { buildStoryLinkFromArticleId, isEmpty } from 'src/utils/utils'
import { SessionContext } from 'src/features/session/SessionContext'
import PropTypes from 'prop-types'

import FileIcon from 'src/static/icons/file'
import styles from './LeftBar.module.sass'
import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'

import History from 'src/features/fw-ai/children/history/History'
import Faq from 'src/features/fw-ai/children/faq/Faq'
import ConsultedSourcesSection from 'src/components/ConsultedSourcesSection'
import { ChatOutlined } from '@mui/icons-material'

import Svg from 'src/components/Svg'
import FwAIByDougall from 'src/assets/images/powered.svg'
import usePiwik from 'src/hooks/usePiwik'
import { licenseTypes } from 'src/services/FW5ML/parsers/UserParser'
import { useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { getLastMessage } from 'src/features/fw-ai/children/dougallChat/ChatSelector'
import { CONSULTED_SOURCES } from 'src/constants/featureFlagConstants'

/**
 * Left Bar Container Styles
 */
const LeftBarContainer = styled(Box, {
  name: 'LeftBar',
  label: 'leftBarContainer',
  overridesResolver: (props, styles) => [styles.container]
})({
  height: 'calc(100% - 20px)',
  display: 'flex',
  flexDirection: 'column',
  '> .bottomSection': {
    marginTop: 'auto',
    width: '100%',
    overflowY: 'auto',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': { display: 'none' }
  }
})

/**
 * Left Bar Button Styles
 */
const ButtonStyled = styled(Box, {
  name: 'LeftBar',
  label: 'leftBarButton',
  overridesResolver: (props, styles) => [styles.button]
})(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  margin: '13px 10px',
  '> h1': {
    fontSize: '16px',
    lineHeight: '28px',
    color: theme.palette.leftBar.buttonText
  }
}))

// Adjusted IconContainer to fit your needs
const IconContainer = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#2d3e50',
  borderRadius: '50%',
  width: 26,
  height: 26,
  padding: 1,
  marginRight: 10
}))

/**
 * TitleIcon component
 */
const ButtonIcon = ({ icon: IconComponent, ...props }) => {
  return (
    <IconContainer>
      <IconComponent sx={{ color: 'white', fontSize: 18 }} {...props} />
    </IconContainer>
  )
}

ButtonIcon.propTypes = {
  icon: PropTypes.elementType
}

const LeftBarButton = ({ icon, label, onClick }) => {
  return (
    <ButtonStyled onClick={onClick}>
      <ButtonIcon icon={icon} />
      <Typography variant="h1">{label}</Typography>
    </ButtonStyled>
  )
}
LeftBarButton.propTypes = {
  icon: PropTypes.object,
  label: PropTypes.string,
  onClick: PropTypes.func
}

const LeftBar = ({ listExpanded = 0 }) => {
  const { handleTracking, handleActionTracking } = usePiwik()
  const {
    setActiveModal,
    activeModal,
    setCurrentPrompt,
    currentPrompt,
    isFetching
  } = useChat()
  const history = useHistory()
  const { t } = useTranslation()
  const flags = useFlags()
  const { userHasLicense = () => false, getUserPartyId } =
    useContext(SessionContext)
  const isPlus = userHasLicense(licenseTypes.LICENSE_PLUS)
  const partyId = getUserPartyId()
  const message = useSelector(getLastMessage(), isEqual)
  const showConsultedSources =
    !isEmpty(message?.consultedSources) && flags[CONSULTED_SOURCES]

  // const { handleNewTopic } = useTopic()

  // 0 - None
  // 1 - Popular Prompts
  // 2 - History
  // 3 - FAQ
  const [listOpen, setListOpen] = useState(listExpanded)

  const listsOpenHandler = id => {
    let trackingAction = null

    if (id === 'history') {
      if (listOpen === 2) {
        trackingAction = 'collapse'
        setListOpen(0)
      } else {
        trackingAction = 'expand'
        setListOpen(2)
      }
    } else if (id === 'faq') {
      if (listOpen === 3) {
        trackingAction = 'collapse'
        setListOpen(0)
      } else {
        trackingAction = 'expand'
        setListOpen(3)
      }
    }

    if (trackingAction) {
      handleTracking('firstword-ai', trackingAction, id)
    }
  }

  const feedbackRedirect = () => {
    handleTracking('firstword-ai', 'click', 'feedback')
    return history.push('/contact-us', { from: history.location.pathname })
  }

  const onConsultedSourcesClose = () => {
    setActiveModal(null)
    setCurrentPrompt({ answerId: '', promptId: '' })
    const trackData = {
      languageClick: currentPrompt?.urls?.links[0]?.languageCode,
      userType: isPlus ? 'plus' : 'free',
      interactionId: message?.interactionId
    }
    handleActionTracking('Site/Click/SourcesConsultedExit', trackData)
  }

  const handleConsultedSourcesClick = ({
    urls = [],
    element = {},
    onClickEvent,
    ...tag
  }) => {
    const { count, links } = element
    const trackData = {
      partyId,
      promptId: message?.promptId,
      pos: tag?.pos,
      interactionId: message?.interactionId,
      answerId: message?.answerId,
      sourceName: tag?.btnId
    }
    handleActionTracking('Site/Click/SourcesConsulted', trackData)
    // when clicking tag with single sources
    if (count === 1) {
      setActiveModal(null)
      const url = buildStoryLinkFromArticleId(links[0]?.articleId)
      window.open(url, '_blank', 'noopener,noreferrer')
      // when clicking tag with multiple sources
    } else {
      onClickEvent.stopPropagation()
      setCurrentPrompt({
        promptId: message.promptId,
        answerId: message.answerId,
        urls: element,
        selectedUrl: tag?.btnId
      })

      const overlayData = {
        languageClick: links[0]?.languageCode,
        pos: trackData?.pos,
        interactionId: trackData?.interactionId
      }

      handleActionTracking('Site/Serve/SourcesConsultedOverlay', {
        ...overlayData,
        userType: isPlus ? 'plus' : 'free'
      })
      setActiveModal('consulted-sources')
    }
  }

  return (
    <LeftBarContainer
      data-testid="left-bar"
      onClick={() => {
        if (activeModal === 'consulted-sources') {
          onConsultedSourcesClose()
        }
      }}
    >
      <Svg src={FwAIByDougall.toString()} className={styles.fwLogo} />
      {/* <PopularPrompts openCallback={listsOpenHandler} isOpen={listOpen === 1} /> */}

      {showConsultedSources && (
        <ConsultedSourcesSection
          isFetching={isEmpty(message) || isFetching}
          title={'Consulted Sources Include'}
          tooltip={t('consultedSourcesTooltip')}
          icon={<FileIcon />}
          message={{ urls: message?.consultedSources }}
          onTagClick={handleConsultedSourcesClick}
          hideLabels={true}
          testid={'sources-consulted'}
          selectedTag={currentPrompt?.selectedUrl}
        />
      )}
      <History openCallback={listsOpenHandler} isOpen={listOpen === 2} />
      <div className="bottomSection">
        <Faq openCallback={listsOpenHandler} isOpen={listOpen === 3} />
        <LeftBarButton
          icon={ChatOutlined}
          label={t('feedbackLeftBar')}
          onClick={feedbackRedirect}
        />
      </div>
    </LeftBarContainer>
  )
}

LeftBar.propTypes = {
  /**
   * Number of list that should be expanded on mount
   */
  listExpanded: PropTypes.number
}

export default LeftBar
