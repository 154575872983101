import PropTypes from 'prop-types'
import { ClearAll } from '@mui/icons-material'
import { Button } from '@mui/material'
import React, { useMemo } from 'react'

export const ClearAllFiltersButton = ({
  show = false,
  variant = 'outlined',
  size = 'small',
  sx,
  label = 'Clear All Filters',
  onClearAllFilters = () => {}
}) => {
  const defaultSx = useMemo(
    () => ({
      marginLeft: 'auto',
      borderColor: '#e27746',
      color: '#e27746',
      '&:hover': {
        borderColor: '#DE6427'
      },
      '@media (max-width: 600px)': {
        width: '100%',
        marginLeft: 0
      }
    }),
    []
  )

  return (
    show && (
      <Button
        variant={variant}
        size={size}
        onClick={onClearAllFilters}
        sx={sx || defaultSx}
        startIcon={<ClearAll />}
      >
        {label}
      </Button>
    )
  )
}

ClearAllFiltersButton.propTypes = {
  show: PropTypes.bool,
  variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  sx: PropTypes.object,
  label: PropTypes.string,
  onClearAllFilters: PropTypes.func
}

export default ClearAllFiltersButton
