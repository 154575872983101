import React, { Fragment, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { SessionContext } from 'src/features/session/SessionContext'
import { licenseTypes } from 'src/services/FW5ML/parsers/UserParser'
import RiverInterest from 'src/components/RiverInterest'
import Button from 'src/lib/ui/Buttons/Button'
import Typography from 'src/lib/ui/Typography'
import styles from './RiverHeader.module.sass'
import { siteName } from 'src/utils/oneCodeBase'
import { treeToArray } from 'src/utils'
import _get from 'lodash/get'
import {
  requestInsertInterest,
  requestDeleteInterest
} from 'src/features/myInterests/MyInterestsSlice'

const RiverHeader = ({
  title,
  showFilterMenu,
  toggleFilters,
  riverKey,
  riverArgs
}) => {
  const dispatch = useDispatch()
  const showRiverInterestButton = riverKey === 'tag' && siteName !== 'Reports'
  const { checked: sessionReady, user = {} } = useContext(SessionContext)
  const userInterests = treeToArray(
    _get(user, 'interests.stated', {}),
    (category, tag) => ({
      category,
      tag
    })
  )

  const hndAddInterest = (...args) => {
    if (!sessionReady) return

    if (user?.license === licenseTypes.LICENSE_PLUS) {
      dispatch(requestInsertInterest(...args))
    } else {
      dispatch(
        paywallRedirectFlow({
          type: 'plus_feature',
          subtype: 'add_interests'
        })
      )
    }
  }

  const hndDeleteInterest = (...args) => {
    if (!sessionReady) return

    if (user?.license === licenseTypes.LICENSE_PLUS) {
      dispatch(requestDeleteInterest(...args))
    } else {
      dispatch(
        paywallRedirectFlow({
          type: 'plus_feature',
          subtype: 'remove_interests'
        })
      )
    }
  }

  return (
    <Fragment>
      <Typography className={styles.headline} type="headline">
        <span className={styles.title} data-testid="RiverHeader_title">
          {title}
        </span>
        {showRiverInterestButton && (
          <RiverInterest
            riverArgs={riverArgs}
            userInterests={userInterests}
            onAddInterest={hndAddInterest}
            onDeleteInterest={hndDeleteInterest}
          />
        )}
      </Typography>
      {showFilterMenu && (
        <div className={styles.bar}>
          <div className={styles.buttons}>
            <Button
              minWidth="0"
              iconLeft="svg/custom/filter"
              tier="action"
              raised={false}
              className={styles.contentRiverFilterButton}
              textCase="none"
              onClick={toggleFilters}
            >
              Filter
            </Button>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default RiverHeader
