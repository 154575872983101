import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import commonStyles from 'src/styles/common.module.sass'
import Page from 'src/components/Page'
import TopContent from './components/RiverTopContent'
import MainContent from './components/RiverMainContent'
import SidebarContent from './components/RiverSidebarContent'
import Metadata from 'src/components/Metadata'
import { getConfig } from 'src/features/config/ConfigSelector'
import styles from './River.module.sass'
import { contentRiversConfigModule } from 'src/config/sites/river'
import cx from 'classnames'
import * as selectors from './RiverSelector'
import { actions } from './RiverSlice'
import { getRiverConfig } from './config/riverConfig'
import { push } from 'connected-react-router'

const RiverPage = () => {
  const dispatch = useDispatch()
  const riverStateConfig = useSelector(getConfig('riverConfig')) || {}
  const { flex = 'column' } = riverStateConfig
  const { mediaEventTypes } = contentRiversConfigModule
  const riverId = useSelector(selectors.getRiverId)
  const riverKey = useSelector(selectors.getRiverKey)
  const riverConfig = getRiverConfig(riverId)
  const loading = useSelector(selectors.getLoading)
  const [filtersOpen, setFiltersOpen] = useState(false)

  const {
    title: configTitle,
    stringTitle,
    getFeaturedContentTag = false,
    showFeaturedContent: withFeaturedContent = false,
    showSingleFeaturedContent: withSingleFeaturedContent = false
  } = riverConfig

  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen)
  }

  useEffect(() => {
    // this will update the loadingFeaturedContent prop for the Featured content loading
    if (withFeaturedContent || withSingleFeaturedContent) {
      dispatch(actions.requestFeaturedContent(riverId))
    }

    if (riverId) {
      dispatch(actions.loadFiltersFromUrl({ riverId }))
      dispatch(
        actions.loadContentRiver({
          withFeaturedContent,
          withSingleFeaturedContent,
          getFeaturedContentTag
        })
      )
    }
  }, [riverId])

  const handleClearAllFilters = () => {
    if (!riverId) return

    const currentParams = new URLSearchParams(window.location.search)

    const basePath = `${window.location.pathname}`
    //We clean all filters except rst and sort
    const preservedParams = new URLSearchParams()
    ;['rst', 'sort'].forEach(param => {
      if (currentParams.has(param))
        preservedParams.set(param, currentParams.get(param))
    })

    const searchQuery = window.location.pathname.replace(basePath, '') || ''

    const newUrl = `${basePath}${encodeURIComponent(
      searchQuery
    )}?${preservedParams.toString()}`

    //We clean the existing filters from Redux
    dispatch(actions.clearSearchFilters({ riverId }))

    //Then we push the new url
    dispatch(push(newUrl))

    //And lastly, we fetch everything again
    dispatch(actions.loadFiltersFromUrl({ riverId }))
    dispatch(
      actions.loadContentRiver({
        withFeaturedContent,
        withSingleFeaturedContent,
        getFeaturedContentTag
      })
    )
  }

  return (
    <Page>
      <Metadata title={stringTitle || configTitle} />

      {/* Top section */}
      <div className={commonStyles.top}>
        <TopContent riverConfig={riverConfig} />
      </div>

      <div className={commonStyles.content}>
        {/* Main section */}
        <div
          className={cx(
            flex === 'row'
              ? commonStyles.mainWithoutSidebar
              : commonStyles.main,
            mediaEventTypes.includes(riverKey) && styles.mediaEvent
          )}
        >
          <MainContent
            riverId={riverId}
            riverKey={riverKey}
            riverConfig={riverConfig}
            loading={loading}
            riverStateConfig={riverStateConfig}
            filtersOpen={filtersOpen}
            toggleFilters={toggleFilters}
            onClearAllFilters={handleClearAllFilters}
          />
        </div>

        {/* Sidebar section */}
        {/* No sidebar for Events River and Row flex */}
        {!mediaEventTypes.includes(riverKey) && flex !== 'row' && (
          <div className={commonStyles.sidebar}>
            <SidebarContent
              riverId={riverId}
              riverKey={riverKey}
              riverConfig={riverConfig}
              loading={loading}
              riverStateConfig={riverStateConfig}
              filtersOpen={filtersOpen}
              toggleFilters={toggleFilters}
            />
          </div>
        )}
      </div>
    </Page>
  )
}

export default RiverPage
