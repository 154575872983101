import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { actions } from './PaymentSlice'
import { monetaryMap } from '../../utils'

function usePayment() {
  const dispatch = useDispatch()
  const payment = useSelector(state => state?.paymentReducer) || {}
  const { currency = 'usd', price = null } = payment

  const priceWithCurrency = price ? `${monetaryMap[currency]}${price}` : null

  const getPrice = () => dispatch(actions.requestGetPrice())

  useEffect(() => {
    getPrice()
  }, [])

  return {
    getPrice,
    payment,
    currency,
    price,
    priceWithCurrency
  }
}

export default usePayment
