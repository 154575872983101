import { compose } from 'redux'
import { connect } from 'react-redux'
import { actions as landingPageActions } from 'src/features/gatedLandingPage/GatedLandingPageSlice'
import RegisterLandingPage from '.'

const mapStateToProps = state => ({
  landingPageCompany: state.landingPageReducer.registrationInfo.config,
  busyLandingPage: state.landingPageReducer.loading
})

const mapDispatchToProps = dispatch => ({
  loadCompanyContent: data =>
    dispatch(landingPageActions.requestLandingCompanyData(data))
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  RegisterLandingPage
)
