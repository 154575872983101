import { useServices } from 'src/services/ServiceContext'
import { useSelector } from 'react-redux'
import {
  getViewsError,
  getViewsLoading
} from 'src/features/views/ViewsSelector'

function useViewProps() {
  const { Piwik } = useServices(['Piwik'])

  const viewsError = useSelector(getViewsError)
  const viewsLoading = useSelector(getViewsLoading)

  const handleViewMoreClick = (page = 'home', section) => {
    // Space at the end becomes "-" via cleanParam in Piwik/index
    const viewMorePrefix = 'View More '
    Piwik.track(page, 'button-click', `${viewMorePrefix}${section}`)
  }

  return {
    viewsError,
    viewsLoading,
    handleViewMoreClick
  }
}

export default useViewProps
