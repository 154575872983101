import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../../lib/ui/Icon'
import { Link } from 'react-router-dom'
import styles from './ViewMoreLink.module.sass'
import { useSelector } from 'react-redux'
import { getConfig } from 'src/features/config/ConfigSelector'

const ViewMoreLink = React.memo(
  ({ children, to, className, isExternal = false, ...props }) => {
    const internalToken = useSelector(getConfig('internal_token')) || null
    const externalLink = internalToken ? `${to}?it=${internalToken}` : to
    const Component = isExternal ? 'a' : Link

    return (
      <Component
        href={isExternal ? externalLink : undefined}
        to={!isExternal ? to : undefined}
        className={styles.link + ` ${className || ''}`}
        rel={isExternal ? 'noopener noreferrer' : undefined}
        {...props}
      >
        <span className={styles.text}>{children}</span>
        <Icon
          icon="svg/material-design-icons/hardware/keyboard_arrow_right"
          colorTier="secondary"
          className={styles.icon}
        />
      </Component>
    )
  }
)

ViewMoreLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  isExternal: PropTypes.bool
}

export default ViewMoreLink
