import { createSlice } from '@reduxjs/toolkit'

const STACK_SIZE = 1

const initialState = {
  savedSearches: [],
  deleting: [],
  saving: []
}

const mySavedSearchesSlice = createSlice({
  name: 'mySavedSearchesReducer',
  initialState,
  reducers: {
    requestSavedSearchList: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'request',
          asyncKey: 'savedSearchList'
        }
      }),
      reducer: state => {
        state.savedSearches = []
      }
    },
    successSavedSearchList: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'success',
          asyncKey: 'savedSearchList'
        }
      }),
      reducer: (state, { payload }) => {
        state.savedSearches = payload
      }
    },
    errorSavedSearchList: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'error',
          asyncKey: 'savedSearchList'
        }
      }),
      reducer: () => {}
    },
    requestSavedSearchSave: (state, { payload }) => {
      const { name, search } = payload
      state.saving = [...state.saving, { name, search }].slice(-1 * STACK_SIZE)
    },
    successSavedSearchSave: (state, { payload }) => {
      state.saving = state.saving.filter(search => search.name !== payload)
    },
    errorSavedSearchSave: (state, { payload }) => {
      state.saving = state.saving.filter(search => search.name !== payload)
    },
    requestSavedSearchDelete: (state, { payload }) => {
      state.deleting.push(payload.search_id)
    },
    successSavedSearchDelete: (state, { payload }) => {
      state.savedSearches = state.savedSearches.filter(
        ({ search_id: savedId }) => savedId !== payload
      )
      state.deleting = state.deleting.filter(search => search !== payload)
    },
    errorSavedSearchDelete: (state, { payload }) => {
      state.deleting = state.deleting.filter(search => search !== payload.id)
    },
    requestSaveSearchAlert: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'request',
          asyncKey: 'saveSearchAlert'
        }
      }),
      reducer: () => {}
    },
    successSaveSearchAlert: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'success',
          asyncKey: 'saveSearchAlert'
        }
      }),
      reducer: () => {}
    },
    errorSaveSearchAlert: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'error',
          asyncKey: 'saveSearchAlert'
        }
      }),
      reducer: () => {}
    }
  }
})

export const { actions, reducer } = mySavedSearchesSlice

export const {
  requestSavedSearchList,
  successSavedSearchList,
  errorSavedSearchList,

  requestSavedSearchSave,
  successSavedSearchSave,
  errorSavedSearchSave,

  requestSavedSearchDelete,
  successSavedSearchDelete,
  errorSavedSearchDelete,

  requestSaveSearchAlert,
  successSaveSearchAlert,
  errorSaveSearchAlert
} = actions

export default reducer
