import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  formState: {
    prompt: ''
  },
  fetching: false,
  messages: [],
  tags: [],
  showInterests: false,
  activeModal: null,
  actionHandler: {
    action: '',
    prompt: '',
    promptId: '',
    answerId: ''
  },
  linkedPromptId: null,
  promptNum: null,
  current: {
    promptId: '',
    answerId: ''
  },
  urlRequested: false,
  isTyping: false
}

const chatSlice = createSlice({
  name: 'chatReducer',
  initialState,
  reducers: {
    setFormState: (state, { payload }) => {
      state.formState = { ...state.formState, ...payload }
    },
    promptRequestStart: state => {
      state.fetching = true
    },
    promptSuccess: state => {
      state.fetching = false
    },
    promptFail: state => {
      state.fetching = false
    },
    addMessage: (state, { payload }) => {
      state.messages.push({ ...payload })
    },
    addMessages: (state, { payload }) => {
      state.messages = payload
    },
    editLastMessage: (state, { payload }) => {
      const idx = state.messages.length - 1
      if (idx >= 0) {
        state.messages[idx] = { ...state.messages[idx], ...payload }
      }
    },
    resetMessages: state => {
      state.messages = []
      state.tags = []
    },
    addTags: (state, { payload }) => {
      state.tags = payload.tags
    },
    showInterests: state => {
      state.showInterests = true
    },
    setAction: (state, { payload }) => {
      state.actionHandler = payload
    },
    setLinkedPromptId: (state, { payload }) => {
      state.linkedPromptId = payload
    },
    resetLinkedPromptId: state => {
      state.linkedPromptId = null
    },
    setCurrentPrompt: (state, { payload }) => {
      state.current.promptId = payload.promptId
      state.current.answerId = payload.answerId
      state.current.urls = payload.urls
      state.current.selectedUrl = payload.selectedUrl
    },
    resetCurrentPrompt: state => {
      state.current.promptId = ''
      state.current.answerId = ''
    },
    setPromptNum: (state, { payload }) => {
      state.promptNum = payload
    },
    resetPromptNum: state => {
      state.promptNum = null
    },
    setUrlRequested: (state, { payload }) => {
      state.urlRequested = payload
    },
    setTyping: (state, { payload }) => {
      state.isTyping = payload
    },
    setActiveModal: (state, { payload }) => {
      state.activeModal = payload
    },
    promptRequest: () => {},
    validatePromptAccess: () => {},
    actionSubmit: () => {},
    shareEmail: () => {}
  }
})

export const { actions, reducer } = chatSlice

export const {
  setFormState,
  promptRequest,
  promptRequestStart,
  promptSuccess,
  promptFail,
  addMessage,
  addMessages,
  editLastMessage,
  resetMessages,
  addTags,
  showInterests,
  setAction,
  setLinkedPromptId,
  resetLinkedPromptId,
  setCurrentPrompt,
  resetCurrentPrompt,
  setPromptNum,
  resetPromptNum,
  setUrlRequested,
  setTyping,
  setActiveModal,
  validatePromptAccess,
  actionSubmit,
  shareEmail
} = actions

export default reducer
