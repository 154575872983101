import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom'
import LineClamp from 'react-dotdotdot'
import Typography from '../../lib/ui/Typography'
import styles from './SponsoredHeadline.module.sass'

const SponsoredHeadline = ({ title = '', to = '/', body = '' }) => (
  <article className={styles.container}>
    <div className={styles.content}>
      <header>
        <Link to={to} className={styles.link}>
          <Typography type="title" className={styles.title} gutterBottom>
            {title}
          </Typography>
        </Link>
      </header>
      {
        <LineClamp clamp={3} className={styles.body}>
          <Link to={to} className={styles.bodyLink}>
            <ReactMarkdown children={body} />
          </Link>
        </LineClamp>
      }
    </div>
  </article>
)

SponsoredHeadline.propTypes = {
  /**
   * Headline title
   */
  title: PropTypes.string,
  /**
   * Headline body. Accepts Markdown
   */
  body: PropTypes.string,
  /**
   * Path to redirect the user to when clicked
   */
  to: PropTypes.string
}

export default SponsoredHeadline
