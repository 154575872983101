import React from 'react'
import FeaturedContent from './children/FeaturedContent'

const RiverTopContent = ({ riverConfig }) => {
  const { showFeaturedContent = false, showSingleFeaturedContent = false } =
    riverConfig

  return (
    <>
      {(showFeaturedContent || showSingleFeaturedContent) && (
        <FeaturedContent single={showSingleFeaturedContent} />
      )}
    </>
  )
}

export default RiverTopContent
