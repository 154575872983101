import React, { Fragment } from 'react'
import { push } from 'connected-react-router'
import { notificationsEnqueue } from 'src/features/notifications/NotificationsSlice'
import { requestBootstrap } from 'src/features/bootstrap/BootstrapSlice'
import RoutedLink from 'src/components/RoutedLink'
import { TOKEN_EXPIRED } from 'src/services/FW5ML/errorCodes'
import { TOKEN_TYPE_FULL } from 'src/services/session/tokenTypes'
import whitelistSessionUrls from 'src/services/FW5ML/constants/whitelistSessionUrls'
import { piwikRequest } from 'src/features/piwik/PiwikSlice'

export const getAuthToken = async ({ endpoint, services }) => {
  if (endpoint === 'getAuthToken') {
    return process.env.REACT_APP_ML_AUTH_TOKEN
  }

  const token = await services.Session.tokenLoad()
  return token ?? null
}

export const killSessionIfTokenExpired = async ({
  error,
  url,
  dispatch,
  services
}) => {
  if (error?.status === 401) {
    const errorCode = error?.data?.error?.code
    const tokenType = await services.Session.tokenTypeLoad()

    if (errorCode === TOKEN_EXPIRED && tokenType === TOKEN_TYPE_FULL) {
      //Is an email login, nl login or register confirmation, do not destroy the session yet
      if (!whitelistSessionUrls.includes(url)) {
        await services.Session.destroySession()
        dispatch(requestBootstrap())
        dispatch(push('/sign-in'))
        dispatch(
          notificationsEnqueue({
            message: (
              <Fragment>
                Your link to sign in to the site expired. Please request a new
                one by <RoutedLink label="clicking here" to="/sign-in" />.
              </Fragment>
            )
          })
        )
        dispatch(
          piwikRequest({
            tracking: {
              category: 'notification',
              action: 'error',
              name: 'user-session-killed-due-to-token-expiry'
            }
          })
        )
      }
    }
  }
}
