import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { SessionContext } from 'src/features/session/SessionContext'
import NotifyUpdatesAndValid from './NotifyUpdatesAndValid'
import NotifyUpdatesAndInvalid from './NotifyUpdatesAndInvalid'
import { getStory } from 'src/features/singleStory/SingleStorySelector'

const NotifyUpdates = () => {
  const {
    authenticated,
    user,
    checked: sessionReady
  } = useContext(SessionContext)

  const story = useSelector(getStory())

  const isTherapyTrends = ['Therapy Trends'].includes(
    story?.reportSettings?.reportBrand
  )

  if (!sessionReady || !story || !isTherapyTrends) {
    return null
  }

  if (!authenticated) {
    return <NotifyUpdatesAndInvalid story={story} />
  }

  return <NotifyUpdatesAndValid story={story} user={user} />
}

NotifyUpdates.propTypes = {
  story: PropTypes.object,
  item: PropTypes.object
}

export default NotifyUpdates
