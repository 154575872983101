import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Icon from 'src/lib/ui/Icon'
import Typography from 'src/lib/ui/Typography'
import styles from './FilterSection.module.sass'
import { filterLabelsConfigModule } from 'src/config/sites/river'
import { getConfig } from 'src/features/config/ConfigSelector'
import classNames from 'classnames/bind'
import FilterInput from '../FilterInput'
import { getFoundTags } from 'src/features/river/RiverSelector'
import usePiwik from 'src/hooks/usePiwik'

const { getKey } = filterLabelsConfigModule

const cx = classNames.bind(styles)

const FilterSection = ({
  riverId,
  index,
  openFilters = [],
  updateOpen = () => {}
}) => {
  const filterCategory = getKey(index)
  const { handleTracking } = usePiwik()
  const [foundTags, setFoundTags] = useState({})
  const [loading, setLoading] = useState(false)
  const open = openFilters?.find(item => item?.category === index)?.open

  const handleClick = () => {
    updateOpen(index, !open)
    handleTracking('search', !open ? 'expand' : 'collapse', filterCategory)
  }

  const {
    freeInputTypeFor: freeInputType = [],
    singleInputFor = [],
    orderFiltersByPosition = false,
    siteInputType = 'pharma',
    predeterminedTags = {}
  } = useSelector(getConfig('filter_input_config'))

  const tagsForCategory = useSelector(getFoundTags)
  const singleSelect = singleInputFor.includes(index)

  useEffect(() => {
    if (freeInputType.includes(index)) {
      const tags = tagsForCategory[index]?.tagOptions ?? []
      const loading = tagsForCategory[index]?.loadingTags ?? false
      setLoading(loading)
      setFoundTags(tags)
    }
  }, [tagsForCategory])

  useEffect(() => {
    setFoundTags(predeterminedTags[index] ?? [])
  }, [index])

  return (
    <div className={styles.section}>
      <div
        onClick={handleClick}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          border: 'none',
          cursor: 'pointer'
        }}
        className={cx(
          styles.title,
          siteInputType === 'fwreports' ? styles.fwReportsFilter : ''
        )}
      >
        <Typography type="subheading">{filterCategory}</Typography>
        <div>
          {open ? (
            <Icon icon="svg/material-design-icons/navigation/arrow_drop_down" />
          ) : (
            <Icon icon="svg/custom/arrow-drop-right" />
          )}
        </div>
      </div>
      {open && (
        <div className={cx(styles.group, { open, display: 'block' })}>
          <FilterInput
            options={foundTags}
            index={index}
            open={open}
            inputType={freeInputType.includes(index) ? 'freeInputText' : ''}
            loading={loading}
            type={siteInputType}
            filterCategory={filterCategory}
            orderFiltersByPosition={orderFiltersByPosition}
            singleSelect={singleSelect}
            riverId={riverId}
          />
        </div>
      )}
    </div>
  )
}

FilterSection.propTypes = {
  index: PropTypes.any,
  openFilters: PropTypes.array,
  updateOpen: PropTypes.func
}
export default FilterSection
