import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  settings: {}
}

const mySettingsSlice = createSlice({
  name: 'mySettingsReducer',
  initialState,
  reducers: {
    requestAccountSettingsLoad: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'request',
          asyncKey: 'accountSettingsLoad'
        }
      }),
      reducer: () => {}
    },
    successAccountSettingsLoad: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'success',
          asyncKey: 'accountSettingsLoad'
        }
      }),
      reducer: (state, action) => {
        state.settings = action.payload
      }
    },
    errorAccountSettingsLoad: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'error',
          asyncKey: 'accountSettingsLoad'
        }
      }),
      reducer: () => {}
    },

    requestAccountSettingsUpdate: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'request',
          asyncKey: 'accountSettingsUpdate'
        }
      }),
      reducer: () => {}
    },
    successAccountSettingsUpdate: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'success',
          asyncKey: 'accountSettingsUpdate'
        }
      }),
      reducer: (state, action) => {
        state.settings = {
          ...state.settings,
          ...action.payload
        }
      }
    },
    errorAccountSettingsUpdate: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'error',
          asyncKey: 'accountSettingsUpdate'
        }
      }),
      reducer: () => {}
    }
  }
})

export const { reducer, actions } = mySettingsSlice

export const {
  successAccountSettingsLoad,
  errorAccountSettingsLoad,
  requestAccountSettingsLoad,
  successAccountSettingsUpdate,
  errorAccountSettingsUpdate,
  requestAccountSettingsUpdate
} = actions

export default reducer
