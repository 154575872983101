import React, { Component } from 'react'
import PropTypes from 'prop-types'
import icons from 'src/assets/icons/listIcons'
import { ListItem, ListItemIcon, ListItemText } from '../List'
import styles from './MenuItem.module.sass'
import cx from 'classnames'

class MenuItem extends Component {
  render = () => {
    const {
      children,
      active,
      icon,
      iconColor,
      iconPosition,
      divider,
      className,
      mode,
      ...other
    } = this.props
    return (
      <ListItem
        {...other}
        divider={mode === 'sidebar' && divider}
        ripple
        active={active}
        className={cx(styles.item, className)}
        backgroundColor="transparent"
      >
        {iconPosition === 'right' && (
          <ListItemText type="menu-item" primary={children} />
        )}
        {icon && (
          <ListItemIcon icon={icon} colorTier={active ? 'action' : iconColor} />
        )}
        {iconPosition === 'left' && (
          <ListItemText type="menu-item" primary={children} />
        )}
      </ListItem>
    )
  }
}
MenuItem.displayName = 'MenuItem'
MenuItem.defaultProps = {
  icon: 'svg/material-design-icons/hardware/keyboard_arrow_right',
  iconColor: 'icon',
  iconPosition: 'right',
  active: false,
  divider: true
}
MenuItem.propTypes = {
  /**
   * An additional custom className for the root element
   */
  className: PropTypes.string,
  /**
   * The icon button color
   */
  iconColor: PropTypes.oneOf([
    '',
    'icon',
    'action',
    'primary',
    'secondary',
    'tertiary',
    'default'
  ]),
  /**
   * The svg icon to show for the left button
   */
  icon: PropTypes.oneOf(['', ...icons]),
  /**
   * The position of the icon
   */
  iconPosition: PropTypes.oneOf(['left', 'right']),
  /**
   * The menu item children (Contains a link or text if using the a mouse / touch event for navigation)
   */
  children: PropTypes.node,
  /**
   * If `true`, the menu item is active
   */
  active: PropTypes.bool,
  /**
   * If `true`, a bottom divider will be shown.
   */
  divider: PropTypes.bool,
  /**
   * The menu display mode
   */
  mode: PropTypes.oneOf(['sidebar', 'popout'])
}
export default MenuItem
