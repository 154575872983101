import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { stages } from './constants'
import { actions } from './BootstrapSlice'
import { getStage } from './BootstrapSelector'
import SpinnerPage from 'src/components/SpinnerPage'

const BootstrapPage = props => {
  const dispatch = useDispatch()
  const stage = useSelector(getStage)
  const configBootstrapped = useSelector(
    state => state?.configReducer?.bootstrapped
  )

  useEffect(() => {
    dispatch(actions.requestBootstrap())
  }, [])

  if (
    stage === stages.UNINITIALIZATED ||
    stage === stages.BOOTSTRAPPING ||
    !configBootstrapped
  ) {
    return <SpinnerPage />
  }

  if (stage === stages.INITIALIZED || stage === stages.FAILED) {
    return props.children
  }

  return <SpinnerPage />
}

export default React.memo(BootstrapPage)
