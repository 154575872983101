import { compose } from 'redux'
import { connect } from 'react-redux'
import { actions } from './LoginSlice'
import * as flags from 'src/features/asyncFlags/AsyncFlagsSelector'
import LoginPage from './components/LoginPage'
import { withServices } from '../../services/ServiceContext'

const mapStateToProps = state => ({
  error: flags.getError('passwordlessLogin')(state),
  busy: flags.isBusy('passwordlessLogin')(state)
})

const mapDispatchToProps = dispatch => ({
  requestPasswordlessLogin: email =>
    dispatch(actions.requestPasswordlessLogin(email))
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withServices(resolve => ({
    Piwik: resolve('Piwik')
  }))
)(LoginPage)
