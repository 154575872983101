import React from 'react'
import PropTypes from 'prop-types'
import StoryRiver from '../../components/StoryRiver'
import FeaturedStoryExcerpt from '../../components/FeaturedStoryExcerpt'
import commonStyles from '../../styles/common.module.sass'
import styles from './FeaturedRiver.module.sass'
import StorySpinner from '../../components/StorySpinner'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

const FeaturedRiver = ({
  view,
  error,
  loading,
  getArticleLink,
  onTypeClick,
  single
}) => {
  if (!view) return null
  if (loading) return <StorySpinner />
  if (error)
    return (
      <div className={commonStyles.storyError}>
        Error loading the articles: {view.error}
      </div>
    )
  return (
    <StoryRiver>
      <div className={cx(!single && styles.container)}>
        {view.map(story => (
          <FeaturedStoryExcerpt
            key={story.id}
            to={getArticleLink(story)}
            type={story.type}
            typeTo={`/river/type/${story.type}`}
            title={story.title}
            published={story.published}
            congress={story.congress}
            plus={story.flags.isPaid}
            image={story.image}
            onTypeClick={onTypeClick}
            source={story.source}
            verticalAlign={!single}
            featured={single}
          />
        ))}
      </div>
    </StoryRiver>
  )
}

FeaturedRiver.propTypes = {
  view: PropTypes.array,
  error: PropTypes.string,
  loading: PropTypes.bool,
  getArticleLink: PropTypes.func,
  onTypeClick: PropTypes.func,
  single: PropTypes.bool
}

export default FeaturedRiver
