import { useSelector } from 'react-redux'
import IAV from './children/IAV'
import Ad from 'src/features/ads/components/Ad'
import ShowForMediaSize from 'src/components/Responsive/ShowForMediaSize'
import styles from '../../HomePage.module.sass'

const HomeSidebarContent = () => {
  const homeMediumRectangleAd3 = useSelector(
    state => state?.adsReducer.homeMediumRectangle3 || null
  )

  return (
    <>
      <ShowForMediaSize showFor={['large']}>
        <IAV />

        {homeMediumRectangleAd3 && (
          <Ad {...homeMediumRectangleAd3} className={styles.homeRectangleAd} />
        )}
      </ShowForMediaSize>
    </>
  )
}

export default HomeSidebarContent
