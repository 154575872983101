import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  requestUpdateDeliveryTime,
  openUpdateDeliveryTime,
  closeUpdateDeliveryTime
} from '../MyNewslettersSlice'
import Newsletter from '../components/Newsletter'

const mapStateToProps = state => ({
  deliveryTime: state.myNewslettersReducer.newsletterSettings.delivery_time,
  region: state.session.user?.region
})

const mapDispatchToProps = dispatch => ({
  updateDeliveryTime: values => {
    const { newsletterUuid, deliveryTime } = values
    dispatch(
      requestUpdateDeliveryTime({
        newsletterUuid,
        deliveryTime
      })
    )
  },
  openUpdateDeliveryTime: newsletterUuid => {
    dispatch(openUpdateDeliveryTime(newsletterUuid))
  },
  closeUpdateDeliveryTime: newsletterUuid => {
    dispatch(closeUpdateDeliveryTime(newsletterUuid))
  }
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(Newsletter)
