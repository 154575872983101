import { createSlice } from '@reduxjs/toolkit'

const initialState = { fetching: true, faqs: [] }

const faqSlice = createSlice({
  name: 'faqReducer',
  initialState,
  reducers: {
    requestFaq: state => {
      state.fetching = true
    },
    requestSuccess: (state, { payload }) => {
      state.fetching = false
      state.faqs = payload
    },
    requestFail: state => {
      state.fetching = false
      state.faqs = []
    }
  }
})

export const { actions, reducer } = faqSlice
export const { requestFaq, requestSuccess, requestFail } = actions
export default reducer
