import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Typography from 'src/lib/ui/Typography'
import Footnote from 'src/lib/ui/Footnote'
import PaymentForm from '../PaymentForm'
import commonStyles from 'src/styles/common.module.sass'
import cx from 'classnames'
import styles from './PaymentPage.module.sass'
import { history } from 'src/app/Router'
import { getCurrentYear, historyHandler } from 'src/utils'
import ReactHtmlParser from 'react-html-parser'
import { getConfig } from 'src/features/config/ConfigSelector'
import PayWallPageStyles from 'src/features/benefits/components/PayWallPage/PayWallPage.module.sass'
import PayWallSection from 'src/features/benefits/components/PayWallPage/PayWallSection.js'

const PaymentPage = props => {
  if (props.success) {
    historyHandler('/checkout/confirmation', '', history, 'push')
  }

  const webpageContentConfig = useSelector(getConfig('webpage_content')) ?? {}
  const paywallConfig = webpageContentConfig?.checkout?.paywall ?? {}
  const descriptionListItems = paywallConfig?.list_description ?? []
  const contactInfo = paywallConfig?.contact_description ?? null

  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <div className={styles.container}>
          <PayWallSection
            plus
            subheading={props?.price ? `$ ${props.price} / Year` : ''}
          >
            <ul className={PayWallPageStyles.list}>
              {descriptionListItems.map((item, index) => (
                <li
                  className={PayWallPageStyles.item}
                  key={`checkout-desc-item-${index}`}
                >
                  {item}
                </li>
              ))}
            </ul>
          </PayWallSection>

          <Footnote textClassName="Footnote" className={styles.containerCenter}>
            <div className={styles.footnotes}>
              {contactInfo && ReactHtmlParser(contactInfo)}
              <p>Conditions of Use | Privacy Notice</p>
              <p>&copy; {getCurrentYear()} FirstWord. All rights reserved</p>
            </div>
          </Footnote>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.container}>
          <Typography
            type="headline"
            gutterBottom
            className={cx(commonStyles.headline, styles.title)}
          >
            Pay with card
          </Typography>
          <PaymentForm {...props} />
        </div>
      </div>
    </div>
  )
}

PaymentPage.propTypes = {
  success: PropTypes.bool,
  price: PropTypes.string
}

export default PaymentPage
