import { createSlice } from '@reduxjs/toolkit'

const createFlags = (method, payload = null) => {
  switch (method) {
    case 'request':
      return {
        busy: true,
        error: null
      }
    case 'error':
      return {
        busy: false,
        error: payload
      }

    default:
      return undefined
  }
}

const asyncFlagsSlice = createSlice({
  name: 'asyncFlagsReducer',
  initialState: {},
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(
      action => {
        return action?.meta?.asyncFlags && action?.meta?.asyncKey
      },
      (state, action) => {
        const { payload = null, meta } = action
        const flagState = createFlags(meta?.method, payload)
        state[meta?.asyncKey] = flagState
      }
    )
  }
})

export default asyncFlagsSlice.reducer
