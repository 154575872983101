import { isEmpty } from '../../utils'
import { getMiscEndpoint, dougallEndpoints, piwikSiteId } from '../../constants'

const DougallService = (DougallML, DougallController, DougallParser) => ({
  /**
   * Chat Prompt Request as a Stream
   * @param { object } payload
   * @returns response object
   */
  async promptStreamRequest(payload) {
    const { applicationLinkedPromptId: linkedPromptId, hcpPrompt } = payload

    const response = await DougallML.sseFetch(
      process.env.NODE_ENV !== 'development'
        ? dougallEndpoints.gptStreamPrompt
        : 'http://localhost:3005/v2/gpt/prompt',
      'POST',
      { ...payload }
    )

    if (response) {
      DougallController.handleStreamAnswer({
        response,
        linkedPromptId,
        hcpPrompt
      })
    }

    return response
  },
  async historyRequest(deviceId = undefined) {
    const source = parseInt(piwikSiteId)
    const response = await DougallML.fetch(
      dougallEndpoints.gptHistory,
      'POST',
      {
        pagination: {
          page: 1,
          setSize: 10
        },
        deviceId,
        source
      }
    )

    if (!isEmpty(response?.data)) {
      return {
        raw: response.data.responseData?.history || [],
        history: DougallParser.historyParser(response.data.responseData)
      }
    }
    return {
      raw: [],
      history: []
    }
  },
  /**
   * Fetch prompt thread
   * @param {*} deviceId
   * @param {*} promptId parent/linked promptId
   * @returns parsed response
   */
  async promptHistoryRequest(
    deviceId = undefined,
    linkedPromptId = undefined,
    promptId = undefined,
    sharedPrompt = undefined
  ) {
    const source = parseInt(piwikSiteId)
    const response = await DougallML.fetch(
      dougallEndpoints.gptHistory,
      'POST',
      {
        deviceId,
        applicationLinkedPromptId: linkedPromptId,
        applicationPromptId: promptId,
        sharedPrompt,
        source
      }
    )

    if (!isEmpty(response?.data)) {
      return DougallParser.promptHistoryParser(
        response.data.responseData.history
      )
    }
    return {}
  },
  /**
   * Delete single history prompt
   * @param {*} promptId
   * @param {*} deviceId
   * @returns
   */
  async deleteHistoryPrompt(promptId, deviceId = undefined, source) {
    const response = await DougallML.fetch(dougallEndpoints.gptHistory, 'PUT', {
      applicationPromptId: promptId,
      deviceId,
      source
    })

    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  /**
   * FAQ request
   * @param {*} payload
   * @returns
   */
  async faqRequest(payload) {
    const response = await DougallML.fetch(getMiscEndpoint, 'POST', {
      ...payload
    })

    if (!isEmpty(response?.data)) {
      return response.data
    }
    return {}
  },
  /**
   * FAQ request
   * @param {*} payload
   * @returns
   */
  async liveFeedRequest(payload) {
    const response = await DougallML.fetch(getMiscEndpoint, 'POST', {
      ...payload
    })

    if (!isEmpty(response?.data)) {
      return response.data
    }
    return {}
  },
  /**
   * Popular Prompts get request
   * @param {*} promptId
   * @returns
   */
  async popularPromptsRequest(payload) {
    const response = await DougallML.fetch(
      // dougallEndpoints.gptPopularPrompts,
      getMiscEndpoint,
      'POST',
      { ...payload }
    )

    if (!isEmpty(response?.data)) {
      // return response.data.responseData
      return response.data
    }
    return {}
  },
  /**
   * Dougall MD
   * request for extra tag buttons in feed
   */
  async referenceUrlsRequest(payload) {
    const response = await DougallML.fetch(
      dougallEndpoints.gptReferenceUrls,
      'POST',
      { ...payload }
    )

    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  /**
   * Answer Action Request
   * @param {} payload
   * @returns response
   */
  async answerActionRequest(payload) {
    const response = await DougallML.fetch(
      dougallEndpoints.gptAnswerAction,
      'POST',
      payload
    )

    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  /**
   * Answer Feedback Submit
   * @param {} payload
   * @returns response
   */
  async answerFeedbackSubmit(payload) {
    const response = await DougallML.fetch(
      dougallEndpoints.gptAnswerFeedback,
      'POST',
      DougallParser.feedbackRequestParser(payload)
    )

    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  /**
   * Landing Misc request
   * @param {*} payload
   * @returns
   */
  async landingRequest(payload) {
    const response = await DougallML.fetch(getMiscEndpoint, 'POST', {
      ...payload
    })

    if (!isEmpty(response?.data)) {
      return response.data
    }
    return {}
  },
  /**
   * History Title Change Request
   * @param {*} payload
   * @returns
   */
  async titleChangeRequest(payload) {
    const response = await DougallML.fetch(
      dougallEndpoints.gptPromptTitle,
      'POST',
      { ...payload }
    )

    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  /**
   * Prompt Share Request
   * @param {*} payload
   * @returns
   */
  async promptShareRequest(payload) {
    const response = await DougallML.fetch(
      dougallEndpoints.gptPromptShare,
      'POST',
      DougallParser.shareRequestParser(payload)
    )

    if (!isEmpty(response?.data)) {
      return response.data
    }
    return {}
  },
  /**
   * Dougall MD
   * Follow Up Prompts Request
   * @param {*} payload
   * @returns
   */
  async followUpPromptsRequest(payload) {
    try {
      const response = await DougallML.fetch(
        dougallEndpoints.gptNonStreamPrompt,
        'POST',
        { ...payload }
      )

      if (!isEmpty(response?.data)) {
        return response.data.responseData.result
      }
      return {}
    } catch (error) {
      console.error(error)
      return { followUpPrompts: [] }
    }
  },
  /**
   * Dougall MD
   * Related Links Request
   * @param {*} payload
   * @returns
   */
  async relatedLinksRequest(payload) {
    const response = await DougallML.fetch(getContentTagsEndpoint, 'POST', {
      ...payload
    })

    if (!isEmpty(response?.data)) {
      return response.data
    }
    return {}
  }
})

export default DougallService
