import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  rightMenuOpen: false,
  leftMenuOpen: false
}

const mobileMenuSlice = createSlice({
  name: 'mobileMenuReducer',
  initialState,
  reducers: {
    toggleRightMenu: state => {
      state.rightMenuOpen = !state.rightMenuOpen
      state.leftMenuOpen = false
    },
    toggleLeftMenu: state => {
      state.leftMenuOpen = !state.leftMenuOpen
      state.rightMenuOpen = false
    },
    closeLeftMenu: state => {
      state.leftMenuOpen = false
    }
  }
})

export const { actions, reducer } = mobileMenuSlice
export const { toggleRightMenu, toggleLeftMenu, closeLeftMenu } = actions
export default reducer
