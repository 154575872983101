import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { SessionContext } from 'src/features/session/SessionContext'
import interestStyles from 'src/components/Interest/Interest.module.sass'
import Button from 'src/lib/ui/Buttons/Button'
import { Link } from 'react-router-dom'
import Icon from 'src/lib/ui/Icon'
import classNames from 'classnames/bind'
import { getConfig } from 'src/features/config/ConfigSelector'
import { actions } from 'src/features/singleStory/SingleStorySlice'
import { piwikRequest } from 'src/features/piwik/PiwikSlice'

const cxInterests = classNames.bind(interestStyles)

const NotifyMe = ({ article = {}, onParentClick = () => {} }) => {
  const dispatch = useDispatch()
  const { user = {} } = useContext(SessionContext) || {}
  const notifyMeSettings = useSelector(getConfig('notify_me_settings')) || {}

  const notifyMeResultArticle =
    useSelector(state => state.singleStoryReducer?.notifyMeResultArticle) ||
    null
  const { newsletter_notify_me: newsletterNotifyMe = {} } = notifyMeSettings

  const fetchNotifyMeResults = data =>
    dispatch(actions.requestNotifyMeResults(data))

  const notifyMeSubscribe = data =>
    dispatch(actions.requestNotifyMeSubscribe(data))

  const handleTracking = (category, action, name) => {
    // Piwik tracking
    dispatch(
      piwikRequest({
        customVarUpdate: {
          key: 'article_id',
          value: article?.id
        },
        tracking: {
          category,
          action,
          name
        }
      })
    )
  }

  const handleNotifyMeClick = () => {
    if (!newsletterNotifyMe?.newsletter_uuid || !article?.id) return
    onParentClick()
    notifyMeSubscribe({
      newsletterUuid: newsletterNotifyMe?.newsletter_uuid,
      articleId: article?.id
    })
    handleTracking('article', 'button-click', 'notify-me-of-the-results')
  }

  useMemo(() => {
    if (!article?.id) return
    const foundMapping = newsletterNotifyMe?.mapping?.find(item =>
      item?.preview_story_type?.includes(article?.type)
    )
    if (foundMapping) {
      fetchNotifyMeResults({
        article,
        resultType: foundMapping.results_story_type
      })
    }
  }, [article?.id])

  const notifyMeComponent = useMemo(() => {
    // Return if no article props have been passed
    if (!article?.id || !article?.type || notifyMeResultArticle?.loading)
      return null

    // Article story type matches the criteria of notify me possible story types to show the button
    if (
      newsletterNotifyMe?.mapping?.find(item =>
        item?.preview_story_type?.includes(article?.type)
      )
    ) {
      if (notifyMeResultArticle?.story?.id) {
        return (
          <Link to={`/story/${notifyMeResultArticle?.story?.id}`}>
            <Button
              type="submit"
              tier="secondary"
              textCase="none"
              onClick={() =>
                handleTracking('article', 'button-click', 'view-results-now')
              }
            >
              View Results Now
            </Button>
          </Link>
        )
      }

      // User is already subscribed to by notified of results
      if (
        user?.notifyMe?.find(item =>
          item?.preview_article_ids?.includes(article?.id)
        )
      )
        return (
          <div style={{ marginBottom: '10px' }}>
            <div className={cxInterests(interestStyles.interest, 'inline')}>
              <Icon
                className={cxInterests(interestStyles.icon, 'disabled')}
                icon="svg/material-design-icons/navigation/check"
                colorTier="default"
                style={{ marginRight: '5px' }}
              />
              <span className={interestStyles.simpleTag}>
                You will be notified via email when the results are published
              </span>
            </div>
          </div>
        )

      return (
        <div style={{ marginBottom: '10px' }}>
          <Button
            type="submit"
            tier="secondary"
            textCase="none"
            onClick={() => handleNotifyMeClick()}
          >
            Notify me of the results
          </Button>
        </div>
      )
    }

    return null
  }, [user?.notifyMe, notifyMeSettings, notifyMeResultArticle])

  return notifyMeComponent || null
}

NotifyMe.propTypes = {
  article: PropTypes.object,
  onParentClick: PropTypes.func
}

export default NotifyMe
