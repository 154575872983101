import config from 'src/config/fw-ai/site.config.json'
import { idhBrandId, phnxSiteIDv1 } from './index'

const apiContent = process.env.REACT_APP_PHNX_API_CONTENT
const apiUser = process.env.REACT_APP_PHNX_API_USER
const apiToken = process.env.REACT_APP_PHNX_API_AUTH
const apiUGC = process.env.REACT_APP_PHNX_API_UGC
const CDN = process.env.REACT_APP_PHNX_CDN
const deviceIDScript = process.env.REACT_APP_DEVICE_ID
const apiDougall = process.env.REACT_APP_PHNX_API_DOUGALL
/**
 * CONTENT
 */
const feedBuilderEndpoint = `${apiContent}${config.sections.siteRiver.endpoint}`
const articleUrlEndpoint = `${apiContent}/fb/search/article/url`
const openSearchEndpoint = `${apiContent}/fb/search/open`
const querySearchEndpoint = `${apiContent}/fb/search/query`
const getMiscEndpoint = `${apiContent}/content/misc`
const getContentTagsEndpoint = `${apiContent}/content/tags`
/**
 * UGC
 */
const commentsEndpoint = `${apiUGC}/ugc/comments`
const scoreEndpoint = `${apiUGC}/ugc/score`

/**
 * CDN
 */
const publishersListEndpoint = `${CDN}/assets/newsletter/dg/journal_logos/publishers.json`
const localeEndpoint = `${CDN}${config.configuration.localization.localeResource}`

/**
 * Device ID
 */
const deviceIdEndpoint = deviceIDScript

/**
 * FirstWord AI
 */
const dougallEndpoints = {
  gptStreamPrompt: `${apiDougall}v2/gpt/prompt`,
  gptHistory: `${apiDougall}v2/gpt/prompt/history`,
  gptReferenceUrls: `${apiDougall}v2/gpt/reference-urls`,
  gptAnswerAction: `${apiDougall}v2/gpt/actions`,
  gptAnswerFeedback: `${apiDougall}v2/gpt/actions/feedback`,
  gptPromptTitle: `${apiDougall}v2/gpt/prompt/title`,
  gptPromptShare: `${apiDougall}v2/gpt/prompt/share`,
  gptNonStreamPrompt: `${apiDougall}v2/gpt/ns-prompt`
}

const authEndpoints = {
  /**
   * USER
   */
  loginEndpoint: `${apiUser}v2/user/login`,
  loginWithEmailEndpoint: `${apiUser}v2/user/otp`,
  logoutEndpoint: `${apiUser}v2/user/logout`,
  profileEndpoint: `${apiUser}v2/user/profile/?idhBrandId=${idhBrandId}`,
  updateProfileEndpoint: `${apiUser}v2/user/profile/?idhBrandId=${idhBrandId}&tokenGen=true`,
  unsubscribeEndpoint: `${apiUser}v2/user/unsubscribe`,
  feedbackEndpoint: `${apiUser}v2/user/feedback`,
  contactUsEndpoint: `${apiUser}v2/user/contact-us`,
  registrationEndpoint: `${apiUser}v2/user/registration`,
  userVerifyEndpoint: `${apiUser}v2/user/verify`,
  sendEmailEndpoint: `${apiUser}v2/user/share`,

  /**
   * AUTH
   */
  tokenGetEndpoint: `${apiToken}v2/auth/token`,
  tokenVerifyEndpoint: `${apiToken}v2/auth/token/verify`,
  tokenRefreshEndpoint: `${apiToken}v2/auth/token/refresh`
}

const endpointVersions = {
  /**
   * Version 2 of user and auth endpoints
   */
  V2: {
    /**
     * USER
     */
    loginEndpoint: `${apiUser}v2/user/login`,
    loginWithEmailEndpoint: `${apiUser}v2/user/otp`,
    logoutEndpoint: `${apiUser}v2/user/logout`,
    profileEndpoint: `${apiUser}v2/user/profile/?idhBrandId=${idhBrandId}`,
    updateProfileEndpoint: `${apiUser}v2/user/profile/?idhBrandId=${idhBrandId}&tokenGen=true`,
    unsubscribeEndpoint: `${apiUser}v2/user/unsubscribe`,
    feedbackEndpoint: `${apiUser}v2/user/feedback`,
    userVerifyEndpoint: `${apiUser}v2/user/verify`,

    /**
     * AUTH
     */
    tokenGetEndpoint: `${apiToken}v2/auth/token`,
    tokenVerifyEndpoint: `${apiToken}v2/auth/token/verify`,
    tokenRefreshEndpoint: `${apiToken}v2/auth/token/refresh`
  },

  /**
   * Version 1 of user and auth endpoints
   */
  V1: {
    /**
     * USER
     */
    loginEndpoint: `${apiUser}v1/user/login`,
    loginWithEmailEndpoint: `${apiUser}v1/user/otp?siteId=${phnxSiteIDv1}`,
    logoutEndpoint: `${apiUser}v1/user/logout`,
    profileEndpoint: `${apiUser}v1/user/profile/?idhBrandId=${idhBrandId}`,
    updateProfileEndpoint: `${apiUser}v1/user/profile/?idhBrandId=${idhBrandId}&tokenGen=true`,
    unsubscribeEndpoint: `${apiUser}v1/user/unsubscribe`,
    feedbackEndpoint: `${apiUser}v1/user/feedback`,
    userVerifyEndpoint: `${apiUser}v1/user/verify`,

    /**
     * AUTH
     */
    tokenGetEndpoint: `${apiToken}v1/auth/token/${phnxSiteIDv1}`,
    tokenVerifyEndpoint: `${apiToken}v1/auth/token/verify/${phnxSiteIDv1}`,
    tokenRefreshEndpoint: `${apiToken}v1/auth/token/refresh/${phnxSiteIDv1}`
  }
}

export {
  dougallEndpoints,
  authEndpoints,
  CDN,
  getMiscEndpoint,
  endpointVersions,
  scoreEndpoint,
  feedBuilderEndpoint,
  articleUrlEndpoint,
  openSearchEndpoint,
  querySearchEndpoint,
  commentsEndpoint,
  publishersListEndpoint,
  deviceIdEndpoint,
  localeEndpoint,
  getContentTagsEndpoint
}
