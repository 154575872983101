import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './PrestitialAd.module.sass'
import SimpleHeader from 'src/components/SimpleHeader'
import Ad from 'src/features/ads/components/Ad'
import Typography from 'src/lib/ui/Typography'
import { getPageFound } from 'src/features/config/utils'
import ShowForMediaSize from 'src/components/Responsive/ShowForMediaSize.js'
import Modal from 'src/components/Modal'
import { PRESTITITAL_AD_COOKIE_EXPIRE_MINUTES } from 'src/constants'
import commonStyles from 'src/styles/common.module.sass'
import cx from 'classnames'
import { getAxiosRequest } from 'src/utils'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { getConfig } from 'src/features/config/ConfigSelector'

const AdModal = ({ prestitialAd = {}, hidePrestitialAd }) => {
  const timer = 15
  const [counter, setCounter] = useState(timer)

  // Timer logic
  useEffect(() => {
    const timer =
      counter >= 0 &&
      setInterval(() => setCounter(counter => counter - 1), 1000)

    if (counter <= 0) {
      hidePrestitialAd()
    }
    return () => clearInterval(timer)
  }, [counter])

  // Return Ad
  return (
    <div>
      <SimpleHeader />
      <Modal
        className={styles.modal}
        containerClassName={styles.modalContainer}
      >
        <div
          className={cx(
            commonStyles.content,
            commonStyles.centered,
            styles.container
          )}
        >
          <Ad {...prestitialAd} onAdClick={hidePrestitialAd} />
          <div className={styles.description}>
            <div className={styles.descriptionRedirect}>
              <Typography
                type="title"
                gutterBottom
                onClick={() => hidePrestitialAd()}
              >
                Continue to site{' '}
              </Typography>
              <img
                src="https://freepngimg.com/thumb/arrow/131125-arrow-free-download-png-hq.png"
                alt="continue"
              />
            </div>

            <div className={styles.descriptionRedirectCounter}>
              <Typography type="body1" gutterBottom>
                Redirect to site in {counter} seconds
              </Typography>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

AdModal.propTypes = {
  prestitialAd: PropTypes.object,
  hidePrestitialAd: PropTypes.func
}

const PrestitialAd = ({
  prestitialAd = {},
  services = {},
  resetPrestitialAd = () => {}
}) => {
  const [clientIp, setClientIp] = useState('')
  const [prestitialAdPageFound, setPrestitialAdPageFound] = useState(null)
  const adConfig = useSelector(getConfig('ip_ad_whitelist')) || {}
  const ipApiUrl = adConfig.getClientIpUrl
  const cookie =
    services?.Session?.getCookie(adConfig?.prestitialAd?.cookieName) || null

  //If the user's IP is whitelisted, the ad won't get rendered
  const preventAdRender = (adConfig?.ips || []).some(whiteListedIp => {
    //Removes the slash at the end of some whitelisted ip's */
    const whiteListedIpAddress = whiteListedIp.split('/')[0]
    //Returns true if it finds the clientIp on the string
    return _.startsWith(clientIp, whiteListedIpAddress)
  })

  const getClientIp = async () => {
    const res = await getAxiosRequest(ipApiUrl)
    setClientIp(res?.ip || '')
  }

  const hidePrestitialAd = () => {
    resetPrestitialAd()
    if (!cookie) setCookieExpiry()
  }

  const setCookieExpiry = () => {
    if (prestitialAd?.emptyContent === false && services.Session) {
      const minutes =
        prestitialAd.expiry_cookie_minutes ||
        PRESTITITAL_AD_COOKIE_EXPIRE_MINUTES

      const cookieExpiredDate = new Date(
        new Date().getTime() + minutes * 60 * 1000
      )

      services.Session.setCookie(adConfig?.prestitialAd?.cookieName, true, {
        expires: cookieExpiredDate
      })
    }
  }

  // We obtain the user's IP once the component begins to render
  useEffect(() => {
    getClientIp()
  }, [])

  // Ad render logic
  useEffect(() => {
    if (prestitialAd?.ready) {
      const pageFound = getPageFound(
        prestitialAd?.emptyContent === false ? prestitialAd.pagesToInclude : []
      )
      setPrestitialAdPageFound(!!pageFound)
    }
  }, [prestitialAd?.ready])

  if (
    cookie ||
    prestitialAdPageFound === false ||
    preventAdRender ||
    !prestitialAd?.ready ||
    (prestitialAd?.ready && prestitialAd?.emptyContent)
  ) {
    return null
  }

  // Return Ad
  return (
    <ShowForMediaSize showFor={['large']}>
      <AdModal
        prestitialAd={prestitialAd}
        hidePrestitialAd={hidePrestitialAd}
      />
    </ShowForMediaSize>
  )
}

PrestitialAd.propTypes = {
  prestitialAd: PropTypes.object,
  services: PropTypes.object,
  resetPrestitialAd: PropTypes.func
}

export default React.memo(PrestitialAd)
