import React from 'react'
import { Route, Switch, Redirect } from 'react-router'
import { withProtectionAndRedirection } from '../../components/LicenseProtected'
import { licenseTypes } from '../../services/FW5ML/parsers/UserParser'
import RiverPage from './RiverPage'
import MyReports from '../myReports/MyReportsPage'

const RiverRoutes = () => (
  <Switch>
    {/* Main routes */}
    <Route
      exact
      path="/river/:key(my-fw)/:args*"
      component={withProtectionAndRedirection(
        withProtectionAndRedirection(RiverPage, {
          showFor: {
            license: licenseTypes.LICENSE_PLUS,
            paywall: 'plus_only',
            subtype: 'my_fw_stories'
          }
        }),
        {
          showFor: {
            authenticated: true,
            license: licenseTypes.LICENSE_PLUS,
            paywall: 'plus_only',
            subtype: 'my_fw_stories'
          },
          redirectForOthers: '/'
        }
      )}
    />

    <Route
      exact
      path="/river/:key(your-reports)/:args*"
      component={MyReports}
    />

    <Route
      exact
      path="/river/:key(story-watch|events|resources)/:args*"
      component={RiverPage}
    />
    <Route
      exact
      path="/river/:key(top-stories|iav|conference-news|related-news|related-analysis|tag|type|recent|all-reports|upcoming)/:args*"
      component={RiverPage}
    />

    {/* Search */}
    <Route exact path="/search" component={RiverPage} />
    <Route exact path="/search/:key/:args*" component={RiverPage} />

    {/* Redirect any legacy route */}
    <Redirect
      exact
      from="/stories/:key/:args*"
      to={`/river/:key/:args*${window.location.search}`}
    />

    {/* Defaults */}
    <Route exact path="/river/:key/:args*" component={RiverPage} />
  </Switch>
)

export default RiverRoutes
