import { getAxiosRequest } from 'src/utils'
import { arrayify } from 'src/utils'

class AiMatch {
  constructor(config, getState, AiMatchParser) {
    this.config = config
    this.getState = getState
    this.parser = AiMatchParser
  }

  waitForState(fn) {
    return new Promise(resolve => {
      let timeSpent = 0
      const timer = setInterval(() => {
        const test = fn(this.getState())
        if (!!test || timeSpent >= 5000) {
          clearInterval(timer)
          resolve()
        }
        timeSpent += 100
      }, 100)
    })
  }

  async getRequestUrl(config) {
    if (config.waitForState) {
      await this.waitForState(config.waitForState)
    }
    const params = await this.parser.buildParams({
      aiMatchSite: this.config.AI_MATCH_SITE,
      getState: this.getState,
      ...config
    })
    return `${this.config.AI_MATCH_BASE_URL}/${params}`
  }

  async getAimatchAdProperties(ad, aimatchURL, profile) {
    const data = await getAxiosRequest(aimatchURL)
    const mediaTypes = arrayify(ad?.mediaType)
    let adFound = null

    for (let mediaType of mediaTypes) {
      if (!adFound || adFound?.emptyContent) {
        switch (mediaType) {
          case 'image':
            if (typeof data !== 'string') return {}
            adFound = await this.parser.parseImageAd({ ad, profile, data })
            break
          case 'iframe':
            adFound = await this.parser.parseIframeAd({ profile, ...data })
            break
          case 'html':
            adFound = await this.parser.parseHTMLAd({ data })
            break
          case 'json':
            adFound = await this.parser.parseJSONAd({ data })
            break
        }
      }
    }

    return adFound || {}
  }
}

export default AiMatch
