import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import Page from 'src/components/Page'
import commonStyles from 'src/styles/common.module.sass'
import SingleStory from 'src/components/SingleStory'
import Metadata from 'src/components/Metadata'
import { getStory } from 'src/features/singleStory/SingleStorySelector'
import PropTypes from 'prop-types'

const SimpleStoryPage = ({ match, fromXML }) => {
  const id = match?.params?.id
  const story = useSelector(getStory())
  const [renderPage, setRenderPage] = useState(false)

  useEffect(() => {
    if (fromXML) {
      // Using window.location.href to push and reload the page, in order to process the auth token
      window.location.href = `/print/${id}?t=${match?.params?.login}`
    } else {
      setRenderPage(true)
    }
  }, [])

  if (!renderPage) {
    return <></>
  }
  return (
    <Page simplePage>
      <Metadata
        title={story?.title}
        description={story?.teaser}
        image={story?.image}
      />

      <div
        className={commonStyles.content}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <section className={commonStyles.main}>
          <SingleStory id={id} simplePage={true} />
        </section>
      </div>
    </Page>
  )
}

SimpleStoryPage.propTypes = {
  match: PropTypes.object,
  fromXML: PropTypes.bool
}

export default SimpleStoryPage
