import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useMediaSize from 'src/hooks/useMediaSize'
import styles from './SearchBar.module.sass'
import classNames from 'classnames/bind'
import Button from 'src/lib/ui/Buttons/Button'
import { licenseTypes } from 'src/services/FW5ML/parsers/UserParser'
import { SessionContext } from 'src/features/session/SessionContext'
import SearchBarOptions from './SearchBarOptions'
import { reset } from 'redux-form'
import { actions } from 'src/features/river/RiverSlice'
import { actions as savedSearchActions } from 'src/features/mySavedSearches/MySavedSearchesSlice'
import * as selectors from 'src/features/river/RiverSelector'
import { push } from 'connected-react-router'
import usePiwik from 'src/hooks/usePiwik'

const cx = classNames.bind(styles)

function SearchBar({ toggleFilters, articlesLoaded, riverId }) {
  const large = useMediaSize() === 'large'
  const dispatch = useDispatch()
  const { handleTracking } = usePiwik()
  const { userHasLicense = () => false } = useContext(SessionContext)

  const hndSortClick = sort => e => {
    const sortUrlParam = sort === 'publication_date' ? 'date' : 'relevancy'

    const currentParams = new URLSearchParams(window.location.search)

    currentParams.set('sort', sortUrlParam)

    const updatedUrl = `${window.location.pathname}?${currentParams.toString()}`

    dispatch(push(updatedUrl))
    dispatch(actions.setSorting({ riverId, sort }))
  }

  const sortBy = useSelector(selectors.getSorting)
  const riverArgs = useSelector(selectors.getRiverArgs)
  const searchBody = useSelector(selectors.getSearchBody)

  const hndSaveClick = () => {
    dispatch(
      savedSearchActions.requestSavedSearchSave({
        name: riverArgs,
        search: searchBody
      })
    )
    handleTracking('search', 'save', searchBody?.query)
  }

  const cleanSearchForm = () => {
    dispatch(reset('search'))
    dispatch(push('/search'))
  }

  return (
    <div className={styles.bar}>
      <div className={styles.buttons}>
        <Button
          minWidth="0"
          iconLeft="svg/material-design-icons/content/add_circle"
          tier="action"
          raised={false}
          className={cx(styles.button, {
            disabled: !userHasLicense(licenseTypes.LICENSE_PLUS)
          })}
          textCase="none"
          onClick={hndSaveClick}
        >
          Save
        </Button>
        {!large && articlesLoaded && (
          <Button
            minWidth="0"
            iconLeft="svg/custom/filter"
            tier="action"
            raised={false}
            className={styles.filterButton}
            textCase="none"
            onClick={toggleFilters}
          >
            Filter
          </Button>
        )}

        {articlesLoaded && (
          <Button
            minWidth="0"
            iconLeft="svg/material-design-icons/action/search"
            data-piwik-class="new-search"
            tier="action"
            raised={false}
            className={styles.button}
            onClick={cleanSearchForm}
            textCase="none"
          >
            New
          </Button>
        )}
      </div>
      {articlesLoaded && (
        <SearchBarOptions
          options={{
            _score: 'Relevancy',
            publication_date: 'Date'
          }}
          value={sortBy}
          onClick={hndSortClick}
        />
      )}
    </div>
  )
}

export default SearchBar
