import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { styled } from '@mui/material/styles'

import Modal from 'src/components/DougallModal'
import Typography from 'src/lib/ui/Typography'
import { TraditionalInput } from 'src/lib/ui/Input'

import styles from './FeedbackModal.module.sass'

const altButtonTheme = {
  marginBottom: '0',
  fontSize: '14px',
  fontWeight: 'bold',
  lineHeight: '1.43',
  paddingTop: '8px',
  paddingBottom: '8px',
  height: '36px'
}

/**
 * Feedback modal input
 * theme path: EmailModal.styleOverrides.modalTitle
 */
const FeedBackModalInput = styled(TraditionalInput, {
  name: 'FeedbackModal',
  label: 'feedbackModalInput',
  overridesResolver: (props, styles) => [styles.inputContainer]
})(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '20px',
  padding: '21px 17px 21px 24px!important',
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: '30px !important'
  },
  '&:hover': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px !important'
    }
  },
  '&.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px !important'
    }
  },
  textarea: {
    maxHeight: '58px'
  }
}))

const FeedbackModal = ({
  open = false,
  onConfirm = () => {},
  onCancel = () => {},
  onTextAreaChange = () => {}
}) => {
  const { t } = useTranslation()
  const [shareText1, shareText2] = t('shareTitle').split(': ')

  return (
    <Modal
      open={open}
      actionCaption={t('inviteFormSubmit')}
      onCancel={onCancel}
      onConfirm={onConfirm}
      altButtonTheme={altButtonTheme}
    >
      <div className={styles.feedbackModal}>
        <Typography type="display2">{shareText1 ?? ''}:</Typography>
        <Typography type="display2">{shareText2 ?? ''}</Typography>
        <FeedBackModalInput
          data-testid="feedbackModalInput"
          name="feedback"
          label=""
          maxRows={4}
          minRows={4}
          multiline
          fullWidth
          sx={{ marginTop: '15px' }}
          placeholder={t('emailShareFormMessage')}
          onChange={onTextAreaChange}
          inputProps={{
            'data-testid': 'feedback-input'
          }}
        />
      </div>
    </Modal>
  )
}

FeedbackModal.propTypes = {
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  onTextAreaChange: PropTypes.func
}

export default FeedbackModal
