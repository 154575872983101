import React from 'react'
import PropTypes from 'prop-types'
import styles from './FilterList.module.sass'
import { filterLabelsConfigModule } from '../../../../config/sites/river'
import isEmpty from 'lodash/isEmpty'

const { getKey } = filterLabelsConfigModule

const FilterList = ({ tags, type: typeProp, publication_date: date }) => {
  const type = !isEmpty(typeProp) && typeProp
  const filters = { ...tags, type }
  return (
    (!isEmpty(tags) || type || date) && (
      <p className={styles.filters}>
        {date && (
          <span>
            <b>Date Range: </b>
            {`${date} `}
          </span>
        )}
        {Object.keys(filters).map(
          (category, index) =>
            !!filters[category] && (
              <span key={index}>
                <b>{getKey(category)}: </b>
                {`${filters[category].join(', ')}`}
              </span>
            )
        )}
      </p>
    )
  )
}
FilterList.propTypes = {
  /*
   * The filter tags
   */
  tags: PropTypes.object,
  /**
   * The filter type
   */
  type: PropTypes.array,
  /**
   * The filter date
   */
  publication_date: PropTypes.string
}
export default FilterList
