import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  newsletters: [],
  all_newsletters: [],
  subscribed: [],
  pendingForDeletion: [],
  pendingForInsertion: [],
  newsletterSettings: {
    delivery_time: [],
    busy: false,
    error: ''
  }
}

const myNewslettersSlice = createSlice({
  name: 'myNewslettersReducer',
  initialState,
  reducers: {
    requestListNewsletters: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'request',
          asyncKey: 'listNewsletters'
        }
      }),
      reducer: state => {
        state.newsletters = []
      }
    },
    successListNewsletters: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'success',
          asyncKey: 'listNewsletters'
        }
      }),
      reducer: (state, { payload }) => {
        state.newsletters = payload
      }
    },
    errorListNewsletters: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'error',
          asyncKey: 'listNewsletters'
        }
      }),
      reducer: () => {}
    },
    requestListAllNewsletters: state => {
      state.all_newsletters = []
    },
    successListAllNewsletters: (state, { payload }) => {
      state.all_newsletters = payload
    },
    errorListAllNewsletters: () => {},
    requestListSubscribedNewsletters: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'request',
          asyncKey: 'listSubscribedNewsletters'
        }
      }),
      reducer: state => {
        state.subscribed = []
      }
    },
    successListSubscribedNewsletters: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'success',
          asyncKey: 'listSubscribedNewsletters'
        }
      }),
      reducer: (state, { payload }) => {
        state.subscribed = payload
      }
    },
    errorListSubscribedNewsletters: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'error',
          asyncKey: 'listSubscribedNewsletters'
        }
      }),
      reducer: () => {}
    },
    requestDeleteNewsletter: (state, { payload }) => {
      state.pendingForDeletion.push(payload.id)
    },
    successDeleteNewsletter: (state, { payload }) => {
      state.pendingForDeletion = state.pendingForDeletion.filter(
        id => id !== payload.id
      )
      state.subscribed = state.subscribed.filter(
        newsletter => newsletter.id !== payload.id
      )
    },
    errorDeleteNewsletter: (state, { payload }) => {
      state.pendingForDeletion = state.pendingForDeletion.filter(
        id => id !== payload.id
      )
    },
    requestInsertNewsletter: (state, { payload }) => {
      state.pendingForInsertion.push(payload.id)
    },
    successInsertNewsletter: (state, { payload }) => {
      state.pendingForInsertion = state.pendingForInsertion.filter(
        id => id !== payload.id
      )
      state.subscribed.push(payload)
    },
    errorInsertNewsletter: (state, { payload }) => {
      state.pendingForInsertion = state.pendingForInsertion.filter(
        id => id !== payload.id
      )
    },
    requestInsertNewsletters: (state, { payload }) => {
      state.pendingForInsertion.push(payload.id)
    },
    successInsertNewsletters: (state, { payload }) => {
      state.pendingForInsertion = state.pendingForInsertion.filter(
        id => id !== payload.id
      )
      state.subscribed.push(payload)
    },
    errorInsertNewsletters: (state, { payload }) => {
      state.pendingForInsertion = state.pendingForInsertion.filter(
        id => id !== payload.id
      )
    },
    requestUnsubscribeNewsletters: (state, { payload }) => {
      const mappedIds = payload.map(n => n.id)
      state.pendingForDeletion = [
        ...new Set([...state.pendingForDeletion, ...mappedIds])
      ]
    },
    setNewsletterSettings: (state, { payload }) => {
      state.newsletterSettings = {
        ...state.newsletterSettings,
        ...payload.newsletterSettings
      }
    },
    requestUpdateDeliveryTime: state => {
      state.newsletterSettings.busy = true
    },
    successUpdateDeliveryTime: (state, { payload }) => {
      const { newsletterUuid, deliveryTime } = payload
      state.newsletterSettings.delivery_time =
        state.newsletterSettings.delivery_time.map(d =>
          d.newsletter_uuid === newsletterUuid
            ? { ...d, user_time: deliveryTime }
            : d
        )
      state.newsletterSettings.busy = false
      state.newsletterSettings.error = ''
    },
    errorUpdateDeliveryTime: (state, { payload }) => {
      state.newsletterSettings.busy = false
      state.newsletterSettings.error = payload.error
    },
    openUpdateDeliveryTime: (state, { payload }) => {
      state.newsletterSettings.delivery_time =
        state.newsletterSettings.delivery_time.map(d =>
          d.newsletter_uuid === payload ? { ...d, open: true } : d
        )
      state.newsletterSettings.busy = false
      state.newsletterSettings.error = ''
    },
    closeUpdateDeliveryTime: (state, { payload }) => {
      state.newsletterSettings.delivery_time =
        state.newsletterSettings.delivery_time.map(d =>
          d.newsletter_uuid === payload ? { ...d, open: false } : d
        )
      state.newsletterSettings.busy = false
      state.newsletterSettings.error = ''
    },
    handleFilterSelect: () => {}
  }
})

export const { actions, reducer } = myNewslettersSlice

export const {
  requestListNewsletters,
  successListNewsletters,
  errorListNewsletters,
  requestListAllNewsletters,
  successListAllNewsletters,
  errorListAllNewsletters,
  requestListSubscribedNewsletters,
  successListSubscribedNewsletters,
  errorListSubscribedNewsletters,
  requestDeleteNewsletter,
  successDeleteNewsletter,
  errorDeleteNewsletter,
  requestInsertNewsletter,
  successInsertNewsletter,
  errorInsertNewsletter,
  requestInsertNewsletters,
  successInsertNewsletters,
  errorInsertNewsletters,
  requestUnsubscribeNewsletters,
  setNewsletterSettings,
  requestUpdateDeliveryTime,
  successUpdateDeliveryTime,
  errorUpdateDeliveryTime,
  openUpdateDeliveryTime,
  closeUpdateDeliveryTime,
  handleFilterSelect
} = actions

export default reducer
