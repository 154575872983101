import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  path: ''
}

const loadingSlice = createSlice({
  name: 'loadingReducer',
  initialState,
  reducers: {
    setPathURL: (state, { payload: { search } }) => {
      state.path = search
    },
    pathReset: state => {
      state.path = ''
    },
    processExternalURL: () => {}
  }
})

export const { actions, reducer } = loadingSlice
export const { setPathURL, pathReset, processExternalURL } = actions
export default reducer
