import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styles from './NotifyUpdates.module.sass'
import RequestAccessModal from 'src/features/paywall/components/RequestModal'
import usePiwik from 'src/hooks/usePiwik'
import EmailConfirmation from 'src/features/paywall/components/EmailConfirmation'

export const NotifyUpdatesAndInvalid = ({ story }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const { handleTrackingWithArticleIdProp } = usePiwik()

  const handleOpenModal = () => {
    handleTrackingWithArticleIdProp(
      'pop-up',
      'view',
      'notify-me-anon_form',
      story.id
    )
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    handleTrackingWithArticleIdProp('pop-up', 'click', 'close-window', story.id)
    setModalOpen(false)
  }

  const withSubmitTracking = () => {
    handleTrackingWithArticleIdProp(
      'pop-up',
      'button-click',
      'notify-me-anon_form_submit',
      story.id
    )
  }

  const handleSubmitForm = () => {
    setModalOpen(false)
    setIsConfirmationModalOpen(true)
  }

  return (
    <>
      <button onClick={handleOpenModal} className={styles.notifySideButton}>
        Notify Me of Future Updates
      </button>
      <RequestAccessModal
        open={modalOpen}
        onCancel={handleCloseModal}
        onSuccess={handleSubmitForm}
        withSubmitTracking={withSubmitTracking}
      />
      <EmailConfirmation
        open={isConfirmationModalOpen}
        onCancel={() => setIsConfirmationModalOpen(false)}
      />
    </>
  )
}

NotifyUpdatesAndInvalid.propTypes = {
  story: PropTypes.object
}

export default NotifyUpdatesAndInvalid
