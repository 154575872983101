import React from 'react'
import { useSelector } from 'react-redux'
import { getLatestRelatedArticleDate } from 'src/features/singleStory/SingleStorySelector'
import Logo from 'src/components/Logo'
import Tag from 'src/components/Tag'
import FullImage from 'src/components/FullImage'
import Typography from 'src/lib/ui/Typography'
import styles from '../Article.module.sass'
import ActionButtons from 'src/features/actionButtons/ActionButtons'
import SaveButton from 'src/components/SaveButton'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

const ArticleHeader = ({
  story,
  likeCount,
  liked,
  onLikeUpdate,
  title,
  configSettings,
  simplePage = false
}) => {
  const latestArticleUpdateDate = useSelector(getLatestRelatedArticleDate())

  const getDisplayDate = () => {
    const publishedDate = story?.published
      ? dayjs.unix(story.published).utc().format('MMMM DD, YYYY')
      : null

    const updatedDate =
      latestArticleUpdateDate &&
      dayjs.unix(latestArticleUpdateDate).utc().format('MMMM DD, YYYY')

    return updatedDate || (latestArticleUpdateDate === null && publishedDate)
  }

  const displayDate = getDisplayDate()

  const {
    showActionBar = false,
    headerFlex = 'column',
    showUpdated = false,
    showTags = true,
    showRef = true
  } = configSettings

  return (
    <header>
      {simplePage && (
        <div className={styles.logoContainer}>
          <Logo className={styles.logo} />
        </div>
      )}
      <div className={headerFlex === 'row' ? styles.headerRowContainer : ''}>
        <div>
          <div className={styles.topActions}>
            {showTags && story?.type && (
              <Tag label={story.type} to={`/river/type/${story.type}`} />
            )}
            <SaveButton />
          </div>
          <Typography type="headline" gutterBottom className={styles.title}>
            {title}
          </Typography>
          <div
            className={headerFlex === 'row' ? styles.articleHeaderInline : ''}
          >
            {story?.author && (
              <div className={styles.author}>{story.author}</div>
            )}
            {displayDate && (
              <div className={styles.published}>
                {showUpdated ? 'Last Updated : ' : 'Published : '}
                {displayDate}
              </div>
            )}
            {showRef && story?.source && (
              <div className={styles.source}>Ref: {story.source}</div>
            )}
          </div>
          {!simplePage && showActionBar && (
            <div
              className={styles.actionButtonsContainer}
              style={{ marginBottom: '40px' }}
            >
              <ActionButtons
                learnMoreProps={{ active: false }}
                reportSettings={story?.reportSettings}
                articleId={story?.id}
                articleBody={story?.body}
                articleTitle={title}
                singleStory={true}
                likeProps={{
                  liked,
                  likeCount,
                  action: onLikeUpdate
                }}
              />
            </div>
          )}
        </div>
        {story?.image && (
          <div
            className={
              headerFlex === 'row'
                ? styles.imageContainerSmall
                : styles.imageContainer
            }
          >
            <FullImage
              src={story.image}
              width="100%"
              height="100%"
              objectFit="contain"
            />
          </div>
        )}
      </div>
    </header>
  )
}

export default ArticleHeader
