import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { getFetching } from './FaqSelector' // removed getFaqs

import PromptList from 'src/components/PromptList/PromptList'
import { Box, Typography, styled } from '@mui/material'
import { LiveHelpOutlined } from '@mui/icons-material'

import { getFaqs } from 'src/features/config/ConfigSelector'

const FaqAnswer = styled(Typography, {
  name: 'Faq',
  label: 'faqAnswer',
  overridesResolver: (props, styles) => [styles.answer]
})({
  fontSize: '11px',
  marginTop: '1px',
  marginBottom: '5px',
  whiteSpace: 'pre-line'
})

const transformFaqData = faqs => {
  return (faqs ?? []).map(faq => ({
    caption: faq.question,
    children: [
      {
        id: faq.trackingId,
        caption: faq.answer
      }
    ]
  }))
}

const Faq = ({ nightMode = false, openCallback, isOpen }) => {
  const isFetching = useSelector(getFetching())
  const faqs = useSelector(getFaqs())

  const formattedFaqs = transformFaqData(faqs)

  const { t } = useTranslation()

  return (
    <Box>
      <PromptList
        id="faq"
        title={t('faq')}
        icon={LiveHelpOutlined}
        items={formattedFaqs}
        customChildrenRender={item => (
          <FaqAnswer sx={{ color: nightMode ? '#fff' : '#5a5a5a' }}>
            {item.caption}
          </FaqAnswer>
        )}
        isFetching={isFetching}
        collapsibleContent
        nightMode={nightMode}
        wrapText
        manualOpenCallback={openCallback}
        isOpen={isOpen}
        containerStyles={{ '& h1': { fontSize: '16px !important' } }}
        dividers={false}
      />
      {!isFetching && faqs && faqs.length === 0 && (
        <Typography
          sx={{ color: nightMode ? '#fff' : 'inherit' }}
          align="center"
        >
          {'No frequently asked questions found'}
        </Typography>
      )}
    </Box>
  )
}

Faq.propTypes = {
  nightMode: PropTypes.bool,
  openCallback: PropTypes.func,
  isOpen: PropTypes.bool
}

export default Faq
