import React, { useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import NoReportsFoundView from './NoReportsFoundView'
import StoryRiverView from 'src/components/StoryRiverView'
import { actions } from 'src/features/myReports/MyReportsSlice'
import useViewProps from 'src/hooks/useViewProps'

const ProfileReportsView = props => {
  const title = 'Your FirstWord Reports'
  const dispatch = useDispatch()
  const {
    loading = null,
    data,
    error
  } = useSelector(state => state.myReportsReducer.reports, shallowEqual)

  const { handleViewMoreClick } = useViewProps()

  useEffect(() => {
    dispatch(actions.requestListReports({ size: 3 }))
  }, [])

  if (loading !== false) return

  if (data?.results && data.results.length > 0) {
    return (
      <StoryRiverView
        view={{ stories: data?.results }}
        viewsError={error}
        viewsLoading={loading}
        handleViewMoreClick={() => handleViewMoreClick('home', title)}
        title={title}
        viewMore={{
          to: '/river/your-reports',
          show: true,
          title: 'Of Your FirstWord Reports',
          handleMore: 'Your Reports'
        }}
        includeTags={false}
        imagesLength={true}
        showActionBar={true}
        actionButtonsConfig={{
          sharedButtonsConfig: {
            align: 'right'
          }
        }}
      />
    )
  }

  return <NoReportsFoundView {...props} />
}

export default ProfileReportsView
