import { createSlice } from '@reduxjs/toolkit'
import { stages } from './constants'

const initialState = {
  stage: stages.UNINITIALIZATED,
  notice: true
}

const bootstrapSlice = createSlice({
  name: 'bootstrapReducer',
  initialState,
  reducers: {
    requestBootstrap: state => {
      state.stage = stages.BOOTSTRAPPING
    },
    successBootstrap: state => {
      state.stage = stages.INITIALIZED
    },
    errorBootstrap: state => {
      state.stage = stages.FAILED
    },
    setNoticeVisibility: (state, { payload }) => {
      state.notice = payload
    }
  }
})

export const { reducer, actions } = bootstrapSlice

// Actions
export const {
  requestBootstrap,
  successBootstrap,
  errorBootstrap,
  setNoticeVisibility
} = actions

// Selectors
export const getStage = state => state.bootstrapReducer.stage

export default reducer
