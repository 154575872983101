import StoryRiverView from 'src/components/StoryRiverView'
import { useSelector } from 'react-redux'
import { getViewByName } from 'src/features/views/ViewsSelector'
import useViewProps from 'src/hooks/useViewProps'

const AllNews = () => {
  const title = 'All News'
  const view = useSelector(getViewByName('recent news')) || null
  const { viewsError, viewsLoading, handleViewMoreClick } = useViewProps()

  return (
    <StoryRiverView
      view={view}
      viewsError={viewsError}
      viewsLoading={viewsLoading}
      handleViewMoreClick={() => handleViewMoreClick('story', title)}
      title={title}
      viewMore={{
        to: '/river/recent-news',
        show: true,
        handleMore: 'All News'
      }}
      sidebar={true}
      showTeaser={false}
      tagsClickable={true}
    />
  )
}

export default AllNews
