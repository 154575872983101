import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Typography,
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Chip
} from '@mui/material'
import { Link } from 'react-router-dom'
import styles from './plusAction.module.sass'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import Header from './header'
import Logo from 'src/components/Logo'

import usePiwik from 'src/hooks/usePiwik.js'

const PlusActionPaywall = ({
  showRegistration,
  setShowUpgrade,
  isAnonymous,
  onClose,
  trackingKey,
  featureText,
  headerColor,
  showRegistrationButton,
  showCounter
}) => {
  const { handleTrackingWithArticleId } = usePiwik()

  return (
    <div className={styles.modalBackdrop}>
      <Card>
        <CardHeader
          className={styles.cardHeader}
          component={() => (
            <Header
              showRegistrationButton={showRegistrationButton}
              showCounter={showCounter}
              onClose={onClose}
              featureText={featureText}
              headerColor={headerColor}
            />
          )}
        />

        <CardContent>
          <Box
            sx={{
              maxHeight: 'calc(100vh - 200px)',
              overflowY: 'auto',
              padding: '16px',
              '@media (max-width: 700px)': {
                maxHeight: 'calc(100vh - 250px)'
              }
            }}
          >
            <Grid container>
              <Grid sm={1} />
              <Grid item xs={12} sm={7}>
                <Box className={styles.chipAndTitleContainer}>
                  <Logo className={styles.logo} />
                  <Chip
                    variant="outlined"
                    label="Exclusive Content"
                    sx={{
                      borderColor: '#007e67ff',
                      color: '#007e67ff',
                      borderRadius: '8px',
                      '@media (max-width: 680px)': {
                        borderRadius: '2px',
                        height: '15px',
                        marginTop: '5px'
                      }
                    }}
                  />
                </Box>
                <Typography
                  variant="h6"
                  component="h2"
                  className={styles.pharmaInsightsTitle}
                  styles={{ fontWeight: 'bold' }}
                >
                  Unlock exclusive insights into the pharmaceutical industry
                </Typography>
                <Box className={styles.pharmaInsightsList}>
                  <div className={styles.pharmaInsightsListItemContainer}>
                    <Typography
                      variant="body1"
                      className={styles.pharmaInsightsListItem}
                    >
                      <AddCircleIcon sx={{ marginTop: '2px' }} />
                      Customise your service by monitoring specific interests of
                      importance to you, including products, companies,
                      conditions, medical meetings, regulatory areas, markets,
                      and more.
                    </Typography>
                  </div>
                  <div className={styles.pharmaInsightsListItemContainer}>
                    <Typography
                      variant="body1"
                      className={styles.pharmaInsightsListItem}
                    >
                      <AddCircleIcon sx={{ marginTop: '2px' }} />
                      Unlimited access to timely, event-driven physician
                      intelligence on major news developments, issues, and
                      events.
                    </Typography>
                  </div>
                  <div className={styles.pharmaInsightsListItemContainer}>
                    <Typography
                      variant="body1"
                      className={styles.pharmaInsightsListItem}
                    >
                      <AddCircleIcon sx={{ marginTop: '2px' }} />
                      Unlimited access to archives of 20+ years of searchable
                      content.
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                {!isAnonymous && (
                  <Box className={styles.buttonsContainer}>
                    <Button
                      variant="contained"
                      size="large"
                      style={{
                        backgroundColor: '#ec8756ff',
                        color: 'white',
                        width: '100%',
                        alignItems: 'flex-end',
                        textTransform: 'none'
                      }}
                      className={styles.requestButton}
                      onClick={showRegistration}
                    >
                      Request a Free Trial
                    </Button>
                    <Button
                      variant="outlined"
                      size="large"
                      style={{
                        borderColor: '#ec8756ff',
                        color: '#ec8756ff',
                        width: '100%',
                        alignItems: 'flex-end',
                        marginBottom: '10px',
                        borderWidth: '2px',
                        textTransform: 'none'
                      }}
                      className={styles.upgradeButton}
                      onClick={setShowUpgrade}
                    >
                      Upgrade Now
                    </Button>
                    <Typography className={styles.contactText}>
                      If you are already a PLUS subscriber, <br />
                      <Link
                        to="/contact-us"
                        rel="noopener noreferrer"
                        className={styles.contactLink}
                        onClick={() => {
                          handleTrackingWithArticleId(
                            'paywall',
                            'button-click',
                            `${trackingKey}_contact-us`
                          )
                          onClose()
                        }}
                      >
                        contact us here
                      </Link>
                      .
                    </Typography>
                  </Box>
                )}

                {isAnonymous && (
                  <Box className={styles.buttonsContainer}>
                    <Button
                      variant="contained"
                      size="large"
                      style={{
                        backgroundColor: '#ec8756ff',
                        color: 'white',
                        width: '100%',
                        alignItems: 'flex-end',
                        textTransform: 'none'
                      }}
                      className={styles.requestButton}
                      onClick={showRegistration}
                    >
                      Request a Free Trial
                    </Button>
                    <Typography className={styles.contactText}>
                      Already have a FirstWord account? <br />
                      <Link
                        to="/sign-in"
                        rel="noopener noreferrer"
                        className={styles.contactLink}
                        onClick={() => {
                          handleTrackingWithArticleId(
                            'paywall',
                            'button-click',
                            `${trackingKey}_sign-in`
                          )
                          onClose()
                        }}
                      >
                        Sign in
                      </Link>
                    </Typography>
                  </Box>
                )}
              </Grid>

              <Grid item sm={1} />
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </div>
  )
}

PlusActionPaywall.propTypes = {
  showRegistration: PropTypes.func,
  setShowUpgrade: PropTypes.func,
  isAnonymous: PropTypes.bool,
  onClose: PropTypes.func,
  trackingKey: PropTypes.string,
  featureText: PropTypes.string,
  headerColor: PropTypes.string,
  showRegistrationButton: PropTypes.bool,
  showCounter: PropTypes.bool
}

export default PlusActionPaywall
