import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Tooltip from '../../../../lib/ui/Tooltip'
import { renderToStaticMarkup } from 'react-dom/server'
import styles from './FieldTooltip.module.sass'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

const FieldTooltip = props => {
  const [visible, setVisible] = useState(false)
  const hideTooltip = () => setVisible(false)
  const showTooltip = () => setVisible(true)

  useEffect(() => {
    window.addEventListener('scroll', hideTooltip)
    return () => {
      window.removeEventListener('scroll', hideTooltip)
    }
  }, [])

  return (
    <div
      className={cx(styles.tip, {
        visible
      })}
    >
      <Tooltip
        {...props}
        tip={renderToStaticMarkup(
          <Fragment>
            <a href="/contact-us/subscriber" className={styles.link}>
              Contact us
            </a>
            {props?.field &&
              props.field !== '' &&
              ` to change your ${props.field}`}
          </Fragment>
        )}
        placement="bottom"
        delayHide={100} // Delay hide to permit slight mouseout from tooltip -- aids cursor movement to tooltip link
        clickable
        afterShow={showTooltip}
        afterHide={hideTooltip}
      />
    </div>
  )
}

FieldTooltip.propTypes = {
  field: PropTypes.string
}

export default FieldTooltip
