import EnhancedBillboard from 'src/assets/images/marketing-specs-page/Enhanced-Billboard.png'
import AdvertorialNewsletter from 'src/assets/images/marketing-specs-page/Advertorial-Newsletter.png'
import AdvertorialWebsite from 'src/assets/images/marketing-specs-page/Advertorial-Website.png'
import CustomEBlastThumbnail from 'src/assets/images/marketing-specs-page/Custom-eBlast-Thumbnail.png'
import DisplayAdsThumbnail from 'src/assets/images/marketing-specs-page/Display-Ads-Thumbnail.png'
import EventSponsorThumbnail from 'src/assets/images/marketing-specs-page/Event-Sponsor-Thumbnail.png'
import Leaderboard from 'src/assets/images/marketing-specs-page/Leaderboard.png'
import NewsletterBanner from 'src/assets/images/marketing-specs-page/Newsletter-Banner.png'
import NewslettersThumbnail from 'src/assets/images/marketing-specs-page/Newsletters-Thumbnail.png'
import SponsoredHeadline from 'src/assets/images/marketing-specs-page/Sponsored-Headline.png'
import MediumRectangle from 'src/assets/images/marketing-specs-page/Medium-Rectangle.png'

export default {
  Enhanced: EnhancedBillboard,
  AdvertorialNewsletter: AdvertorialNewsletter,
  AdvertorialWebsite: AdvertorialWebsite,
  CustomEBlast: CustomEBlastThumbnail,
  DisplayAds: DisplayAdsThumbnail,
  EventSponsor: EventSponsorThumbnail,
  Leaderboard: Leaderboard,
  NewsletterBanner: NewsletterBanner,
  Newsletters: NewslettersThumbnail,
  Sponsored: SponsoredHeadline,
  MediumRectangle: MediumRectangle
}
