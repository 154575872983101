import { all, call, put, takeEvery, select } from 'redux-saga/effects'
import { actions as liveFeedActions } from './LiveFeedSlice'

function* liveFeedRequest(services) {
  const LiveFeedRepository = services('LiveFeedRepository')
  const liveFeedTags = yield select(state => state.liveFeedReducer.liveFeedTags)
  try {
    const response = yield call(
      [LiveFeedRepository, 'getLiveFeed'],
      liveFeedTags
    )
    if (response.status === 200) {
      yield put(
        liveFeedActions.requestLiveFeedSuccess(response['data']['data'])
      )
    } else {
      yield put(liveFeedActions.requestLiveFeedFailed())
    }
  } catch (error) {
    yield put(liveFeedActions.requestLiveFeedFailed())
  }
}

export default function* watchLiveFeedRequest(services) {
  yield all([
    takeEvery(liveFeedActions.requestLiveFeed, liveFeedRequest, services)
  ])
}
