import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import AddIcon from '@mui/icons-material/Add'
import PredeterminatedListFilters from './PredeterminatedListFilters'
import styles from './FilterInput.module.sass'
import cx from 'classnames'
import StorySpinner from 'src/components/StorySpinner'
import { actions } from 'src/features/river/RiverSlice'

const FreeInputText = ({
  options = [],
  index = '',
  handleOptionSelect = () => {},
  type = '',
  filterCategory = '',
  onTagSearch = () => () => {},
  isLoadingTags = false,
  riverId
}) => {
  const dispatch = useDispatch()
  const [filterOptions, setFilterOptions] = useState([])
  const [found, setFound] = useState(true)
  const [inputSearchValue, setInputSearchValue] = useState('')

  const cleanTagOptions = category => {
    dispatch(actions.cleanTagsSearch({ category, riverId }))
  }

  useEffect(() => {
    return () => {
      cleanTagOptions(index)
    }
  }, [])

  useEffect(() => {
    setFilterOptions(options)
  }, [options])

  useEffect(() => {
    const getSelected = filterOptions.filter(opt => opt.selected)

    if (inputSearchValue.length < 3) {
      setFound(true)
    }

    if (inputSearchValue.length >= 3 && filterOptions.length <= 0) {
      setFound(false)
    }
    if (
      inputSearchValue.length >= 3 &&
      filterOptions.length === getSelected.length
    ) {
      setFound(false)
    }

    if (
      filterOptions.length >= 1 &&
      filterOptions.length !== getSelected.length
    ) {
      setFound(true)
    }
  }, [inputSearchValue, filterOptions])

  const handleInputChange = (value = '') => {
    setInputSearchValue(value)

    if (value.length >= 3) {
      onTagSearch(value, index)()
      setFilterOptions(options)
    }
    if (inputSearchValue.length < 3) {
      cleanTagOptions(index)
    }
  }

  const handleNewOptionSelect = searchValue => {
    setFound(true)
    const newOption = {}

    newOption.value = searchValue?.trim() ?? ''
    newOption.selected = false
    newOption.field = index
    handleOptionSelect(newOption)
  }

  const siteStyles = type?.toLowerCase() ?? ''

  return (
    <div>
      <div className={styles.inputContainer}>
        {!found && !isLoadingTags && (
          <div className={styles.addIconContainer}>
            <AddIcon
              onClick={() => {
                handleNewOptionSelect(inputSearchValue)
              }}
              fontSize="small"
            />
          </div>
        )}
        <input
          className={cx(
            styles.input,
            siteStyles === 'fwreports'
              ? styles.inputSearchReports
              : styles.inputSearch
          )}
          type="text"
          value={inputSearchValue}
          onChange={e => {
            handleInputChange(e.target?.value)
          }}
          placeholder={`Search ${filterCategory}`}
        />
      </div>
      {filterOptions.length > 0 && (
        <div className={siteStyles === 'fwreports' ? styles.floatingList : ''}>
          {isLoadingTags ? (
            <StorySpinner />
          ) : (
            <PredeterminatedListFilters
              options={filterOptions}
              index={index}
              handleOptionSelect={handleOptionSelect}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default FreeInputText
