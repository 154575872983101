import React from 'react'
import { Link } from 'react-router-dom'
import styles from 'src/styles/common.module.sass'
import Typography from 'src/lib/ui/Typography'
import myReportViewStyles from '../YourReportsView.module.sass'
import PropTypes from 'prop-types'
import StoryRiver from 'src/components/StoryRiver'

const AnonReportsView = () => {
  return (
    <StoryRiver title="Your FirstWord Reports" titleType="title">
      <div className={myReportViewStyles.description}>
        <Typography variant="body1">
          To view the FirstWord Reports you have access to,{' '}
          <Link to="/sign-in" className={styles.activeLink}>
            sign in
          </Link>{' '}
          or{' '}
          <Link to="/register" className={styles.activeLink}>
            register
          </Link>
          .
        </Typography>
      </div>
    </StoryRiver>
  )
}

AnonReportsView.propTypes = {
  title: PropTypes.string
}

export default AnonReportsView
