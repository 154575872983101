import { createSlice } from '@reduxjs/toolkit'

const loginSlice = createSlice({
  name: 'loginReducer',
  initialState: {},
  reducers: {
    requestPasswordlessLogin: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'request',
          asyncKey: 'passwordlessLogin'
        }
      }),
      reducer: () => {}
    },
    successPasswordlessLogin: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'success',
          asyncKey: 'passwordlessLogin'
        }
      }),
      reducer: () => {}
    },
    errorPasswordlessLogin: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'error',
          asyncKey: 'passwordlessLogin'
        }
      }),
      reducer: () => {}
    }
  }
})

export const { actions, reducer } = loginSlice

export const {
  requestPasswordlessLogin,
  successPasswordlessLogin,
  errorPasswordlessLogin
} = actions

export default reducer
