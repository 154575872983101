import SvgIcon from '@mui/material/SvgIcon'

const MoreIcon = props => (
  <SvgIcon viewBox="0 0 17 17" {...props}>
    <defs>
      <path id="1uyaupc5aa" d="M0 0h11v11H0z" />
    </defs>
    <g transform="rotate(45 3.379 10.621)" fill="none" fillRule="evenodd">
      <mask id="3qju0ngc8b" fill="#fff">
        <use xlinkHref="#1uyaupc5aa" />
      </mask>
      <path
        d="m9.207 2.5-6 6m0-6 6 6"
        stroke="#4E5BA6"
        strokeWidth="1.667"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0,0"
        mask="url(#3qju0ngc8b)"
      />
    </g>
  </SvgIcon>
)
export default MoreIcon
