import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { includes, lowerCase } from 'lodash'

import { Box, Button, styled, IconButton } from '@mui/material'
import Typography from 'src/lib/ui/Typography'
import MinusIcon from '../../static/icons/minus'
import MoreIcon from '../../static/icons/more'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import AddCircleIcon from '@mui/icons-material/AddCircle'

/**
 * Tags Display Container
 * theme path: TagsDisplay.styleOverrides.container
 */
const TagsDisplayContainer = styled(Box, {
  name: 'TagsDisplay',
  label: 'tagsDisplayContainer',
  overridesResolver: (props, styles) => [styles.container]
})(({ theme }) => ({
  '& .tags-display-text': {
    lineHeight: 1.5,
    fontSize: '12px',
    color: theme.palette?.tagsDisplay?.text || '#fff',
    fontWeight: 500,
    width: '100%',
    margin: '10px 0px',
    textAlign: 'center',
    '@media all and (max-width: 820px)': {
      color: theme.palette?.tagsDisplay?.textMobile || '#363f72'
    }
  }
}))

/**
 * Tags Section Container
 * theme path: TagsDisplay.styleOverrides.tagsContainer
 */
const TagsContainer = styled(Box, {
  name: 'TagsDisplay',
  label: 'tagsDisplayTagsContainer',
  overridesResolver: (props, styles) => [styles.tagsContainer]
})({
  display: 'flex',
  flexDirection: 'column',
  margin: '3px 0px 3px',
  '> div': {
    display: 'flex',
    flexWrap: 'wrap'
  }
})

/**
 * Tag Button Container
 * theme path: TagsDisplay.styleOverrides.tagButtonContainer
 */
const TagButtonContainer = styled(Box, {
  name: 'TagsDisplay',
  label: 'tagsButtonContainer',
  overridesResolver: (props, styles) => [styles.tagButtonContainer]
})(() => ({
  display: 'contents'
}))

/**
 * Tag Button
 * theme path: TagsDisplay.styleOverrides.tagButton
 */
const TagButton = styled(Button, {
  name: 'TagsDisplay',
  label: 'tagsDisplayButton',
  overridesResolver: (props, styles) => [styles.tagButton],
  shouldForwardProp: prop => prop !== 'isSelected'
})(({ theme, isSelected }) => ({
  borderRadius: '4px',
  border: `solid 1px ${theme.palette?.tagsDisplay?.buttonBorder || '#e27746'}`,
  margin: '4px 4px',
  minWidth: 'auto',
  fontSize: '12px',
  color: isSelected
    ? theme.palette?.tagsDisplay?.buttonTextSelected || '#fff'
    : theme.palette?.tagsDisplay?.buttonText || '#e27746',
  backgroundColor: isSelected
    ? theme.palette?.tagsDisplay?.buttonBackgroundSelected || '#e27746'
    : theme.palette?.tagsDisplay?.buttonBackground || 'transparent',
  padding: '4px 7px 3px',
  lineHeight: '1.5',
  fontWeight: '500',
  maxWidth: '98%',
  '&:hover': {
    border: `solid 1px ${
      isSelected
        ? '#b3b8db'
        : theme.palette?.tagsDisplay?.buttonHoverBorder || '#e27746'
    }`,
    backgroundColor: isSelected
      ? theme.palette?.tagsDisplay?.buttonBackgroundSelected || '#e27746'
      : theme.palette?.tagsDisplay?.buttonHoverBackground || ' #e2774620'
  },
  '@media all and (max-width: 820px)': {
    color: theme.palette?.tagsDisplay?.buttonTextMobile || '#e27746',
    border: `solid 1px ${
      theme.palette?.tagsDisplay?.buttonBorderMobile || '#e27746'
    }`
  }
}))

/**
 * Tag Display Label
 * theme path: TagsDisplay.styleOverrides.tagLabel
 */
const TagDisplayLabel = styled(Box, {
  name: 'TagsDisplay',
  label: 'tagsDisplayLabel',
  overridesResolver: (props, styles) => [styles.tagLabel]
})(() => ({
  '@media all and (max-width: 820px)': {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}))

/**
 * Tags Elements Container
 * theme path: TagsDisplay.styleOverrides.tagsElementsContainer
 */
const TagsElementsContainer = styled(Box, {
  name: 'TagsDisplay',
  label: 'tagsDisplayLabel',
  overridesResolver: (props, styles) => [styles.tagsElementsContainer],
  shouldForwardProp: prop => !['isResponsive', 'scrollHeight'].includes(prop)
})(({ isResponsive, scrollHeight }) => ({
  maxHeight: isResponsive && '105px',
  overflow: 'hidden',
  transition: 'max-height 0.15s ease-out',
  '&.isOpen': {
    maxHeight: `${scrollHeight}px !important`,
    transition: 'max-height 0.25s ease-in'
  },
  '@media all and (min-width: 821px)': {
    maxHeight: 'unset'
  }
}))

/**
 * Tags Show More Button
 * theme path: TagsDisplay.styleOverrides.showMoreButton
 */
const ShowMoreButton = styled(Box, {
  name: 'TagsDisplay',
  label: 'showMoreButton',
  overridesResolver: (props, styles) => [styles.showMoreButton]
})(() => ({
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'flex-end',
  color: '#4e5ba6',
  height: '20px',
  textDecoration: 'underline',
  alignItems: 'center',
  marginTop: '4px',
  '> p': { fontSize: '14px' }
}))

const TagsSection = ({
  url,
  urlIdx,
  hideLabels,
  handleButtonClick,
  accessToken,
  interactionId,
  mtmSource,
  selectedTag,
  displayShowmore
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [overflowActive, setOverflowActive] = useState(false)
  const tagsContainer = useRef(null)
  const { section, elements } = url
  let errorKey = includes(['conditions', 'maladies'], lowerCase(section))
    ? 'chatNoConditionFound'
    : 'chatNoMedicationsFound'

  useEffect(() => {
    const checkOverflowY = () => {
      if (tagsContainer.current) {
        setOverflowActive(
          tagsContainer.current.scrollHeight >
            tagsContainer.current.clientHeight
        )
      }
    }

    checkOverflowY()
    window.addEventListener('resize', checkOverflowY)
    return () => {
      window.removeEventListener('resize', checkOverflowY)
    }
  }, [])

  return (
    <TagsContainer key={section}>
      <TagsElementsContainer
        ref={tagsContainer}
        className={isOpen && 'isOpen'}
        isResponsive={displayShowmore}
        scrollHeight={tagsContainer?.current?.scrollHeight}
        data-testid="tagsContainer"
      >
        {elements.length > 0
          ? elements.map((element, idx) => {
              const { name, refUrl = '', count } = element
              const countLabel = count && count > 1 ? ` (${count})` : ''

              const link = refUrl
                .replace(/{%access_token%}/gi, accessToken)
                .replace(/{%interactionId%}/gi, interactionId)
                .replace(/{%mtm_source%}/gi, mtmSource)

              return (
                <TagButtonContainer key={name}>
                  <TagButton
                    data-url={link}
                    type="button"
                    isSelected={selectedTag === name}
                    onClick={e => {
                      handleButtonClick({
                        url: link,
                        urlIdx,
                        tracking: { name },
                        rawPos: idx + 1,
                        element,
                        onClickEvent: e
                      })
                    }}
                  >
                    <TagDisplayLabel>{name}</TagDisplayLabel>
                    <Box sx={{ marginLeft: '5px' }}>{countLabel}</Box>
                    {/* We'll add this plus functionality later
                    {selectedTag === name ? (
                      <AddCircleOutlineIcon
                        sx={{ height: '18px', fill: '#fff' }}
                      />
                    ) : (
                      <AddCircleIcon sx={{ height: '18px', fill: '#007e6f' }} />
                    )} */}
                  </TagButton>
                </TagButtonContainer>
              )
            })
          : !hideLabels && (
              <Typography
                className="tags-display-text"
                translationKey={errorKey}
              />
            )}
      </TagsElementsContainer>
      {displayShowmore && overflowActive && (
        <ShowMoreButton
          data-testid="showmore"
          onClick={() => setIsOpen(!isOpen)}
        >
          <Typography
            data-testid="showmoreText"
            translationKey={isOpen ? 'viewLess' : 'viewMore'}
          />
          <IconButton size="small" sx={{ padding: '5px 0' }}>
            {isOpen ? (
              <MinusIcon sx={{ width: '15px', height: '12px' }} />
            ) : (
              <MoreIcon sx={{ width: '17px', height: '17px' }} />
            )}
          </IconButton>
        </ShowMoreButton>
      )}
    </TagsContainer>
  )
}

TagsSection.propTypes = {
  url: PropTypes.object,
  urlIdx: PropTypes.number,
  hideLabels: PropTypes.bool,
  handleButtonClick: PropTypes.func,
  accessToken: PropTypes.string,
  interactionId: PropTypes.string,
  mtmSource: PropTypes.string,
  selectedTag: PropTypes.string,
  displayShowmore: PropTypes.bool
}

const TagsDisplay = ({
  urls = [],
  hideLabels = false,
  accessToken = '',
  interactionId = '',
  mtmSource = '',
  tags = [],
  onTagClick,
  selectedTag,
  displayShowmore = false
}) => {
  const handleUrlClick = ({
    url,
    urlIdx,
    tracking,
    rawPos,
    element,
    onClickEvent
  }) => {
    const tagDetails = tags.filter(e => {
      if (e.tagNameLanguageUsed && e.tagNameLanguageUsed !== 'None') {
        return e.tagNameLanguageUsed === tracking.name
      } else {
        return e.tagName === tracking.name
      }
    })[0]
    const { tagId, tagName, tagType } = tagDetails || {}

    // Check if it is the second section
    // If it is add the number of previous section tags
    const pos = urlIdx > 0 ? rawPos + urls[0].elements.length : rawPos
    if (onTagClick)
      onTagClick({
        destURL: url,
        btnId: `${tracking.name}`,
        pos,
        tagId,
        tagName,
        tagType,
        urls,
        element,
        onClickEvent
      })
  }
  return (
    <TagsDisplayContainer>
      {urls.length > 0
        ? urls.map((url, idx) => (
            <div key={url.section}>
              <TagsSection
                url={url}
                urlIdx={idx}
                hideLabels={hideLabels}
                handleButtonClick={handleUrlClick}
                accessToken={accessToken}
                interactionId={interactionId}
                mtmSource={mtmSource}
                selectedTag={selectedTag}
                displayShowmore={displayShowmore}
              />
            </div>
          ))
        : !hideLabels && (
            <Typography
              className="tags-display-text"
              translationKey="chatNoTagsFound"
            />
          )}
    </TagsDisplayContainer>
  )
}

TagsDisplay.propTypes = {
  /**
   * Tags Parsed in sections ready to display
   */
  urls: PropTypes.array,
  /**
   * Hide Labels when a category has no elements
   */
  hideLabels: PropTypes.bool,
  /**
   * User Access Token
   */
  accessToken: PropTypes.string,
  /**
   * User Interaction Id
   */
  interactionId: PropTypes.string,
  /**
   * Matomo Source for tag link
   */
  mtmSource: PropTypes.string,
  /**
   * Tags related to the urls in order to complement urls information
   */
  tags: PropTypes.array,
  /**
   * Function executed on url/tag clicked
   */
  onTagClick: PropTypes.func,
  /**
   * Selected Tag when Consulted Sources modal is open
   */
  selectedTag: PropTypes.string,
  /**
   * Display show more button
   */
  displayShowmore: PropTypes.bool
}

export default TagsDisplay
