import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Typography from 'src/lib/ui/Typography'
import styles from 'src/features/static/Static.module.sass'
import { getConfig } from 'src/features/config/ConfigSelector'
import ReactHtmlParser from 'react-html-parser'
import BasicLoader from 'src/lib/ui/BasicLoader/BasicLoader'

const LoginEmailSent = ({ parentClassName = '' }) => {
  const [loading, setLoading] = useState(true)
  const webpageContent = useSelector(getConfig('webpage_content')) || {}
  const loginConfig = webpageContent['sign-in'] || {}
  const { emailSent = {} } = loginConfig

  const {
    header = 'Email Sent',
    content = [
      "We've sent you an email. Please click the link within the email to sign in to your account. If you haven’t received the email, please check your spam and promotions folders.",
      "If you do not have a FirstWord account, please <a href='/register'>register</a> to set up your account and begin receiving emails."
    ]
  } = emailSent

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false)
    }, 4500)

    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false)
    }, 3500)

    return () => clearTimeout(timer)
  }, [])

  return (
    <div className={parentClassName}>
      {loading ? (
        <BasicLoader />
      ) : (
        <div className={parentClassName}>
          <Typography type="title" className={styles.title} paragraph>
            {header}
          </Typography>
          {content?.map((item, index) => (
            <Typography
              key={`login-email-sent-description-${index}`}
              type="body1"
              className={styles.paragraph}
              paragraph
            >
              {ReactHtmlParser(item)}
            </Typography>
          ))}
        </div>
      )}
    </div>
  )
}

LoginEmailSent.propTypes = {
  parentClassName: PropTypes.string
}

export default LoginEmailSent
