import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { actions } from './CatalogsSlice'

const withCatalogs =
  (catalogs = {}) =>
  WrappedComponent => {
    class ComponentWithCatalogs extends Component {
      componentDidMount() {
        // Initialize catalogs
        this.props.registerCatalogs(catalogs)
        this.props.requestCatalogs(catalogs)
      }

      componentWillUnmount() {
        // Clean up
        this.props.cleanCatalogs(catalogs)
      }

      render() {
        const { catalogs } = this.props
        return <WrappedComponent {...this.props} catalogs={catalogs} />
      }
    }

    const mapStateToProps = state => ({
      catalogs: Object.keys(catalogs).reduce(
        (acc, key) => ({
          ...acc,
          [key]: state.catalogsReducer[key]
        }),
        {}
      )
    })

    const mapDispatchToProps = dispatch => ({
      registerCatalogs: catalogs =>
        dispatch(actions.registerCatalogs(catalogs)),
      requestCatalogs: catalogs => dispatch(actions.requestCatalogs(catalogs)),
      cleanCatalogs: () => dispatch(actions.cleanCatalogs())
    })

    ComponentWithCatalogs.propTypes = {
      registerCatalogs: PropTypes.func,
      requestCatalogs: PropTypes.func,
      cleanCatalogs: PropTypes.func,
      catalogs: PropTypes.object
    }

    return connect(mapStateToProps, mapDispatchToProps)(ComponentWithCatalogs)
  }

export default withCatalogs
