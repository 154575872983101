import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import StoryRiverView from 'src/components/StoryRiverView'
import { actions as storyActions } from 'src/features/singleStory/SingleStorySlice'
import * as storySelectors from 'src/features/singleStory/SingleStorySelector'
import NoTherapyTrendsFound from './NoTherapyTrendsFound'
import { getStory } from 'src/features/singleStory/SingleStorySelector'

const ReportRelatedUpdates = () => {
  const dispatch = useDispatch()
  const story = useSelector(getStory())

  const {
    data = {},
    loading = false,
    error = null
  } = useSelector(storySelectors.getRelatedUpdates())

  const { articles = [], criteria = null } = data

  const getTitle = () => {
    if (story?.reportSettings?.reportBrand === 'Therapy Trends') {
      return 'Related Updates'
    }

    if (criteria !== 'latestArticles') {
      return 'Related Reports'
    }

    return 'More FirstWord Reports'
  }

  const view = {
    stories: articles
  }

  useEffect(() => {
    if (story?.id) {
      dispatch(storyActions.requestRelatedUpdates(story))
    }
  }, [story?.id])

  if (!story?.id) return null

  if (criteria === 'therapyTrendsNotFound') {
    return <NoTherapyTrendsFound />
  }

  return (
    <StoryRiverView
      view={view}
      viewsError={error}
      viewsLoading={loading}
      showTitleOnly={true}
      title={getTitle()}
      sidebar={true}
      viewMore={{
        show: false
      }}
      showTeaser={false}
      includeTags={false}
      showPublished={false}
      tagsClickable={true}
    />
  )
}

ReportRelatedUpdates.propTypes = {
  story: PropTypes.object
}

export default ReportRelatedUpdates
