import FeaturesAndBenefits from './components/FeaturesAndBenefits'
import PrivacyPolicyPage from './components/PrivacyPolicyPage'
import CookiePolicyPage from './components/CookiePolicyPage'
import LoginEmailSentPage from './components/LoginEmailSentPage'
import RegisterEmailSentPage from './components/RegisterEmailSentPage'
import DeviceLimitPage from './components/DeviceLimitPage'
import NotFoundPage from './components/NotFoundPage'
import DeviceConfirmationSentPage from './components/DeviceConfirmationSentPage'
import FirstAnswersPage from './components/FirstAnswersPage'
import MarketingSpecsPage from './components/MarketingSpecsPage'
import ConferenceRoomsToZoomsPage from './components/ConferenceRoomsToZoomsPage'
import SEEAUKPrivacy from './components/SEEAUKPrivacy'
import CCPAPrivacyPolicy from './components/CCPAPrivacyPolicy'

const staticPages = {
  privacy: PrivacyPolicyPage,
  cookies: CookiePolicyPage,
  loginEmailSent: LoginEmailSentPage,
  registerEmailSent: RegisterEmailSentPage,
  deviceLimit: DeviceLimitPage,
  featuresAndBenefits: FeaturesAndBenefits,
  notFound: NotFoundPage,
  deviceConfirmationSent: DeviceConfirmationSentPage,
  firstAnswers: FirstAnswersPage,
  marketingSpecs: MarketingSpecsPage,
  conferenceRoomsToZooms: ConferenceRoomsToZoomsPage,
  SEEAUKPrivacy: SEEAUKPrivacy,
  CCPAPrivacyPolicy: CCPAPrivacyPolicy
}

const StaticPage = (pageKey, others) => props =>
  staticPages[pageKey]({ others, ...props })

export default StaticPage
