import React, { Fragment } from 'react'
import RoutedLink from 'src/components/RoutedLink'
import { push } from 'connected-react-router'
import { TOKEN_EXPIRED } from 'src/services/FW5ML/errorCodes.js'
import { TOKEN_TYPE_FULL } from './tokenTypes'
import { notificationsEnqueue } from 'src/features/notifications/NotificationsSlice'
import { requestBootstrap } from 'src/features/bootstrap/BootstrapSlice'
import whitelistSessionUrls from 'src/services/FW5ML/constants/whitelistSessionUrls'

const SessionInterceptors = (Session, Piwik, dispatch) => ({
  killSessionIfTokenExpired: async error => {
    console.log({ errorSession: error })
    if (error.response && error.response.status === 401) {
      const { error: responseError = {} } = error.response.data
      const { code: errorCode } = responseError
      const { url } = error.response.config
      const tokenType = await Session.tokenTypeLoad()
      if (errorCode === TOKEN_EXPIRED && tokenType === TOKEN_TYPE_FULL) {
        //Is an email login, nl login or register confirmation, do not destroy the session yet
        if (!whitelistSessionUrls.includes(url)) {
          await Session.destroySession()
          dispatch(push('/sign-in'))
          dispatch(requestBootstrap())
          dispatch(
            notificationsEnqueue({
              message: (
                <Fragment>
                  Your link to sign in to the site expired. Please request a new
                  one by <RoutedLink label="clicking here" to="/sign-in" />.
                </Fragment>
              )
            })
          )
          dispatch(
            Piwik.track(
              'notification',
              'error',
              'user-session-killed-due-to-token-expiry'
            )
          )
        }
      }
    }
    throw error
  }
})

export default SessionInterceptors
