import React from 'react'
import { useDispatch } from 'react-redux'
import Button from 'src/lib/ui/Buttons/Button'
import { Input } from 'src/lib/ui/Input'
import styles from './SearchForm.module.sass'
import { Field, reduxForm } from 'redux-form'
import { actions } from 'src/features/river/RiverSlice'
import validate from './validate'

const SearchForm = ({ handleSubmit }) => {
  const dispatch = useDispatch()

  const handleFormSubmit = values => {
    validate(values)
    if (values?.query) {
      dispatch(actions.redirectSearch(values.query))
    }
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <p className={styles.start}>Start a new search:</p>
      <div className={styles.search}>
        <Field
          component={Input}
          name="query"
          className={styles.input}
          errorAlign="center"
        />
        <Button
          type="submit"
          textCase="none"
          tier="secondary"
          className={styles.button}
        >
          Search
        </Button>
      </div>
    </form>
  )
}

export default reduxForm({
  form: 'search',
  destroyOnUnmount: false,
  enableReinitialize: false,
  touchOnBlur: false,
  initialValues: { query: '' },
  validate
})(SearchForm)
