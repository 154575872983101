import { get as getObjectProperty } from 'lodash'
import siteConfig from 'src/config/fw-ai/site.config.json'

/**
 * Exposes siteConfig import & export a get function based on lodash.get
 * @type {{}}
 */
const useConfig = () => {
  const getSiteConfig = (path, defaultValue) => {
    return getObjectProperty(siteConfig, path, defaultValue)
  }

  return {
    config: siteConfig,
    getSiteConfig
  }
}

export default useConfig
