import React from 'react'
import styles from './ReportRelatedUpdates.module.sass'
import SectionHeading from 'src/components/SectionHeading'

const NoTherapyTrendsFound = () => {
  return (
    <div>
      <SectionHeading title="Related Updates" />

      <p className={styles.body}>
        There are currently no related updates for this report. To be notified
        when new updates are available, please click the "Notify Me" button
        below.
      </p>
    </div>
  )
}

export default NoTherapyTrendsFound
